import { useState } from "react"
import { withLoadablePageWithParams } from "../../../base/Page"
import Api from "../../../session/Api"
import { FormDialog } from "../../../templates/form/form-dialog"
import { ShippingMasterFormFields } from "./form-fields"
import Button from "../../../components/Button"
import { SHIPMENT_MASTER_STATUS, TRANSPORTATION_LEG_STATUS, shipmentMasterStatusLabel, transportationStatusHeaderStyles, transportationStatusLabel } from "."
import UIUtil from "../../../util/UIUtil"
import { ChartRelationship32, Edit16, Document16, Play32, Analytics16, DeliveryParcel24, Analytics24, Close16, ChartBullet24, ArrowLeft16, ArrowDown16, OverflowMenuHorizontal16, ErrorFilled32, CheckmarkFilled32, StopSignFilled16, Hourglass16, ArrowRight16, CheckmarkFilled16, Number_116, Number_216, Number_316 } from '@carbon/icons-react'
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view"
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_SHIPMENT_MASTER, getObjectTypeName, getObjectTypeUrl } from "../../../constants/ObjectTypes"
import Util from "../../../util/Util"
import { Tag } from "carbon-components-react"
import { Link } from "react-router-dom"
import { UploadField } from "../../../components/upload/upload-field"
import { pdf } from "@react-pdf/renderer"
import print from 'print-js';
import { createBriefManifest } from "../shipment/item-page/pdf/brief-manifest"
import { createDetailedManifest } from "../shipment/item-page/pdf/detailed-manifest"
import { SidePanel } from "../../../templates/draft/components/side-panel"
import { MasterProfit } from "./views/master-profit"

const useItem = (defaultItem) => {
    const [master, setMaster] = useState(defaultItem)

    const updateTransportationLegStatus = (id, status, onDone) => {
        const startDate = item => {
            if (status === TRANSPORTATION_LEG_STATUS.STATUS_STARTED) {
                return new Date().getTime();
            } else if (status === TRANSPORTATION_LEG_STATUS.STATUS_NOT_STARTED) {
                return 0;
            } else {
                return item.actualStartDate
            }
        }
        const endDate = item => {
            if (status === TRANSPORTATION_LEG_STATUS.STATUS_FINISHED) {
                return new Date().getTime();
            } else {
                return 0;
            }
        }

        const update = {
            ...master,
            transportationLegs: master.transportationLegs.map(item => item.id === id ? ({
                ...item, status,
                actualStartDate: startDate(item),
                actualEndDate: endDate(item),
            }) : item)
        }

        Api.updateShipmentMaster(update, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                setMaster(response.payload)
            } else {
                UIUtil.showError(response.message);
            }
            onDone();
        })
    }

    return {
        master, setMaster, updateTransportationLegStatus
    }
}

const EditDialog = ({ visible, onClose, item, setItem, endpoint }) => (
    <FormDialog
        size="lg"
        item={item} setItem={setItem} label="Shipment Master" title="Editing"
        saveApi={(data, listener) => Api.updateShipmentMaster(data, listener)}
        open={visible} onClose={onClose} payload={endpoint}
    >
        {ShippingMasterFormFields}
    </FormDialog>
)

const TransportationLegItem = ({ index, item, updateTransportationLegStatus, isFinal, allowClear, allowStart, allowFinish }) => {
    const [loading, setLoading] = useState(false);
    const update = (status) => {
        setLoading(true);
        updateTransportationLegStatus(item.id, status, () => setLoading(false))
    }
    const hasBtns = allowStart || allowClear || allowFinish;
    return (
        <div style={{ marginBottom: '1rem', display: 'flex', }}>
            <div style={{ flex: 1, padding: '1rem', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', borderRadius: 10, display: 'flex', alignItems: 'center', gap: '0.25rem', ...transportationStatusHeaderStyles(item.status) }}>
                    <StopSignFilled16 />
                    <p style={{ fontSize: 18, fontWeight: 'bolder' }}>Leg No {index + 1}</p>
                    <p style={{ fontSize: 14, marginLeft: '1rem' }}>{item.departurePortName} <ArrowRight16 style={{ marginInline: "0.25rem" }} /> {item.arrivalPortName}</p>

                    <div style={{ flex: '1' }} />

                    {item.status === TRANSPORTATION_LEG_STATUS.STATUS_NOT_STARTED &&
                        <Tag renderIcon={OverflowMenuHorizontal16} type="high-contrast">{transportationStatusLabel(item.status)}</Tag>}
                    {item.status === TRANSPORTATION_LEG_STATUS.STATUS_STARTED &&
                        <Tag renderIcon={Hourglass16} type="blue">{transportationStatusLabel(item.status)}</Tag>}
                    {item.status === TRANSPORTATION_LEG_STATUS.STATUS_FINISHED &&
                        <Tag renderIcon={CheckmarkFilled16} type="green">{transportationStatusLabel(item.status)}</Tag>}

                    {/* <Tag type="high-contrast">{transportationStatusLabel(item.status)}</Tag> */}
                    {/* 006616 finalie */}
                    {/* f4f4f4 */}
                </div>

                <div style={{ display: 'flex' }}>
                    <ValueField style={{ flex: 1 }} title="Vessel Name">{item.vesselName}</ValueField>
                    <ValueField style={{ flex: 1 }} title="Voyage No">{item.voyageNo}</ValueField>
                    {/* <ValueField style={{ flex: 1 }} title="Yard">{item.yardName}</ValueField> */}
                </div>

                <div style={{ display: 'flex' }}>
                    <ValueField style={{ flex: 1 }} title="Scheduled start">{Util.getFullDate(item.scheduledStartDate)}</ValueField>
                    <ValueField style={{ flex: 1 }} title="Scheduled end">{Util.getFullDate(item.scheduledEndDate)}</ValueField>
                    {/* <div style={{ flex: 1 }} /> */}
                </div>
                <div style={{ display: 'flex' }}>
                    <ValueField style={{ flex: 1 }} title="Actual start">{Util.getFullDate(item.actualStartDate)}</ValueField>
                    <ValueField style={{ flex: 1 }} title="Actual end">{Util.getFullDate(item.actualEndDate)}</ValueField>
                    {/* <div style={{ flex: 1 }} /> */}
                </div>
            </div>

            <div style={{ width: 150, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem', flexDirection: 'column' }}>
                {allowStart &&
                    <button disabled={loading} onClick={() => update(TRANSPORTATION_LEG_STATUS.STATUS_STARTED)} className='lead-flow-icon-button lead-flow-icon-button-primary' style={{ marginTop: '3rem', width: 64, height: 64 }}>
                        <Play32 />
                    </button>}

                {allowFinish &&
                    <button disabled={loading} onClick={() => update(TRANSPORTATION_LEG_STATUS.STATUS_FINISHED)} className='lead-flow-icon-button lead-flow-icon-button-success' style={{ marginTop: '3rem', width: 64, height: 64 }}>
                        <CheckmarkFilled32 />
                    </button>}

                {allowClear &&
                    <button disabled={loading} onClick={() => update(TRANSPORTATION_LEG_STATUS.STATUS_NOT_STARTED)} className='lead-flow-icon-button lead-flow-icon-button-secondary' style={{ marginTop: '3rem', width: 64, height: 64 }}>
                        <ErrorFilled32 />
                    </button>}

                {!isFinal && <>
                    <div style={{ width: 1, flex: 1, background: "black", marginTop: !hasBtns ? '1rem' : undefined, opacity: 0.25, marginBottom: '1rem' }}>

                    </div>
                    <ArrowDown16 />
                </>}
                {isFinal && <>
                    <div style={{ width: 1, flex: 1, background: "black", marginTop: !hasBtns ? '1rem' : undefined, opacity: 0.25, marginBottom: '1rem' }}>

                    </div>
                    {item.status === TRANSPORTATION_LEG_STATUS.STATUS_FINISHED ?
                        <Tag type="green" renderIcon={CheckmarkFilled16} size="sm">Destination</Tag> : <Tag size="sm">Destination</Tag>}
                </>}
            </div>
        </div>
    )
}

const BillItem = ({ item }) => (
    <div style={{ width: '100%', marginBottom: '1rem', padding: '1rem', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <ChartBullet24 style={{ marginRight: '1rem' }} />
            {/* <p style={{ marginLeft: '0.5rem', fontSize: 18 }}>Bill</p> */}


            <ValueField style={{ marginBottom: 0, flex: 1 }} title={getObjectTypeName(item.otherPartyType)}>
                <Link to={getObjectTypeUrl(item.otherPartyType) + '/' + item.otherPartyId}>{item.otherPartyName}</Link>
            </ValueField>
            <ValueField style={{ marginBottom: 0, flex: 1 }} title="Bill no">{item.billNo}</ValueField>
            <ValueField style={{ marginBottom: 0, flex: 1 }} title="Amount">SAR {Util.formatMoney(item.amount)}</ValueField>

            {Util.isStringExists(item.document) ?
                <ValueField style={{ marginBottom: 0, flex: 1 }} title="Vendor Document"><a download href={Api.downloadUploadFieldFile(item.document)}>Download</a></ValueField>
                : <div style={{ flex: 1 }} />}


            {/* <Link to={"/bill/" + item.id}>
                <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                    <ArrowRight16 />
                </button>
            </Link> */}
        </div>
    </div>
)


const ShipmentItem = ({ item }) => (
    <div style={{ width: '100%', marginBottom: '1rem', padding: '1rem', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <DeliveryParcel24 />
            <p style={{ marginLeft: '0.5rem', fontSize: 18 }}>{item.shipmentNo}</p>

            <div style={{ flex: 1 }} />

            <Link to={"/shipment-job/" + item.id}>
                <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                    <ArrowRight16 />
                </button>
            </Link>
        </div>
    </div>
)

const ValueField = ({ title, children, style = {} }) => (
    <div style={{ marginBottom: '0.75rem', ...style }}>
        <label style={{ marginBottom: 0, opacity: 0.65, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16 }}>{children}</p>
    </div>
)

const Group = ({ title, titleMargin, children }) => (
    <div style={{ marginBottom: '3rem', }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: titleMargin ? '1rem' : undefined }}>{title}</h4>

        {children}
    </div>
)

const StatusTag = ({ active, icon, children }) => (
    <Tag style={{ opacity: active ? 1 : 0.65 }} type={active ? "blue" : undefined} renderIcon={icon}>{children}</Tag>
)



const BriefManifest = ({ master }) => {
    const [loading, setLoading] = useState(false);
    const onPrintBtn = async () => {
        setLoading(true);
        try {
            const { PDF } = createBriefManifest({})
            const blob = await pdf(<PDF payload={master} />).toBlob();
            print(URL.createObjectURL(blob));
        } finally {
            setLoading(false);
        }
    }
    return <Button loading={loading} onClick={onPrintBtn} renderIcon={Document16} size="sm" kind="secondary" style={{ borderRadius: 50 }}>Cargo Manifest</Button>
}

const DetailedManifest = ({ master }) => {
    const [loading, setLoading] = useState(false);
    const onPrintBtn = async () => {
        setLoading(true);
        try {
            const { PDF } = createDetailedManifest({})
            const blob = await pdf(<PDF payload={master} />).toBlob();
            print(URL.createObjectURL(blob));
        } finally {
            setLoading(false);
        }
    }
    return <Button loading={loading} onClick={onPrintBtn} renderIcon={Document16} size="sm" kind="secondary" style={{ borderRadius: 50 }}>Master Manifest</Button>
}



const View = ({ payload: { item, endpoint } }) => {
    const { master, setMaster, updateTransportationLegStatus } = useItem(item)
    const [editVisible, setEditVisible] = useState(false)
    const [showProfitLoss, setShowProfitLoss] = useState(false);
    return (
        <div>
            <div style={{ paddingInline: '6rem', paddingTop: '3rem' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '1rem', gap: '0.25rem' }}>
                    <div>
                        <ChartRelationship32 style={{ width: 64, height: 64 }} />
                        <h3 style={{ fontWeight: 'bold', marginTop: '1rem' }}>Shipment Master</h3>
                        <h4>{master.masterNo}</h4>
                    </div>
                    <div style={{ flex: 1 }} />
                    <Button className="green-button" onClick={() => setShowProfitLoss(true)} size="sm" renderIcon={Analytics16} style={{ borderRadius: 50 }}>Profit/Loss</Button>
                    <Button onClick={() => setEditVisible(true)} size="sm" renderIcon={Edit16} style={{ borderRadius: 50 }}>Edit</Button>
                </div>

                <div style={{ background: "#f4f4f4", borderRadius: 7, padding: '1rem', marginBottom: '3rem', border: '1px solid #00000020', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    <h4 style={{ fontWeight: 'bold' }}>Status</h4>
                    <div style={{ display: 'flex' }}>
                        {/* <ValueField style={{ flex: 1 }} title="Current Yard">{master.currentYard}</ValueField> */}
                        <ValueField style={{ flex: 1 }} title="Current Port">{master.currentPort}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Days since arrival">{master.daysSinceArrival}</ValueField>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        <StatusTag active={master.status >= SHIPMENT_MASTER_STATUS.STATUS_SCHEDULED} icon={Number_116}>Scheduled</StatusTag>
                        <ArrowRight16 />
                        <StatusTag active={master.status >= SHIPMENT_MASTER_STATUS.STATUS_TRANSPORTING} icon={Number_216}>Transporting</StatusTag>
                        <ArrowRight16 />
                        <StatusTag active={master.status >= SHIPMENT_MASTER_STATUS.STATUS_AT_DESTINATION} icon={Number_316}>At Destination</StatusTag>
                    </div>
                </div>

                <Group title="Details">
                    <div style={{ display: 'flex' }}>
                        <ValueField style={{ flex: 1 }} title="Origin Port">{master.originPortName}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Destination Port">{master.destinationPortName}</ValueField>
                        <div style={{ flex: 1 }} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <ValueField style={{ flex: 1 }} title="Created by"><Link to={'/staff-users/' + master.userId}>{master.createdByFullName}</Link></ValueField>
                        <ValueField style={{ flex: 1 }} title="Created on">{Util.getDate(master.createDate)}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Last update on">{Util.getDate(master.updateDate)}</ValueField>
                    </div>
                </Group>

                <Group title="Documents" titleMargin>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}><BriefManifest master={master} /></div>
                        <div style={{ flex: 1 }}><DetailedManifest master={master} /></div>
                        <div style={{ flex: 1 }}></div>
                    </div>
                </Group>


                <Group title="Transportation" titleMargin>
                    {master.transportationLegs.map((item, index) =>
                        <TransportationLegItem key={item.id}
                            allowStart={item.status === TRANSPORTATION_LEG_STATUS.STATUS_NOT_STARTED && (index === 0 || master.transportationLegs[index - 1]?.status === TRANSPORTATION_LEG_STATUS.STATUS_FINISHED)}
                            allowFinish={item.status === TRANSPORTATION_LEG_STATUS.STATUS_STARTED}
                            allowClear={item.status === TRANSPORTATION_LEG_STATUS.STATUS_FINISHED && ((master.transportationLegs.length - 1 === index) || master.transportationLegs[index + 1]?.status === TRANSPORTATION_LEG_STATUS.STATUS_NOT_STARTED)}
                            isFinal={master.transportationLegs.length - 1 === index}


                            {...{ item, index, updateTransportationLegStatus }} />)}
                </Group>

                <Group title="Bills" titleMargin>
                    {master.bills.map(bill => (
                        <BillItem key={bill.id} item={bill} />
                    ))}
                </Group>

                <Group title="Shipments" titleMargin>
                    {master.shipments.map(shipment => (
                        <ShipmentItem key={shipment.id} item={shipment} />
                    ))}
                </Group>

                <div style={{ marginTop: '6rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                    <AdvancedNotesView title="Attachments" objectId={master.id} objectType={OBJECT_TYPE_SHIPMENT_MASTER} />
                </div>

                <div style={{ height: '3rem' }} />
            </div>


            <EditDialog endpoint={endpoint} item={master} setItem={setMaster} visible={editVisible} onClose={() => setEditVisible(false)} />

            {showProfitLoss &&
                <SidePanel onClose={() => setShowProfitLoss(false)} background="transparent">
                    {/* <LockScroll />/ */}
                    <div onClick={() => setShowProfitLoss(false)} style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
                        <div onClick={e => e.stopPropagation()} style={{
                            position: 'relative', background: 'white', padding: '1rem', boxShadow: '0px 8px 17px 2px rgba(0,0,0,0.14) , 0px 3px 14px 2px rgba(0,0,0,0.12) , 0px 5px 5px -3px rgba(0,0,0,0.2) ',
                            width: 512, minHeight: 'calc(100vh - 3rem)'
                        }}>
                            {/* <div style={{ paddingBlock: '6rem', paddingInline: '3rem' }}>
                                <div style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', marginBottom: '3rem' }}>
                                    <Analytics24 />
                                    <h4 style={{ fontWeight: 'bold', flex: 1 }}>Profit/Loss</h4>
                                    <button onClick={() => setShowProfitLoss(false)} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                                        <Close16 />
                                    </button>
                                </div>


                            </div> */}
                            <div style={{ padding: '1rem', paddingBottom: '0.25rem' }}>
                                <h3 style={{}}>Profit/Loss</h3>
                            </div>
                            <MasterProfit master={master} />

                            <button style={{
                                position: 'absolute', left: 0, transform: 'translateX(-50%)', top: '1.5rem',
                                animation: '250ms actual-opacity-fade-in-anim', width: 45, height: 45,
                            }} onClick={() => setShowProfitLoss(false)} className='lead-flow-icon-button lead-flow-icon-button-primary'>
                                <ArrowLeft16 />
                            </button>
                        </div>
                    </div>
                </SidePanel>
            }
        </div>
    )
}


export const ShipmentMasterDetailPage = withLoadablePageWithParams(params => listener => Api.getShipmentMaster(params.masterId, listener), View)