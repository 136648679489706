import { withLoadablePage } from "../../../../base/Page"
import Api from "../../../../session/Api"
import { FormSection, useForm } from "../../../../templates/form/form";
import { CUSTOMER_ENTITY_TYPE, TAX_TYPES } from "../../sales";
import { Migrate24, ChartBullet24, License24, AddFilled16, Reset16 } from '@carbon/icons-react'
import { useMemo, useState } from 'react'
import Util, { big, max } from "../../../../util/Util";
import { BillItemsTable } from "../components/bill-items-table";
import UIUtil from "../../../../util/UIUtil";
import { useHistory } from "react-router-dom";
import useStore from "../../../../hooks/useStore";
import Button from "../../../../components/Button";
import { SOURCE_TYPE_SUPPLIER } from "../../../../constants/Constants";
import { OBJECT_TYPE_PRODUCT } from "../../../../constants/ObjectTypes";

function CreateBtn({ store, endpoint }) {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [itemsValue] = useStore(store, 'items');
    const [vendorId] = useStore(store, "vendorId");
    const [locationId] = useStore(store, 'locationId')

    const [voucherNo] = useStore(store, "voucherNo")
    const [date] = useStore(store, "date");

    const amounts = useCalcAmounts(store);
    const supplier = useMemo(() => endpoint.suppliers.find($ => $.id === vendorId), [vendorId, endpoint])
    const destination = useMemo(() => endpoint.destinations.find($ => $.id === locationId), [locationId, endpoint])

    const onClick = () => {
        const items = itemsValue.slice(0, itemsValue.length - 1)

        if (!date || date <= 0) {
            UIUtil.showInfo("No date set")
            return;
        }

        if (!Util.isStringExists(voucherNo)) {
            UIUtil.showInfo("No invoice no set")
            return;
        }

        if (!supplier) {
            UIUtil.showInfo("No supplier selected")
            return;
        }

        if (!destination) {
            UIUtil.showInfo("No destination selected");
            return;
        }

        if (items.length === 0) {
            UIUtil.showInfo("No products/services in invoice")
            return;
        }

        const customItem = items.find($ => !$.item.selectedItem);
        if (customItem) {
            UIUtil.showInfo(`Item '${customItem.item.name}' does not exist`)
            return;
        }




        UIUtil.showSuccess();
        setLoading(true)
        const req = {
            purchaseSupplierInvoiceNo: voucherNo,
            setDate: date,

            sourceType: SOURCE_TYPE_SUPPLIER,
            sourceId: supplier.id,

            destinationType: destination.destinationType,
            locationId: destination.id,

            items: items.filter($ => $.item.selectedItem?.type === "PRODUCT").map(item => ({
                itemId: item.item.selectedItem?.id,
                itemType: OBJECT_TYPE_PRODUCT,
                quantityValue: big(item.qty).toFixed(4),
                amount: big(item.amount).toFixed(4),
            })),

            salesAmountSubtotal: amounts.subtotal,
            salesAmountTax: amounts.tax,
            amount: amounts.gross,



            salesAmountDiscount: 0,
            amountAdditionalCosts: 0,
            transactionPaymentMethods: [],
        }

        Api.createStockFlow(req, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                history.replace("/stock-flow/" + response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return <Button loading={loading} onClick={onClick} size={"sm"} renderIcon={AddFilled16} style={{ borderRadius: 7 }}>Create</Button>
}


function useCalcAmounts(store) {
    const [items] = useStore(store, "items");
    const [taxId] = useStore(store, "taxId");

    return useMemo(() => {
        const taxPerc = TAX_TYPES.find(tax => tax.id === taxId)?.perc ?? 0;

        const subtotal = items.slice(0, items.length - 1).map(item => big(item.qty).times(big(item.amount))).reduce((t, c) => t.add(c), big(0));
        const tax = big(taxPerc).div(100).times(subtotal);
        const gross = subtotal.plus(tax);

        return {
            subtotal: subtotal.round(2).toNumber(),
            tax: tax.round(2).toNumber(),
            gross: gross.round(2).toNumber(),
        }
    }, [items, taxId])
}


const BottomBar = ({ store, endpoint }) => {
    const amounts = useCalcAmounts(store);
    return (
        <div style={{ height: '4rem', width: '100%', background: '#1c1c1c', display: 'flex', alignItems: 'center', gap: '1.5rem', paddingInline: '3rem', color: 'white' }}>
            <div>
                <label className="bx--label" style={{ color: 'white', opacity: 0.65, marginBottom: 0 }}>Subtotal</label>
                <h4 style={{}}>SAR {Util.formatMoney(amounts.subtotal)}</h4>
            </div>
            <h4>+</h4>
            <div>
                <label className="bx--label" style={{ color: 'white', opacity: 0.65, marginBottom: 0 }}>Tax</label>
                <h4 style={{}}>SAR {Util.formatMoney(amounts.tax)}</h4>
            </div>
            <h4>=</h4>
            <div>
                {/* <label className="bx--label" style={{ color: 'white', opacity: 0.65, marginBottom: 0 }}>Net</label> */}
                <h3 style={{ fontWeight: 'bold', color: '#009900' }}>SAR {Util.formatMoney(amounts.gross)}</h3>
            </div>
            <div style={{ flex: 1 }} />

            <CreateBtn store={store} endpoint={endpoint} />
        </div>
    )
}


const Items = ({ billables, editable, value, setValue }) => {
    return <BillItemsTable billables={billables} editable={editable} items={value} setItems={setValue} />
}

function View({ payload: endpoint }) {
    const form = useForm(observable => {
        observable.set("date", new Date().getTime());
        observable.set("items", [{ tempId: Util.newTempId() }])

        observable.set("locationId", endpoint.locations[0]?.id)

        observable.set("taxId", TAX_TYPES[0].id)
    }, {
        skipFieldEffect: true
    })


    return (<>
        <div style={{ height: 'calc(100vh - 3rem - 4rem)', overflow: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'stretch', minHeight: '100%' }}>
                <div style={{ flex: 2 }}>
                    <div style={{ paddingInline: '3rem', paddingBlock: '3rem', }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: '1rem' }}>
                            <Migrate24 />
                            <h4 style={{ fontWeight: 'bold' }}>Create Bill</h4>
                        </div>

                        <FormSection>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <div style={{ flex: 1 }}>
                                    <form.DateField fieldKey="date" title="Date" />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <form.TextField fieldKey="voucherNo" title="Voucher No" />
                                </div>
                            </div>
                            <form.ComboBoxField fieldKey="vendorId" title="Vendor" options={endpoint.vendors} />
                            <form.ComboBoxField fieldKey="locationId" title="Inventory Destination" help="Default location" options={endpoint.locations} />
                        </FormSection>

                        <FormSection title={"Amounts"}>
                            <form.ComboBoxField fieldKey="taxId" title="Tax" options={TAX_TYPES} />
                        </FormSection>

                    </div>
                </div>
                <div style={{ flex: 3, background: '#f4f4f4', borderLeft: '1px solid #00000005' }}>
                    <div style={{ paddingInline: '3rem', paddingBlock: '3rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <ChartBullet24 />
                            <h4 style={{ fontWeight: 'bold', flex: 1 }}>Products/Expenses</h4>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Tax NOT included</p>
                        </div>
                    </div>

                    <form.CustomField fieldKey="items" childrenProps={{ billables: endpoint.billables, editable: true, }}>
                        {Items}
                    </form.CustomField>
                </div>
            </div>
        </div>

        <form.ExtField _skipFormMargin childrenProps={{ endpoint }}>
            {BottomBar}
        </form.ExtField>
    </>)
}

export const NewBill = withLoadablePage(listener => Api.getV2BillsEndpoint(listener), View)