
export function BalanceView({ balance }) {
    balance = balance ? balance : 0;
    let neg = balance < 0;
    let zero = balance === 0;
    if (balance < 0) {
        balance = '(' + Math.abs(balance) + ')';
    }

    return (
        <div style={{ display: 'flex', textDecoration: 'none', alignItems: 'center', gap: '0.15rem', justifyContent: 'flex-start', paddingRight: '0rem', color: neg ? 'red' : zero ? '#1c1c1c' : 'green' }}>
            <p style={{ opacity: '0.65', fontSize: 12, textDecoration: 'none' }}>Balance:</p>
            <h4 style={{ fontSize: 14, textDecoration: 'none' }}>SAR {balance}</h4>
        </div>
    )
}