import { Loading, TextInput } from "carbon-components-react";
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../components/Button";
import Api from "../../../session/Api";
import { FormDialog } from "../../../templates/form/form-dialog";
import UIUtil from "../../../util/UIUtil";
import Util, { big } from "../../../util/Util";

import { CloseOutline16, TrashCan16, Edit16, ChartPie32, Report32, AirlineManageGates32, Wallet32, Finance32, Money32, Save16, CheckmarkFilled16, ProgressBarRound16 } from '@carbon/icons-react'
import { useHistory } from "react-router-dom";
import { fixIdForApi } from "../../../templates/form/form-api";
import { FormSection, useFormField } from "../../../templates/form/form";
import { TaxInput } from "../../stock-flow/TaxInput";
import useStore from "../../../hooks/useStore";
import { CommissionDivisionEditor } from "../components/commission-division-editor";
import { ProfitEditor } from "../components/profit-editor";
import { CommissionEditor } from "../components/commission-editor";
import { MinimalTable } from "../../../components/minimal-table";

function CommissionAmountField({ store, hasDeal }) {
    const [propertyAmount] = useFormField(undefined, store, "propertyAmount", 0)
    const [tax, setTax] = useFormField(undefined, store, "commissionTaxAmount", 0)
    const [commissionAmount, setCommissionAmount] = useFormField(undefined, store, "commissionAmount", 0);
    const [commissionRate, setCommissionRate] = useFormField(undefined, store, "commissionRate", 2);

    const [forceEditTax, setForceEditTax] = useState(false);


    const commissionAmountWithTax = useMemo(() =>
        big(commissionAmount).plus(big(tax)).round(2, 1), [commissionRate, tax, commissionAmount])
    useEffect(() => {
        const amount = (big(commissionRate).div(big(100))).times(big(propertyAmount)).round(2, 1);
        setForceEditTax(!amount.eq(big(store.get("commissionAmount", 0))));
        setCommissionAmount(amount);
    }, [store, commissionRate, propertyAmount])

    return (<>
        <div style={{ marginBottom: '1rem' }}>
            <label className="bx--label">{'Commission Rate'}</label>
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                <TextInput
                    size="lg" hideSteppers min={0} invalidText="Invalid numeric value"
                    value={commissionRate} onChange={e => setCommissionRate(e.target.value < 0 ? 0 : e.target.value)} />
                <p>%</p>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                {hasDeal && !forceEditTax ? (<>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            <label className="bx--label">Tax Amount</label>
                            <h4>SAR {Util.formatMoney(tax)}</h4>
                        </div>
                        <Button size="sm" renderIcon={Edit16} onClick={() => setForceEditTax(true)}>Edit Tax Amount</Button>
                    </div>
                </>) : (
                    <TaxInput
                        amount={commissionAmount}
                        taxValue={tax} onTaxValueChange={setTax} />
                )}
            </div>

            <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
                <div style={{ flex: 1, background: '#0f62fe20', border: '1px solid #0f62fe60', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Wallet32 style={{ color: '#0f62fe' }} />
                    <div>
                        <label style={{ color: '#0f62fe', marginBottom: 0 }} className="bx--label">Commission Amount</label>
                        <h4>SAR {Util.formatMoney(commissionAmount)}</h4>
                    </div>
                </div>
                <div style={{ flex: 1, background: '#000000', border: '1px solid black', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Finance32 style={{ color: 'white' }} />
                    <div>
                        <label style={{ color: 'white', marginBottom: 0 }} className="bx--label">Commission Amount <strong>(with tax)</strong></label>
                        <h4 style={{ color: 'white' }}>SAR {Util.formatMoney(commissionAmountWithTax)}</h4>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

const CommissionDivision = ({ store, agents }) => {
    const [value, setValue] = useFormField(undefined, store, 'commissionDivisions', []);
    const [commissionAmount] = useFormField(undefined, store, "commissionAmount", 0);

    const totalAmountDivided = useMemo(() =>
        value
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)), [value, commissionAmount])

    return (<>
        <div style={{ paddingInline: '0.5rem' }}>
            <CommissionDivisionEditor highZ defaultItems={value ?? []} onUpdate={setValue} agents={agents} commissionAmount={commissionAmount} />
        </div>

        <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }}>
            <div style={{ flex: 1, background: '#99000020', border: '1px solid #99000060', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                <ChartPie32 style={{ color: 'red' }} />
                <div style={{ marginLeft: '1rem' }}>
                    <label style={{ color: 'red', marginBottom: 0 }} className="bx--label">Total Agent Distribution</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-end' }}>
                        <h4>SAR {Util.formatMoney(totalAmountDivided)}</h4>
                        <p style={{ fontSize: 12, opacity: 0.65 }}> // </p>
                        <p>SAR {Util.formatMoney(commissionAmount)}</p>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

const ProfitAmounts = ({ store }) => {
    const [value, setValue] = useFormField(undefined, store, 'profits', []);

    const totalAmountProfit = useMemo(() =>
        value
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)), [value])

    return (<>
        <div style={{ paddingInline: '0.5rem' }}>
            <ProfitEditor highZ defaultItems={value ?? []} onUpdate={setValue} />
        </div>

        <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }}>
            <div style={{ flex: 1, background: '#00990020', border: '1px solid #00990060', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                <Money32 style={{ color: 'green' }} />
                <div style={{ marginLeft: '1rem' }}>
                    <label style={{ color: 'green', marginBottom: 0 }} className="bx--label">Total Profit Amount</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-end' }}>
                        <h4>SAR {Util.formatMoney(totalAmountProfit)}</h4>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

const IndirectCommissions = ({ store, agents }) => {
    const [value, setValue] = useFormField(undefined, store, 'indirectCommissions', []);

    const total = useMemo(() =>
        value
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)), [value])

    return (<>
        <div style={{ paddingInline: '0.5rem' }}>
            <CommissionEditor highZ defaultItems={value ?? []} onUpdate={setValue} agents={agents} />
        </div>

        <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }}>
            <div style={{ flex: 1, background: '#99000020', border: '1px solid #99000060', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                <AirlineManageGates32 style={{ color: 'red' }} />
                <div style={{ marginLeft: '1rem' }}>
                    <label style={{ color: 'red', marginBottom: 0 }} className="bx--label">Total Indirect Commission</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-end' }}>
                        <h4>SAR {Util.formatMoney(total)}</h4>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

const FinancialSummary = ({ store }) => {
    const [data, setData] = useState(() => store.toObject());
    useEffect(() => store.subscribeGlobal(() => setData(store.toObject())), [store])

    const rows = useMemo(() => {
        let arr = [];
        arr.push({ desc: "Property Price", amt: data.propertyAmount })
        arr.push({ desc: "Commission Rate", val: parseFloat(data.commissionRate ?? 0).toFixed(2) + '%' })

        arr.push({ isDivider: 1, title: "Commission Calculations" })

        arr.push({ desc: "Commission", amt: data.commissionAmount })
        arr.push({ desc: "Commission Tax", amt: data.commissionTaxAmount })

        arr.push({ isDivider: 1, title: "Commission Distribution" })

        arr.push({ desc: "Agent Commission Distribution", amt: data.commissionDivisions?.map(item => big(item.amount)).reduce((t, c) => t.add(c), big(0)) })
        arr.push({ desc: "Company Commission Left", amt: big(data.commissionAmount).minus(big(data.commissionDivisions?.map(item => big(item.amount)).reduce((t, c) => t.add(c), big(0)))) })

        arr.push({ isDivider: 1, title: "Profits and Indirect commissions" })
        arr.push({ desc: "Company Profit", amt: data.profits?.map(item => big(item.amount)).reduce((t, c) => t.add(c), big(0)) })
        arr.push({ desc: "Agent Indirect Commission", amt: data.indirectCommissions?.map(item => big(item.amount)).reduce((t, c) => t.add(c), big(0)) })

        arr.push({ desc: "", val: " " })
        arr.push({ desc: "", val: " " })
        arr.push({ desc: "", val: " " })
        arr.push({ desc: "", val: " " })


        arr.push({
            desc: "Total amount to receive from buyer",
            strong: true,
            amt: data.commissionAmount !== undefined ? big(data.commissionAmount)
                .plus(big(data.commissionTaxAmount))
                .plus(data.profits?.map(item => big(item.amount)).reduce((t, c) => t.add(c), big(0)))
                .plus(data.indirectCommissions?.map(item => big(item.amount)).reduce((t, c) => t.add(c), big(0))) : 0
        })

        return arr;
    }, [data])
    return (
        <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }}>
            <div style={{ flex: 1, background: '#99990020', border: '1px solid #99990060', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', alignItems: 'flex-start' }}>
                <Report32 style={{ color: '#4a4a00' }} />
                <div style={{ marginLeft: '1rem', flex: 1 }}>
                    <label style={{ color: '#4a4a00', }} className="bx--label">Financial Summary</label>

                    <MinimalTable columns={[
                        {
                            name: "desc",
                            title: "Description",
                            flex: 1,
                            renderCell: item => item.strong ? <strong style={{ marginTop: '1rem' }}>{item.desc}</strong> : item.desc
                        },
                        {
                            name: "amt",
                            title: "Amount",
                            flex: 1,
                            renderCell: item => item.val ? item.val : (item.strong ? <h4 style={{ color: 'green', marginTop: '1rem' }}><>SAR {Util.formatMoney(item.amt)}</></h4> : <>SAR {Util.formatMoney(item.amt ?? 0)}</>)
                        },
                    ]} items={rows} />
                </div>
            </div>
        </div>
    )
}

function FormView(form, payload) {
    const { TextField, TextAreaField, SwitchField, ComboBoxField, DateField, CustomField, ExtField } = form;
    return (
        <div>
            <FormSection title="Property Info">
                <ComboBoxField title="Property" fieldKey="propertyId" options={payload.properties.map(item => ({ ...item, title: item.value }))} />
                <TextField title="Property Price" fieldKey="propertyAmount" />
            </FormSection>

            <FormSection title="Deal Record Info (optional)">
                <TextField title="Ref no (optional)" fieldKey="refNo" />
                <DateField title="Deal date (optional)" fieldKey="dealDate" />
            </FormSection>

            <FormSection title="Commission Amount">
                <ExtField childrenProps={{ hasDeal: payload.deal !== undefined && payload.deal !== null }}>
                    {CommissionAmountField}
                </ExtField>
            </FormSection>

            <FormSection title="Commission Distribution">
                <ExtField childrenProps={{ agents: payload.agents }}>
                    {CommissionDivision}
                </ExtField>
            </FormSection>

            <FormSection title="Profit Amounts">
                <ExtField>
                    {ProfitAmounts}
                </ExtField>
            </FormSection>

            <FormSection title="Indirect Commissions">
                <ExtField childrenProps={{ agents: payload.agents }}>
                    {IndirectCommissions}
                </ExtField>
            </FormSection>

            <FormSection title="Deal Financial Summary">
                <ExtField>
                    {FinancialSummary}
                </ExtField>
            </FormSection>
        </div>
    )
}

function UnpostedDealFooterBtns({ form, loading, onClose, onSave, onUpdate, onSetItemCalled, dealId }) {
    const history = useHistory();

    const [deleteLoading, setDeleteLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const isLoading = loading || deleteLoading || confirmLoading;

    const originalDataRef = useRef(null);
    useEffect(() => {
        originalDataRef.current = form.getFormData();
    }, [form])

    const onDelete = () => {
        setDeleteLoading(true)
        Api.deletePropertyDeal(dealId, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                onUpdate(response.payload);
                onSetItemCalled?.();
                onClose();
            } else {
                UIUtil.showError(response.message);
                setDeleteLoading(false);
            }
        })
    }

    const onConfirm = () => {
        setConfirmLoading(true);
        const data = fixIdForApi(form.getFormData());
        Api.confirmPropertyDeal({ dealId, ...data }, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                if (onSetItemCalled) {
                    onSetItemCalled(true);
                    onClose();
                } else {
                    history.push("/property-deal/" + dealId)
                }
            } else {
                UIUtil.showError(response.message);
                setConfirmLoading(false);
            }
        })
    }

    return (<>
        <Button style={{ pointerEvents: isLoading ? 'none' : undefined }} renderIcon={CloseOutline16} kind="secondary" onClick={onClose}>
            Cancel
        </Button>
        <Button style={{ pointerEvents: isLoading ? 'none' : undefined }} renderIcon={deleteLoading ? ProgressBarRound16 : TrashCan16} loading={deleteLoading} kind="danger" onClick={() => UIUtil.confirm(onDelete)}>
            Delete
        </Button>
        <Button style={{ pointerEvents: isLoading ? 'none' : undefined }} renderIcon={deleteLoading ? ProgressBarRound16 : Save16} loading={loading} onClick={onSave}>
            Update
        </Button>
        <Button style={{ pointerEvents: isLoading ? 'none' : undefined }}
            className="green-button"
            renderIcon={CheckmarkFilled16}
            loading={confirmLoading}
            onClick={onConfirm}
        >
            Post/Confirm
        </Button>
    </>)
}

export function DealFormDialog({ onClose, onUpdate, leadId, dealId, onSetItemCalled }) {
    const [payload, setPayload] = useState(undefined);
    useEffect(() => {
        Api.getPropertyDealEndpoints(leadId, dealId, response => {
            if (response.status === true) {
                setPayload(response.payload);
            } else {
                UIUtil.showError(response.message);
                onClose();
            }
        })
    }, [])
    if (!payload) {
        return <Loading />
    }

    const hasDeal = payload.deal !== undefined && payload.deal !== null;
    const item = payload.deal ? {
        ...payload.deal,
        dealDate: Util.isNumberExist(payload.deal.dealDate) ? payload.deal.dealDate : undefined
    } : {};
    const setItem = item => {
        onClose();
        if (!hasDeal) {
            onUpdate(item);
        }
        if (onSetItemCalled) {
            onSetItemCalled();
        }
    }
    const customFooterButtons = hasDeal ? ((props) => {
        return <UnpostedDealFooterBtns {...props} onUpdate={onUpdate} dealId={dealId} onSetItemCalled={onSetItemCalled} />
    }) : undefined

    return (
        <FormDialog open onClose={onClose} label={hasDeal ? "Unposted/Unconfirmed" : "Creating"} title="Property Deal"
            item={item} setItem={setItem} payload={payload} preventCloseOnClickOutside creating={!hasDeal}
            saveApi={(data, listener) => {
                if (hasDeal) {
                    Api.updatePropertyDeal({ dealId, ...data }, listener)
                } else {
                    Api.createPropertyDeal({ leadId, ...data }, listener)
                }
            }}
            customFooterButtons={customFooterButtons}
        >
            {FormView}
        </FormDialog>
    )
}