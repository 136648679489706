import { useState } from "react"
import { withLoadablePageWithParams } from "../../base/Page"
import Api from "../../session/Api"
import UIUtil from "../../util/UIUtil";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { DataShare32, TrashCan16, ArrowRight16, ArrowRight32 } from '@carbon/icons-react'
import { AdvancedNotesView } from "../../views/advanced-notes/advanced-notes-view";
import { OBJECT_TYPE_FUND_TRANSFER, OBJECT_TYPE_SHIPMENT_MASTER } from "../../constants/ObjectTypes";
import Util from "../../util/Util";

function DeleteBtn({ item }) {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const onDeleteBtn = () => UIUtil.confirm(() => {
        setLoading(true)
        Api.deleteFundTransfer(item.id, response => {
            if (response.status === true) {
                history.replace("/fund-transfer")
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    })

    return (
        <Button loading={loading} kind="danger" onClick={onDeleteBtn} size="sm" renderIcon={TrashCan16} style={{ borderRadius: 50 }}>Delete</Button>
    )
}


const ValueField = ({ title, children, style = {} }) => (
    <div style={{ marginBottom: '0.75rem', ...style }}>
        <label style={{ marginBottom: 0, opacity: 0.65, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16 }}>{children}</p>
    </div>
)

const Group = ({ title, titleMargin, children }) => (
    <div style={{ marginBottom: '3rem', }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: titleMargin ? '1rem' : undefined }}>{title}</h4>

        {children}
    </div>
)

const View = ({ payload: item }) => {
    return (
        <div>
            <div style={{ paddingInline: '6rem', paddingTop: '3rem' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem' }}>
                    <div>
                        <DataShare32 style={{ width: 64, height: 64 }} />
                        <h3 style={{ fontWeight: 'bold', marginTop: '1rem' }}>Fund Transfer</h3>
                        <h4>{item.id}</h4>
                    </div>
                    <div style={{ flex: 1 }} />
                    <DeleteBtn item={item} />

                    <Link to={"/journal-entry/" + item.journalEntryId}>
                        <Button size="sm" renderIcon={ArrowRight16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Journal Entry</Button>
                    </Link>
                </div>


                <Group title="Flow">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <h2>{item.fromCashAccount}</h2>
                        <div style={{ flex: 1 }} />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', background: '#1c1c1c', width: 48, height: 48, borderRadius: 48 }}>
                            <ArrowRight32 />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, borderRadius: 48, marginInline: '3rem' }}>
                            <h2 style={{ color: '#009900' }}>SAR {Util.formatMoney(item.amount)}</h2>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', background: '#1c1c1c', width: 48, height: 48, borderRadius: 48 }}>
                            <ArrowRight32 />
                        </div>
                        <div style={{ flex: 1 }} />
                        <h2>{item.toCashAccount}</h2>
                    </div>
                </Group>

                <Group title="Details">
                    <div style={{ display: 'flex' }}>
                        <ValueField style={{ flex: 1 }} title="Created by"><Link to={'/staff-users/' + item.byUserId}>{item.byUserFullName}</Link></ValueField>
                        <ValueField style={{ flex: 1 }} title="Date">{Util.getDateOnly(item.date)}</ValueField>
                        <div style={{ flex: 1 }} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <ValueField style={{ flex: 1 }} title="Memo">
                            <span style={{ whiteSpace: 'pre-wrap' }}>{Util.isStringExists(item.memo) ? item.memo : '-'}</span>
                        </ValueField>

                    </div>
                    {/* <div style={{ display: 'flex' }}>
                        <ValueField style={{ flex: 1 }} title="Created by"><Link to={'/staff-users/' + master.userId}>{master.createdByFullName}</Link></ValueField>
                        <ValueField style={{ flex: 1 }} title="Created on">{Util.getDate(master.createDate)}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Last update on">{Util.getDate(master.updateDate)}</ValueField>
                    </div> */}
                </Group>

                <div style={{ marginTop: '6rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                    <AdvancedNotesView title="Attachments" objectId={item.id} objectType={OBJECT_TYPE_FUND_TRANSFER} />
                </div>

                <div style={{ height: '3rem' }} />

            </div>
        </div>
    )
}

export const FundTransferDetailPage = withLoadablePageWithParams(params => listener => Api.getFundTransfer(params.fundTransferId, listener), View)