import React from 'react'
import Page from '../../base/Page';
import Button from '../../components/Button';
import Api from '../../session/Api';


import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16
} from '@carbon/icons-react'
import { Purchase16, Star16, Screen32, ErrorFilled16, ArrowRight16, WarningFilled32, Cafe16, Cafe32, Time16, Hourglass16, StarFilled20 } from '@carbon/icons-react'
import { CheckmarkFilled16 } from '@carbon/icons-react'
import { ComboBox, DatePicker, DatePickerInput, Link, RadioTile, Tag, TileGroup } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar
} from 'carbon-components-react';
import TransactionListView from '../transaction/TransactionListView';
import Util, { DAY_MS, handleEndDate } from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import StockFlowField from '../stock-flow/StockFlowField';
import { DESTINATION_TYPE_STORE, DESTINATION_TYPE_WAREHOUSE } from '../../constants/Constants';
import ProfilePic from '../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_WAREHOUSE } from '../../constants/ObjectTypes';
import ProductField from '../../views/product/ProductField';
import Rate from '../../components/Rate';


import { subDays, addDays } from 'date-fns';
////import { DateRangePicker } from 'react-date-range';
import DateRangePicker from '../../components/date/DateRangePicker'
import Calendar from '../../components/date/Calendar'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

const HEADERS = [
    {
        header: "Operator",
        key: "userFullName"
    },
    {
        header: "Status",
        key: "ended"
    },
    {
        header: "Total Sales",
        key: "totalSales"
    },
    {
        header: "Average Review",
        key: "averageReviews"
    },

    {
        header: "Cash Open",
        key: "startDate"
    },
    {
        header: "Opening Amount",
        key: "startAmount"
    },

    {
        header: "Cash Closed",
        key: "endDate"
    },
    {
        header: "Closing Amount",
        key: "endAmount"
    },
]


class POSSessionReportPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,

            generatingReport: false,
            generatedReport: false,

            report: undefined,

            dateValue: new Date().getTime(),
            startDateValue: subDays(new Date(), 1).getTime(),
            endDateValue: new Date().getTime(),

            suppliers: [],
            customers: [],
            warehouses: [],
            stores: [],

            locationPickerKey: Util.newTempId()
        }
    }

    clearReport() {
        this.setState({
            generatingReport: false,
            generatedReport: false,

            dateValue: new Date().getTime(),
            startDateValue: subDays(new Date(), 1).getTime(),
            endDateValue: new Date().getTime(),
        })
    }

    canGenerateReport() {
        if (this.isOneDay()) {
            return Util.isNumberExist(this.state.dateValue);
        } else {
            return Util.isNumberExist(this.state.startDateValue) && Util.isNumberExist(this.state.endDateValue);
        }
    }

    generateReportBtn() {
        this.setState({ generatingReport: true })
        Api.getPOSSessionReport(this.isOneDay() ? this.state.dateValue : this.state.startDateValue, handleEndDate(this.state.endDateValue), this.props.cashClosedMode === true, response => {
            if (response.status === true) {
                this.setState({ generatingReport: false, report: response.payload, generatedReport: true, })
                UIUtil.showSuccess();
            } else {
                this.setState({ generatingReport: false, generatedReport: false })
                UIUtil.showError(response.message);
            }
        })
    }

    onPageStart() {
        this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => ({
            suppliers: payload.suppliers,
            customers: payload.customers,
            warehouses: payload.warehouses,
            stores: payload.stores,
        }))
    }

    isOneDay() {
        return this.props.cashClosedMode;
    }

    renderRangePicker() {
        return (
            <DateRangePicker
                //onChange={item => setState([item.selection])}
                onChange={item => this.setState({ startDateValue: item.selection.startDate.getTime(), endDateValue: item.selection.endDate.getTime() })}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[{
                    startDate: new Date(this.state.startDateValue),
                    endDate: new Date(this.state.endDateValue),
                    key: 'selection'
                }]}
                direction="horizontal"
            />
            // <DatePicker datePickerType={"range"}
            // key="range-picker-pos-session-report"
            // //value={[this.state.startDateValue, this.state.endDateValue]}
            // onChange={e => {
            //     if (e.length > 1) {
            //         this.setState({
            //             startDateValue: e[0].getTime(),
            //             endDateValue: e[1].getTime()
            //         })
            //     } else if (e.length == 1) {
            //         this.setState({
            //             startDateValue: e[0].getTime(),
            //         })
            //     } else {
            //         this.setState({
            //             startDateValue: 0,
            //             endDateValue: 0
            //         })
            //     }
            // }}
            // >
            //     <DatePickerInput
            //         style={{flex: 1}}
            //         placeholder="mm/dd/yyyy"
            //         labelText={"Start date"}
            //     />
            //     <DatePickerInput
            //         placeholder="mm/dd/yyyy"
            //         labelText="End date"
            //     />
            // </DatePicker>
        )
    }

    renderDatePicker() {
        return (
            <Calendar onChange={date => this.setState({ dateValue: date.getTime() })} date={new Date(this.state.dateValue)} />
            // <DatePicker datePickerType={"single"}
            // key="date-picker-pos-session-report"
            // //value={[this.state.dateValue]}
            // onChange={e => {
            //     if (e.length > 0) {
            //         this.setState({
            //             dateValue: e[0].getTime(),
            //         })
            //     } else {
            //         this.setState({
            //             dateValue: 0,
            //         })
            //     }
            // }}
            // >
            //     <DatePickerInput
            //         style={{flex: 1}}
            //         placeholder="mm/dd/yyyy"
            //         labelText={"Date"}
            //     />
            // </DatePicker>
        )
    }

    renderInput() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>{this.props.cashClosedMode ? 'Cash Closed' : 'Work Periods'}</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>

                    <Section icon={Number_132} title={this.isOneDay() ? "Select Date" : "Select Range"}>
                        {this.isOneDay() ? this.renderDatePicker() : this.renderRangePicker()}
                    </Section>

                    <Section icon={Number_232} title="Report" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.generateReportBtn.bind(this)} disabled={!this.canGenerateReport()} loading={this.state.generatingReport} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    getRow(id) {
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    /*
    const HEADERS = [
    { },
    {
        header: "Time Stats",
        key: "ended"
    },
    { },
    {
        header: "Average Review",
        key: "averageReviews"
    },


        
    },
]
*/

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);

        switch (header.key) {
            case "ended":
                return (
                    <div>
                        <Tag renderIcon={cell.value ? ErrorFilled16 : CheckmarkFilled16} style={{ color: cell.value ? undefined : 'green', margin: 0, marginTop: '0.5rem' }}>
                            {cell.value ? "Ended" : "Active"}
                        </Tag>
                        <br />
                        <label className="bx--label" style={{ marginTop: '1rem', marginBottom: 0, fontWeight: 'bold' }}>Total active</label>
                        <p style={{ fontSize: 14, }}>{Util.formatSecondsPretty(((row.endDate - row.startDate) - row.totalBreakTime) / 1000)}</p>

                        <label className="bx--label" style={{ marginTop: '0.5rem', marginBottom: 0, fontWeight: 'bold' }}>Total break</label>
                        <p style={{ fontSize: 14, }}>{Util.formatSecondsPretty((row.totalBreakTime) / 1000)}</p>
                        <div style={{ minHeight: '0.5rem' }} />
                    </div>
                )

            case "userFullName":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ProfilePic size={34} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, row.userId)} />
                        <p style={{ marginLeft: '0.5rem' }}>{cell.value}</p>
                    </div>
                )

            case "totalSales": case "startAmount": case "endAmount":
                if (cell.value !== null && cell.value !== undefined) {
                    return <> SAR {parseFloat(cell.value).toFixed(2)} </>
                } else {
                    return "-";
                }

            case "averageReviews":
                return <> <Rate disabled value={cell.value} /> </>

            case "endDate": case "startDate":
                return <> <Calendar16 /> {Util.getDate(cell.value)} </>

            default:
                return cell.value;
        }
    }

    renderReport() {
        return (
            <div className="main-content">
                <div onClick={this.clearReport.bind(this)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem' }}>
                    <Link><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Generate another report</Link>
                </div>
                <div style={{ width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2 }}>
                    <div style={{ flex: 1 }}>
                        <h4 style={{}}>{this.props.cashClosedMode ? 'Cash Closed' : 'Work Periods'}</h4>

                        <div style={{ display: 'flex', alignItems: 'center', opacity: 0.65 }}>
                            <Calendar16 style={{ marginRight: '0.25rem' }} /> <p>{Util.getDateOnly(this.isOneDay() ? this.state.dateValue : this.state.startDateValue)}</p>

                            {!this.isOneDay() && <>
                                <p style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>-</p>
                                <Calendar16 style={{ marginRight: '0.25rem' }} /> <p>{Util.getDateOnly(this.state.endDateValue)}</p> </>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '0.5rem' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'green' }}>
                            <p>Total Sales</p>
                            <h3>SAR {this.state.report.values.totalSales.toFixed(2)}</h3>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'green' }}>
                            <p>Total Checkouts</p>
                            <h3>{this.state.report.values.totalCheckouts}</h3>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                            <p>Total Active</p>
                            <h3>{Util.formatSecondsPretty(this.state.report.values.totalActiveTime / 1000)}</h3>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                            <p>Total Break</p>
                            <h3>{Util.formatSecondsPretty(this.state.report.values.totalBreakTime / 1000)}</h3>
                        </div>
                        <div style={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
                            {/* <Star16 style={{ }} /> */}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#f26622' }}>
                                <p>Average Reviews</p>
                                <Rate disabled value={this.state.report.values.averageReviews} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <TransactionListView report={this.state.report} hideToolbar /> */}

                <DataTable rows={this.state.report.items} headers={HEADERS} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => {
                                            this.props.history.push("/pos-session/" + row.id)
                                        })} key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        )
    }

    getLayout() {
        return this.state.generatedReport ? this.renderReport() : this.renderInput()
    }

}

export default POSSessionReportPage;