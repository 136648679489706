import React, { useMemo } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../../base/Page';
import Button from '../../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_ACCOUNT_LEDGER, OBJECT_TYPE_ACCOUNT_LEDGER_GROUP, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_LOYALTY_CARD_ISSUE, OBJECT_TYPE_STORE, OBJECT_TYPE_TERMINAL } from "../../../constants/ObjectTypes";
import Api from '../../../session/Api';
import ItemTemplate from '../../../templates/ItemTemplate';
import { Edit16 } from '@carbon/icons-react'
import { ComposedModal, ModalBody, ModalFooter, ModalHeader, NumberInput } from 'carbon-components-react';
import UIUtil from '../../../util/UIUtil';
import { BalanceView } from '../../../views/accounting/BalanceView';
import GeneralLedgerResultPage from '../../accounting-reports/general-ledger-report/GeneralLedgerResultPage';
import { ReportPage } from '../../../reporting-engine/base/report-page';

const LedgerReport = ({ item }) => {
    const query = useMemo(() => btoa(JSON.stringify({
        selectedAccountLedger: item,
        filters: [
            { id: "ledgerId", property: "ledgerId", operator: "EQ", value: item.id },
        ]
    })), [item])
    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ height: '100vh', width: '100%' }}>
                <ReportPage reportName="LedgerReport" overrideQuery={query} componentMode />
            </div>
        </div>
    )
    // return (
    //     <div style={{ marginTop: '1rem' }}>
    //         <GeneralLedgerResultPage
    //             embedded hideSubsidiary
    //             hideSubsidiaryReportButton
    //             accountIdOverride={item.id} />
    //     </div>
    // )
}

class AccountLedgersDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined,
            item: undefined,

            showOpeningBalanceDialog: false,
            setOpeningBalanceValue: 0,
            loadingSettingBalance: false
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_ACCOUNT_LEDGER, listener)
            } else {
                Api.getItem(OBJECT_TYPE_ACCOUNT_LEDGER, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload,
            item: payload.item
        }))
    }

    renderBalanceItem() {
        const item = this.state.item;

        if (!item.hasJournalEntries) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '1rem' }}>
                    <Button onClick={() => this.setState({ showOpeningBalanceDialog: true, setOpeningBalanceValue: 0, loadingSettingBalance: false })} renderIcon={Edit16}>Set Opening Balance</Button>
                </div>
            )
        } else {
            let balance = item.balance ? item.balance : 0;
            let neg = balance < 0;
            if (balance < 0) {
                balance = '(' + Math.abs(balance) + ')';
            }

            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-center', justifyContent: 'center', paddingRight: '1rem', color: neg ? 'red' : 'green' }}>
                    <p>Balance</p>
                    <h4>SAR {balance}</h4>
                </div>
            )
        }
    }

    setOpeningBalance() {
        this.setState({ loadingSettingBalance: true })
        Api.setOpeningBalance(this.state.item.id, this.state.setOpeningBalanceValue, response => {
            this.setState({ loadingSettingBalance: false })
            if (response.status === true) {
                this.setState(prevState => ({
                    showOpeningBalanceDialog: false,
                    item: {
                        ...prevState.item,
                        balance: prevState.setOpeningBalanceValue,
                        hasJournalEntries: true
                    }
                }))
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_ACCOUNT_LEDGER,
                    itemResult: this.state.itemResult,
                    pagePath: "/account-ledgers/",
                    history: this.props.history,
                    hasCustomBtn: <BalanceView item={this.state.item} />,
                    customTabs: [
                        {
                            title: "Ledger Report",
                            component: LedgerReport
                        },
                    ]
                    //hasCustomBtn: this.renderBalanceItem()
                })}

                <ComposedModal key="opening-balance-dialog" size="sm" open={this.state.showOpeningBalanceDialog} onClose={() => this.setState({ showOpeningBalanceDialog: false })}>
                    <ModalHeader label="Account Ledger" title="Setting Opening Balance" />
                    <ModalBody>
                        <NumberInput
                            invalidText="Invalid number"
                            data-modal-primary-focus
                            value={this.state.setOpeningBalanceValue}
                            onChange={(e, { value }) => this.setState({ setOpeningBalanceValue: value })}
                            hideSteppers
                            label="Opening Balance"
                        />
                    </ModalBody>
                    <ModalFooter
                    // onRequestSubmit={() => this.startSession()}
                    // primaryButtonText="Start" secondaryButtonText="Cancel"
                    >

                        <Button kind="secondary" onClick={() => this.setState({ showOpeningBalanceDialog: false })}>
                            Cancel
                        </Button>
                        <Button
                            loading={this.state.loadingSettingBalance}
                            disabled={this.state.setOpeningBalanceValue == 0}
                            onClick={() => this.setOpeningBalance()}>
                            Set
                        </Button>

                    </ModalFooter>
                </ComposedModal>
            </div>
        )
    }

}

export default withRouter(AccountLedgersDetailPage);