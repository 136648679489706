import React, { useEffect, useState } from 'react'
import Page from '../../../base/Page';

import Button from '../../../components/Button';
import Api from '../../../session/Api';


import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16,
    ListDropdown32,
    FlowStream16,
    ChartBullet16,
    Currency16,
    GraphicalDataFlow16,
    ArrowRight16
} from '@carbon/icons-react'
import { ComboBox, DatePicker, DatePickerInput, Link, RadioTile, TileGroup } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar,
    Tag,
    Link as LinkButton
} from 'carbon-components-react';

import Util from '../../../util/Util';
import UIUtil from '../../../util/UIUtil';
import StockFlowField from '../../stock-flow/StockFlowField';
import { DESTINATION_TYPE_STORE, DESTINATION_TYPE_WAREHOUSE } from '../../../constants/Constants';
import ProfilePic from '../../../components/ProfilePic';
import { getObjectTypeName, getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_MOBILE_UNIT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_VENUE, OBJECT_TYPE_WAREHOUSE } from '../../../constants/ObjectTypes';
import ProductField from '../../../views/product/ProductField';
import { withRouter, Link as LK } from 'react-router-dom';
import { BalanceTagDivisionDialog } from '../../../views/accounting/balance-tags/BalanceTagDivsionDialog';

// const HEADERS = [
//     {
//         header: "Journal Entry Voucher No",
//         key: "journalEntryId"
//     },
//     {
//         header: "Subsidiary Account",
//         key: "subsidiaryType"
//     },
//     {
//         header: "Subsidiary Name",
//         key: "subsidiaryAccountName"
//     },
//     {
//         header: "Date",
//         key: "date"
//     },
//     {
//         header: "Debit",
//         key: "debitAmount"
//     },
//     {
//         header: "Credit",
//         key: "creditAmount",
//     }
// ]

const headers = (hasMoreThanOneAccount, hideSubsidiary) => {
    let arr;
    if (hasMoreThanOneAccount) {
        arr = [{
            header: "Voucher No",
            key: "journalEntryId"
        },
        {
            header: "Voucher Memo",
            key: "memo"
        },
        {
            header: "Account Name",
            key: "accountName"
        },
        {
            header: "Subsidiary Account",
            key: "subsidiaryType"
        },
        {
            header: "Subsidiary Name",
            key: "subsidiaryAccountName"
        },
        {
            header: "Date",
            key: "date"
        },
        {
            header: "Debit",
            key: "debitAmount"
        },
        {
            header: "Credit",
            key: "creditAmount",
        },
        {
            header: "Balance",
            key: "balanceAmount",
        },
        {
            header: "Action",
            key: "action"
        }]
    } else {
        arr = [{
            header: "Voucher No",
            key: "journalEntryId"
        },
        {
            header: "Voucher Memo",
            key: "memo"
        },
        {
            header: "Subsidiary Account",
            key: "subsidiaryType"
        },
        {
            header: "Subsidiary Name",
            key: "subsidiaryAccountName"
        },
        {
            header: "Date",
            key: "date"
        },
        {
            header: "Debit",
            key: "debitAmount"
        },
        {
            header: "Credit",
            key: "creditAmount",
        },
        {
            header: "Balance",
            key: "balanceAmount",
        },
        {
            header: "Action",
            key: "action"
        }]
    }
    if (hideSubsidiary) {
        arr = arr.filter(header => header.key != "subsidiaryType" && header.key != "subsidiaryAccountName")
    }
    return arr;
}

function BalanceAmountLabel({ item, val }) {
    const [showDialog, setShowDialog] = useState(false);
    const hasTags = item.balanceTags?.length > 0;

    useEffect(() => {
        if (!hasTags) {
            setShowDialog(false);
        }
    }, [hasTags])

    if (hasTags) {
        const showTagDivison = () => {
            //UIUtil.showOverlay2(onClose => <BalanceTagDivisionDialog visible onClose={onClose} />)
            setShowDialog(true);
        }
        return (<>
            <h4>{val}</h4>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <LinkButton onClick={showTagDivison} style={{ cursor: 'pointer' }}>View division</LinkButton>
            </div>
            {showDialog && <BalanceTagDivisionDialog total={item.balance} balanceTags={item.balanceTags} visible onClose={() => setShowDialog(false)} />}
        </>)
    } else {
        return <h4 style={{}}>{val}</h4>;
    }
}

const BalanceLabel = ({ isFocusedMode, report }) => {
    if (isFocusedMode) {
        let balance = report.balance;
        if (balance < 0) {
            balance = Math.abs(Util.formatMoney(balance.toFixed(2)));
        } else {
            balance = Util.formatMoney(balance.toFixed(2))
        }
        // balance = Util.formatMoney(balance)

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-center', justifyContent: 'center', paddingRight: '1rem', }}>
                <p style={{ fontWeight: 'bold' }}>Total</p>
                {/* <h4>SAR {balance}</h4> */}
                <BalanceAmountLabel item={report} val={balance} />
            </div>
        )
    } else {
        let balance = report.balance;
        let neg = balance < 0;
        if (balance < 0) {
            balance = '(' + Math.abs(Util.formatMoney(balance.toFixed(2))) + ')';
        } else {
            balance = Util.formatMoney(balance.toFixed(2))
        }
        // balance = Util.formatMoney(balance)

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-center', justifyContent: 'center', paddingRight: '1rem', color: neg ? 'red' : 'green' }}>
                <p style={{ fontWeight: 'bold' }}>Balance</p>
                {/* <h4>SAR {balance}</h4> */}
                <BalanceAmountLabel item={report} val={balance} />
            </div>
        )
    }
}

class GeneralLedgerResultPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            report: undefined
        }
    }

    get isFocusedMode() {
        return this.props.showDebitOnly || this.props.showCreditOnly
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.props.showDebitOnly) {
                Api.getGeneralLedgerReportWithReq(this.getAccountId(), { showDebitOnly: true }, listener)
            } else if (this.props.showCreditOnly) {
                Api.getGeneralLedgerReportWithReq(this.getAccountId(), { showCreditOnly: true }, listener)
            } else {
                Api.getGeneralLedgerReport(this.getAccountId(), listener)
            }
        }, report => ({ report }))
    }

    get embedded() {
        return this.props.embedded;
    }

    getAccountId() {
        if (this.props.accountIdOverride) {
            return this.props.accountIdOverride
        } else {
            return this.getPathParams().accountId;
        }
    }

    getCellHeader(cell) {
        for (const header of headers(this.state.report.hasMoreThanOneAccount, this.props.hideSubsidiary)) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    getRow(id) {
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);
        const item = row.entry;

        switch (header.key) {
            // case "name":
            //     return (
            //         <div style={{display: 'flex', alignItems: 'center'}}>
            //             <ProfilePic size={34} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, row.id)} />
            //             <p style={{marginLeft: '0.5rem'}}>{cell.value}</p>
            //         </div>
            //     )

            case "journalEntryId":
                return Util.getVoucherNumber(cell.value)

            case "subsidiaryType":
                return cell.value == -1 ? "-" : getObjectTypeName(cell.value)

            case "objectType":
                if (cell.value == OBJECT_TYPE_WAREHOUSE) {
                    return "Warehouse"
                } else if (cell.value == OBJECT_TYPE_STORE) {
                    return "Store"
                } else if (cell.value == OBJECT_TYPE_VENUE) {
                    return "Venue"
                } else if (cell.value == OBJECT_TYPE_MOBILE_UNIT) {
                    return "Mobile Unit"
                } else {
                    return "-";
                }

            case "debitAmount": case "creditAmount":
                if ((row.debit && header.key == "debitAmount") || (!row.debit && header.key == "creditAmount")) {
                    return <> SAR {Util.formatMoney(parseFloat(row.amount))} </>
                } else {
                    return null;
                }

            case "balanceAmount":
                return <> SAR {Util.formatMoney(parseFloat(row.balance))} </>

            case "date":
                return <> <Calendar16 /> {Util.getDate(cell.value)} </>

            case "action":
                return (<>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.5rem', paddingRight: '0.5rem' }}>


                        {Util.isNumberExist(item.stockFlowId) &&
                            <LK target={"_blank"} to={'/stock-flow/' + item.stockFlowId}>
                                <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                    <FlowStream16 />
                                </button>
                            </LK>}
                        {Util.isNumberExist(item.billId) &&
                            <LK target={"_blank"} to={'/bill/' + item.billId}>
                                <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                    <ChartBullet16 />
                                </button>
                            </LK>}
                        {Util.isNumberExist(item.transactionId) &&
                            <LK target={"_blank"} to={'/transaction/' + item.transactionId}>
                                <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                    <Currency16 />
                                </button>
                            </LK>}
                        {Util.isNumberExist(item.serviceSaleId) &&
                            <LK target={"_blank"} to={'/service-sale/' + item.serviceSaleId}>
                                <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                    <GraphicalDataFlow16 />
                                </button>
                            </LK>}

                        {!item.systemGenerated && <Tag size="sm">User defined</Tag>}

                        <LK target={"_blank"} to={'/journal-entry/' + item.id}>
                            <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                                <ArrowRight16 />
                            </button>
                        </LK>
                    </div>
                </>)

            default:
                return cell.value;
        }
    }

    renderBalance() {
        return <BalanceLabel isFocusedMode={this.isFocusedMode} report={this.state.report} />
        // if (this.isFocusedMode) {
        //     let balance = this.state.report.balance;
        //     if (balance < 0) {
        //         balance = Math.abs(balance.toFixed(2));
        //     } else {
        //         balance = balance.toFixed(2)
        //     }

        //     return (
        //         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-center', justifyContent: 'center', paddingRight: '1rem', }}>
        //             <p style={{ fontWeight: 'bold' }}>Total</p>
        //             <h4>SAR {balance}</h4>
        //         </div>
        //     )
        // } else {
        //     let balance = this.state.report.balance;
        //     let neg = balance < 0;
        //     if (balance < 0) {
        //         balance = '(' + Math.abs(balance.toFixed(2)) + ')';
        //     } else {
        //         balance = balance.toFixed(2)
        //     }

        //     return (
        //         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-center', justifyContent: 'center', paddingRight: '1rem', color: neg ? 'red' : 'green' }}>
        //             <p style={{ fontWeight: 'bold' }}>Balance</p>
        //             <h4>SAR {balance}</h4>
        //         </div>
        //     )
        // }
    }

    getLayout() {
        // return JSON.stringify(this.state.report)

        return (
            <div className="main-content">
                {!this.embedded && <div onClick={() => this.props.history.goBack()} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem' }}>
                    <Link><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Generate another report</Link>
                </div>}
                <div style={{ width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2, display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        {!this.embedded && <h3>{this.state.report.accountName}</h3>}
                        {/* <h4>Balance: SAR {this.state.report.balance.toFixed(2)}</h4> */}
                        {this.renderBalance()}
                    </div>
                    {!this.props.hideSubsidiaryReportButton &&
                        <Button renderIcon={ListDropdown32} onClick={() => this.props.history.push("/subsidiary-ledger-report", { defaultAccountId: this.getAccountId() })}>
                            Subsidiary Report
                        </Button>}
                </div>
                {/* <TransactionListView report={this.state.report} hideToolbar /> */}

                <DataTable rows={this.state.report.items} headers={headers(this.state.report.hasMoreThanOneAccount, this.props.hideSubsidiary)} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => {
                                            // const item = this.getRow(row.id);
                                            // this.props.history.push("/journal-entry/" + item.journalEntryId);
                                        })} key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        )
    }

}

export default withRouter(GeneralLedgerResultPage);