
import {
    Document32, Notebook16, ArrowLeft16, Report32, Run32, Calendar32, DocumentPdf32, ErrorFilled16, Currency32,
    Currency16, ArrowRight16, ChartBullet32, Catalog32, Money32, List32, TreeView32, RequestQuote16, Notebook32,
    Migrate16, Certificate16, GraphicalDataFlow32, Activity16, Account32, Purchase16, InProgress24, GroupPresentation32, Ticket32,
    Theater32, Ticket24, QrCode20, Account24, Receipt16, PlaneSea32
} from '@carbon/icons-react'
import { ButtonSet, Link as DLink, Tag } from "carbon-components-react"
import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { DISCOUNT_TYPE, STRIPE_API_KEY, STRIPE_PROCESSING_FEE_PERC, VAT } from '../../app/Config';
import { withLoadablePageWithParams } from '../../base/Page';
import Button from '../../components/Button';
import ProfilePic from '../../components/ProfilePic';
import { ACCOUNT_TYPE_GUARDIAN, ACCOUNT_TYPE_STUDENT, TRANSACTION_DIRECTION_TYPE_INWARD } from '../../constants/Constants';
import { getObjectTypeName, getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_COURSE, OBJECT_TYPE_MOVIE, OBJECT_TYPE_SERVICE_SALE } from '../../constants/ObjectTypes';
import { ServiceSaleStatusIndicator, SERVICE_SALE_STATUS_NOT_PAID, SERVICE_SALE_STATUS_PAID, SERVICE_SALE_STATUS_PARTIALLY_PAID, SERVICE_SALE_STATUS_REVOKED } from '../../domain/service-sale';

import useApi from '../../hooks/useApi';
import Api from '../../session/Api';
import { getAccountRole } from '../../session/SessionManager';
import UIUtil from '../../util/UIUtil';
import Util, { big } from '../../util/Util';
import { TypeTag } from '../../views/editable-tables/ProductServiceTable';
import NoteView from '../../views/notes/NoteView';
import { PaymentDialog } from '../../views/payment-dialog';
import TransactionEditor from '../transaction/TransactionEditor';

import { InvoiceDocumentDownload } from '../../pdfs/invoice-document/InvoiceDocumentPdf'
import ImageView from '../../components/ImageView';
import { TransactionPayButton } from '../../views/transactions/transaction-pay-button';
import { isMobileScreen } from '../../App';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { CurrencyConvertionBanner } from '../../components/currency-convertion-banner';
import { printShipmentDoc } from '../shipping/shipment/item-page/pdf';
import { createInvoice } from '../shipping/shipment/item-page/pdf/invoice';

function hasCurrency(serviceSale) {
    const currency = serviceSale?.usingCurrency;
    return currency !== undefined && currency !== null
}
function realAmount(serviceSale) {
    return hasCurrency(serviceSale) ? serviceSale?.realCurrencyAmount : serviceSale?.amount
}
function realAmountPaid(serviceSale) {
    return hasCurrency(serviceSale) ? serviceSale?.realCurrencyAmountPaid : serviceSale?.amountPaid
}
function currencyCode(serviceSale) {
    return serviceSale?.usingCurrency?.code ?? "SAR"
}

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const getAmountStatus = serviceSale => {
    const amount = serviceSale.amount;
    const amountPaid = serviceSale.amountPaid;

    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Fully Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;

        status += " (" + currencyCode(serviceSale) + " " + amountLeft.toFixed(2) + " left)";
    }

    return { status, statusColor }
}

const Section = ({ id, children, icon, title, subtitle, extraTopMargin }) => (
    <div id={id} style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem' }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)

const TransactionItem = ({ serviceSale, transaction }) => {
    const [expanded, setExpanded] = useState(false);

    const paymentMethodHeight = 80 + convertRemToPixels(0.5);

    let dividedAmount = 0;
    if (transaction.againstItems && transaction.againstItems.length !== undefined) {
        for (const item of transaction.againstItems) {
            if (item.serviceSaleId == serviceSale.id) {
                dividedAmount = item.dividedAmount;
                break;
            }
        }
    }

    return (
        <div style={{
            transition: '250ms', height: expanded ? 75 + (115 + (paymentMethodHeight * transaction.paymentMethods.length - 1)) : 75, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: 14 }}>Transaction (voucher no: {Util.getTransactionVoucherNumber(transaction.id, transaction.directionType, transaction.sequenceNo)})</p>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <h2>SAR {dividedAmount.toFixed(2)}</h2>
                        <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}> / SAR {transaction.amount.toFixed(2)} (Total Transaction Amount)</p>
                    </div>



                    {/* <h2>SAR {transaction.amount.toFixed(2)}</h2> */}
                </div>
                <p style={{ fontSize: 12, opacity: 0.65 }}>View details</p>
                <ArrowRight16 style={{ opacity: 0.65, transition: '250ms', marginLeft: '0.25rem', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
            </div>
            <div style={{ height: 115 + (paymentMethodHeight * transaction.paymentMethods.length - 1), paddingLeft: '15px', paddingBottom: 15, paddingRight: 15, overflow: 'auto' }} >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Link target="_blank" to={"/transaction/" + transaction.id} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={Currency16} style={{ width: 195 }}>Open Transaction</Button>
                    </Link>
                </div>

                <TransactionEditor
                    hideTotal
                    readOnly showColorForIncome
                    defaultPaymentMethods={transaction.paymentMethods}
                    currency={"SAR"}
                    //incoming={!isSourceExternal}
                    incoming={true}
                    totalAmount={transaction.amount} />
            </div>
        </div>
    )
}



const Title = ({ serviceSale, onPaymentBtn }) => {
    const history = useHistory();
    const accountRole = getAccountRole();

    const [loading, revoke] = useApi(listener => Api.revokeServiceSale(serviceSale.id, listener), () => window.location.reload());


    if (isMobileScreen) {
        return (<>
            <div style={{ background: "#f4f4f4", color: 'black', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000020', paddingInline: '1rem', width: 'calc(100% + 10vw)', marginLeft: '-5vw', height: "3rem", gap: '0.5rem' }}>
                <button onClick={() => history.goBack()} className='lead-flow-icon-button' style={{ marginRight: '1rem' }}>
                    <ArrowLeft16 />
                </button>
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 35, height: 35 }}>
                    
                </div> */}
                <Account24 />
                <h4 style={{ fontWeight: 'bold', flex: 1, marginLeft: '-0.25rem' }}>Invoice</h4>
                <ServiceSaleStatusIndicator status={serviceSale.status} />
                {serviceSale.live && <Tag size="sm" type="magenta" renderIcon={Activity16}>Live</Tag>}
            </div>

            {(accountRole == ACCOUNT_TYPE_STUDENT || accountRole == ACCOUNT_TYPE_GUARDIAN) && (serviceSale.status != SERVICE_SALE_STATUS_PAID) && (
                Util.isStringExists(STRIPE_API_KEY) &&
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <PaymentButton serviceSale={serviceSale} />
                </div>
            )}
        </>)
    }

    return (<>
        <DLink onClick={() => history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {(accountRole == ACCOUNT_TYPE_STUDENT || accountRole == ACCOUNT_TYPE_GUARDIAN) ? (<>
                <Account32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
                <h1 style={{ flex: 1 }}>Invoice</h1>
            </>) : serviceSale.ticketSale ? (<>
                <Ticket32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
                <h1 style={{ flex: 1 }}>Ticket {!serviceSale.hasTicketOnly && 'Sale'}</h1>
            </>) : (<>
                <GraphicalDataFlow32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
                <h1 style={{ flex: 1 }}>Service Sale</h1>
            </>)}

            {(accountRole != ACCOUNT_TYPE_STUDENT && accountRole != ACCOUNT_TYPE_GUARDIAN) &&
                (serviceSale.status == SERVICE_SALE_STATUS_PARTIALLY_PAID || serviceSale.status == SERVICE_SALE_STATUS_NOT_PAID) && (
                    <div style={{ marginRight: 2 }}>
                        <TransactionPayButton
                            amount={parseFloat(parseFloat(realAmount(serviceSale) - realAmountPaid(serviceSale)).toFixed(2))}
                            direction={TRANSACTION_DIRECTION_TYPE_INWARD} itemId={serviceSale.id} itemType={OBJECT_TYPE_SERVICE_SALE}
                            partyItemType={serviceSale.payerType} partyId={serviceSale.payerId} />
                    </div>
                )}
            {(accountRole != ACCOUNT_TYPE_STUDENT && accountRole != ACCOUNT_TYPE_GUARDIAN) && !serviceSale.ticketSale && (
                serviceSale.status == SERVICE_SALE_STATUS_NOT_PAID ? (
                    <ButtonSet style={{ width: '392px', gap: 2 }}>
                        <Button loading={loading} onClick={() => UIUtil.confirm(revoke)} kind="danger" renderIcon={ErrorFilled16} style={{ width: 195 }}>
                            Revoke
                        </Button>
                        <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                    </ButtonSet>
                ) : (
                    <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
                )
            )}

            {(accountRole == ACCOUNT_TYPE_STUDENT || accountRole == ACCOUNT_TYPE_GUARDIAN) && (serviceSale.status != SERVICE_SALE_STATUS_PAID) && (
                Util.isStringExists(STRIPE_API_KEY) &&
                <PaymentButton serviceSale={serviceSale} />
            )}

            {Util.isNumberExist(serviceSale.ticketSaleStockFlowId) &&
                <Link to={"/stock-flow/" + serviceSale.ticketSaleStockFlowId}>
                    <Button renderIcon={ArrowRight16} size="sm" style={{ borderRadius: 50 }} kind="primary">View Stock Flow Sale</Button>
                </Link>}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            {!serviceSale.hasTicketOnly && <ServiceSaleStatusIndicator status={serviceSale.status} />}
            {serviceSale.live && <Tag size="sm" type="magenta" renderIcon={Activity16}>Live</Tag>}
        </div>
    </>)
}

const Amount = ({ serviceSale }) => {
    const amount = serviceSale.amount;
    const amountPaid = serviceSale.amountPaid;
    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;
    }

    return (<>
        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
            <p>
                {currencyCode(serviceSale)} {Util.formatMoney(amount)}
            </p>
        </div>

        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
            <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                {currencyCode(serviceSale)} {Util.formatMoney(amountPaid)}
            </p>
        </div>
    </>)
}

const PrintShipmentBtn = ({ serviceSale }) => {
    const [loading, setLoading] = useState(false);
    const onClick = async () => {
        try {
            setLoading(true);
            const shipment = serviceSale.parentShipment;
            const isTaxInvoice = shipment.status === "DONE" || serviceSale.secondaryShipmentId === shipment.id;
            await printShipmentDoc(shipment, createInvoice(shipment, serviceSale.id), {
                title: isTaxInvoice ? "TAX INVOICE" : "PROFORMA INVOICE"
            });
        } finally {
            setLoading(false);
        }
    }
    return (
        <Button onClick={onClick} style={{ width: '100%' }} loading={loading} renderIcon={Receipt16}>Invoice</Button>
    )
}

const PrintBtn = ({ serviceSale }) => {
    let btn;
    if (serviceSale.parentShipment) {
        btn = (
            <PrintShipmentBtn serviceSale={serviceSale} />
        )
    } else if (Util.isNumberExist(serviceSale.saleId)) {
        btn = (
            <Link to={"/sale/" + serviceSale.saleId} style={{ textDecoration: 'none' }}>
                <Button kind="secondary" style={{ width: '100%' }} renderIcon={Certificate16}>Sale Voucher</Button>
            </Link>
        )
    } else {
        //btn = <PurchaseVoucherDownload stockFlow={this.state.stockFlow} />
        btn = serviceSale.invoice && <InvoiceDocumentDownload invoice={serviceSale.invoice} />;
    }

    if (btn) {
        return (
            <div style={{ marginBottom: '2rem' }}>
                {btn}
            </div>
        )
    } else {
        return null;
    }
}

const RecordInfo = ({ serviceSale }) => (
    <Section icon={Account32} title="Sale Record Info">
        {Util.isStringExists(serviceSale.refNo) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Sale No'}</label>
                <p style={{}}>{serviceSale.refNo}</p>
            </div>}
        {Util.isNumberExist(serviceSale.saleDate) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Sale Date'}</label>
                <p style={{}}>{Util.getDateOnly(serviceSale.saleDate)}</p>
            </div>}
    </Section>
)


const Info = ({ serviceSale }) => {
    const role = getAccountRole();
    return (
        <Section icon={Report32} title="Document Info">
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Voucher Number'}</label>
                <p style={{}}>{Util.getVoucherNumber(serviceSale.id)}</p>
            </div>

            {(role != ACCOUNT_TYPE_STUDENT && role != ACCOUNT_TYPE_GUARDIAN) && !serviceSale.ticketSale && <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{getObjectTypeName(serviceSale.payerType)}</label>
                <Link target="_blank" to={getObjectTypeUrl(serviceSale.payerType) + '/' + serviceSale.payerId}><p style={{}}>{serviceSale.payerName}</p></Link>
            </div>}

            {(role == ACCOUNT_TYPE_GUARDIAN) && !serviceSale.ticketSale && <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{getObjectTypeName(serviceSale.payerType)}</label>
                <p style={{}}>{serviceSale.payerName}</p>
            </div>}

            {serviceSale.ticketSale && <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{getObjectTypeName(serviceSale.payerType)}</label>
                <p style={{}}>Guest</p>
            </div>}

            <PrintBtn serviceSale={serviceSale} />

            <Amount serviceSale={serviceSale} />

            {/* <Link target="_blank" to={"/journal-entry/" + serviceSale.journalEntryId} style={{ textDecoration: 'none', }}>
                <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: 195 }}>Associated Journal Entry</Button>
            </Link> */}
        </Section>
    )
}

const Initiation = ({ serviceSale }) => (
    <Section icon={Run32} title="Initiation">
        <label style={{}} className="bx--label">Initiator</label>
        <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} to={Util.isNumberExist(serviceSale.initiatorId) ? getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + serviceSale.initiatorId : ''}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, serviceSale.initiatorId)} />
                <p>{serviceSale.initiatorFullName}</p>
            </div>
        </Link>

        <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Date</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar32 style={{ marginRight: '0.5rem' }} />
            <p>{Util.getDate(serviceSale.initiationDate)}</p>
        </div>
    </Section>
)

const Transactions = ({ serviceSale }) => (
    <Section icon={Currency32} title="Transactions"
        subtitle={getAmountStatus(serviceSale).status} subtitleColor={getAmountStatus(serviceSale).statusColor}
    >
        {serviceSale.transactions.map(transaction => <TransactionItem key={transaction.id} serviceSale={serviceSale} transaction={transaction} />)}
    </Section>
)

const AmountBreakdown = ({ serviceSale }) => (
    <Section icon={Money32} title="Amount Breakdown">
        {Util.isNumberExist(serviceSale.subtotal) && <>
            <p style={{ fontSize: 14 }}>Subtotal (+)</p>
            <h2>{currencyCode(serviceSale)} {Util.formatMoney(serviceSale.subtotal)}</h2>
        </>}

        {(DISCOUNT_TYPE === "NORMAL" || DISCOUNT_TYPE === "TAX_INCLUDED") && Util.isNumberExist(serviceSale.discount) && <>
            <p style={{ fontSize: 14 }}>Discount (-)</p>
            <h2>{currencyCode(serviceSale)} {Util.formatMoney(serviceSale.discount)}</h2>
        </>}

        {Util.isNumberExist(serviceSale.tax) && <>
            <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Tax (+)</p>
            <h2>{currencyCode(serviceSale)} {Util.formatMoney(serviceSale.tax)}</h2>
        </>}

        {DISCOUNT_TYPE === "TAX_PLUS_DISCOUNT" && Util.isNumberExist(serviceSale.discount) && <>
            <p style={{ fontSize: 14 }}>Discount (-)</p>
            <h2>{currencyCode(serviceSale)} {Util.formatMoney(serviceSale.discount)}</h2>
        </>}

        <p style={{ marginTop: '1rem' }}>Total Amount</p>
        <h1 style={{ color: 'green' }}>{currencyCode(serviceSale)} {Util.formatMoney(serviceSale.amount)}</h1>
    </Section>
)


const ItemRecord = ({ serviceSale, item, role }) => {
    const total = useMemo(() => big(item.qty).times(big(item.unitAmount)).toFixed(2), [item])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', height: 40 }}>
            <div style={{ flex: 5, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                {!serviceSale.ticketSale && <>
                    <ProfilePic size={24} src={Api.getThumbnail(item.itemType, item.itemId)} />
                    <div style={{ width: '0.5rem' }} />
                </>}
                {(item.itemType == -1 || role == ACCOUNT_TYPE_STUDENT || role == ACCOUNT_TYPE_GUARDIAN) ? (
                    <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>
                        {item.description}
                    </p>
                ) : (
                    <Link to={getObjectTypeUrl(item.itemType) + '/' + item.itemId} target="_blank">
                        <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>
                            {item.description}
                        </p>
                    </Link>
                )}
            </div>
            {(role != ACCOUNT_TYPE_STUDENT && role != ACCOUNT_TYPE_GUARDIAN && !serviceSale.ticketSale) && <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <TypeTag type={item.itemType} />
            </div>}
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{currencyCode(serviceSale)} {Util.formatMoney(item.unitAmount)}</p>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.qty}x</p>
            </div>
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{currencyCode(serviceSale)} {Util.formatMoney(total)}</p>
            </div>
            {hasCapabilitySupport("shipping") && <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.taxRateOverride ?? VAT.FRAC}</p>
            </div>}
        </div>
    )
}

const ItemsTable = ({ serviceSale }) => {
    const items = serviceSale.items;
    const role = getAccountRole();
    return (
        <Section icon={List32} title="List">
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 5, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Description</h6>
                    </div>
                    {(role != ACCOUNT_TYPE_STUDENT && role != ACCOUNT_TYPE_GUARDIAN && !serviceSale.ticketSale) && <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Type</h6>
                    </div>}
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>{isMobileScreen ? 'Amount' : 'Unit Amount'}</h6>
                    </div>
                    <div style={{ flex: 1, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Qty</h6>
                    </div>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Total</h6>
                    </div>
                    {hasCapabilitySupport("shipping") && <div style={{ flex: 1, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Tax</h6>
                    </div>}
                </div>
                {items.map((item) =>
                    <ItemRecord serviceSale={serviceSale} key={item.id} item={item} role={role} />)}
            </div>
        </Section>
    )
}

const CourseSessionRecord = ({ serviceSale, item }) => {
    const total = useMemo(() => big(item.qty).times(big(item.unitAmount)).toFixed(2), [item])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', minHeight: 40 }}>
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.courseSessionType}</p>
            </div>
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>
                    {item.courseSessionDate}
                    {isMobileScreen && (' ' + item.courseSessionTime)}
                </p>
            </div>
            {!isMobileScreen && <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.courseSessionTime}</p>
            </div>}
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.courseSessionDuration}</p>
            </div>
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{currencyCode(serviceSale)} {Util.formatMoney(total)}</p>
            </div>
        </div>
    )
}
const CourseSessionsTable = ({ serviceSale }) => {
    const items = serviceSale.items;
    // const role = getAccountRole();
    return (
        <Section icon={List32} title="Sessions">
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Type</h6>
                    </div>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Date</h6>
                    </div>
                    {!isMobileScreen && <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Time</h6>
                    </div>}
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Duration</h6>
                    </div>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                </div>
                {items.map((item) =>
                    <CourseSessionRecord serviceSale={serviceSale} key={item.id} item={item} />)}
            </div>
        </Section>
    )
}


const AccountRecord = ({ serviceSale, item, }) => {
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', height: 40 }}>
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.accountLedgerName}</p>
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{currencyCode(serviceSale)} {Util.formatMoney(item.amount)}</p>
            </div>
            <div style={{ flex: 4, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.narration}</p>
            </div>
        </div>
    )
}

const AccountsTable = ({ serviceSale }) => {
    const items = serviceSale.accountDivisions;
    return (
        <Section icon={List32} title="Revenue Accounts">
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Account</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                    <div style={{ flex: 4, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Narration</h6>
                    </div>
                </div>
                {items.map((item) =>
                    <AccountRecord serviceSale={serviceSale} key={item.id} item={item} />)}
            </div>
        </Section>
    )
}



const PaymentButton = ({ serviceSale }) => {
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);

    if (Util.isNumberExist(serviceSale.paymentAmountUnderProcess)) {
        return (
            <div style={{ background: '#f4f4f4', border: '1px solid #00000020', borderRadius: 10, overflow: 'hidden', paddingBlock: '0.25rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <InProgress24 />
                <div>
                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>Payment under process</p>
                    <p style={{ fontSize: 12, }}>{currencyCode(serviceSale)} {Util.formatMoney(serviceSale.paymentAmountUnderProcess)}</p>
                </div>
            </div>
        )
    }


    let amount = serviceSale.amount - serviceSale.amountPaid;
    let actualAmount = undefined;
    if (Util.isNumberExist(STRIPE_PROCESSING_FEE_PERC)) {
        actualAmount = amount;
        amount = parseFloat(big(amount).times(big(1).plus(big(STRIPE_PROCESSING_FEE_PERC))).toFixed(2))
    }

    return (<>
        <Button className="green-button" onClick={() => setShowPaymentDialog(true)} renderIcon={Purchase16}>Pay Invoice</Button>
        <PaymentDialog serviceSaleId={serviceSale.id}
            actualAmount={actualAmount}
            defAmount={amount}
            visible={showPaymentDialog} onClose={() => setShowPaymentDialog(false)} />
    </>)
}

const PaymentHistory = ({ serviceSale }) => (
    <Section icon={Currency32} title="Payment History" subtitle={getAmountStatus(serviceSale).status} subtitleColor={getAmountStatus(serviceSale).statusColor}
    >
        {serviceSale.transactions.map(transaction => {
            let dividedAmount = 0;
            let processingFee = 0;
            if (transaction.againstItems && transaction.againstItems.length !== undefined) {
                for (const item of transaction.againstItems) {
                    if (item.serviceSaleId == serviceSale.id) {
                        dividedAmount = item.dividedAmount;
                        break;
                    }
                }
            }

            if (Util.isNumberExist(STRIPE_PROCESSING_FEE_PERC)) {
                processingFee = parseFloat(big(STRIPE_PROCESSING_FEE_PERC).times(big(dividedAmount)).toFixed(2))
            }

            if (isMobileScreen) {
                return (
                    <div style={{
                        marginBottom: '1rem', background: '#f4f4f4', border: '1px solid #00000020', width: '100%', padding: '1rem', borderRadius: 10, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',

                    }}>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Tag type="blue">ID: {Util.getVoucherNumber(transaction.id)}</Tag>
                        </div>
                        <div style={{ flex: 1, minWidth: '1rem' }} />
                        <p style={{ fontSize: 12 }}>{Util.getDateOnly(transaction.initiationDate)}</p>
                        <p>
                            Payment of <strong style={{ color: 'green' }}>{currencyCode(serviceSale)} {Util.formatMoney(dividedAmount)}</strong> {Util.isNumberExist(processingFee) && <span style={{ fontSize: 12, opacity: 0.65 }}>+ {processingFee} (processing fee)</span>}
                        </p>

                    </div>
                )
            }

            return (
                <div style={{
                    marginBottom: '1rem', background: '#f4f4f4', border: '1px solid #00000020', width: '100%', padding: '1rem', borderRadius: 10, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
                    display: 'flex', alignItems: 'center'
                }}>
                    <div style={{ marginRight: '0.5rem' }}>
                        <p>
                            Payment of <strong style={{ color: 'green' }}>{currencyCode(serviceSale)} {Util.formatMoney(dividedAmount)}</strong> {Util.isNumberExist(processingFee) && <span style={{ fontSize: 12, opacity: 0.65 }}>+ {processingFee} (processing fee)</span>}
                        </p>
                    </div>
                    <Tag type="blue">ID: {Util.getVoucherNumber(transaction.id)}</Tag>
                    <div style={{ flex: 1, minWidth: '1rem' }} />
                    <p style={{ fontSize: 12 }}>{Util.getDateOnly(transaction.initiationDate)}</p>
                </div>
            )
        })}
    </Section>
)

const ClassTypeInfo = ({ serviceSale }) => {
    return (
        <Section icon={GroupPresentation32} title="Class Invoice">
            <label style={{}} className="bx--label">No of classes (sessions)</label>
            <p>{serviceSale.predefinedClassTotalCount}</p>

            {/* <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Date</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Calendar32 style={{ marginRight: '0.5rem' }} />
                <p>{Util.getDate(serviceSale.initiationDate)}</p>
            </div> */}
        </Section>
    )
}


const TicketInfo = ({ serviceSale }) => {
    const openTicketQrCode = ticket => {
        UIUtil.showQrCode('TI-ID-' + btoa((ticket.id).toString()))
    }
    return (
        <Section icon={Theater32} title="Movie Ticket Info">
            <div style={{ width: '100%', background: '#f4f4f4', borderRadius: 10, overflow: 'hidden', border: '1px solid #00000020', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)', marginBottom: '1rem', display: 'flex', padding: '1rem' }}>
                <div style={{ width: 150, minWidth: 150, height: 225, borderRadius: 10, overflow: 'hidden', border: '1px solid #00000040' }}>
                    <ImageView src={Api.getThumbnail(OBJECT_TYPE_MOVIE, serviceSale.ticketSaleMovieId)} style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ marginLeft: '3rem', flex: 1 }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem', }}>
                        <h3 style={{ flex: 1 }}>{serviceSale.ticketSaleMovieName}</h3>
                        <Link to={'/movies/' + serviceSale.ticketSaleMovieId}>
                            <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                                <ArrowRight16 />
                            </button>
                        </Link>
                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        <div style={{ marginBottom: '1rem' }}>
                            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Cinema</label>
                            <p style={{}}>{serviceSale.ticketSaleCinemaName}</p>
                        </div>

                        <div style={{ marginBottom: '1rem' }}>
                            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Hall</label>
                            <p style={{}}>{serviceSale.ticketSaleHallName}</p>
                        </div>

                        <div style={{ marginBottom: '1rem' }}>
                            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Showtime</label>
                            <p style={{}}>{serviceSale.ticketSaleShowtime}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div style={{ marginTop: '1rem', display: 'flex' }}>
                <div style={{ width: 'calc(150px + 1rem + 3rem)' }}>

                </div>
                <div style={{ flex: 1 }}>
                    {serviceSale.ticketSaleTickets.map(ticket => (
                        <div key={ticket.id} style={{
                            marginBottom: '1rem', background: '#f4f4f4', border: '1px solid #00000020', width: '100%', padding: '1rem', borderRadius: 10, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
                            display: 'flex', alignItems: 'center'
                        }}>
                            <Ticket32 />
                            <div style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}>
                                <p>Ticket</p>
                            </div>
                            <Tag type='blue'>{ticket.seatName}</Tag>

                            <div style={{ flex: 1, minWidth: '1rem' }} />
                            <button onClick={() => openTicketQrCode(ticket)} className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                <QrCode20 />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </Section>
    )
}

const CourseInfo = ({ serviceSale }) => {
    const role = getAccountRole();
    const isStudent = role == ACCOUNT_TYPE_STUDENT;
    const isGuardian = role == ACCOUNT_TYPE_GUARDIAN;
    return (
        <Section icon={GroupPresentation32} title="Course">
            <div style={{ width: '100%', background: '#f4f4f4', borderRadius: 10, overflow: 'hidden', border: '1px solid #00000020', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)', marginBottom: '1rem', display: isMobileScreen ? undefined : 'flex', padding: '1rem' }}>
                <div style={{ width: isMobileScreen ? '100%' : 225, minWidth: isMobileScreen ? undefined : 225, height: 150, borderRadius: 10, overflow: 'hidden', border: '1px solid #00000040' }}>
                    <ImageView src={Api.getThumbnail(OBJECT_TYPE_COURSE, serviceSale.courseId)} style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ marginLeft: isMobileScreen ? undefined : '3rem', marginTop: isMobileScreen ? '1rem' : undefined, flex: 1 }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', marginBottom: isMobileScreen ? undefined : '0.5rem', }}>
                        <h3 style={{ flex: 1 }}>{serviceSale.courseName}</h3>
                        {!isGuardian && <Link to={isStudent ? ('/my-courses/' + serviceSale.courseId) : ('/courses/' + serviceSale.courseId)}>
                            <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                                <ArrowRight16 />
                            </button>
                        </Link>}
                    </div>
                </div>
            </div>
        </Section>
    )
}


const ShipmentInfo = ({ serviceSale }) => {
    const shipment = serviceSale.parentShipment;
    return (
        <Section icon={PlaneSea32} title="Shipment">
            <div style={{ width: '100%', background: '#f4f4f4', borderRadius: 10, overflow: 'hidden', border: '1px solid #00000020', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)', marginBottom: '1rem', padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h3 style={{ flex: 1 }}>Shipment - {shipment.shipmentNo}</h3>
                    <Link to={"/shipment-job/" + shipment.id}>
                        <Button style={{ borderRadius: 7 }} renderIcon={ArrowRight16}>Open</Button>
                    </Link>
                </div>
            </div>
        </Section>
    )
}



const View = ({ payload: serviceSale }) => {
    const role = getAccountRole();

    if (serviceSale.hasTicketOnly) {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <Title serviceSale={serviceSale} />
                    {serviceSale.ticketSale && <TicketInfo serviceSale={serviceSale} />}
                </div>
            </div>
        )
    }

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: isMobileScreen ? '0rem' : '6rem', paddingBottom: '6rem' }}>
            <div style={{ width: isMobileScreen ? '90vw' : '75vw' }}>
                <Title serviceSale={serviceSale} />
                {hasCurrency(serviceSale) && <CurrencyConvertionBanner currency={serviceSale.usingCurrency} />}
                {serviceSale.ticketSale && <TicketInfo serviceSale={serviceSale} />}
                {Util.isNumberExist(serviceSale.courseId) && <CourseInfo serviceSale={serviceSale} />}
                {serviceSale.parentShipment && <ShipmentInfo serviceSale={serviceSale} />}
                {(Util.isNumberExist(serviceSale.saleDate) || Util.isStringExists(serviceSale.refNo)) && <RecordInfo serviceSale={serviceSale} />}
                <Info serviceSale={serviceSale} />
                {serviceSale.predefinedClass === true && <ClassTypeInfo serviceSale={serviceSale} />}
                {(role != ACCOUNT_TYPE_STUDENT && role != ACCOUNT_TYPE_GUARDIAN) && serviceSale.status !== SERVICE_SALE_STATUS_REVOKED && Util.isNumberExist(serviceSale.journalEntryId) && <Section icon={TreeView32} title="Accounting">
                    <Link target="_blank" to={"/journal-entry/" + serviceSale.journalEntryId} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: undefined }}>Associated Journal Entry</Button>
                    </Link>
                </Section>}

                {(role == ACCOUNT_TYPE_STUDENT || role == ACCOUNT_TYPE_GUARDIAN) && serviceSale.transactions !== null && serviceSale.transactions.length > 0 &&
                    <PaymentHistory serviceSale={serviceSale} />}

                {!Util.isNumberExist(serviceSale.courseId) && serviceSale.items !== null && serviceSale.items.length > 0 &&
                    <ItemsTable serviceSale={serviceSale} />}
                {Util.isNumberExist(serviceSale.courseId) && serviceSale.items !== null && serviceSale.items.length > 0 &&
                    <CourseSessionsTable serviceSale={serviceSale} />}


                {/* amount list */}
                {(role != ACCOUNT_TYPE_STUDENT && role != ACCOUNT_TYPE_GUARDIAN) && serviceSale.accountDivisions !== null && serviceSale.accountDivisions.length > 0 &&
                    <AccountsTable serviceSale={serviceSale} />}


                {(role != ACCOUNT_TYPE_STUDENT && role != ACCOUNT_TYPE_GUARDIAN) && serviceSale.transactions !== null && serviceSale.transactions.length > 0 &&
                    <Transactions serviceSale={serviceSale} />}

                <AmountBreakdown serviceSale={serviceSale} />
                {(role != ACCOUNT_TYPE_STUDENT && role != ACCOUNT_TYPE_GUARDIAN) && <Initiation serviceSale={serviceSale} />}
                {(role != ACCOUNT_TYPE_STUDENT && role != ACCOUNT_TYPE_GUARDIAN) && Util.isStringExists(serviceSale.info) && <Section icon={Catalog32} title="Additional Information">
                    <p style={{ whiteSpace: 'pre-line' }}>{serviceSale.info}</p>
                </Section>}

                {(role != ACCOUNT_TYPE_STUDENT && role != ACCOUNT_TYPE_GUARDIAN) && <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                    <NoteView objectId={serviceSale.id} objectType={OBJECT_TYPE_SERVICE_SALE} readonly={serviceSale.status === SERVICE_SALE_STATUS_REVOKED} />
                </Section>}
            </div>
        </div>
    )
}

export default withLoadablePageWithParams(params => listener => Api.getServiceSale(params.serviceSaleId, listener), View)

