import React from 'react'
import Page from '../../base/Page';
import ImageView from '../../components/ImageView';
import { OBJECT_TYPE_PRODUCT } from '../../constants/ObjectTypes';
import Api from '../../session/Api';
import Util from '../../util/Util';

class ProductInfoPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            productPreview: undefined
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getProductPreview(this.getPathParams().itemId, listener), payload => ({
            productPreview: payload
        }))
    }

    getLayout() {
        return (
            <div style={{}}>
                <div style={{ height: '3rem' }} />

                <div style={{ paddingLeft: '2rem', paddingRight: '2rem', }}>
                    <h1>{this.state.productPreview.productName}</h1>
                    <h4>SAR {this.state.productPreview.amount.price}</h4>
                </div>

                <div style={{ height: '1rem' }} />

                <ImageView src={Api.getPublicThumbnail(OBJECT_TYPE_PRODUCT, this.getPathParams().itemId)} style={{ width: '100%', height: 250 }} />

                <div style={{ height: '1rem' }} />

                <div style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                    {Util.isStringExists(this.state.productPreview.productDesc) ? (
                        <p>
                            {this.state.productPreview.productDesc}
                        </p>
                    ) : (
                        <p style={{ opacity: 0.45 }}>
                            No description
                        </p>
                    )}
                </div>

                <div style={{ height: '3rem' }} />
            </div>
        )
    }

    hideGoHomeBtn() {
        return true;
    }

}

export default ProductInfoPage;