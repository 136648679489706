import { useMemo, useState } from "react";
import { withLoadablePageWithParams } from "../../../base/Page";
import { OBJECT_TYPE_FUND_TRANSFER, OBJECT_TYPE_PRODUCT } from "../../../constants/ObjectTypes";
import Api from "../../../session/Api";
import { Wheat32, ArrowRight16, Edit16, ArrowRight32, TrashCan16 } from '@carbon/icons-react'
import { Link, useHistory } from "react-router-dom";
import UIUtil from "../../../util/UIUtil";
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view";
import Button from "../../../components/Button";
import { PackageUnitsList } from "../../product/ProductDetailPage";
import { Tag } from "carbon-components-react";
import { IngredientFormDialog } from "./fields";
import { getReportLink } from "../../../reporting-engine/base/report-page";

function DeleteBtn({ item }) {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const onDeleteBtn = () => UIUtil.confirm(() => {
        setLoading(true)
        Api.deleteItem(OBJECT_TYPE_PRODUCT, item.id, response => {
            if (response.status === true) {
                history.replace(getReportLink("IngredientList"))
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    })

    return (
        <Button loading={loading} kind="danger" onClick={onDeleteBtn} size="sm" renderIcon={TrashCan16} style={{ borderRadius: 50 }}>Delete</Button>
    )
}


const ValueField = ({ title, children, style = {} }) => (
    <div style={{ marginBottom: '0.75rem', ...style }}>
        <label style={{ marginBottom: 0, opacity: 0.65, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16 }}>{children}</p>
    </div>
)

const Group = ({ title, titleMargin, children }) => (
    <div style={{ marginBottom: '3rem', }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: titleMargin ? '1rem' : undefined }}>{title}</h4>

        {children}
    </div>
)

const View = ({ payload }) => {
    const [item, setItem] = useState(payload.b);
    const [editVisible, setEditVisible] = useState(false);

    const info = useMemo(() => {
        const find = (property, list) => payload.a[list].find($ => $.id === item[property])?.value ?? "-"
        return {
            department: find("departmentId", "departments"),
            category: find("categoryId", "categories"),
            subcategory: find("subcategoryId", "subcategories"),
        }
    }, [item, payload])

    return (
        <div>
            <div style={{ paddingInline: '6rem', paddingTop: '3rem' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem' }}>
                    <div>
                        <Wheat32 style={{ width: 64, height: 64 }} />
                        <h3 style={{ fontWeight: 'bold', marginTop: '1rem' }}>{item.name}</h3>
                        <h4 style={{ color: '#0f62fe' }}>Ingredient</h4>
                        <Tag type="blue">{item._barcode}</Tag>
                    </div>
                    <div style={{ flex: 1 }} />
                    <DeleteBtn item={item} />
                    <Button onClick={() => setEditVisible(true)} size="sm" renderIcon={Edit16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Edit</Button>
                </div>


                <Group title="Details">
                    <div style={{ display: 'flex' }}>
                        <ValueField style={{ flex: 1 }} title="Current Stock">{item._stock}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Unit of Measurement">{item.uom}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Avg Cost">SAR {item._avgCost?.toFixed(2)}</ValueField>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <ValueField style={{ flex: 1 }} title="Department">{info.department}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Category">{info.category}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Subcategory">{info.subcategory}</ValueField>
                    </div>
                </Group>

                <Group title="Package Units">
                    <PackageUnitsList item={item} />
                </Group>

                <div style={{ marginTop: '6rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                    <AdvancedNotesView title="Attachments" objectId={item.id} objectType={OBJECT_TYPE_PRODUCT} />
                </div>

                <div style={{ height: '3rem' }} />

            </div>

            <IngredientFormDialog title="Updating" endpoint={payload.a} item={item} onClose={() => setEditVisible(false)} visible={editVisible} onSuccess={setItem} />
        </div>
    )
}

export const IngredientDetailPage = withLoadablePageWithParams(params => listener => Api.getIngredient(params.itemId, listener), View)