
import { Close16, ArrowRight16 } from '@carbon/icons-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ArrayInput } from '../../../../../components/array-input'
import Button from '../../../../../components/Button'
import Util, { big } from '../../../../../util/Util'
import { Link } from 'react-router-dom'
import UIUtil from '../../../../../util/UIUtil'
import print from 'print-js';
import { pdf } from '@react-pdf/renderer'
import { createLoadingList } from '../pdf/loading-list'
import { InlineLoading } from 'carbon-components-react'
import { printReceiptVoucher } from '../../../../../pdfs/receipt-voucher/ReceiptVoucherPdf'
import { printInvoice } from '../../../../../pdfs/invoice-document/InvoiceDocumentPdf'
import { TRANSACTION_DIRECTION_TYPE_INWARD } from '../../../../../constants/Constants'
import { OBJECT_TYPE_SERVICE_SALE } from '../../../../../constants/ObjectTypes'
import { TransactionPayButton } from '../../../../../views/transactions/transaction-pay-button'
import { SystemGeneratedDocumentWithPayload } from './documents'
import { SHIPMENT_DIR_TYPE } from '../..'

const ListValueEntry = ({ label, value, lg }) => (<>
    <div style={{ minHeight: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem', paddingBlock: 5 }}>
        <p style={{ fontSize: lg ? 16 : 14, opacity: 0.65, flex: 2, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: lg ? 16 : 14, flex: 1, outline: 'none', textAlign: 'end' }}>{value}</p>
    </div>
</>)

const List = ({ label, children }) => (<>
    <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>{label}</h4>
    <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '1rem' }}>
        <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            {children}
        </div>
    </div>
</>)

const getItemQty = item => item.qty ? big(item.qty) : big(1)
const formatItemAmount = item => Util.formatMoney((big(item.amount).times(getItemQty(item))).toNumber())

export function Profit({ shipment, }) {
    //REPEATED IN MASTERS AND SHIPMENT PROFIT VIEW
    const expenseRecords = useMemo(() => {
        const expenses = []
        const expense = (label, amount, href) => {
            if (Util.isNumberExist(amount)) {
                expenses.push({
                    label, amount, href
                })
            }
        }

        expense("Bill of Entry Bill", shipment.billOfEntryAmount)
        if (shipment.dirType === SHIPMENT_DIR_TYPE.IMPORT) {
            expense("Transportation Bill", shipment.transportationCharge);
        }
        const billsAgainst = shipment.billsAgainst.filter($ => !big($.against.againstAmount).eq(big(0)))
        if (billsAgainst.length) {
            const getRatio = (amount, against) => amount.eq(big(0)) ? big(0) : against.div(amount)
            const getTotalBill = bill => bill.accounts.map($ => big($.amount)).reduce((t, c) => t.add(c), big(0))

            const accounts = [...new Set(billsAgainst.flatMap($ => $.accounts).map($ => $.label))];
            const billBalances = billsAgainst.flatMap(bill => bill.accounts.map(account => ({
                acctName: account.label,
                balance: big(account.amount).times(getRatio(getTotalBill(bill), big(bill.against.againstAmount)))
            })))

            const getAcctBalance = acct => billBalances
                .filter($ => $.acctName === acct)
                .map($ => $.balance)
                .reduce((t, c) => t.add(c), big(0))

            accounts.forEach(acct => {
                expense(acct, getAcctBalance(acct).toNumber())
            })
        }
        return expenses;
    }, [shipment])

    const amounts = useMemo(() => {
        const sum = (list, property = 'amount') => (
            list.filter(item => Util.isNumberExist(item[property]))
                .map(item => big(item[property]))
                .reduce((t, c) => t.add(c), big(0))
        )
        const sumFn = (list, propertyFn) => (
            list.filter(item => Util.isNumberExist(propertyFn(item)))
                .map(item => big(propertyFn(item)))
                .reduce((t, c) => t.add(c), big(0))
        )
        const amountSum = (list) => (
            list.filter(item => Util.isNumberExist(item.amount))
                .map(item => big(item.amount).times(getItemQty(item)))
                .reduce((t, c) => t.add(c), big(0))
        )
        const number = (value) => value.round(2).toNumber();



        const revenue = amountSum([...shipment.systemDefinedCharges, ...shipment.userDefinedCharges])
            .add(sumFn(shipment.otherInvoices, invoice => invoice.totals.subTotal))

        const expense = sum(expenseRecords)

        const profitLoss = revenue.minus(expense);

        return {
            revenue: number(revenue),
            expense: number(expense),
            profitLoss: number(profitLoss)
        }
    }, [expenseRecords])

    return (
        <div style={{ width: '100%', }}>
            <List label="Overview">
                <ListValueEntry label="Revenue (+)" value={"SAR " + Util.formatMoney(amounts.revenue)} />
                <ListValueEntry label="Expense (-)" value={"SAR " + Util.formatMoney(amounts.expense)} />
                <ListValueEntry lg label={<strong>{amounts.profitLoss < 0 ? 'Loss' : 'Profit'}</strong>} value={<strong style={{ color: amounts.profitLoss < 0 ? "red" : "green" }}>SAR {Util.formatAmount(amounts.profitLoss)}</strong>} />
            </List>

            <List label="Breakdown">
                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline', color: 'green' }}>Revenue</span>} />
                {shipment.systemDefinedCharges.map(item => <ListValueEntry key={item.id} label={item.description} value={"SAR " + Util.formatMoney(item.amount)} />)}
                {shipment.userDefinedCharges.map(item => <ListValueEntry key={item.id} label={item.description} value={"SAR " + formatItemAmount(item)} />)}
                {shipment.otherInvoices.map(item => <ListValueEntry key={item.id} label={<Link to={`/service-sale/${item.fromEntityId}`}>{`Invoice #${item.invoiceNo}`}</Link>} value={"SAR " + Util.formatMoney(item.totals.subTotal)} />)}
                <ListValueEntry label={<strong>Total</strong>} value={<strong>SAR {Util.formatMoney(amounts.revenue)}</strong>} />
                <ListValueEntry />

                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline', color: 'red' }}>Expense</span>} />
                {expenseRecords.map(item => <ListValueEntry key={item.label + 'bill'} label={item.href ? <Link to={item.href}>{item.label}</Link> : item.label} value={"SAR " + Util.formatMoney(item.amount)} />)}
                <ListValueEntry label={<strong>Total</strong>} value={<strong>SAR {Util.formatMoney(amounts.expense)}</strong>} />
                <ListValueEntry />

                <ListValueEntry lg label={<strong>{amounts.profitLoss < 0 ? 'Loss' : 'Profit'}</strong>} value={<strong style={{ color: amounts.profitLoss < 0 ? "red" : "green" }}>SAR {Util.formatAmount(amounts.profitLoss)}</strong>} />
            </List>

            <div style={{ height: '6rem' }} />
        </div>
    )
}