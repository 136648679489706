import React from 'react'
import DashboardTitle from '../components/DashboardTitle'

import { Line } from 'react-chartjs-2';
import DashboardCard from '../components/DashboardCard';

import { ChartLineSmooth20 } from '@carbon/icons-react'
import { isStockEntityFifoEngine } from '../../../stock-system/stock-engine';



export default ({ dashboard }) => {
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* <DashboardTitle icon={ChartLineSmooth20} title="This week's sales revenue" /> */}
            <DashboardTitle icon={ChartLineSmooth20} title="This week's sales" />
            <DashboardCard>
                <Line data={{
                    //labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                    labels: dashboard.weekDays,
                    datasets: [
                        {
                            label: 'Revenue',
                            data: dashboard.weekSales,
                            fill: true,
                            backgroundColor: 'rgb(255, 99, 132, 0.2)',
                            borderColor: 'rgba(255, 99, 132, 1)',
                        },
                        ...(isStockEntityFifoEngine() ? [{
                            label: 'Profit',
                            data: dashboard.weekProfits,
                            fill: true,
                            backgroundColor: 'rgba(54, 162, 235, 0.4)',
                            borderColor: 'rgba(54, 162, 235, 1)',
                        }] : [])
                    ],
                }} options={{
                    scales: {
                        y: {
                            min: 0,
                            ticks: {
                                beginAtZero: true,
                                callback: function (value, index, values) {
                                    return 'SAR ' + value;
                                }
                            }
                        },
                    },
                    elements: {
                        line: {
                            tension: 0.4
                        }
                    },
                    // plugins: {
                    //     tooltip: {

                    //     }
                    // }
                }} />
            </DashboardCard>
        </div>
    )
}