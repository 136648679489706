import React, { useEffect, useState } from 'react'
import Page from '../../base/Page';

import {
    WatsonHealthTextAnnotationToggle32,
    ErrorFilled16, Save16,
    Location24,
    Book32, Phone32, ListBulleted32, Location32, ArrowLeft16, ArrowRight20,


    Account32, Printer32, Email32, Hashtag32, Wikis32, Finance32, LocationCompany32,
    TextItalic32
} from '@carbon/icons-react'

import './SystemValuesPage.scss'
import Util from '../../util/Util';
import { ButtonSet, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from 'carbon-components-react';
import Button from '../../components/Button';
import Api, { createApiWithLang } from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { TranslateBanner } from '../../templates/translate/translate-banner';

const VAL_GROUPS = [
    {
        name: "Invoice Document",
        values: {
            "usrsys_inv_doc_company_name": {
                icon: Account32,
                title: "Name",
                desc: "Full company name",
            },
            "usrsys_inv_doc_trn": {
                icon: Book32,
                title: "Company TRN",
                desc: "Tax Registration Number",
            },
            "usrsys_inv_doc_address": {
                icon: Location32,
                title: "Address",
                desc: "Full company address",
            },


            "usrsys_inv_doc_tel": {
                icon: Phone32,
                title: "Tel",
                desc: "Printed document footnote",
            },
            "usrsys_inv_doc_fax": {
                icon: Printer32,
                title: "Fax",
                desc: "Printed document footnote",
            },
            "usrsys_inv_doc_email": {
                icon: Email32,
                title: "Email",
                desc: "Printed document footnote",
            },


            "usrsys_inv_doc_bank_account_no": {
                icon: Hashtag32,
                title: "Account No",
                desc: "Company bank account",
            },
            "usrsys_inv_doc_iban": {
                icon: Wikis32,
                title: "IBAN",
                desc: "Company IBAN",
            },
            "usrsys_inv_doc_bank_name": {
                icon: Finance32,
                title: "Bank Name",
                desc: "Company bank name",
            },
            "usrsys_inv_doc_bank_branch": {
                icon: LocationCompany32,
                title: "Branch Name",
                desc: "Company bank branch",
            },
            "usrsys_inv_doc_bank_swift": {
                icon: Wikis32,
                title: "SWIFT Code",
                desc: "Company bank SWIFT",
            },
        }
    },
    ...(hasCapabilitySupport("terminalPos") ? [{
        name: "POS Receipt",
        values: {
            "usrsys_address": {
                icon: Location32,
                title: "Address",
                desc: "Printed on receipts",
            },
            "usrsys_phone": {
                icon: Phone32,
                title: "Tel",
                desc: "Printed on receipts",
            },
            "usrsys_trn": {
                icon: Book32,
                title: "TRN No",
                desc: "Printed on receipts",
            },
            "usrsys_terms_and_conditions": {
                icon: ListBulleted32,
                title: "Terms",
                desc: "Printed on receipts",
            },
            "usrsys_receipt_bottom_text": {
                icon: TextItalic32,
                title: "Bottom Text",
                desc: "A text to be printed on the bottom of the receipt",
            },
        }
    }] : [])
]



const valArr = values => {
    let arr = [];
    for (const value in values) {
        arr.push({
            key: value,
            ...values[value]
        })
    }
    return arr;
}


const ValueComponent = ({ icon, title, desc, onClick }) => {
    return (
        <div onClick={onClick} className="system-values-type-btn" style={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
            {React.createElement(icon, { style: { width: 115, height: 115 } })}
            <h2>{title}</h2>
            <p style={{ textAlign: 'center' }}>{desc}</p>
        </div>
    )
}

const EditorModal = ({ api, visible, systemValue, onClose }) => {
    // const systemValue = VALUES[systemValueKey]
    const systemValueKey = systemValue.key;

    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    const [value, setValue] = useState("")

    const onUpdateBtn = () => {
        setUpdating(true);
        api.setSystemValue(systemValueKey, value, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                onClose();
            } else {
                UIUtil.showError(response.message)
            }
            setUpdating(false)
        })
    }

    useEffect(() => {
        if (visible && Util.isStringExists(systemValueKey)) {
            setLoading(true)
            api.getSystemValue(systemValueKey, response => {
                if (response.status === true) {
                    setValue(response.payload ? response.payload : "")
                    setLoading(false)
                } else {
                    onClose();
                    UIUtil.showError(response.message)
                }
            })
        }
    }, [visible, systemValueKey])

    if (!systemValue) {
        return null;
    }

    return (
        <ComposedModal
            key="note-editor-modal"
            open={visible}
            onClose={onClose}
            size="sm">
            <ModalHeader label="Editing" title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {React.createElement(systemValue.icon, { width: 24, height: 24 })}
                    <h4 style={{ marginLeft: '0.25rem' }}>{systemValue.title}</h4>
                </div>
            } />
            <ModalBody style={{ paddingRight: '1rem' }}>
                {loading ? (
                    <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                        <InlineLoading />
                    </div>
                ) : (
                    <TextArea
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        labelText="Value"
                        placeholder="Input value" />
                )}

                {/* <div style={{height: '1rem'}} /> */}


            </ModalBody>
            <ModalFooter>
                <ButtonSet style={{ width: '100%' }}>
                    <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16} >
                        Cancel
                    </Button>
                    <Button onClick={onUpdateBtn} renderIcon={Save16} disabled={loading || updating}
                    >
                        Save
                    </Button>
                </ButtonSet>
            </ModalFooter>
        </ComposedModal>
    )
}

class SystemValuesPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            selectedValue: undefined,
            lang: "EN",
            api: Api
        }
    }

    get isEditingValue() {
        //return Util.isStringExists(this.state.selectedValue)
        return this.state.selectedValue !== undefined;
    }

    setLang(lang) {
        this.setState({
            lang, api: (!Util.isStringExists(lang) || lang === "EN") ? Api : createApiWithLang(lang)
        })
    }

    getLayout() {
        return (<>
            {hasCapabilitySupport("arabicReceipt") && <TranslateBanner lang={this.state.lang} setLang={lang => this.setLang(lang)} />}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }} className="menu-tile">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3rem' }}>
                        <WatsonHealthTextAnnotationToggle32 style={{ width: 64, height: 64, marginRight: '0.5rem' }} />
                        <div>
                            <h1>System Values</h1>
                            <p style={{ fontSize: 18 }}>Update and set system values</p>
                        </div>
                    </div>

                    {VAL_GROUPS.map(group => (
                        <div key={group.name + "-system-value-group"} style={{ marginBottom: '6rem' }}>
                            <p style={{ opacity: 0.65, marginBottom: '1rem' }}>{group.name}</p>
                            <div style={{ width: '100%', height: 2, backgroundColor: 'black', marginBottom: '1rem' }} />
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                {valArr(group.values)
                                    .map(value => <ValueComponent {...value} key={value.key + '-' + group.name + "-system-set-card"}
                                        onClick={() => this.setState({ selectedValue: value })} />)}
                            </div>
                        </div>
                    ))}

                    {/* <p style={{opacity: 0.65, marginBottom: '1rem'}}>Select system value</p>
                    <div style={{width: '100%', height: 2, backgroundColor: 'black', marginBottom: '1rem'}} />
                    <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', }}>
                        {VAL_ARR.map(value => <ValueComponent {...value} onClick={() => this.setState({ selectedValueKey: value.key })} />)}
                    </div> */}
                </div>


                {this.isEditingValue && <EditorModal
                    api={this.state.api}
                    systemValue={this.state.selectedValue}
                    visible={this.isEditingValue} onClose={() => this.setState({ selectedValue: undefined })} />}
            </div>
        </>)
    }

    isPageLoadable() {
        return false;
    }

}

export default SystemValuesPage;