import { ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput } from "carbon-components-react";
import { useEffect, useState } from "react";
import { Purchase16, ErrorFilled16 } from '@carbon/icons-react'
import Button from "../../components/Button";
import Util, { big } from "../../util/Util";
import UIUtil from "../../util/UIUtil";
import { STRIPE_API_KEY, STRIPE_PROCESSING_FEE_PERC } from "../../app/Config";
import Api from "../../session/Api";
import { showStripePayment } from "./stripe-payment";


export function PaymentDialog({ serviceSaleId, actualAmount, defAmount, visible, onClose }) {
    const [amount, setAmount] = useState(defAmount);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible) {
            setAmount(defAmount);
        }
    }, [visible])

    const onPayBtn = () => {
        const amountToPay = big(defAmount);
        const amountPaying = big(amount);

        if (amountPaying.gt(amountToPay)) {
            UIUtil.showError("You are overpaying! Max amount to pay is: SAR " + Util.formatMoney(defAmount))
            return;
        }

        setLoading(true);
        Api.stripeCreateIntentForServiceSale(serviceSaleId, amountPaying.toFixed(2), response => {
            setLoading(false);
            if (response.status === true) {
                const clientSecret = response.payload;

                showStripePayment(serviceSaleId, clientSecret, amount);
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    return (<>
        <ComposedModal open={visible} onClose={onClose}>
            <ModalHeader label="Online" title="Payment" />
            <ModalBody>
                <label className="bx--label" style={{ margin: 0 }}>Amount to Pay</label>
                {Util.isNumberExist(actualAmount) &&
                    <p style={{ fontSize: 14, opacity: 1, marginTop: '0.5rem' }}>{actualAmount.toFixed(2)} + {(defAmount - actualAmount).toFixed(2)} <span style={{ fontSize: 12, opacity: 0.65 }}>(processing fee)</span></p>}
                <h4 style={{ color: 'green', marginBottom: '1rem' }}>SAR {Util.formatMoney(defAmount)}</h4>
                <TextInput
                    style={{ width: '100%' }}
                    data-modal-primary-focus
                    value={amount}
                    onChange={e => setAmount(e.target.value.trim())}
                    labelText="Amount paying"
                    placeholder="Input amount"
                    helperText={Util.isNumberExist(STRIPE_PROCESSING_FEE_PERC) ? `Amount includes a ${(STRIPE_PROCESSING_FEE_PERC * 100).toFixed(1)}% processing fee` : undefined}
                />
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                    Close
                </Button>
                <Button loading={loading} disabled={!Util.isNumberExist(amount)} onClick={onPayBtn} renderIcon={Purchase16}>
                    Pay
                </Button>
            </ModalFooter>
        </ComposedModal>
    </>)
}