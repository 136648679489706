import { ButtonSet, Checkbox, ComboBox, ComposedModal, ContentSwitcher, DatePicker, DatePickerInput, InlineLoading, ModalBody, ModalFooter, ModalHeader, RadioTile, Switch, TextInput, TileGroup } from 'carbon-components-react'
import React, { useEffect, useMemo, useState } from 'react'

import {
    ArrowLeft16,
    OverflowMenuVertical16,
    ShoppingCart16,
    ShoppingCart24,
    Minimize16,
    ChartNetwork24,
    Receipt16,
    ChartNetwork16,
    QrCode32,
    Delete16,
    CheckmarkFilled16,

    ShoppingCatalog24, //quotation
    ShoppingCartPlus24, //sales
    SubtractAlt24, //expired
    Connect24, //ongoing

    DataVis_232,
    Store32,
    Van32,

    ErrorFilled16,
    Edit16,
    Share16
} from '@carbon/icons-react'
import Api, { createApiWithHeaders } from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';
import { Link } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_SALES_CART_WORK, OBJECT_TYPE_VENUE } from '../../../constants/ObjectTypes';
import CartList from '../components/CartList';
import { makeObservable } from '../../../util/makeObservable';
import ProductView, { ProductViewLowerHeight } from './ProductView';
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SUPERVISOR, ACCOUNT_TYPE_WAREHOUSE_KEEPER, SALES_CART_WORK_STATUS_EXPIRED, SALES_CART_WORK_STATUS_ONGOING, SALES_CART_WORK_STATUS_QUOTATION, SALES_CART_WORK_STATUS_SALES, SALES_CART_WORK_STATUS_SALES_ORDER, SOURCE_TYPE_MOBILE_UNIT, SOURCE_TYPE_STORE, SOURCE_TYPE_WAREHOUSE } from '../../../constants/Constants';
import Util from '../../../util/Util';
import { hasCapabilitySupport } from '../../../app/Capabilities';
import TransactionEditor from '../../transaction/TransactionEditor';
import usePosSession from '../state/usePosSession';
import useStore from '../../../hooks/useStore';
import Button from '../../../components/Button';
import QRCode from 'react-qr-code';
import { printPosReceipt } from '../../../pdfs/pos-receipt/PosReceiptPdf';
import { GenericPosPdfBtn, printGenericPosPdf } from '../../../pdfs/generic-pos-print/GenericPosPdf';
import { AdidasRomarioQuotationBtn, RahelaRomarioQuotationBtn, RunnersRomarioQuotationBtn, StockFlowInvoiceBtn } from '../../../pdfs/invoice-document/InvoiceDocumentPdf';
import { INVOICE_ALT_PDF } from '../../../app/Config';
import { Salesperson } from '../components/Salesperson';
import { NoTax } from '../components/NoTax';
import { Memo } from '../components/Memo';
import { getAccountRole } from '../../../session/SessionManager';
import { WorkDetails } from '../components/WorkDetails';
import { InventoryNote } from '../components/InventoryNote';
import { InventoryStatus } from '../components/InventoryStatus';
import { InventoryReadyDate } from '../components/InventoryReadyDate';
import { AdidasRomarioProformaPdf } from '../../../pdfs-alt/adidas-romario/AdidasRomarioProformaPdf';
import { RahelaRomarioProformaPdf } from '../../../pdfs-alt/rahela-romario/RahelaRomarioProformaPdf';
import { RunnersRomarioProformaPdf } from '../../../pdfs-alt/runners-romario/RunnersRomarioProformaPdf';
import NoteView from '../../../views/notes/NoteView';
import { StatusTimeline } from '../components/status-timeline';
import { ORDER_SENT, SALES_WORK_STATUSES, statusManager } from '../domain/statuses';
import { share } from '../../monitoring/TerminalMonitoringView';
import { isAdidas, isRahela, isRomario } from '../../../app/app-util';
import HappinessSlider, { ReadOnlyHappinessSlider } from '../../../components/happiness-slider/HappinessSlider';
import { InventoryAllocate } from '../components/InventoryAllocate';
import { Discounts } from '../components/Discounts';
import { Totals } from '../components/Totals';
import { openPdf, sharePdf } from '../../../markup-template-engine';
import { DeliveryDetails } from '../components/DeliveryDetails';

const Section = ({ children, icon, title, extraTopMargin, hasLink, hasButton, linkText, onLinkClick }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ flex: hasButton ? 1 : undefined }}>{title}</p>


            {hasLink && <Link style={{ marginLeft: '1rem', fontSize: 12, cursor: 'pointer' }} onClick={onLinkClick}>{linkText}</Link>}
            {/* {hasButton && <Button renderIcon={Product16} onClick={onLinkClick}>{linkText}</Button>} */}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)


const getWorkStatusInfo = status => {
    switch (status) {
        case SALES_CART_WORK_STATUS_SALES:
            return { icon: ShoppingCartPlus24, title: "Sales", color: '#00551a' }
        case SALES_CART_WORK_STATUS_QUOTATION:
            return { icon: ShoppingCatalog24, title: "Quotation", color: '#990000' }
        case SALES_CART_WORK_STATUS_SALES_ORDER:
            // return { icon: ShoppingCart24, title: "Sales Order", color: '#997d00' }
            return { icon: ShoppingCart24, title: "Proforma Invoice", color: '#997d00' }
        case SALES_CART_WORK_STATUS_EXPIRED:
            //return { icon: SubtractAlt24, title: "Expired Quotation", color: '#000000' }
            return { icon: SubtractAlt24, title: "Expired", color: '#000000' }
        case SALES_CART_WORK_STATUS_ONGOING:
            return { icon: Connect24, title: "Ongoing Work", color: "#0f62fe" }
    }
}

const Title = ({ topMargin, customerBottomMargin, ...props }) => <h6 style={{ marginBottom: !customerBottomMargin ? '0.5rem' : customerBottomMargin, marginTop: topMargin ? '1rem' : 0 }}>{props.children}</h6>

const DetailField = ({ title, value, link }) => (<>
    <label className="bx--label">{title}</label>
    {link ? (
        <Link target="_blank" to={link}>
            <p style={{ marginTop: '-0.75rem' }}>{value}</p>
        </Link>
    ) : (
        <p style={{ marginTop: '-0.75rem' }}>{value}</p>
    )}
    <div style={{ height: '1rem' }} />
</>)

const WhiteMulitDetailField = ({ title, value }) => (<>
    <label style={{ color: 'white', opacity: 0.65 }} className="bx--label">{title}</label>
    <p style={{ marginTop: '-0.75rem', whiteSpace: 'pre-wrap' }}>{value}</p>
    <div style={{ height: '0.25remrem' }} />
</>)


const WorkTypeField = ({ status, onStatusChange }) => (
    <ContentSwitcher selectedIndex={status == SALES_CART_WORK_STATUS_SALES ? 1 : 0} onChange={e => onStatusChange(e.name)}>
        <Switch name={SALES_CART_WORK_STATUS_QUOTATION} text="Quotation" />
        <Switch name={SALES_CART_WORK_STATUS_SALES} text="Sale" />
    </ContentSwitcher>
)

const WorkTypeField2 = ({ status, onStatusChange }) => (
    <ContentSwitcher selectedIndex={status == SALES_CART_WORK_STATUS_SALES ? 1 : 0} onChange={e => onStatusChange(e.name)}>
        {/* <Switch name={SALES_CART_WORK_STATUS_SALES_ORDER} text="Sales Order" /> */}
        <Switch name={SALES_CART_WORK_STATUS_SALES_ORDER} text="Proforma Invoice" />
        <Switch name={SALES_CART_WORK_STATUS_SALES} text="Sale" />
    </ContentSwitcher>
)

const NegativeButtons = ({ updating, work, negativeLoading, setNegativeLoading, onUpdated }) => {

    const onDeleteBtn = () => {
        setNegativeLoading(true)
        Api.deleteOngoingWork(work.id, response => {
            if (response.status === true) {
                onUpdated({ deleted: true })
                UIUtil.showSuccess()
            } else {
                setNegativeLoading(false)
                UIUtil.showError(response.message)
            }
        })
    }

    const onRevokeBtn = () => {
        setNegativeLoading(true)
        Api.revokeQuotation(work.id, response => {
            if (response.status === true) {
                onUpdated(response.payload)
                UIUtil.showSuccess()
            } else {
                setNegativeLoading(false)
                UIUtil.showError(response.message)
            }
        })
    }

    const onBtn = (btnFunc) => {
        UIUtil.confirmWithMsg("Are you sure? This action is irreversible and will be permanent!", btnFunc)
    }

    if (work.status == SALES_CART_WORK_STATUS_ONGOING) {
        return (
            <Button
                disabled={updating} loading={negativeLoading}
                onClick={() => onBtn(onDeleteBtn)} kind="danger" style={{ minWidth: 200 }} renderIcon={Delete16} size="lg">Delete</Button>
        )
    } else if (work.status == SALES_CART_WORK_STATUS_QUOTATION || work.status == SALES_CART_WORK_STATUS_SALES_ORDER) {
        return (
            <Button
                disabled={updating} loading={negativeLoading}
                onClick={() => onBtn(onRevokeBtn)} kind="danger" style={{ minWidth: 200 }} renderIcon={ErrorFilled16} size="lg">Revoke</Button>
            //Revoke Quotation
        )
    } else {
        return null;
    }
}


const CreateQuotationForm = ({ work, onUpdated }) => {
    const [updating, setUpdating] = useState(false)
    const [negativeLoading, setNegativeLoading] = useState(false)

    const [expireDate, setExpireDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() + 1)
        return date;
    })
    const [sendEmail, setSendEmail] = useState(false)
    const [emailAddresses, setEmailAddresses] = useState(() => Util.isStringExists(work.customerEmailAddress) ? [{
        id: Util.newTempId(),
        address: work.customerEmailAddress
    }] : [])
    const [emailAddressCursorId, setEmailAddressCursorId] = useState(Util.newTempId())

    const onUpdateBtn = () => {
        setUpdating(true)
        Api.createQuotation({
            id: work.id,
            salesCartId: work.salesCartId,

            quotationExpirationDate: expireDate.getTime(),
            quotationSendEmail: sendEmail,
            quotationEmailAddresses: sendEmail ? emailAddresses.filter(e => Util.isStringExists(e.address)).map(e => e.address) : [],
        }, response => {
            if (response.status === true) {
                onUpdated(response.payload)
                UIUtil.showSuccess()
            } else {
                setUpdating(false)
                UIUtil.showError(response.message)
            }
        })
    }

    return (<>
        <div style={{ padding: '1rem', }}>
            <Title topMargin>Creating Quotation</Title>

            {/* <label className="bx--label" style={{marginBottom: '-0.75rem'}}>Expiration Date</label> */}
            <DatePicker light dateFormat="d/m/Y" datePickerType="single" value={expireDate} onChange={e => setExpireDate(e[0])}>
                <DatePickerInput
                    // placeholder="mm/dd/yyyy"
                    placeholder="dd/mm/yyyy"
                    labelText="Expiration Date"
                />
            </DatePicker>
            <div style={{ height: '1rem' }} />

            <div style={{ cursor: 'pointer' }} onClick={() => setSendEmail(prev => !prev)}>
                <Checkbox labelText="Send email" checked={sendEmail} />
            </div>
            <div style={{ height: '1rem' }} />

            {sendEmail && <>
                <label className="bx--label" style={{ marginBottom: '-0.75rem' }}>Email Addresses</label>
                {emailAddresses.concat([{ id: emailAddressCursorId }]).map((emailAddress) => (<>
                    <div style={{ height: '0.5rem' }} />
                    <TextInput
                        key={emailAddress.id}
                        light
                        placeholder={emailAddress.id == emailAddressCursorId ? "+ address" : ""}
                        value={emailAddress.address}
                        onBlur={e => setEmailAddresses(emailAddresses => {
                            let addresses = [...emailAddresses]
                            if (!Util.isStringExists(e.target.value)) {
                                addresses = emailAddresses.filter(address => address.id != emailAddress.id)
                            }
                            return addresses;
                        })}
                        onChange={e => setEmailAddresses(emailAddresses => {
                            let addresses = [...emailAddresses]
                            let found = false;
                            for (let i = 0; i < addresses.length; i++) {
                                const address = addresses[i];
                                if (address.id == emailAddress.id) {
                                    addresses[i] = {
                                        ...address,
                                        address: e.target.value
                                    }
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                addresses.push({
                                    id: emailAddress.id,
                                    address: e.target.value
                                })
                                setEmailAddressCursorId(Util.newTempId())
                            }
                            return addresses;
                        })}
                    />
                </>))}
                <div style={{ height: '1rem' }} />
            </>}
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonSet>
                <NegativeButtons {...{ updating, onUpdated, work, negativeLoading, setNegativeLoading }} />
                <Button onClick={onUpdateBtn} style={{ minWidth: 200 }} renderIcon={CheckmarkFilled16}
                    loading={updating} disabled={negativeLoading}
                    size="lg">Create Quotation</Button>
            </ButtonSet>
        </div>
    </>)
}


const CreateSalesOrder = ({ work, onUpdated }) => {
    const [updating, setUpdating] = useState(false)
    const [negativeLoading, setNegativeLoading] = useState(false)


    const onUpdateBtn = () => {
        setUpdating(true)
        Api.createSalesOrder({
            id: work.id,
            salesCartId: work.salesCartId,
        }, response => {
            if (response.status === true) {
                onUpdated(response.payload)
                UIUtil.showSuccess()
            } else {
                setUpdating(false)
                UIUtil.showError(response.message)
            }
        })
    }

    return (<>
        <div style={{ padding: '1rem', }}>
            {/* <Title topMargin>Creating Sales Order</Title> */}
            <Title topMargin>Creating Proforma Invoice</Title>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonSet>
                <NegativeButtons {...{ updating, onUpdated, work, negativeLoading, setNegativeLoading }} />
                <Button onClick={onUpdateBtn} style={{ minWidth: 200 }} renderIcon={CheckmarkFilled16}
                    loading={updating} disabled={negativeLoading}
                    size="lg">
                    {/* Create Sales Order */}
                    Create Proforma Invoice
                </Button>
            </ButtonSet>
        </div>
    </>)
}


const CreateSalesForm = ({ sessionStore, work, onUpdated, warehouses, mobileUnits, stores }) => {
    const role = useMemo(() => getAccountRole(), [])

    const [updating, setUpdating] = useState(false)
    const [negativeLoading, setNegativeLoading] = useState(false)

    const [sourceType, setSourceType] = useState(SOURCE_TYPE_WAREHOUSE)
    const [sourceId, setSourceId] = useState(0)
    const [sourceIdPickerKey, setSourceIdPickerKey] = useState(Util.newTempId())
    const [paymentMethods, setPaymentMethods] = useState([])

    const [totals] = useStore(sessionStore, "posTotals")
    const [courierId] = useStore(sessionStore, "courierId")

    useEffect(() => {
        setSourceId(0)
        setSourceIdPickerKey(Util.newTempId())
    }, [sourceType])

    const onUpdateBtn = () => {
        setUpdating(true)
        createApiWithHeaders({
            'rbt-courier-subsidiary-id': courierId
        }).createCartSale({
            id: work.id,
            salesCartId: work.salesCartId,

            stockFlowSourceType: sourceType,
            stockFlowSourceId: sourceId,
            salesPaymentMethods: paymentMethods,
        }, response => {
            if (response.status === true) {
                onUpdated(response.payload)
                UIUtil.showSuccess()
            } else {
                setUpdating(false)
                UIUtil.showError(response.message)
            }
        })
    }

    const getSourceName = () => {
        switch (sourceType) {
            case SOURCE_TYPE_WAREHOUSE:
                return "Warehouse";
            case SOURCE_TYPE_STORE:
                return "Store";
            case SOURCE_TYPE_MOBILE_UNIT:
                return "Mobile Unit"
        }
    }

    const getSourceList = () => {
        switch (sourceType) {
            case SOURCE_TYPE_WAREHOUSE:
                return warehouses
            case SOURCE_TYPE_STORE:
                return stores
            case SOURCE_TYPE_MOBILE_UNIT:
                return mobileUnits
        }
    }

    if (role === ACCOUNT_TYPE_SALES) {
        return null;
    }

    return (<>
        <div style={{ padding: '1rem', }}>


            {work.venueId > 0 ? <>
                <Title topMargin>Creating Venue Sale (invoice)</Title>
                {/* <label className="bx--label" style={{}}>Creating venue invoice</label> */}

            </> : <>
                <Title topMargin>Creating Sale</Title>

                <label className="bx--label" style={{}}>Stock source</label>
                <TileGroup className={"horizontal-tile-radio"} valueSelected={sourceType} onChange={value => setSourceType(value)}>
                    <RadioTile light value={SOURCE_TYPE_WAREHOUSE}>
                        <RadioItem icon={DataVis_232} title="Warehouse" desc="Sell stock from warehouse" />
                    </RadioTile>
                    <RadioTile light value={SOURCE_TYPE_STORE}>
                        <RadioItem icon={Store32} title="Store" desc="Sell stock from store" />
                    </RadioTile>
                    {hasCapabilitySupport("mobileUnit") &&
                        <RadioTile light value={SOURCE_TYPE_MOBILE_UNIT}>
                            <RadioItem icon={Van32} title="Mobile unit" desc="Sell stock from mobile unit" />
                        </RadioTile>}
                </TileGroup>
                <div style={{ height: '1rem' }} />

                <ComboBox
                    key={sourceIdPickerKey}
                    light
                    titleText={getSourceName()}
                    items={getSourceList()}
                    itemToString={item => item !== null ? item.value : ""}
                    selectedItem={getSourceList().filter(item => item.id == sourceId)[0]}
                    //initialSelectedItem={this.state[stateValue(field.property)]}
                    //onChange={e => this.setState({sourceIdValue: e.selectedItem !== null ? e.selectedItem.id : 0})} 
                    onChange={e => {
                        setSourceId(e.selectedItem !== null ? e.selectedItem.id : 0)
                        setSourceIdPickerKey(Util.newTempId())
                    }}
                />
                <div style={{ height: '1rem' }} />

                <div style={{ height: '1rem' }} />
                <Title topMargin>Payment</Title>
                <TransactionEditor
                    posMode
                    onUpdate={paymentMethods => setPaymentMethods(paymentMethods)}
                    hideDirection
                    currency={"SAR"}
                    incoming
                    totalAmount={totals.total !== null ? totals.total : 0}
                    customerId={work.customerId}
                    hasCourierPM={!!courierId}
                />
            </>}


        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonSet>
                <NegativeButtons {...{ updating, onUpdated, work, negativeLoading, setNegativeLoading }} />
                <Button onClick={onUpdateBtn} style={{ minWidth: 200 }} renderIcon={CheckmarkFilled16}
                    loading={updating} disabled={negativeLoading}
                    size="lg">Create Sale</Button>
            </ButtonSet>
        </div>
    </>)
}


const OngoingForm = ({ sessionStore, work, onUpdated, warehouses, mobileUnits, stores }) => {
    const [workStatus, setWorkStatus] = useState(SALES_CART_WORK_STATUS_QUOTATION)


    return (<>
        {/* <div style={{ padding: '1rem', }}>
            <Title>Work Type</Title>
            <WorkTypeField status={workStatus} onStatusChange={setWorkStatus} />
        </div> */}

        {workStatus == SALES_CART_WORK_STATUS_QUOTATION && <CreateQuotationForm work={work} onUpdated={onUpdated} />}
        {/* {workStatus == SALES_CART_WORK_STATUS_SALES && <CreateSalesForm {...{ sessionStore, work, onUpdated, warehouses, mobileUnits, stores }} />} */}
    </>)
}

const QuotationForm = ({ sessionStore, work, onUpdated, warehouses, mobileUnits, stores }) => {
    const role = useMemo(() => getAccountRole(), [])
    const [workStatus, setWorkStatus] = useState(SALES_CART_WORK_STATUS_SALES_ORDER)


    return (<>
        {role !== ACCOUNT_TYPE_SALES && <div style={{ padding: '1rem', }}>
            <Title>Work Type</Title>
            <WorkTypeField2 status={workStatus} onStatusChange={setWorkStatus} />
        </div>}

        {workStatus == SALES_CART_WORK_STATUS_SALES_ORDER && <CreateSalesOrder work={work} onUpdated={onUpdated} />}
        {workStatus == SALES_CART_WORK_STATUS_SALES && <CreateSalesForm {...{ sessionStore, work, onUpdated, warehouses, mobileUnits, stores }} />}
    </>)
}



const QuotationDetail = ({ work }) => {
    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '0.5rem' }}>
            <Title>Quotation Details</Title>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <DetailField title="Quotation No" value={Util.getVoucherNumber(work.id)} />
                </div>
                <div style={{ flex: 1 }}>
                    <DetailField title="Quotation Date" value={Util.getDateOnly(work.quotationCreatedDate)} />
                </div>
                <div style={{ flex: 1, color: 'red' }}>
                    <DetailField title="Expiration Date" value={Util.getDateOnly(work.quotationExpirationDate)} />
                </div>
            </div>
        </div>
    )
}

const Attachments = ({ work }) => {
    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem' }}>
            <Title>Attachments</Title>
            <NoteView light objectId={work.id} objectType={OBJECT_TYPE_SALES_CART_WORK} />
        </div>
    )
}


function OrderSentFields({ statusOps, work, onUpdated }) {
    const [loading, setLoading] = useState(false);

    const orderSent = statusOps(ORDER_SENT)
    if (!orderSent.done) {
        return <div />;
    }

    const onUpdateBtn = async () => {
        const input = await UIUtil.inputPrompt({ label: "Shipment Tracking No", title: "Update", initialValue: work.shipmentTrackingNo })
        if (!input) {
            return;
        }

        setLoading(true)
        try {
            const update = await Api.asyncSetSaleWorkShipmentTrackingNo(work.id, input);
            onUpdated(update);
            UIUtil.showSuccess(true)
        } catch (e) {
            UIUtil.showError(e?.roboErpApiErr ? e.msg : "")
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div style={{ borderRadius: 5, background: '#1c1c1c', color: 'white', padding: '1rem', marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1, }}>
                <h6>Shipment Tracking No</h6>
                {Util.isStringExists(work.shipmentTrackingNo) && <p>{work.shipmentTrackingNo}</p>}
                {!Util.isStringExists(work.shipmentTrackingNo) && <p style={{ fontSize: 12, opacity: 0.65 }}>Not specified</p>}
            </div>
            {orderSent.current &&
                <Button loading={loading} onClick={onUpdateBtn} renderIcon={Edit16} style={{ borderRadius: 7, alignSelf: 'center' }}>Update</Button>}
        </div>
    )
}

function CustomerFeedback({ work }) {

    if (!work.customerFeedbackSubmitted) {
        return <div />
    }

    return (
        <div style={{ borderRadius: 5, background: '#1c1c1c', color: 'white', padding: '1rem', marginTop: '0.5rem', }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <h6 style={{ flex: 1 }}>Customer Feedback</h6>
                <div style={{ flex: 1, }}>
                    <ReadOnlyHappinessSlider value={work.customerRate} />
                </div>
            </div>
            <WhiteMulitDetailField title={"Additional Feedback"} value={Util.isStringExists(work.customerReview) ? work.customerReview : "-"} />
        </div>
    )
}

const StatusSection = ({ work, onUpdated }) => {
    const [loading, setLoading] = useState(false);

    const setStatus = async status => {
        if (loading) {
            return;
        }

        const confirmed = await UIUtil.confirmPrompt({ message: "Are you sure you want to set the status to: " + status })
        if (!confirmed) {
            return;
        }

        // const stopLoading = await UIUtil.pageLoader()
        setLoading(true)
        try {
            const update = await Api.asyncSetSaleWorkState(work.id, status)
            onUpdated(update);
            UIUtil.showSuccess(true)
        } catch (e) {
            UIUtil.showError(e?.roboErpApiErr ? e.msg : "")
            console.log(e)
        } finally {
            setLoading(false)
        }
    }
    const statusOps = statusManager(work)

    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem', pointerEvents: loading ? 'none' : undefined }}>
            <Title>Status</Title>
            <StatusTimeline statusReport={work.statusReport.status} work={work} onStatusSelected={setStatus} />
            <OrderSentFields {...{ statusOps, work, onUpdated }} />
            <CustomerFeedback {...{ work }} />
        </div>
    )
}


const SalesOrderDetail = ({ work }) => {
    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '0.5rem' }}>
            {/* <Title>Sales Order Details</Title> */}
            <Title>Proforma Invoice Details</Title>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    {/* <DetailField title="Sales Order Voucher No" value={Util.getVoucherNumber(work.salesOrderId)} /> */}
                    <DetailField title="Proforma Invoice Voucher No" value={Util.getVoucherNumber(work.salesOrderId)} />
                </div>
                <div style={{ flex: 1 }}>
                    {/* <DetailField title="Sales Order Date" value={Util.getDateOnly(work.salesOrderCreationDate)} /> */}
                    <DetailField title="Proforma Invoice Date" value={Util.getDateOnly(work.salesOrderCreationDate)} />
                </div>
                <div style={{ flex: 1 }} />
            </div>

            {/* <Title>Created From Quotation</Title>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <DetailField title="Quotation No" value={Util.getVoucherNumber(work.id)} />
                </div>
                <div style={{flex: 1}}>
                    <DetailField title="Quotation Date" value={Util.getDateOnly(work.quotationCreatedDate)} />
                </div>
                <div style={{flex: 1}} />
            </div> */}
        </div>
    )
}


const SalesDetail = ({ work }) => {
    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '0.5rem' }}>
            <Title>Sales Details</Title>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <DetailField title="Sales Voucher No" value={Util.getVoucherNumber(work.salesStockFlowId)} />
                </div>
                <div style={{ flex: 1 }}>
                    <DetailField title="Sales Date" value={Util.getDateOnly(work.salesCreationDate)} />
                </div>
                <div style={{ flex: 1 }}>
                    <DetailField title="Sales Stock Source" value={work.stockFlowSourceName} />
                </div>
            </div>
        </div>
    )
}


const ExpiredDetail = ({ work }) => {
    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '0.5rem' }}>
            <Title>Expiration Details</Title>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <DetailField title="Expiration Reason" value={work.revoked ? 'Revoked' : 'Expired'} />
                </div>
                <div style={{ flex: 1 }}>
                    {work.revoked ? (
                        <DetailField title="Revoke Date" value={Util.getDateOnly(work.revokedDate)} />
                    ) : (
                        <DetailField title="Expiration Date" value={Util.getDateOnly(work.quotationExpirationDate)} />
                    )}
                </div>
                <div style={{ flex: 1 }}>
                    <DetailField title="Original Status" value={getWorkStatusInfo(work.originalStatus).title} />
                </div>
            </div>
        </div>
    )
}

const DocumentsView = ({ work, sessionStore }) => {
    if (work.status != SALES_CART_WORK_STATUS_QUOTATION && work.status != SALES_CART_WORK_STATUS_SALES_ORDER && work.status != SALES_CART_WORK_STATUS_SALES) {
        return null;
    }
    switch (work.status) {
        case SALES_CART_WORK_STATUS_QUOTATION:
            return (
                <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '0.5rem' }}>
                    <Title>Documents</Title>
                    <div style={{ display: 'flex', paddingBottom: '0.5rem' }}>
                        <div style={{ flex: 1 }}>
                            {hasCapabilitySupport("templateEngineQuotation") ? (
                                <Button onClick={() => openPdf('Quotation', work.id)} style={{ width: '100%' }} renderIcon={Receipt16}>Quotation</Button>
                            ) : INVOICE_ALT_PDF === "adidas-romario" ? (
                                <AdidasRomarioQuotationBtn quotationId={work.id} salesOrder={false} />
                            ) : INVOICE_ALT_PDF === "rahela-romario" ? (
                                <RahelaRomarioQuotationBtn quotationId={work.id} salesOrder={false} />
                            ) : INVOICE_ALT_PDF === "runners-romario" ? (
                                <RunnersRomarioQuotationBtn quotationId={work.id} salesOrder={false} />
                            ) : (
                                <GenericPosPdfBtn title="Quotation" icon={Receipt16} posStore={sessionStore}
                                    details={[
                                        { title: "Quotation No", value: Util.getVoucherNumber(work.id) },
                                        { title: "Quotation Date", value: Util.getDateOnly(work.quotationCreatedDate) },
                                        { title: "Expiration Date", value: Util.getDateOnly(work.quotationExpirationDate) },
                                    ]} />
                            )}
                        </div>
                        <div style={{ flex: 1 }}>
                            {hasCapabilitySupport("templateEngineQuotation") && (
                                <Button kind="secondary" onClick={() => sharePdf('Quotation', work.id)} style={{ width: '100%' }} renderIcon={Share16}>Share Quotation</Button>
                            )}
                            {/* <Button style={{width: '100%'}} renderIcon={Receipt16}>Order print</Button> */}
                        </div>
                        <div style={{ flex: 1 }}>
                            {/* <DetailField title="Original Status" value={getWorkStatusInfo(work.originalStatus).title} /> */}
                        </div>
                    </div>
                </div>
            )
        case SALES_CART_WORK_STATUS_SALES_ORDER:
            return (
                <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '0.5rem' }}>
                    <Title>Documents</Title>
                    <div style={{ display: 'flex', paddingBottom: '0.5rem' }}>
                        <div style={{ flex: 1 }}>
                            {/* <GenericPosPdfBtn title="Sales Order" icon={Receipt16} posStore={sessionStore}
                                details={[
                                    { title: "Sales Order Voucher No", value: Util.getVoucherNumber(work.salesOrderId) },
                                    { title: "Sales Order Date", value: Util.getDateOnly(work.salesOrderCreationDate) },
                                ]} /> */}

                            {hasCapabilitySupport("templateEngineQuotation") ? (
                                <Button onClick={() => openPdf('ProformaInvoice', work.id)} style={{ width: '100%' }} renderIcon={Receipt16}>Proforma Invoice</Button>
                            ) : INVOICE_ALT_PDF === "adidas-romario" ? (
                                <AdidasRomarioQuotationBtn quotationId={work.id} salesOrder PDFComponent={AdidasRomarioProformaPdf} />
                            ) : INVOICE_ALT_PDF === "rahela-romario" ? (
                                <RahelaRomarioQuotationBtn quotationId={work.id} salesOrder PDFComponent={RahelaRomarioProformaPdf} />
                            ) : INVOICE_ALT_PDF === "runners-romario" ? (
                                <RunnersRomarioQuotationBtn quotationId={work.id} salesOrder PDFComponent={RunnersRomarioProformaPdf} />
                            ) : (
                                <GenericPosPdfBtn title="Proforma Invoice" icon={Receipt16} posStore={sessionStore}
                                    details={[
                                        { title: "Proforma Invoice Voucher No", value: Util.getVoucherNumber(work.salesOrderId) },
                                        { title: "Proforma Invoice Date", value: Util.getDateOnly(work.salesOrderCreationDate) },
                                    ]} />
                            )}
                        </div>
                        <div style={{ flex: 1 }}>
                            {/* <Button style={{width: '100%'}} renderIcon={Receipt16}>Order print</Button> */}
                            {hasCapabilitySupport("templateEngineQuotation") && (
                                <Button kind="secondary" onClick={() => sharePdf('ProformaInvoice', work.id)} style={{ width: '100%' }} renderIcon={Share16}>Share Proforma</Button>
                            )}
                        </div>
                        <div style={{ flex: 1 }}>
                            {/* <DetailField title="Original Status" value={getWorkStatusInfo(work.originalStatus).title} /> */}
                        </div>
                    </div>
                </div>
            )
        case SALES_CART_WORK_STATUS_SALES:
            return (
                <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '0.5rem' }}>
                    <Title>Documents</Title>
                    <div style={{ display: 'flex', paddingBottom: '0.5rem' }}>
                        <div style={{ flex: 1 }}>
                            {/* <Button style={{width: '100%'}} renderIcon={Receipt16}>Delivery Order</Button> */}
                            <GenericPosPdfBtn title="Delivery Note" icon={Receipt16} posStore={sessionStore}
                                showReceiverSign
                                details={[
                                    { title: "Customer", value: work.customerName },
                                    { title: "Invoice No", value: Util.getVoucherNumber(work.salesStockFlowId) },
                                    { title: "Date", value: Util.getDateOnly(work.salesCreationDate) },
                                ]} />
                        </div>
                        <div style={{ flex: 1 }}>
                            {/* <GenericPosPdfBtn title="Sales Invoice" icon={Receipt16} posStore={sessionStore}
                                details={[
                                    { title: "Sales Voucher No", value: Util.getVoucherNumber(work.salesStockFlowId) },
                                    { title: "Sales Date", value: Util.getDateOnly(work.salesCreationDate) },
                                    // {title: "Sales Stock Source", value: work.stockFlowSourceName },
                                ]} /> */}
                            <StockFlowInvoiceBtn stockFlowId={work.salesStockFlowId} />
                        </div>
                        <div style={{ flex: 1 }}>
                            {/* <DetailField title="Original Status" value={getWorkStatusInfo(work.originalStatus).title} /> */}
                            {hasCapabilitySupport("templateEngineQuotation") && (
                                <Button kind="secondary" onClick={() => sharePdf('TaxInvoice', work.salesStockFlowId)} style={{ width: '100%' }} renderIcon={Share16}>Share Tax Invoice</Button>
                            )}
                        </div>
                    </div>
                </div>
            )
    }
    // return (
    //     <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '0.5rem' }}>
    //         <Title>Documents</Title>
    //         <div style={{display: 'flex', paddingBottom: '0.5rem'}}>
    //             <div style={{flex: 1}}>
    //                 <Button style={{width: '100%'}} renderIcon={Receipt16}>Voucher print</Button>
    //             </div>
    //             <div style={{flex: 1}}>
    //                 <Button style={{width: '100%'}} renderIcon={Receipt16}>Order print</Button>
    //             </div>
    //             <div style={{flex: 1}}>
    //                 {/* <DetailField title="Original Status" value={getWorkStatusInfo(work.originalStatus).title} /> */}
    //             </div>
    //         </div>
    //     </div>
    // )
}

const WorkView = ({ sessionStore, work, onUpdated, warehouses, mobileUnits, stores, inventoryStatuses, preloadedWork, skipProductView }) => {
    const role = useMemo(() => getAccountRole(), [])
    const warehouseKeeper = role == ACCOUNT_TYPE_WAREHOUSE_KEEPER;
    return (
        <div style={{ width: '100%', flex: 1, overflow: 'auto', paddingTop: '1rem' }}>
            <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '0rem' }}>
                <Title>Work Info</Title>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <DetailField title="Label" value={Util.isStringExists(work.label) ? work.label : "Unlabeled"} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <DetailField title={work.venueId > 0 ? "Venue" : "Customer"} value={work.customerName} link={getObjectTypeUrl(work.venueId > 0 ? OBJECT_TYPE_VENUE : OBJECT_TYPE_CUSTOMER)
                            + "/" + (work.venueId > 0 ? work.venueId : work.customerId)} />
                    </div>
                    <div style={{ flex: 1 }} />
                </div>
            </div>

            <StatusSection work={work} onUpdated={onUpdated} />
            <Attachments work={work} />

            {work.status == SALES_CART_WORK_STATUS_QUOTATION &&
                <QuotationDetail work={work} />}
            {work.status == SALES_CART_WORK_STATUS_SALES_ORDER &&
                <SalesOrderDetail work={work} />}
            {work.status == SALES_CART_WORK_STATUS_SALES &&
                <SalesDetail work={work} />}
            {work.status == SALES_CART_WORK_STATUS_EXPIRED &&
                <ExpiredDetail work={work} />}

            <WorkDetails editable={(work.status == SALES_CART_WORK_STATUS_ONGOING || work.status == SALES_CART_WORK_STATUS_QUOTATION || work.status == SALES_CART_WORK_STATUS_SALES_ORDER) && !warehouseKeeper} sessionStore={sessionStore} />

            {hasCapabilitySupport("extendedPosCheckout") && <>
                <div style={{ paddingLeft: '1rem', paddingRight: '1rem', }}>
                    <Title>Delivery Details</Title>
                </div>
                <DeliveryDetails editable={(work.status == SALES_CART_WORK_STATUS_ONGOING || work.status == SALES_CART_WORK_STATUS_QUOTATION || work.status == SALES_CART_WORK_STATUS_SALES_ORDER) && !warehouseKeeper} sessionStore={sessionStore} />
            </>}


            {(work.status == SALES_CART_WORK_STATUS_QUOTATION || work.status == SALES_CART_WORK_STATUS_SALES_ORDER) &&
                <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem' }}>
                    <Title>Inventory Details</Title>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
                        <div style={{ flex: 1 }}>
                            <InventoryStatus sessionStore={sessionStore} statuses={inventoryStatuses} editable={role === ACCOUNT_TYPE_ADMINISTRATION || role === ACCOUNT_TYPE_SUPERVISOR || role === ACCOUNT_TYPE_WAREHOUSE_KEEPER} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <InventoryReadyDate sessionStore={sessionStore} editable={role === ACCOUNT_TYPE_ADMINISTRATION || role === ACCOUNT_TYPE_SUPERVISOR || role === ACCOUNT_TYPE_WAREHOUSE_KEEPER} />
                        </div>
                        <div style={{ flex: 2 }}>
                            <InventoryNote sessionStore={sessionStore} editable={role === ACCOUNT_TYPE_ADMINISTRATION || role === ACCOUNT_TYPE_SUPERVISOR || role === ACCOUNT_TYPE_WAREHOUSE_KEEPER} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <InventoryAllocate
                                stores={stores}
                                warehouses={warehouses}
                                mobileUnits={mobileUnits}
                                sessionStore={sessionStore}
                                editable={role === ACCOUNT_TYPE_ADMINISTRATION || role === ACCOUNT_TYPE_SUPERVISOR || role === ACCOUNT_TYPE_WAREHOUSE_KEEPER} />
                        </div>
                    </div>
                </div>}

            <DocumentsView work={work} sessionStore={sessionStore} />

            <CartList sessionStore={sessionStore}
                preventCustomPrice={work.status == SALES_CART_WORK_STATUS_SALES || work.status == SALES_CART_WORK_STATUS_EXPIRED}
                hasActions={(work.status == SALES_CART_WORK_STATUS_ONGOING || work.status == SALES_CART_WORK_STATUS_QUOTATION || work.status == SALES_CART_WORK_STATUS_SALES_ORDER) && !warehouseKeeper}
                preloadedWork={preloadedWork} />
            <div style={{ height: '2rem' }} />

            {hasCapabilitySupport("extendedPosCheckout") && skipProductView && ((work.status == SALES_CART_WORK_STATUS_ONGOING || work.status == SALES_CART_WORK_STATUS_QUOTATION || work.status == SALES_CART_WORK_STATUS_SALES_ORDER) && !warehouseKeeper) && <>
                <div style={{}}>
                    <div style={{ paddingLeft: '1rem', paddingRight: '1rem', }}>
                        <Title>Products</Title>
                    </div>
                    <div style={{ paddingInline: '1rem' }}>
                        <ProductViewLowerHeight mainSessionStore={sessionStore} hideCollapseBtn />
                    </div>
                </div>
            </>}

            <Discounts sessionStore={sessionStore}
                editable={(work.status == SALES_CART_WORK_STATUS_ONGOING || work.status == SALES_CART_WORK_STATUS_QUOTATION || work.status == SALES_CART_WORK_STATUS_SALES_ORDER) && !warehouseKeeper} />
            <div style={{ height: '2rem' }} />

            <Totals sessionStore={sessionStore} />
            <div style={{ height: '2rem' }} />

            {(!warehouseKeeper || isRahela()) && <>
                {work.status == SALES_CART_WORK_STATUS_ONGOING && <OngoingForm {...{ sessionStore, work, onUpdated, warehouses, mobileUnits, stores }} />}
                {work.status == SALES_CART_WORK_STATUS_QUOTATION && <QuotationForm {...{ sessionStore, work, onUpdated, warehouses, mobileUnits, stores }} />}
                {work.status == SALES_CART_WORK_STATUS_SALES_ORDER && <CreateSalesForm {...{ sessionStore, work, onUpdated, warehouses, mobileUnits, stores }} />}
            </>}

        </div>
    )
}

function customerTrackingUrl(work) {
    // window.location.origin.toString() + "/client-monitor/" + work.session.terminalId + "/" + work.session.currentCartId
    //REPEATED
    return window.location.origin.toString() + "/sw-tracking/" + work.hashId;
}

export default ({ workId, onBack, warehouses, mobileUnits, stores, skipProductView, inventoryStatuses, preloadedWork }) => {
    const [work, setWork] = useState();
    const sessionStore = useMemo(() => {
        if (work) {
            const store = makeObservable()
            store.setObject({ ...work.session, parentId: work.id })
            return store;
        } else {
            return null;
        }
    }, [work])

    const [showQrCode, setShowQrCode] = useState(false)

    const onUpdated = update => {
        if (update && update.deleted) {
            onBack({ ...work, deleted: true })
        } else {
            setWork(update)
        }
    }
    const goBack = () => {
        onBack(work)
    }

    useEffect(() => {
        Api.getSalesCartWork(workId, response => {
            if (response.status === true) {
                setWork({ ...response.payload })
            } else {
                UIUtil.showError(response.message);
                goBack()
            }
        })
    }, [])



    const { icon, title, color } = work ? getWorkStatusInfo(work.status) : {
        icon: ChartNetwork24, title: "Loading...", color: '#000000'
    }

    return (<>
        {!skipProductView && work && (work.status == SALES_CART_WORK_STATUS_ONGOING || SALES_CART_WORK_STATUS_QUOTATION) && <ProductView mainSessionStore={sessionStore} collapseRequest={true} />}
        <div className="dashboard-card" style={{ padding: '0rem', flex: 2, display: 'flex', flexDirection: 'column', minWidth: 0, background: 'black' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button kind="ghost" tooltipPosition="bottom" iconDescription="Back" tooltipAlignment="end" hasIconOnly renderIcon={ArrowLeft16} onClick={goBack} />
                {React.createElement(icon, { style: { marginRight: '0.25rem', marginLeft: '0.5rem', color } })}
                <h4 style={{ flex: 1, color }}>{title}</h4>

                {work && isAdidas() &&
                    <Button onClick={() => setShowQrCode(true)} kind="secondary" renderIcon={QrCode32}>Customer Tracking</Button>}
            </div>
            {work ? <WorkView {...{ sessionStore, work, onUpdated, warehouses, mobileUnits, stores, inventoryStatuses, preloadedWork, skipProductView }} /> :
                <div className="centered-progress-bar" style={{ width: '100%', flex: 1, display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <InlineLoading />
                </div>}
        </div>

        {work && //work.status == SALES_CART_WORK_STATUS_ONGOING && 
            <ComposedModal size="sm" open={showQrCode} onClose={() => setShowQrCode(false)}>
                {/* <ModalHeader label="Remote Monitoring" title="Cart QR Code" /> */}
                <ModalHeader label="Customer Tracking" title="QR Code" />
                <ModalBody style={{ paddingRight: '1rem' }}>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        <QRCode value={customerTrackingUrl(work)} />
                        <a style={{ textAlign: 'center', fontSize: 14 }} target='_blank' href={customerTrackingUrl(work)}>{customerTrackingUrl(work)}</a>
                    </div>
                </ModalBody>
                <ModalFooter
                >
                    <Button kind="secondary" onClick={() => setShowQrCode(false)}>
                        Close
                    </Button>
                    <Button kind="primary" onClick={() => share(customerTrackingUrl(work), () => alert("Copy link: " + customerTrackingUrl(work)))}>
                        Share Link
                    </Button>
                </ModalFooter>
            </ComposedModal>}
    </>)
}