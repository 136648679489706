import Logo from '../../images/Logo.png'
import SquareLogo from '../../images/SquareLogo.png'
import picardlogo from '../../images/other/picard/picardlogo.png'
import { THERMAL_RECEIPT_LOGO } from "../../app/Config";
import { Page, Text, View, Image, Document, StyleSheet, pdf, usePDF, Svg, Path, Font } from '@react-pdf/renderer';
import { hasCapabilitySupport } from '../../app/Capabilities';
import Util from '../../util/Util';
import { paymentMethodText } from '../../pages/transaction/TransactionEditor';
import { ToWords } from 'to-words';
import { isPicard } from '../../app/app-util';

const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
        currency: false,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
    }
});

const numToWord = num =>
    toWords.convert(num)
        .toLowerCase()
        .replace("dollar", "riyal")
        .replace("cent", "halala");


const GRAY = "#F2F2F2";
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
});

const imageProps = THERMAL_RECEIPT_LOGO.squareLogo ? { style: { width: THERMAL_RECEIPT_LOGO.width, height: THERMAL_RECEIPT_LOGO.height, objectFit: 'contain', objectPosition: 'center', maxHeight: 100 }, src: SquareLogo } : { style: { width: 178, height: 37, objectFit: 'contain', objectPosition: 'center' }, src: Logo }

function againstOfInfo(against) {
    if (against.stockFlowId > 0) {
        return { id: against.stockFlowId, voucherNo: Util.getVoucherNumber(against.stockFlowId), type: "Invoice" };
    } else if (against.invoiceDocumentId > 0) {
        return { id: against.invoiceDocumentId, voucherNo: Util.getVoucherNumber(against.invoiceDocumentId), type: "Invoice" };
    } else if (against.billId > 0) {
        return { id: against.billId, voucherNo: Util.getVoucherNumber(against.billId), type: "Bill" };
    } else if (against.purchaseId > 0) {
        return { id: against.purchaseId, voucherNo: Util.getVoucherNumber(against.purchaseId), type: "Bill" };
    } else if (against.serviceSaleId > 0) {
        return { id: against.serviceSaleId, voucherNo: Util.getVoucherNumber(against.serviceSaleId), type: "Invoice" };
    } else if (against.saleId > 0) {
        return { id: against.saleId, voucherNo: Util.getVoucherNumber(against.saleId), type: "Invoice" };
    } else if (against.payoutId > 0) {
        return { id: against.payoutId, voucherNo: Util.getVoucherNumber(against.payoutId), type: "Payout" };;
    } else if (against.commissionId > 0) {
        return { id: against.commissionId, voucherNo: Util.getVoucherNumber(against.commissionId), type: "Commission" };;
    } else if (against.v2SaleId > 0) {
        return { id: against.v2SaleId, voucherNo: against.againstVoucherNo, type: "Invoice" };
    } else if (against.v2BillId > 0) {
        return { id: against.v2BillId, voucherNo: against.againstVoucherNo, type: "Bill" };
    } else {
        return { id: 0, voucherNo: "", type: "" };
    }
}

Font.register({
    family: "Cairo",
    fonts: [
        {
            src: "//fonts.gstatic.com/s/cairo/v1/-tPnHq7mmAjcjJRSjsuZGA.ttf"
        },
    ]
})

export function NewPdf({ item }) {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                    {hasCapabilitySupport("extendedPosCheckout") && <View style={{ flex: 1 }} />}
                    {isPicard() ? <>
                        <Image src={picardlogo} style={{ width: 150, height: 80, objectFit: 'contain', objectPosition: 'top left' }} />
                    </> : <>
                        <Image {...imageProps} />
                    </>}
                    <View style={{ flex: 1 }} />
                    {hasCapabilitySupport("shipping") && <>
                        {isPicard() ? <>
                            <View style={{ marginTop: 0, alignItems: 'flex-end' }}>
                                <Text style={{ fontSize: 12, marginBottom: 0, lineHeight: 1.4, fontFamily: "Helvetica-Bold", textAlign: 'right', color: '#00148C' }}>{"Picard Logistics"}</Text>
                                <Text style={{ fontSize: 10, fontFamily: "Helvetica", lineHeight: 1.4, textAlign: 'right', color: '#00148C' }}>
                                    {"FZS5 BB02, 1209 Street\nJebel Ali Free Zone [Jafza]\nDubai, UAE, P.O. Box: 17728\nInfo@picardlogistics.com\n+971 4 3588698"}
                                </Text>
                            </View>
                        </> : <>
                            <View style={{ marginTop: 0, alignItems: 'flex-end' }}>
                                <Text style={{ fontSize: 12, marginBottom: 0, lineHeight: 1.4, fontFamily: "Helvetica-Bold", textAlign: 'right', color: '#00148C' }}>{"EXCELLS SHIPPING LLC"}</Text>
                                <Text style={{ fontSize: 10, fontFamily: "Helvetica", lineHeight: 1.4, textAlign: 'right', color: '#00148C' }}>{"P.O.BOX: 63067, DUBAI - U.A.E\nTEL: +971 4 2209620, FAX: +971 4 2209610\nEmail: info@excellsshipping.com\nWebsite: www.excellsshipping.com"}</Text>
                            </View>
                        </>}
                    </>}
                </View>
                <Text style={{ width: '100%', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 14, borderBottom: '1px solid black', paddingBottom: 5 }}>Receipt Voucher</Text>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 25 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 14 }}>Amount: </Text>
                        <Text style={{ minWidth: 100, padding: 5, border: '2px solid black', textAlign: 'center', lineHeight: 0, fontSize: 14 }}>SAR {Util.formatMoney(item.amount)}</Text>
                    </View>

                    <View style={{ flex: 1 }} />

                    <View style={{ flexDirection: 'row', width: 200 }}>
                        <View style={{ paddingRight: 5 }}>
                            <Text style={{ fontSize: 14, }}>No: </Text>
                            <Text style={{ fontSize: 14 }}>Date: </Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: 14, textAlign: 'right' }}>{Util.getTransactionVoucherNumber(item.id, item.directionType, item.sequenceNo)}</Text>
                            <Text style={{ fontSize: 14, textAlign: 'right' }}>{Util.getDateOnly(item.transactionDate > 0 ? item.transactionDate : item.initiationDate)}</Text>
                        </View>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                    <View style={{ paddingRight: 25 }}>
                        <Text style={{ fontSize: 14, marginBottom: 5 }}>Received from: </Text>
                        <Text style={{ fontSize: 14 }}>The sum of: </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        {/[\u0600-\u06FF]/.test(item.otherPartyName) ? (
                            <Text style={{ fontSize: 14, marginBottom: 5, textAlign: 'right', borderBottom: '1px dashed black', fontFamily: 'Cairo' }}>{item.otherPartyName}</Text>
                        ) : (
                            <Text style={{ fontSize: 14, marginBottom: 5, textAlign: 'right', borderBottom: '1px dashed black' }}>{item.otherPartyName}</Text>
                        )}
                        <Text style={{ fontSize: 14, textAlign: 'right', borderBottom: '1px dashed black' }}>{numToWord(item.amount)}</Text>
                    </View>
                </View>

                {item.againstItems.length > 0 && <View style={{ marginTop: 15 }}>
                    <Text style={{ fontSize: 14, fontFamily: 'Helvetica-Bold' }}>Against of:</Text>
                    {item.againstItems.map((against, index) => {
                        const info = againstOfInfo(against);
                        return (
                            <View style={{ marginTop: index > 0 ? 5 : 0, flexDirection: 'row' }}>
                                <Text style={{ flex: 1, fontSize: 12, }}>{info.type} No: {info.voucherNo}</Text>
                                <Text style={{ fontSize: 12 }}>SAR {Util.formatMoney(against.dividedAmount)}</Text>
                            </View>
                        )
                    })}
                </View>}

                {/* <View style={{ marginTop: 15 }}>
                    <Text style={{ fontSize: 14, fontFamily: 'Helvetica-Bold' }}>Payment Methods:</Text>
                    {item.paymentMethods.map((method, index) => (
                        <View style={{ marginTop: index > 0 ? 5 : 0, flexDirection: 'row' }}>
                            <Text style={{ flex: 1, fontSize: 12, }}>{paymentMethodText(method.methodType)} {Util.isStringExists(method.paymentId) && `(ID: ${method.paymentId})`} {Util.isStringExists(method.cheque?.chequeNo) && `(Cheque No: ${method.cheque.chequeNo})`}</Text>
                            <Text style={{ fontSize: 12, }}>SAR {Util.formatMoney(method.amount)}</Text>
                        </View >
                    ))}
                </View> */}
                <View style={{ marginTop: 15 }}>
                    <Text style={{ fontSize: 14, fontFamily: 'Helvetica-Bold' }}>Payment Methods:</Text>
                    {item.paymentMethods.map((method, index) => (<>
                        <View style={{ marginTop: index > 0 ? 5 : 0, flexDirection: 'row' }}>
                            <Text style={{ flex: 1, fontSize: 12, }}>
                                {paymentMethodText(method.methodType)} {Util.isStringExists(method.paymentId) && `(ID: ${method.paymentId})`}
                            </Text>
                            {Util.isNumberExist(method.nonDefaultCurrencyAmount) &&
                                // <Text style={{ fontSize: 12, marginRight: 5 }}>{method.nonDefaultCurrencyCode} {method.nonDefaultCurrencyAmount} Rate: {method.nonDefaultCurrencyRate}</Text>}
                                <Text style={{ fontSize: 12, marginRight: 5 }}>{method.nonDefaultCurrencyCode} {method.nonDefaultCurrencyAmount}</Text>}
                            <Text style={{ fontSize: 12, fontFamily: "Helvetica-Bold" }}>SAR {Util.formatMoney(method.amount)}</Text>
                        </View >
                        {method.cheque !== null && method.cheque !== undefined &&
                            <View style={{ marginTop: 0, marginLeft: 15 }}>
                                {Util.isStringExists(method.cheque.bankName) && <Text style={{ fontSize: 11, }}>Bank: {method.cheque.bankName}</Text>}
                                {Util.isStringExists(method.cheque.chequeNo) && <Text style={{ fontSize: 11, }}>Cheque no: {method.cheque.chequeNo}</Text>}
                            </View>}
                    </>))}
                </View>

                {Util.isStringExists(item.info) && <View style={{ marginTop: 15 }}>
                    <Text style={{ fontSize: 14, fontFamily: 'Helvetica-Bold' }}>Being for:</Text>
                    {/[\u0600-\u06FF]/.test(item.info) ? <Text style={{ fontFamily: 'Cairo' }}>{item.info}</Text> : <Text>{item.info}</Text>}
                </View>}


                <View style={{ flexDirection: 'row', marginTop: 75, marginBottom: 25 }}>
                    <Text style={{ marginRight: 5, fontSize: 12, }}>Prepared by:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}>{item.initiatorFullName}</Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Signature:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Date:</Text>
                    <Text style={{ fontSize: 12, borderBottom: '1px solid black', flex: 1 }}>{Util.getDateOnly(item.initiationDate)}</Text>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 25 }}>
                    <Text style={{ marginRight: 5, fontSize: 12, }}>Approved by:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}>{Util.isStringExists(item.approvedByName) ? item.approvedByName : ""}</Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Signature:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Date:</Text>
                    <Text style={{ fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                </View>
                <View style={{ flexDirection: 'row', }}>
                    <Text style={{ marginRight: 5, fontSize: 12, }}>Received by:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}>{Util.isStringExists(item.receivedByName) ? item.receivedByName : ""}</Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Signature:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Date:</Text>
                    <Text style={{ fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                </View>

                <Text style={{ fontFamily: 'Times-Italic', fontSize: 12, marginTop: 50 }}>This receipt voucher is considered invalid without company stamp</Text>
            </Page>
        </Document>
    )
}