import { ContentSwitcher, Switch } from 'carbon-components-react';
import React, { useState } from 'react'

import { Pie } from 'react-chartjs-2';

import { Diagram24 } from '@carbon/icons-react'

export default ({ report }) => {
    const [typeIndex, setTypeIndex] = useState(0);

    const getLabels = () => {
        if (typeIndex == 0) {
            return report.cashiers;
        } else {
            return report.salespeople;
        }
    }

    const getData = () => {
        if (typeIndex == 0) {
            return report.cashierSales;
        } else {
            return report.salespeopleSales;
        }
    }

    return (<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <ContentSwitcher style={{ marginBottom: '0.5rem' }} selectedIndex={typeIndex} onChange={e => setTypeIndex(e.index)}>
            <Switch name={"cashier"} text={"Session"} />
            <Switch name={"salesperson"} text={"Salesperson"} />
        </ContentSwitcher>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, flexDirection: 'column', gap: '0.5rem' }}>
            {getData().length > 0 ? (
                <Pie data={{
                    labels: getLabels(),
                    datasets: [
                        {
                            label: '# of Sales',
                            data: getData(),
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }} options={{
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    if (context.raw && context.raw.toFixed) {
                                        return " SAR " + context.raw.toFixed(2);
                                    } else {
                                        return " SAR " + (context.raw || '0.00');
                                    }
                                }
                            }
                        }
                    }
                }} />
            ) : (<>
                <Diagram24 style={{ opacity: 0.65 }} />
                <p style={{ fontSize: 12, opacity: 0.65 }}>No sales</p>
            </>)}
        </div>
    </div>)
}