
import { fabric } from "fabric";

import { Page, Text, View, Image, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import print from 'print-js';
import { getTemplate, TEMPLATE_TYPE_PRODUCT_LABEL } from "../templates/Template";
import Util from "../../../util/Util";
import Api from "../../../session/Api";
import { ComponentTypes } from "../engine/ComponentTypes";
import { createCanvasBarcode, createCanvasBarcodeWithSize, createCanvasQrCode, createSvgBarcode } from "../engine/CanvasManger";
import { OBJECT_TYPE_PRODUCT } from "../../../constants/ObjectTypes";

export const getAndExportTemplate = async (templateId, items, loadOptions) => {
    const template = await (new Promise((resolve, reject) => {
        Api.getTemplate(templateId, response => {
            if (response.status === true) {
                resolve(response.payload);
            } else {
                reject(response.message);
            }
        })
    }))

    return await exportTemplate(template.canvasWidth, template.canvasHeight, template.type, JSON.parse(template.data), items, loadOptions);
}

export const exportTemplate = async (width, height, templateType, canvasContent, items, loadOptions) => {
    // console.log(items);
    if (!Util.isDebug()) {
        if (canvasContent && canvasContent.objects) {
            const shouldBeAddress = window.location.origin;
            for (const object of canvasContent.objects) {
                if (object.src && typeof (object.src) === 'string') {
                    object.src = object.src.replace("http://", "https://");
                    if (!object.src.includes(shouldBeAddress)) {
                        if (shouldBeAddress.startsWith("https://www.")) {
                            const actualAddress = shouldBeAddress.replace('https://www.', 'https://');
                            object.src = object.src.replace(actualAddress, shouldBeAddress);
                        } else {
                            const actualAddress = shouldBeAddress.replace('https://', 'https://www.');
                            object.src = object.src.replace(actualAddress, shouldBeAddress);
                        }
                    }
                }
            }
        }
    }

    if (loadOptions.loadProducts) {
        const products = await (new Promise((resolve, reject) => {
            Api.getProductsFromIds(items.map(item => item.itemId), response => {
                if (response.status === true) {
                    resolve(response.payload);
                } else {
                    reject(response.message);
                }
            })
        }))

        for (const item of items) {
            for (const product of products) {
                if (product.id == item.itemId) {
                    item.data = product;
                    break;
                }
            }
        }
    } else if (loadOptions.loadBundles) {
        const products = await (new Promise((resolve, reject) => {
            Api.getProductBundlesFromIds(items.map(item => item.itemId), response => {
                if (response.status === true) {
                    resolve(response.payload);
                } else {
                    reject(response.message);
                }
            })
        }))

        for (const item of items) {
            for (const product of products) {
                if (product.id == item.itemId) {
                    item.data = product;
                    break;
                }
            }
        }
    }

    // const template = getTemplate(templateType);

    const nativeCanvas = document.createElement('canvas');
    const canvas = new fabric.Canvas(nativeCanvas, { width, height });

    let elements = [];

    for (const item of items) {
        canvas.clear();
        if (canvasContent) {
            await (new Promise(resolve => canvas.loadFromJSON(canvasContent, resolve)));
        }
        canvas.setBackgroundColor("#FFFFFF")
        canvas.renderAll();

        if (Util.isStringExists(item._templateCopyCount)) {
            item._templateCopyCount = parseInt(item._templateCopyCount);
        }

        if (!Util.isNumberExist(item._templateCopyCount)) {
            item._templateCopyCount = 1;
        }
        // if (!item.data) {
        //     item.data = {}
        // }

        if (item.data) {
            const dynamicComponents = canvas.getObjects().filter(object => object.valueDynamic);
            for (const component of dynamicComponents) {
                const layoutWidth = component.width * component.scaleX;
                const layoutHeight = component.height * component.scaleY;

                const content = component.dynamicValueContent;
                switch (component.componentType) {
                    case ComponentTypes.TEXT:
                        if (content) {
                            if (content.field == "price") {
                                component.set({ text: 'SAR ' + item.data[content.field].toFixed(2) })
                            } else {
                                component.set({ text: item.data[content.field] + '' })
                            }
                        }
                        break;
                    case ComponentTypes.BARCODE:
                        const barcode = item.forceBarcode ? item.forceBarcode : { value: item.data.barcodes[0].value, type: item.data.barcodes[0].type }
                        component.setElement(await createSvgBarcode(barcode.value, barcode.type, component.fill, component.backgroundColor, getComponentSize(layoutWidth, layoutHeight, component)))
                        fixComponentSize(layoutWidth, layoutHeight, component);
                        break;
                    case ComponentTypes.QR_CODE:
                        component.setElement(createCanvasQrCode(window.location.origin.toString() + "/product-preview/" + item.data.id, component.fill, component.backgroundColor))
                        fixComponentSize(layoutWidth, layoutHeight, component);
                        break;
                    case ComponentTypes.IMAGE:
                        // console.log("layout width", layoutWidth)
                        //await (new Promise(resolve => component.setSrc(Api.getThumbnail(OBJECT_TYPE_PRODUCT, item.data.id), resolve)));
                        // await (new Promise(resolve => component.setSrc('/api/list/get-thumbnail/0/27', () => {
                        // await (new Promise(resolve => component.setSrc('/api/image/get-image/ba6ec258-f09e-4050-a806-f4eb4feb80441636327863126', () => {
                        await (new Promise(resolve => component.setSrc(Api.getThumbnail(OBJECT_TYPE_PRODUCT, item.data.id), () => {
                            fixComponentSize(layoutWidth, layoutHeight, component);
                            resolve();
                        })));
                        break;
                }
            }
        }
        canvas.renderAll();


        const data = canvas.toDataURL();
        for (let i = 0; i < item._templateCopyCount; i++) {
            elements.push(<CanvasPDF key={Util.newTempId()} width={width} height={height} canvasURL={data} />)
        }
    }


    const pdfContent = (
        <Document>
            {elements}
        </Document>
    )

    const blob = await pdf(pdfContent).toBlob();
    print(URL.createObjectURL(blob));
}

const CanvasPDF = ({ width, height, canvasURL }) => (
    <Page size={[width, height]}>
        <Image src={canvasURL} style={{ width: width, height: height, objectFit: 'contain', objectPosition: 'center' }} />
    </Page>
)

const getComponentSize = (layoutWidth, layoutHeight, component) => {
    const actualWidth = component.width;
    const actualHeight = component.height;

    const size = resize(layoutWidth, layoutHeight, actualWidth, actualHeight);
    return size;
}

const fixComponentSize = (layoutWidth, layoutHeight, component) => {
    const actualWidth = component.width;
    const actualHeight = component.height;

    const { width, height } = resize(layoutWidth, layoutHeight, actualWidth, actualHeight);

    const scaledWidth = width / actualWidth;
    const scaledHeight = height / actualHeight;

    const newTop = component.top + (layoutHeight / 2) - (height / 2);
    const newLeft = component.left + (layoutWidth / 2) - (width / 2);

    component.set({ scaleX: scaledWidth, scaleY: scaledHeight, top: newTop, left: newLeft });
}

const resize = (layoutWidth, layoutHeight, contentWidth, contentHeight) => {
    const horizScale = layoutWidth / contentWidth;
    const vertScale = layoutHeight / contentHeight;

    const scale = Math.min(horizScale, vertScale);

    const width = contentWidth * scale;
    const height = contentHeight * scale;

    return { width, height }
}