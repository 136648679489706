
import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import print from 'print-js';
import Button from '../../components/Button';

import { Receipt16, ChartCustom16 } from '@carbon/icons-react'
import Util, { isV2 } from '../../util/Util';
import { ReportPdf } from './pdf/ReportPdf';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';


export const printStatementOfAccount = (accountId, accountType, startDate, endDate, onDone) => {
    if (isV2()) {
        Api.getV2StatementOfAccount(accountId, startDate, endDate, response => {
            if (response.status === true) {
                printReport(response.payload).finally(onDone)
            } else {
                UIUtil.showError(response.message);
            }
        })
    } else {
        Api.getStatementOfAccounts({ accountId, accountType, startDate, endDate }, response => {
            if (response.status === true) {
                printReport(response.payload).finally(onDone)
            } else {
                UIUtil.showError(response.message);
            }
        })
    }
}

export const asyncPrintStatementOfAccount = (accountId, accountType, startDate, endDate) => {
    return new Promise((resolve, reject) => Api.getStatementOfAccounts({ accountId, accountType, startDate, endDate }, response => {
        if (response.status === true) {
            printReport(response.payload).finally(resolve)
        } else {
            UIUtil.showError(response.message);
            resolve()
        }
    }))
}


export const printStatementOfAccountWithCurrency = (currency, accountId, accountType, startDate, endDate) => {
    return new Promise((resolve, reject) => Api.getStatementOfAccounts({ currencyId: currency.id, accountId, accountType, startDate, endDate }, response => {
        if (response.status === true) {
            printReport(response.payload, currency.code).finally(resolve)
        } else {
            UIUtil.showError(response.message, currency.code);
            resolve()
        }
    }))
}

async function printReport(report, currency = "SAR") {
    const blob = await pdf(<ReportPdf report={report} currency={currency} />).toBlob();
    window.open(URL.createObjectURL(blob))
    // print(URL.createObjectURL(blob));
}


// export const OutstandingReportPdfBtn = ({ report, on }) => {
//     const [instance, updateInstance] = usePDF({ document:  });

//     return (
//         //width: '100%', 
//         // <a href={instance.url} target="_blank" style={{textDecoration: 'none'}}>
//         <Button
//             kind="secondary"
//             loading={instance.loading} disabled={instance.error}
//             renderIcon={ChartCustom16}
//             size="sm">Get Report</Button>
//         // </a>
//     )
// }