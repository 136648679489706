import { Link } from "react-router-dom";
import { Widget } from ".";
import { Events16, InformationFilled16 } from "@carbon/icons-react"
import Util from "../../util/Util";
import { Tag } from "carbon-components-react";
import { isMobileScreen } from "../../App";

const Card = ({ customer }) => {
    return (
        <div style={{ position: 'relative', background: 'white', padding: '1rem', borderRadius: 5, width: '100%', marginTop: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <div style={{ flex: 1 }}>
                    <Link to={"/customers/" + customer.id} target="_blank">
                        <p style={{ fontSize: 14, flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{customer.name}</p>
                    </Link>
                </div>
                <p style={{ fontSize: 12, opacity: 0.65 }}>
                    Last ordered {customer.lastOrderAgo} ● {Util.getDateOnlyWithoutYear(customer.lastOrderDate)}
                </p>
            </div>
            {customer.lastOrderFromOnlineStore
                ? <Tag size="sm" type="blue">Last seen: Online Store</Tag>
                : <Tag size="sm" type="magenta">Last seen: {customer.storeName}</Tag>}
            <div style={{ marginTop: '1rem', borderRadius: 7, border: '1px solid #00000010', padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem', }}>
                    <h6 style={{ textAlign: 'center', flex: 1 }}>Total Ordered</h6>
                    <h6 style={{ textAlign: 'center', flex: 1 }}>Total Spent</h6>
                    <h6 style={{ textAlign: 'center', flex: 1 }}>Last Order</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem', }}>
                    <h4 style={{ textAlign: 'center', flex: 1, color: '#1b5200' }}>{customer.totalOrderCount + ''}</h4>
                    <h4 style={{ textAlign: 'center', flex: 1, color: '#1b5200' }}>SAR {Util.formatMoney(customer.totalOrderAmount)}</h4>
                    <h4 style={{ textAlign: 'center', flex: 1, color: '#910109' }}>SAR {Util.formatMoney(customer.lastOrderAmount)}</h4>
                </div>
            </div>
        </div>
    )
}

export function InactiveCustomers({ data }) {
    const { customers } = data.inactiveCustomers
    return (
        <Widget icon={Events16} title={"Inactive Customers"} style={{ width: isMobileScreen ? '100%' : 550, maxHeight: 650, overflow: 'auto' }}>
            <div style={{
                borderRadius: 5, width: '100%', height: 40, background: '#A4243B20', border: '1px solid #A4243B', color: '#A4243B',
                display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '0.25rem', paddingInline: '1rem'
            }}>
                <InformationFilled16 />
                <p style={{ fontSize: 14 }}>Sorted by total amount spent</p>
            </div>
            {customers.map(customer => <Card key={customer.id} customer={customer} />)}
        </Widget>
    )
}