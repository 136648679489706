import React from 'react'
import { Content, Header, HeaderContainer, Pagination, HeaderGlobalAction, HeaderGlobalBar, HeaderMenuButton, HeaderName, HeaderPanel, Modal, SideNav, SideNavItems, SideNavLink, SideNavMenuItem, SkipToContent, Switcher, SwitcherDivider, SwitcherItem, TableContainer, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch, Tile, HeaderNavigation, DataTableSkeleton, Tag, ButtonSet, TableSelectAll, TableSelectRow, ExpandableSearch } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from 'carbon-components-react';
import { Add16, Error16, Identification16, Receipt16, Save16, ArrowRight16, ArrowLeft16, Calendar16, QueryQueue16, SubtractAlt16, AddFilled16, Currency16 } from '@carbon/icons-react'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Button from '../../components/Button';
import { Link, useHistory, withRouter } from "react-router-dom";
import DirectionIndicator from '../../components/DirectionIndicator';
import { TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR, TRANSACTION_PARTY_TYPE_STORE, TRANSACTION_PARTY_TYPE_STUDENT, TRANSACTION_PARTY_TYPE_SUPPLIER, TRANSACTION_PARTY_TYPE_TUTOR, TRANSACTION_PARTY_TYPE_VENDOR, TRANSACTION_PARTY_TYPE_VENUE } from '../../constants/Constants';
import ProfilePic from '../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_MOVIE_DISTRIBUTOR, OBJECT_TYPE_STORE, OBJECT_TYPE_STUDENT, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TUTOR, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from '../../constants/ObjectTypes';
import Util from '../../util/Util';
import PaginatedDataTable from '../../components/data-table/PaginatedDataTable';
import { paymentMethodText } from './TransactionEditor';
import { againstOfInfo } from '../../pdfs/payment-voucher/new-pdf';

const TabItem = ({ icon, title, selected, onClick }) => (
    <Button
        style={{ pointerEvents: selected ? 'none' : undefined }} onClick={onClick}
        hasIconOnly={!selected} iconDescription={!selected ? title : undefined}
        kind={selected ? "secondary" : "ghost"} renderIcon={icon}>{selected && title}</Button>
)


const HEADERS = [
    {
        header: "Voucher No",
        key: "id",
        minWidth: 50
    },
    {
        header: "Payer/Recipient",
        key: "otherPartyName",
        minWidth: 150
    },
    {
        header: "Against",
        key: "against",
        minWidth: 150
    },
    {
        header: "Direction",
        key: "directionType",
        minWidth: 180
    },
    {
        header: "Amount",
        key: "amount",
        minWidth: 150
    },
    {
        header: "Date",
        key: "initiationDate",
        minWidth: 175
    },
    {
        header: "Info",
        key: "info",
        minWidth: 200
    },
]

const FILTER_TYPE = {
    ALL: "ALL",
    RECEIPT: "RECEIPT",
    PAYMENT: "PAYMENT"
}

class TransactionListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: this.isLoadAll() ? false : true,
            rows: [],

            totalInwardAmount: 0,
            totalOutwardAmount: 0,

            voucherNoFilter: "",
            typeFilter: FILTER_TYPE.ALL
        }
    }

    getTitle() {
        if (this.props.report || this.props.productList) {
            return null;
        } if ((this.props.partyList && (this.props.partyList.type == OBJECT_TYPE_SUPPLIER || this.props.partyList.type == OBJECT_TYPE_CUSTOMER || this.props.partyList.type == OBJECT_TYPE_VENDOR || this.props.partyList.type == OBJECT_TYPE_MOVIE_DISTRIBUTOR || this.props.partyList.type == OBJECT_TYPE_STORE || this.props.partyList.type == OBJECT_TYPE_VENUE))) {
            return (
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ color: 'green', marginLeft: '0rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AddFilled16 style={{ marginRight: '0.25rem' }} />
                            <h6>Receipt</h6>
                        </div>
                        <h4>SAR {this.state.totalInwardAmount.toFixed(2)}</h4>
                    </div>

                    <div style={{ color: 'red', marginLeft: '3rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SubtractAlt16 style={{ marginRight: '0.25rem' }} />
                            <h6>Payment</h6>
                        </div>
                        <h4>SAR {this.state.totalOutwardAmount.toFixed(2)}</h4>
                    </div>
                </div>
            )
        } else {
            return "Transactions";
        }
    }

    getSubtitle() {
        if (this.props.partyList && (this.props.partyList.type == OBJECT_TYPE_SUPPLIER ||
            this.props.partyList.type == OBJECT_TYPE_CUSTOMER ||
            this.props.partyList.type == OBJECT_TYPE_VENDOR ||
            this.props.partyList.type == OBJECT_TYPE_VENUE ||
            this.props.partyList.type == OBJECT_TYPE_MOVIE_DISTRIBUTOR ||
            this.props.partyList.type == OBJECT_TYPE_STORE)) {
            return undefined;
        }

        if (this.props.productList) {
            return undefined;
        }

        if (this.props.report) {
            return null;
        }

        return "All transactions";
    }

    getNewBtnTitle() {
        return "Initiate Transaction";
    }

    getNewPath() {
        if (this.props.partyList && this.props.partyList.type == OBJECT_TYPE_SUPPLIER) {
            return {
                pathname: "/transaction-creator", defaultTransaction: {
                    otherPartyTypeValue: TRANSACTION_PARTY_TYPE_SUPPLIER + "",
                    otherPartyIdValue: this.props.partyList.id
                }
            }
        } else if (this.props.partyList && this.props.partyList.type == OBJECT_TYPE_CUSTOMER) {
            return {
                pathname: "/transaction-creator", defaultTransaction: {
                    otherPartyTypeValue: TRANSACTION_PARTY_TYPE_CUSTOMER,
                    otherPartyIdValue: this.props.partyList.id
                }
            }
        } else if (this.props.partyList && this.props.partyList.type == OBJECT_TYPE_VENDOR) {
            return {
                pathname: "/transaction-creator", defaultTransaction: {
                    otherPartyTypeValue: TRANSACTION_PARTY_TYPE_VENDOR,
                    otherPartyIdValue: this.props.partyList.id
                }
            }
        } else if (this.props.partyList && this.props.partyList.type == OBJECT_TYPE_STORE) {
            return {
                pathname: "/transaction-creator", defaultTransaction: {
                    otherPartyTypeValue: TRANSACTION_PARTY_TYPE_STORE,
                    otherPartyIdValue: this.props.partyList.id
                }
            }
        } else if (this.props.partyList && this.props.partyList.type == OBJECT_TYPE_VENUE) {
            return {
                pathname: "/transaction-creator", defaultTransaction: {
                    otherPartyTypeValue: TRANSACTION_PARTY_TYPE_VENUE,
                    otherPartyIdValue: this.props.partyList.id
                }
            }
        } else if (this.props.partyList && this.props.partyList.type == OBJECT_TYPE_STUDENT) {
            return {
                pathname: "/transaction-creator", defaultTransaction: {
                    otherPartyTypeValue: TRANSACTION_PARTY_TYPE_STUDENT,
                    otherPartyIdValue: this.props.partyList.id
                }
            }
        } else if (this.props.partyList && this.props.partyList.type == OBJECT_TYPE_TUTOR) {
            return {
                pathname: "/transaction-creator", defaultTransaction: {
                    otherPartyTypeValue: TRANSACTION_PARTY_TYPE_TUTOR,
                    otherPartyIdValue: this.props.partyList.id
                }
            }
        } else if (this.props.partyList && this.props.partyList.type == OBJECT_TYPE_MOVIE_DISTRIBUTOR) {
            return {
                pathname: "/transaction-creator", defaultTransaction: {
                    otherPartyTypeValue: TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR,
                    otherPartyIdValue: this.props.partyList.id
                }
            }
        } else if (this.props.productList) {

        } else {
            return "/transaction-creator";
        }
    }

    getPath() {
        return "/transaction/";
    }

    isLoadAll() {
        //return !this.props.report && !this.props.partyList && !this.props.productList;
        return !this.props.report;
    }

    componentDidMount() {
        if (!this.isLoadAll()) {
            this.setState({ loading: true })
            const listener = response => {
                if (response.status === true && response.payload !== null) {
                    this.setState({ loading: false, rows: response.payload.transactions, totalInwardAmount: response.payload.totalInwardAmount, totalOutwardAmount: response.payload.totalOutwardAmount })
                } else {
                    this.setState({ loading: false, rows: [], totalInwardAmount: 0, totalOutwardAmount: 0 })
                }

                if (response.payload === null) {
                    UIUtil.showError(response.message);
                }
            }

            if (this.props.report) {
                listener({
                    status: true,
                    payload: {
                        transactions: this.props.report.items,
                        totalInwardAmount: 0,
                        totalOutwardAmount: 0
                    },
                })
            } else if (this.props.partyList) {
                Api.getPartyTransactions(this.props.partyList.id, listener);
            } else if (this.props.productList) {
                Api.getProductTransactions(this.props.productList.id, listener);
            } else {
                // Api.getAllTransactions(listener)
                listener({ status: false })
            }
        }
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.rows) {
            if (row.id == id) {
                return row;
            }
        }
    }

    actuallyRenderCell(key, value, row) {
        const cell = { value }
        const item = row;

        switch (key) {
            case "id":
                //return Util.getVoucherNumber(cell.value)
                return Util.getTransactionVoucherNumber(item.id, item.directionType, item.sequenceNo)

            case "otherPartyName":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <ProfilePic size={34} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, row.otherPartyId)} /> */}
                        <p style={{ marginLeft: '0.5rem' }}>{cell.value}</p>
                    </div>
                )

            case "directionType":
                const isInward = row.directionType == TRANSACTION_DIRECTION_TYPE_INWARD;
                return <DirectionIndicator inward={isInward} suffix={isInward ? "(receipt)" : "(payment)"} />

            case "amount":
                return (<div style={{ paddingBlock: '0.25rem' }}>
                    <h5>SAR {Util.formatMoney(cell.value)}</h5>
                    {item.paymentMethods.map(method => (<>
                        <p style={{ fontSize: 14 }}>- {paymentMethodText(method.methodType)}</p>
                        {method.cheque !== null && method.cheque !== undefined &&
                            <div style={{ marginTop: 0, marginLeft: 15 }}>
                                {Util.isStringExists(method.cheque.bankName) && <p style={{ fontSize: 12, }}>Bank: {method.cheque.bankName}</p>}
                                {Util.isStringExists(method.cheque.chequeNo) && <p style={{ fontSize: 12, }}>Cheque no: {method.cheque.chequeNo}</p>}
                            </div>}
                    </>))}
                </div>)

            case "initiationDate":
                return (<>
                    <div style={{ paddingBlock: '0.25rem' }}>
                        {Util.isNumberExist(item.transactionDate) && <>
                            <label style={{ marginBottom: 0 }} className="bx--label">Voucher Date</label>
                            <br />
                            <span><strong>{Util.getDateOnly(item.transactionDate)}</strong></span>
                            <br />
                        </>}

                        <label style={{ marginBottom: 0 }} className="bx--label">Posted Date</label>
                        <br />
                        <span>{Util.getDateOnly(item.initiationDate)}</span>

                    </div>
                </>)

            case "info":
                return (<div style={{ paddingBlock: '0.25rem' }}>
                    {Util.isStringExists(item.refNo) && <p>{item.refNo}</p>}
                    {Util.isStringExists(item.refNo) && Util.isStringExists(item.info) && <div style={{ width: '100%', height: 1, background: '#00000040', marginBlock: 5 }} />}
                    {Util.isStringExists(item.info) && <p>{item.info}</p>}
                </div>)

            case "against":
                return (<div style={{ paddingBlock: '0.25rem' }}>
                    {item.againstItems.map((against, index) => {
                        const info = againstOfInfo(against);
                        return (<>
                            <label style={{ marginBottom: 0 }} className="bx--label">{info.type} No: {info.voucherNo}</label>
                            <br />
                            <strong><span>SAR {Util.formatMoney(against.dividedAmount)}</span></strong>
                            <br style={{ marginBottom: 4 }} />
                        </>)
                    })}
                    {item.againstItems.length === 0 && <span>N/A</span>}
                </div>)

            default:
                return cell.value;
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);

        return this.actuallyRenderCell(header.key, cell.value, row)
    }

    renderFilterTab() {
        if (!this.isLoadAll()) {
            return null;
        }

        return !Util.isStringExists(this.state.idFilter) && !Util.isStringExists(this.state.voucherNoFilter) && (
            <>
                <TabItem key={this.state.typeFilter + '-all-tab'} icon={Currency16} title="All" selected={this.state.typeFilter == FILTER_TYPE.ALL} onClick={() => this.setState({ typeFilter: FILTER_TYPE.ALL })} />
                <TabItem key={this.state.typeFilter + '-payments-tab'} icon={SubtractAlt16} title="Payments" selected={this.state.typeFilter == FILTER_TYPE.PAYMENT} onClick={() => this.setState({ typeFilter: FILTER_TYPE.PAYMENT })} />
                <TabItem key={this.state.typeFilter + '-receipts-tab'} icon={AddFilled16} title="Receipts" selected={this.state.typeFilter == FILTER_TYPE.RECEIPT} onClick={() => this.setState({ typeFilter: FILTER_TYPE.RECEIPT })} />
                <div style={{ flex: 1 }} />
            </>
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ width: '100%' }}>
                    <DataTableSkeleton />
                </div>
            )
        }

        if (this.isLoadAll()) {
            return (
                <div className='tooltip-supported-toolbar' style={{ width: '100%' }}>
                    <PaginatedDataTable
                        reloadRequest={this.state.idFilter + ':fi-all-transactions-list-fr:' + this.state.voucherNoFilter + "--type-filter" + this.state.typeFilter}
                        title={this.getTitle()} description={this.getSubtitle()}
                        loader={(page, sortRequest, listener) => Api.getAllTransactions(page, {
                            filterId: (() => {
                                if (Util.isStringExists(this.state.voucherNoFilter)) {
                                    //const id = Util.getIdFromTransactionVoucherNumber(parseInt(this.state.voucherNoFilter));
                                    const id = Util.getIdFromTransactionVoucherNumber(this.state.voucherNoFilter);
                                    return Math.max(id, 0);
                                } else {
                                    return -1;
                                }
                            })(),
                            // filterRecordId: this.state.idFilter,
                            filterType: this.state.typeFilter,


                            filterOtherPartyId: this.props.partyList?.id,
                            filterProductId: this.props.productList?.id,
                        }, listener)}
                        loadResponseHandler={response => {
                            if (response.payload.totalInwardAmount) {
                                this.setState({ totalInwardAmount: response.payload.totalInwardAmount })
                            }
                            if (response.payload.totalOutwardAmount) {
                                this.setState({ totalOutwardAmount: response.payload.totalOutwardAmount })
                            }
                            return ({ loadedItems: response.payload.transactions, loadedHasMore: response.payload.paginationHasMore });
                        }}
                        columns={HEADERS.map(header => ({
                            id: header.key,
                            name: header.header,
                            render: item => (
                                <div style={{ minWidth: header.minWidth }}>
                                    {this.actuallyRenderCell(header.key, item[header.key], item)}
                                </div>
                            )
                        }))}
                        onClick={row => {
                            // this.setState({ selectedStockFlowId: row.id })
                            // this.props.history.push()
                            window.open(this.getPath() + row.id)
                        }}
                    >
                        {!this.props.hideToolbar && <TableToolbar>
                            <TableToolbarContent>
                                {this.renderFilterTab()}

                                <ExpandableSearch
                                    style={{ minWidth: 48 }}
                                    renderIcon={<div style={{ width: 48, height: 48, minWidth: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                        <Receipt16 />
                                    </div>}
                                    placeholder={"Find by Voucher No"} onChange={e => this.setState({ voucherNoFilter: e.target.value })} value={this.state.voucherNoFilter} />
                                <Link to={this.getNewPath()} style={{ textDecoration: 'none' }}>
                                    <Button style={{ minWidth: 250 }} renderIcon={Add16}>{this.getNewBtnTitle()}</Button>
                                </Link>
                            </TableToolbarContent>
                        </TableToolbar>}
                    </PaginatedDataTable>

                    {/* {this.renderSideView()} */}
                </div>
            )
        }


        return (
            <div style={{ width: '100%' }}>
                <DataTable rows={Util.isStringExists(this.state.idFilter) ? this.state.rows.filter(row => row.transactionId == this.state.idFilter) : (Util.isStringExists(this.state.voucherNoFilter) ? this.state.rows.filter(row => row.id == Util.getIdFromTransactionVoucherNumber(this.state.voucherNoFilter)) : this.state.rows)} headers={HEADERS} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer title={this.getTitle()} description={this.getSubtitle()}>
                            {!this.props.hideToolbar && <TableToolbar>
                                <TableToolbarContent>
                                    <TableToolbarSearch placeholder={"Search"} onChange={onInputChange} />

                                    <ExpandableSearch
                                        style={{ minWidth: 48 }}
                                        renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                            <Receipt16 />
                                        </div>}
                                        placeholder={"Find by Voucher No"} onChange={e => this.setState({ voucherNoFilter: e.target.value })} value={this.state.voucherNoFilter} />

                                    <ExpandableSearch
                                        style={{ minWidth: 48 }}
                                        renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                            <Identification16 />
                                        </div>}
                                        placeholder={"Find by ID"} onChange={e => this.setState({ idFilter: e.target.value })} value={this.state.idFilter} />

                                    <Link to={this.getNewPath()} style={{ textDecoration: 'none' }}>
                                        <Button style={{ minWidth: 250 }} renderIcon={Add16}>{this.getNewBtnTitle()}</Button>
                                    </Link>
                                </TableToolbarContent>
                            </TableToolbar>}
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => this.props.history.push(this.getPath() + row.id))} key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        )
    }

}

export default withRouter(TransactionListView);