import { ComboBox, ComposedModal, DataTableSkeleton, InlineLoading, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import { useEffect, useMemo, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import Button from "../../../components/Button"
import Table from "../../../components/data-table/Table";
import { STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../../../constants/Constants";
import { getInvoiceDocumentStatusDetails, InvoiceDocumentStatusIndicator, INVOICE_DOCUMENT_STATUS_NOT_PAID, INVOICE_DOCUMENT_STATUS_PAID, INVOICE_DOCUMENT_STATUS_REVOKED } from "../../../domain/invoice-document";
import { getStockRequestStatusDetails, StockRequestStatusIndicator } from "../../../domain/stock-request";
import useStore from "../../../hooks/useStore";
import Api from "../../../session/Api";
import { makeObservable } from "../../../util/makeObservable";
import UIUtil from "../../../util/UIUtil";
import Util from "../../../util/Util";
import { Document16, DocumentAdd16, ChartBullet16, UserCertification16, Policy16, ArrowRight16, CheckmarkFilledWarning16, Add16 } from '@carbon/icons-react'
import { getObjectTypeName, OBJECT_TYPE_VENUE } from "../../../constants/ObjectTypes";
import useApi from "../../../hooks/useApi";
import Portal from "../../../util/Portal";
import { getPropertyDealStatusDetails, PropertyDealStatusIndicator, PROPERTY_DEAL_STATUS } from "../../../domain/property-deal";
import ReactTooltip from "react-tooltip";
import { DealFormDialog } from "../dialogs/deal-form-dialog";



const TabItem = ({ icon, title, selected, onClick }) => (
    <Button
        style={{ pointerEvents: selected ? 'none' : undefined }} onClick={onClick}
        hasIconOnly={!selected} iconDescription={!selected ? title : undefined}
        kind={selected ? "secondary" : "ghost"} renderIcon={icon}>{selected && title}</Button>
)

const StatusTab = ({ selectedStatus, onSelect, status }) => {
    if (status == -1) {
        return <TabItem key={status + '-tab-item-stock-request'} title={"All"} icon={Policy16} selected={selectedStatus == status} onClick={() => onSelect(status)} />
    }
    let { icon, text } = getPropertyDealStatusDetails(status);
    return <TabItem key={status + '-tab-item-stock-request'} icon={icon} title={text} selected={selectedStatus == status} onClick={() => onSelect(status)} />
}

const Tabs = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    return (
        <>
            <StatusTab status={-1} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={PROPERTY_DEAL_STATUS.UN_POSTED} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={PROPERTY_DEAL_STATUS.POSTED} selectedStatus={statusFilter} onSelect={setStatusFilter} />

            <div style={{ flex: 1 }} />
        </>
    )
}

const List = ({ tabs, status }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const [refreshReq, setRefreshReq] = useState(() => Util.newTempId());

    const history = useHistory();

    useEffect(() => {
        setLoading(true)
        Api.getPropertyDeals(status, response => {
            if (response.status === true) {
                setRows(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [refreshReq])

    const columns = useMemo(() => [
        {
            id: "id",
            name: "ID",
            render: item => (
                <div style={{ paddingBlock: '0.25rem' }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Voucher No</label>
                    <br />
                    <span>{Util.getVoucherNumber(item.id)}</span>
                    {Util.isNumberExist(item.refNo) && <>
                        <br />
                        <label style={{ marginBottom: 0 }} className="bx--label">Deal No</label>
                        <br />
                        <span><strong>{item.refNo}</strong></span>
                    </>}
                </div>
            )
        },
        {
            id: "propertyName",
            name: "Property",
            render: item => item.propertyName
        },
        {
            id: "sellerName",
            name: "Seller",
            render: item => item.sellerName
        },
        {
            id: "buyerName",
            name: "Buyer",
            render: item => item.buyerName
        },
        {
            id: "agents",
            name: "Agents",
            render: item => item.agents
        },
        {
            id: "amount",
            name: "Amount",
            render: item => (
                <div style={{ paddingBlock: '0.25rem' }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Property amount</label>
                    <br />
                    <span>SAR {Util.formatMoney(item.propertyAmount)}</span>
                    <br />
                    <label style={{ marginBottom: 0 }} className="bx--label">Total amount to receive</label>
                    <br />
                    <span><strong>SAR {Util.formatMoney(item.totalAmountToReceive)}</strong></span>
                </div>
            )
        },
        {
            id: "byUser",
            name: "By User",
            render: item => (
                <div style={{ paddingBlock: '0.25rem' }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Created by</label>
                    <br />
                    <span>{item.initiatorFullName}</span>
                    {Util.isNumberExist(item.postedById) && <>
                        <br />
                        <label style={{ marginBottom: 0 }} className="bx--label">Posted by</label>
                        <br />
                        <span><strong>{item.postedByFullName}</strong></span>
                    </>}
                </div>
            )
        },
        {
            id: "date",
            name: "Date",
            render: item => (
                <div style={{ paddingBlock: '0.25rem' }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Deal Creation Date</label>
                    <br />
                    <span>{Util.getDateOnly(item.initiationDate)}</span>
                    {Util.isNumberExist(item.postedDate) && <>
                        <br />
                        <label style={{ marginBottom: 0 }} className="bx--label">Deal Posted Date</label>
                        <br />
                        <span><strong>{Util.getDateOnly(item.postedDate)}</strong></span>
                    </>}
                    {Util.isNumberExist(item.dealDate) && <>
                        <br />
                        <label style={{ marginBottom: 0 }} className="bx--label">Deal Date</label>
                        <br />
                        <span><strong>{Util.getDateOnly(item.dealDate)}</strong></span>
                    </>}
                </div>
            )
        },
        {
            id: "status",
            name: "Status",
            render: item => <PropertyDealStatusIndicator key={item.id + "-table-status-indicator-property-deal"} status={item.status} />
        },
        {
            id: "action",
            name: "",
            render: item => (<div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {Util.isNumberExist(item.leadId) &&
                    <Link target={"_blank"} to={'/lead/' + item.leadId}>
                        <button className='lead-flow-icon-button'>
                            <UserCertification16 />
                        </button>
                    </Link>}

                {item.status === PROPERTY_DEAL_STATUS.UN_POSTED &&
                    <button className='lead-flow-icon-button lead-flow-icon-button-secondary' onClick={() => {
                        UIUtil.showOverlay2(onClose => (
                            <DealFormDialog leadId={item.leadId} dealId={item.id} onUpdate={() => { }} onSetItemCalled={dealConfirmed => {
                                if (dealConfirmed === true) {
                                    history.push('/property-deal/' + item.id)
                                } else {
                                    setRefreshReq(Util.newTempId())
                                }
                            }} onClose={onClose} />
                        ))
                    }}>
                        <CheckmarkFilledWarning16 />
                    </button>}

                {item.status === PROPERTY_DEAL_STATUS.POSTED &&
                    <Link target={"_blank"} to={'/property-deal/' + item.id}>
                        <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                            <ArrowRight16 />
                        </button>
                    </Link>}
            </div>)
        }
    ], [history])

    return (
        <Table
            hasFooter
            title="Property Deals" description="Manage property deals"
            rows={rows} columns={columns} onClick={id => {
                //history.push("/property-deal/" + id)
            }}>
            {tabs}
            {loading && <InlineLoading style={{ marginLeft: '1rem' }} />}
        </Table>
    )
}

const Content = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    const tabs = <Tabs store={store} />;
    return (
        <List
            key={'table-list-deals-documents-' + statusFilter}
            tabs={tabs}
            status={statusFilter}
        />
    )
}

export default ({ }) => {
    const store = useMemo(() => {
        const observable = makeObservable();
        observable.set("statusFilter", -1)
        return observable;
    }, [])


    return (
        <div className="main-content">
            <Content store={store} />
            <ReactTooltip />
        </div>
    )
}