import { InlineLoading, Tooltip } from 'carbon-components-react';
import React, { useEffect, useMemo, useState } from 'react'

import { ErrorFilled16, Warning16 } from '@carbon/icons-react'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Button from '../../components/Button';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import Util, { isV2 } from '../../util/Util';
import { getPartyObjectType, getPartyTypeName } from '../transaction/transactions';
import { getObjectTypeUrl } from '../../constants/ObjectTypes';
import { getAccountRole } from '../../session/SessionManager';
import { ACCOUNT_TYPE_DELIVERY_MANAGER } from '../../constants/Constants';
import { listDetailItemId } from '../../reporting-engine/base/report-page';

const Loading = () => <div className='centered-progress-bar' style={{ width: '100%', height: 45, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><InlineLoading /><p style={{ fontSize: 14, opacity: 0.65 }}>Loading...</p></div>
const Error = () => <div className='centered-progress-bar' style={{ width: '100%', height: 45, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ErrorFilled16 /><p style={{ fontSize: 14, opacity: 0.65, marginLeft: '0.25rem' }}>An error occurred</p></div>


//const TRANSACTION_ITEM_HEIGHT = 100;
const TRANSACTION_ITEM_HEIGHT = 145;
const TRANSACTION_BOTTOM_MARGIN = 20;
const TRANSACTION_EFFECTIVE_HEIGHT = TRANSACTION_ITEM_HEIGHT + TRANSACTION_BOTTOM_MARGIN;


const TransactionItem = ({ transaction, ignored, onIgnoreBtn, amountDistribution }) => {
    let transactionInfo;
    for (const item of amountDistribution) {
        if (item.id == transaction.id) {
            transactionInfo = item;
            break;
        }
    }

    const showLink = getAccountRole() != ACCOUNT_TYPE_DELIVERY_MANAGER;

    return (
        <div style={{
            transition: '250ms', overflow: 'hidden', background: 'white',
            marginBottom: TRANSACTION_BOTTOM_MARGIN, height: TRANSACTION_ITEM_HEIGHT, maxHeight: TRANSACTION_ITEM_HEIGHT, minHeight: TRANSACTION_ITEM_HEIGHT,
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ display: 'flex', flex: 1, alignItems: 'flex-start', padding: '15px' }}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>

                    <div style={{ marginBottom: '0.5rem' }}>
                        <h5>{isV2() ? transaction.crvSalesDestination : transaction.otherPartyName}</h5>
                        <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', marginBottom: '0rem' }}>{getPartyTypeName(transaction.otherPartyType)}</p>

                        {/* <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', marginBottom: '0rem' }}>{transaction.crvSalesDestination}</p> */}
                        {/* {transaction.otherPartyId > 0 ? (
                            showLink ? (
                                <Link to={getObjectTypeUrl(getPartyObjectType(transaction.otherPartyType)) + "/" + transaction.otherPartyId} target="_blank">
                                    <p style={{ fontSize: 12, marginRight: '1rem', marginBottom: '0rem' }}>View {getPartyTypeName(transaction.otherPartyType)}</p>
                                </Link>
                            ) : (
                                <p style={{ fontSize: 12, marginRight: '1rem', marginBottom: '0rem' }}>View {getPartyTypeName(transaction.otherPartyType)}</p>
                            )
                        ) : (
                            <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', marginBottom: '0rem' }}>{getPartyTypeName(transaction.otherPartyType)}</p>
                        )} */}
                    </div>



                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        {showLink ? (<>
                            <Link to={"/transaction/" + transaction.id} target="_blank">
                                <p style={{ fontSize: 14, display: 'inline' }}>Transaction (no: {Util.getVoucherNumber(transaction.id)})</p>
                            </Link>

                            {Util.isNumberExist(transaction.crvSalesId) && !isV2() && <Link to={"/stock-flow/" + transaction.crvSalesId} target="_blank">
                                <p style={{ fontSize: 14, display: 'inline' }}>Invoice (no: {transaction.crvSalesVoucherNo})</p>
                            </Link>}
                            {Util.isNumberExist(transaction.crvSalesId) && isV2() && <Link to={listDetailItemId("V2SalesList", transaction.crvSalesId)} target="_blank">
                                <p style={{ fontSize: 14, display: 'inline' }}>Invoice (no: {transaction.crvSalesVoucherNo})</p>
                            </Link>}

                            {Util.isNumberExist(transaction.crvOrderId) && <Link to={"/deliverables/" + transaction.crvOrderId} target="_blank">
                                <p style={{ fontSize: 14, display: 'inline' }}>Order (no: {transaction.crvOrderNo})</p>
                            </Link>}
                        </>) : (<>
                            <p style={{ fontSize: 14, display: 'inline' }}>Transaction (voucher no: {Util.getVoucherNumber(transaction.id)})</p>
                            {Util.isNumberExist(transaction.crvSalesId) &&
                                <p style={{ fontSize: 14, display: 'inline' }}>Invoice (no: {transaction.crvSalesVoucherNo})</p>}
                            {Util.isNumberExist(transaction.crvOrderId) &&
                                <p style={{ fontSize: 14, display: 'inline' }}>Order (no: {transaction.crvOrderNo})</p>}
                        </>)}
                    </div>
                    <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', marginBottom: '0rem' }}>{Util.getDate(transaction.initiationDate)}</p>


                    <div style={{ flex: 1, marginTop: '0.25rem', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <div style={{ display: 'flex', width: 200, borderRadius: 5, overflow: 'hidden', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
                            <div className="no-striped-green-bg" style={{ width: 25, height: 25, }} />
                            <div style={{ height: 25, flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ fontSize: 12, opacity: 1 }}>Returned: SAR {transactionInfo.amountReturned.toFixed(2)}</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: 200, borderRadius: 5, overflow: 'hidden', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
                            <div className="striped-green-bg" style={{ width: 25, height: 25, }} />
                            <div style={{ height: 25, flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ fontSize: 12, opacity: 1 }}>Returning: SAR {transactionInfo.amountPaying.toFixed(2)}</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: 200, borderRadius: 5, overflow: 'hidden', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
                            <div style={{ width: 25, height: 25, backgroundColor: '#cecece' }} />
                            <div style={{ height: 25, flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ fontSize: 12, opacity: 1 }}>Remaining: SAR {(transactionInfo.amountLeft - transactionInfo.amountPaying).toFixed(2)}</p>
                            </div>
                        </div>

                    </div>
                </div>
                <Button onClick={onIgnoreBtn}
                    kind={ignored ? "secondary" : "danger--ghost"}
                    renderIcon={ErrorFilled16}
                    size="sm">{ignored ? "Ignoring" : "Ignore"}</Button>
            </div>
        </div>
    )
}

const calcTransactionAmount = (transactions, ignoringTransactionIds) => {
    const isIgnoring = transactionId => ignoringTransactionIds.includes(transactionId);

    let totalToReturn = 0;

    if (transactions) {
        for (const transaction of transactions) {
            if (!isIgnoring(transaction.id)) {
                totalToReturn += transaction.amountRemainingToBeReturned;
            }
        }
    }

    return { totalToReturn: totalToReturn }
}

const caclTransactionAmountDistribution = (transactions, amount, ignoringTransactionIds) => {
    const isIgnoring = transactionId => ignoringTransactionIds.includes(transactionId);

    if (!transactions) {
        return []
    }

    return transactions.map(transaction => {
        let greenHeight = 0;
        let stripedGreenHeight = 0;
        let stripedGreyHeight = 0;
        let emptyHeight = 0;

        let isIgnoringTransaction = isIgnoring(transaction.id);
        let amountPaying = isIgnoringTransaction ? 0 : Math.min(amount, transaction.amountRemainingToBeReturned);

        if (!isIgnoringTransaction) {
            amount -= transaction.amountRemainingToBeReturned
            if (amount <= 0) {
                amount = 0;
            }
        }

        greenHeight = (transaction.totalAmountReturned / transaction.totalAmountToBeReturned) * TRANSACTION_EFFECTIVE_HEIGHT;
        if (isIgnoringTransaction) {
            stripedGreenHeight = (0 / transaction.totalAmountToBeReturned) * TRANSACTION_EFFECTIVE_HEIGHT;
            stripedGreyHeight = (transaction.amountRemainingToBeReturned / transaction.totalAmountToBeReturned) * TRANSACTION_EFFECTIVE_HEIGHT; //ignoring amounts
            emptyHeight = (0 / transaction.totalAmountToBeReturned) * TRANSACTION_EFFECTIVE_HEIGHT;
        } else {
            stripedGreenHeight = (amountPaying / transaction.totalAmountToBeReturned) * TRANSACTION_EFFECTIVE_HEIGHT;
            stripedGreyHeight = (0 / transaction.totalAmountToBeReturned) * TRANSACTION_EFFECTIVE_HEIGHT; //ignoring amounts
            emptyHeight = ((transaction.amountRemainingToBeReturned - amountPaying) / transaction.totalAmountToBeReturned) * TRANSACTION_EFFECTIVE_HEIGHT;
        }

        greenHeight = 15 * (Math.round(greenHeight / 15));
        stripedGreenHeight = 15 * (Math.round(stripedGreenHeight / 15));
        stripedGreyHeight = 15 * (Math.round(stripedGreyHeight / 15));
        emptyHeight = 15 * (Math.round(emptyHeight / 15));

        return {
            id: transaction.id,
            amountToBeReturned: transaction.totalAmountToBeReturned,
            amountReturned: transaction.totalAmountReturned,
            amountLeft: transaction.amountRemainingToBeReturned,
            amountPaying: amountPaying,

            greenHeight, stripedGreenHeight, stripedGreyHeight, emptyHeight,
        }
    })
}

const Progress = ({ transactions, amountDistribution }) => {

    // console.log(amountDistribution)
    return (
        <div style={{
            height: (transactions.length * TRANSACTION_ITEM_HEIGHT) + ((transactions.length - 1) * TRANSACTION_BOTTOM_MARGIN),
            width: 45, borderRadius: 5, backgroundColor: '#cecece', overflow: 'hidden'
        }}>
            {amountDistribution.map((item, index) => (<>
                <div data-tip={"Returned: SAR " + item.amountReturned.toFixed(2)} className={"no-striped-green-bg " + (item.greenHeight > 0 ? "progress-bar-segment-border" : "")} style={{ height: item.greenHeight, width: '100%' }} />

                <div data-tip={"Returning: SAR " + item.amountPaying.toFixed(2)} className={"striped-green-bg " + ((item.stripedGreenHeight > 0 && index > 0) ? "progress-bar-segment-border" : "")} style={{ height: item.stripedGreenHeight, width: '100%' }} />
                <div data-tip={"Ignoring: SAR " + item.amountLeft.toFixed(2)} className={"striped-grey-bg " + ((item.stripedGreyHeight > 0 && index > 0) ? "progress-bar-segment-border" : "")} style={{ height: item.stripedGreyHeight, width: '100%' }} />

                <div data-tip={"Remaining: SAR " + (item.amountLeft - item.amountPaying).toFixed(2)} style={{ height: item.emptyHeight, width: '100%' }} />
            </>))}

            {/* <div className="striped-green-bg" style={{height: 125, width: '100%'}} />
            <div className="striped-grey-bg" style={{height: 100, width: '100%'}} />
            <div className="striped-green-bg" style={{height: 125, width: '100%'}} /> */}
        </div>
    )
}


export default ({ staffUserId, amount, onLoadChange, onSelectionUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [inError, setInError] = useState(false);
    const [transactions, setTransactions] = useState(undefined);

    const [ignoringTransactionIds, setIgnoringTransactionIds] = useState([]);
    const isIgnoring = transactionId => ignoringTransactionIds.includes(transactionId);
    const toggleIgnoring = transactionId => isIgnoring(transactionId) ? setIgnoringTransactionIds(prev => prev.filter(id => id != transactionId)) : setIgnoringTransactionIds(prev => [...prev, transactionId])

    const transactionsAmount = useMemo(() => calcTransactionAmount(transactions, ignoringTransactionIds), [transactions, ignoringTransactionIds])
    const amountDistribution = useMemo(() => caclTransactionAmountDistribution(transactions, amount, ignoringTransactionIds), [transactions, amount, ignoringTransactionIds])


    useEffect(() => {
        if (transactions) {
            onSelectionUpdate({
                totalToReturn: transactionsAmount.totalToReturn,
                distribution: amountDistribution
            })
        } else {
            onSelectionUpdate({
                totalToReturn: 0,
                distribution: []
            })
        }
    }, [transactions, transactionsAmount, amountDistribution])

    useEffect(() => { ReactTooltip.rebuild() }, [])
    useEffect(() => { onLoadChange(loading) }, [loading]);
    useEffect(() => {
        if (inError || transactions) {
            setLoading(false);
        }
    }, [inError, transactions])
    useEffect(() => {
        if (staffUserId) {
            setLoading(true);
            setInError(false);
            setTransactions(undefined)
            setIgnoringTransactionIds([])

            Api.getTransactionsRequiringCashReturn(staffUserId, response => {
                if (response.status === true) {
                    setTransactions(response.payload)
                } else {
                    setInError(true);
                    UIUtil.showError(response.message);
                }
            })
        }
    }, [staffUserId])

    if (loading) { return <Loading /> }
    if (inError) { return <Error /> }
    if (transactions === null || transactions === undefined) return null

    if (transactions.length == 0) {
        return <p style={{ fontSize: 12, opacity: 0.65 }}>No transactions to be returned exists</p>;
    }



    return (
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <p style={{ marginRight: '0.5rem', color: 'red' }}>Total to return:</p>
                <h3 style={{ color: 'red' }}>SAR {Util.formatMoney(transactionsAmount.totalToReturn)}</h3>

                <div style={{ flex: 1 }} />

                <p style={{ marginRight: '0.5rem', color: 'green' }}>Amount Returning:</p>
                <h3 style={{ color: 'green' }}>SAR {Util.formatMoney(parseFloat(amount))}</h3>
            </div>

            {!isNaN(amount) && parseFloat(amount) > transactionsAmount.totalToReturn && <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Warning16 style={{ fill: 'red' }} />
                <p style={{ fontSize: 14, marginLeft: '0.25rem', opacity: 1, color: 'red' }}>Returning amount is greater than total required ({parseFloat(amount).toFixed(2) + ' > ' + transactionsAmount.totalToReturn.toFixed(2)})</p>
            </div>}

            <div style={{ width: '100%', display: 'flex', marginTop: '1rem' }}>
                <div style={{ flex: 1, marginRight: '1rem', minWidth: 0 }}>
                    {transactions.map(transaction => <TransactionItem
                        amountDistribution={amountDistribution}
                        key={transaction.id + '-crvtransselector'}
                        transaction={transaction}
                        ignored={isIgnoring(transaction.id)}
                        onIgnoreBtn={() => toggleIgnoring(transaction.id)} />)}
                </div>
                <div style={{}}>
                    <Progress transactions={transactions} amountDistribution={amountDistribution} />
                </div>
            </div>

            <ReactTooltip />
        </div>
    )
}