import React from 'react'
import { Content, Header, HeaderContainer, Pagination, HeaderGlobalAction, HeaderGlobalBar, HeaderMenuButton, HeaderName, HeaderPanel, Modal, SideNav, SideNavItems, SideNavLink, SideNavMenuItem, SkipToContent, Switcher, SwitcherDivider, SwitcherItem, TableContainer, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch, Tile, HeaderNavigation, DataTableSkeleton, Tag, ButtonSet, TableSelectAll, TableSelectRow, ExpandableSearch } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from 'carbon-components-react';
import {
    Add16, Error16, Identification16, Save16, ArrowRight16,
    ArrowLeft16, Calendar16, QueryQueue16, SubtractAlt16, AddFilled16, Currency16,
    FlowStream16, ChartBullet16, GraphicalDataFlow16, Receipt16
} from '@carbon/icons-react'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Button from '../../components/Button';
import { Link, useHistory, withRouter } from "react-router-dom";
import DirectionIndicator from '../../components/DirectionIndicator';
import { TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_SUPPLIER } from '../../constants/Constants';
import ProfilePic from '../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_SUPPLIER } from '../../constants/ObjectTypes';
import Util from '../../util/Util';
import PaginatedDataTable from '../../components/data-table/PaginatedDataTable';
import { useState } from 'react';

const HEADERS = [
    {
        header: "Voucher Number",
        key: "voucherNumber"
    },
    {
        header: "Entry Date",
        key: "date"
    },
    {
        header: "Amount",
        key: "amount"
    },
    {
        header: "Recorded Date",
        key: "creationDate"
    }
]

class JournalEntriesListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rows: [],

            voucherNumberFilter: ""
        }
    }

    getTitle() {
        return "Journal Entries";
    }

    getSubtitle() {
        return "All journal entries";
    }

    getNewBtnTitle() {
        return "New Entry";
    }

    getNewPath() {
        return "/journal-entry-creator";
    }

    getPath() {
        return "/journal-entry/";
    }

    componentDidMount() {
        this.setState({ loading: true })
        const listener = response => {
            if (response.status === true && response.payload !== null) {
                this.setState({ loading: false, rows: response.payload.entries })
            } else {
                this.setState({ loading: false, rows: [] })
            }

            if (response.payload === null) {
                UIUtil.showError(response.message);
            }
        }

        Api.getAllJournalEntries(listener)
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.rows) {
            if (row.id == id) {
                return row;
            }
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);

        switch (header.key) {

            case "voucherNumber":
                return Util.getJournalEntryVoucherNumber(row.id, row.sequenceNo)

            case "amount":
                return <> SAR {parseFloat(cell.value).toFixed(2)} </>

            case "date": case "creationDate":
                return <> <Calendar16 /> {Util.getDateOnly(cell.value)} </>

            default:
                return cell.value;
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ width: '100%' }}>
                    <DataTableSkeleton />
                </div>
            )
        }


        return (
            <div style={{ width: '100%' }}>
                <DataTable rows={Util.isStringExists(this.state.voucherNumberFilter) ? this.state.rows.filter(row => row.voucherNumber == this.state.voucherNumberFilter) : this.state.rows} headers={HEADERS} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer title={this.getTitle()} description={this.getSubtitle()}>
                            {!this.props.hideToolbar && <TableToolbar>
                                <TableToolbarContent>
                                    <TableToolbarSearch placeholder={"Search"} onChange={onInputChange} />
                                    <ExpandableSearch
                                        style={{ minWidth: 48 }}
                                        renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                            <Identification16 />
                                        </div>}
                                        placeholder={"Find by Voucher Number"} onChange={e => this.setState({ voucherNumberFilter: e.target.value })} value={this.state.voucherNumberFilter} />
                                    <Link to={this.getNewPath()} style={{ textDecoration: 'none' }}>
                                        <Button style={{ minWidth: 250 }} renderIcon={Add16}>{this.getNewBtnTitle()}</Button>
                                    </Link>
                                </TableToolbarContent>
                            </TableToolbar>}
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => this.props.history.push(this.getPath() + row.id))} key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        )
    }

}

//export default withRouter(JournalEntriesListView);

const TABLE_HEADERS = [
    {
        id: "id",
        name: "Entry Voucher Number",
        render: item => Util.getJournalEntryVoucherNumber(item.id, item.sequenceNo)
    },
    {
        id: "memo",
        name: "Memo",
        render: item => item.memo
    },
    {
        id: "creationDate",
        name: "Recorded Date",
        render: item => Util.getDateOnly(item.date)
    },
    {
        id: "date",
        name: "Entry Date",
        render: item => Util.getDateOnly(item.date)
    },
    {
        id: "amount",
        name: "Amount",
        render: item => Util.formatMoney(item.amount)
    },
    {
        id: "action",
        name: "",
        render: item => (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.5rem', paddingRight: '0.5rem' }}>
            {/* {Util.isNumberExist(item.leadId) &&
                <Link target={"_blank"} to={'/lead/' + item.leadId}>
                    <button className='lead-flow-icon-button'>
                        <UserCertification16 />
                    </button>
                </Link>} */}

            {Util.isNumberExist(item.stockFlowId) &&
                <Link target={"_blank"} to={'/stock-flow/' + item.stockFlowId}>
                    <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                        <FlowStream16 />
                    </button>
                </Link>}
            {Util.isNumberExist(item.billId) &&
                <Link target={"_blank"} to={'/bill/' + item.billId}>
                    <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                        <ChartBullet16 />
                    </button>
                </Link>}
            {Util.isNumberExist(item.transactionId) &&
                <Link target={"_blank"} to={'/transaction/' + item.transactionId}>
                    <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                        <Currency16 />
                    </button>
                </Link>}
            {Util.isNumberExist(item.serviceSaleId) &&
                <Link target={"_blank"} to={'/service-sale/' + item.serviceSaleId}>
                    <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                        <GraphicalDataFlow16 />
                    </button>
                </Link>}

            {!item.systemGenerated && <Tag size="sm">User defined</Tag>}

            <Link target={"_blank"} to={'/journal-entry/' + item.id}>
                <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                    <ArrowRight16 />
                </button>
            </Link>
        </div>)
    }
]

export default function () {
    const [voucherNo, setVoucherNo] = useState("");
    return (
        <div>
            <PaginatedDataTable
                title="Journal Entries"
                description="All journal entries"
                columns={TABLE_HEADERS}
                reloadRequest={`--type-filter-${voucherNo}`}
                loader={(page, _, listener) => Api.getJournalEntries(page, {
                    filterId: (() => {
                        if (Util.isStringExists(voucherNo)) {
                            const id = Util.getIdFromJournalEntryVoucherNumber(voucherNo);
                            return id || -1;
                        } else {
                            return 0;
                        }
                    })(),
                }, listener)}
                loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.hasMore })}
                //onClick={({ id }) => history.push('/journal-entry/' + id)}
                onClick={({ id }) => { }}
            >
                <div style={{ display: 'flex', width: '100%' }}>

                    <div style={{ flex: 1 }} />
                    <ExpandableSearch
                        style={{ minWidth: 48 }}
                        renderIcon={<div style={{ width: 48, height: 48, minWidth: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                            <Receipt16 />
                        </div>}
                        placeholder={"Find by Voucher No"} onChange={e => setVoucherNo(e.target.value)} value={voucherNo} />
                    <Link to="/journal-entry-creator" style={{ textDecoration: 'none' }}>
                        <Button kind="secondary" style={{ minWidth: 250 }} renderIcon={Add16}>New Entry</Button>
                    </Link>
                </div>
            </PaginatedDataTable>
        </div>
    )
}