import { Checkbox, InlineLoading, Tag, TextInput } from "carbon-components-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { DynamicTable } from "../../components/dynamic-table"
import { getObjectTypeName, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_SERVICE, OBJECT_TYPE_SERVICE_PACKAGE } from "../../constants/ObjectTypes"

import {
    Product16, Box16, ServiceDesk16, Application16, WatsonHealthTextAnnotationToggle16
} from '@carbon/icons-react'
import Util, { absAmt, big } from "../../util/Util"
import InfiniteComboBox from "../../components/InfiniteComboBox"
import ProfilePic from "../../components/ProfilePic"
import Api from "../../session/Api"
import UIUtil from "../../util/UIUtil"
import { SellableListSelector } from "../../components/list-selector"

const COLUMNS = [
    {
        title: "Item",
        flex: 5
    },
    {
        title: "Type",
        flex: 4
    },
    {
        title: "Amount",
        flex: 2
    },
    {
        title: "Qty",
        flex: 1
    },
    {
        title: "Total",
        flex: 2
    }
]

export const TypeTag = ({ type, ...props }) => {
    switch (type) {
        case OBJECT_TYPE_PRODUCT:
            return <Tag renderIcon={Product16} size="md" type="cyan" {...props}>{getObjectTypeName(OBJECT_TYPE_PRODUCT)}</Tag>
        case OBJECT_TYPE_PRODUCT_BUNDLE:
            return <Tag renderIcon={Box16} size="md" type="magenta" {...props}>{getObjectTypeName(OBJECT_TYPE_PRODUCT_BUNDLE)}</Tag>
        case OBJECT_TYPE_SERVICE:
            return <Tag renderIcon={ServiceDesk16} size="md" type="purple" {...props}>{getObjectTypeName(OBJECT_TYPE_SERVICE)}</Tag>
        case OBJECT_TYPE_SERVICE_PACKAGE:
            return <Tag renderIcon={Application16} size="md" type="green" {...props}>{getObjectTypeName(OBJECT_TYPE_SERVICE_PACKAGE)}</Tag>
        case -1:
            return <Tag renderIcon={WatsonHealthTextAnnotationToggle16} size="md" type="teal" {...props}>Custom Service</Tag>
        default:
            return null;
    }
}

const ItemView = ({ item }) => {
    return (
        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
            <ProfilePic notProfile size={24} />
            <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>{item.name}</p>
            <TypeTag type={item.type} size="sm" />
        </div>
    )
}

const ItemSelector = ({ sellables, item, setItem, light }) => {
    const textValue = item?.name;
    const setTextValue = name => {
        //setItem({ ...item, name, selectedItem: undefined })
        const itemToSelect = sellables.find(item => item.name === textValue);
        if (itemToSelect) {
            // setSelectedItem(itemToSelect)
            setItem({ ...item, name, selectedItem: itemToSelect })
        } else {
            setItem({ ...item, name, selectedItem: undefined })
        }
    }
    const selectedItem = item?.selectedItem;
    const setSelectedItem = selectedItem => {
        //setItem({ ...item, name: selectedItem?.name, selectedItem })
        setItem({ ...item, name: selectedItem ? selectedItem.name : textValue, selectedItem })
    }

    const onClear = () => setItem({ ...item, name: "", selectedItem: undefined })

    return <InfiniteComboBox light={light} ItemView={ItemView} itemToString={item => item.name} items={sellables}
        {...({ textValue, setTextValue, selectedItem, setSelectedItem, onClear })} />
}

const Record = ({ serviceOnly, sellables, currency, item: recordItem, setItem: setRecordItem, useValue }) => {
    // const [item, setItem] = useValue("item", { name: "", selectedItem: undefined });
    // const [amount, setAmount] = useValue("amount", undefined);
    // const [qty, setQty] = useValue("qty", undefined);
    // const [saveCustomService, setSaveCustomService] = useValue("saveCustomService", false)
    const [item, setItem] = useState({ name: "", selectedItem: undefined });
    const [amount, setAmount] = useState(undefined);
    const [qty, setQty] = useState(undefined);
    const [saveCustomService, setSaveCustomService] = useState(false)

    const type = (item?.selectedItem?.type) ?? -1;
    const customServiceName = type === -1 ? item?.name : undefined;
    const total = useMemo(() => big(qty).times(big(amount)).toFixed(2), [amount, qty])
    const dummyItem = recordItem.item === undefined;

    const firstTimeRef = useRef(true)

    useEffect(() => {
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        }

        setRecordItem({ ...recordItem, item, amount, qty, saveCustomService })
    }, [item, amount, qty, saveCustomService])

    useEffect(() => {
        if (!dummyItem) {
            setSaveCustomService(false)
        }
    }, [item])

    const onSetItem = newItem => {
        // if (item.selectedItem?.type !== newItem.selectedItem?.type ||
        //     item.selectedItem?.id !== newItem.selectedItem?.id) {
        //     setRecordItem({
        //         ...recordItem, item: newItem,
        //         amount: newItem.selectedItem?.amount ?? ""
        //     })
        // } else {
        //     setRecordItem({
        //         ...recordItem, item: newItem,
        //     })
        // }

        if (item.selectedItem?.type !== newItem.selectedItem?.type || item.selectedItem?.id !== newItem.selectedItem?.id) {
            setItem(newItem);
            setAmount(newItem.selectedItem?.amount ?? "")
        } else {
            setItem(newItem)
        }
        if (big(qty).lte(big(0))) {
            setQty(1)
        }
    }

    return [
        <div style={{ height: 40, width: '100%' }}>
            {serviceOnly ? (
                <ItemSelector sellables={sellables} item={item} setItem={onSetItem} />
            ) : (
                <SellableListSelector value={item} setValue={value => onSetItem(
                    value ?
                        { ...value, selectedItem: value } :
                        { name: "", selectedItem: undefined })}
                />
            )}
        </div>,
        (dummyItem || (type === -1 && !Util.isStringExists(customServiceName))) ? (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 0.65, fontWeight: 'bold' }}>-</p>
            </div>
        ) : (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <TypeTag type={type} />
                {type === -1 && <div onClick={() => setSaveCustomService(!saveCustomService)}>
                    <Checkbox labelText="Create Service" checked={saveCustomService} />
                </div>}
            </div>
        ),
        <TextInput value={amount} onChange={e => setAmount(absAmt(e.target.value))} placeholder="Input..." />,
        <TextInput value={qty} onChange={e => setQty(absAmt(e.target.value))} placeholder="Input..." />,
        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '1rem' }} />
            <p style={{ fontSize: 14, opacity: 0.65, fontWeight: 'bold' }}>{currency} {total}</p>
        </div>
    ]
}

const useLoadSellables = (serviceOnly) => {
    const [sellables, setSellables] = useState(undefined);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (serviceOnly) {
            setLoading(true)
            Api.getSellables(response => {
                if (response.status === true) {
                    if (serviceOnly) {
                        setSellables(response.payload.filter(item => item.type === OBJECT_TYPE_SERVICE || item.type === OBJECT_TYPE_SERVICE_PACKAGE))
                    } else {
                        setSellables(response.payload)
                    }
                } else {
                    UIUtil.showError(response.message);
                }
                setLoading(false)
            })
        } else {
            setSellables([])
            setLoading(false);
        }
    }, [])

    return [loading, sellables]
}

export function ServiceField({ id, type, name, onUpdate }) {
    const [loading, sellables] = useLoadSellables(true);
    const [item, setItem] = useState(() => ({ name, selectedItem: { id, type, name } }))

    useEffect(() => {
        onUpdate(item)
    }, [item])

    if (loading) {
        return (
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading style={{ width: 'unset' }} />
                <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
            </div>
        )
    }

    return <ItemSelector light sellables={sellables} item={item} setItem={setItem} />
}

export default ({ editable, serviceOnly, items, setItems, currency = "SAR" }) => {
    // const [items, setItems] = useState([])
    const [loading, sellables] = useLoadSellables(serviceOnly);

    if (loading) {
        return (
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading style={{ width: 'unset' }} />
                <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
            </div>
        )

    }

    return (
        <div style={{ width: '100%' }}>
            <DynamicTable
                items={items} setItems={setItems}
                editable={editable} entryView={Record}
                columns={COLUMNS} entryViewProps={{
                    sellables, currency, serviceOnly
                }} />
        </div>
    )
}