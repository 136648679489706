import { Checkbox, ComboBox, InlineLoading, Tag, TextInput } from "carbon-components-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { DynamicTable } from "../../../../components/dynamic-table"
import { getObjectTypeName, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_SERVICE, OBJECT_TYPE_SERVICE_PACKAGE } from "../../../../constants/ObjectTypes"

import {
    Product16, Box16, ServiceDesk16, Application16, WatsonHealthTextAnnotationToggle16, Edit16, EditOff16
} from '@carbon/icons-react'
import Util, { absAmt, big } from "../../../../util/Util"
import InfiniteComboBox from "../../../../components/InfiniteComboBox"
import ProfilePic from "../../../../components/ProfilePic"
import Api from "../../../../session/Api"
import UIUtil from "../../../../util/UIUtil"
import { TAX_TYPES } from ".."
import { AmountTagSelectorNormalField } from "../../../../pages/journal-entries/tag-selector"

const COLUMNS = [
    {
        title: "",
        width: '7px'
    },
    {
        title: "Item",
        flex: 4
    },
    {
        title: "Class",
        flex: 3
    },
    {
        title: "Memo",
        flex: 3
    },
    {
        title: "Price",
        flex: 1
    },
    {
        title: "Qty",
        flex: 1
    },
    {
        title: "Gross",
        flex: 2
    },
    {
        title: "Tax (optional)",
        flex: 2
    },
]

export const TypeTag = ({ type, ...props }) => {
    switch (type) {
        case OBJECT_TYPE_PRODUCT:
            return <Tag renderIcon={Product16} size="md" type="cyan" {...props}>{getObjectTypeName(OBJECT_TYPE_PRODUCT)}</Tag>
        case OBJECT_TYPE_PRODUCT_BUNDLE:
            return <Tag renderIcon={Box16} size="md" type="magenta" {...props}>{getObjectTypeName(OBJECT_TYPE_PRODUCT_BUNDLE)}</Tag>
        case OBJECT_TYPE_SERVICE:
            return <Tag renderIcon={ServiceDesk16} size="md" type="purple" {...props}>{getObjectTypeName(OBJECT_TYPE_SERVICE)}</Tag>
        case OBJECT_TYPE_SERVICE_PACKAGE:
            return <Tag renderIcon={Application16} size="md" type="green" {...props}>{getObjectTypeName(OBJECT_TYPE_SERVICE_PACKAGE)}</Tag>
        case -1:
            return <Tag renderIcon={WatsonHealthTextAnnotationToggle16} size="md" type="teal" {...props}>Custom Service</Tag>
        default:
            return null;
    }
}

const ItemView = ({ item }) => {
    return (
        <div style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020' }}>
            {/* <ProfilePic notProfile size={24} /> */}
            <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>{item.name}</p>
            {item.type === OBJECT_TYPE_PRODUCT && <p style={{ fontSize: 12, opacity: 0.65 }}>Product</p>}
            {item.type === OBJECT_TYPE_PRODUCT_BUNDLE && <p style={{ fontSize: 12, opacity: 0.65 }}>Service</p>}
            {/* <TypeTag type={item.type} size="sm" /> */}
        </div>
    )
}
ItemView.noPadding = true;

const ItemSelector = ({ sellables, item, setItem, light }) => {
    const textValue = item?.name;
    const setTextValue = name => {
        //setItem({ ...item, name, selectedItem: undefined })
        const itemToSelect = sellables.find(item => item.name === textValue);
        if (itemToSelect) {
            // setSelectedItem(itemToSelect)
            setItem({ ...item, name, selectedItem: itemToSelect })
        } else {
            setItem({ ...item, name, selectedItem: undefined })
        }
    }
    const selectedItem = item?.selectedItem;
    const setSelectedItem = selectedItem => {
        //setItem({ ...item, name: selectedItem?.name, selectedItem })
        setItem({ ...item, name: selectedItem ? selectedItem.name : textValue, selectedItem })
    }

    const onClear = () => setItem({ ...item, name: "", selectedItem: undefined })

    return <InfiniteComboBox light={light} ItemView={ItemView} itemToString={item => item.name} items={sellables}
        {...({ textValue, setTextValue, selectedItem, setSelectedItem, onClear })} />
}


const GrossInput = ({ total, setTotal }) => {
    const onEditBtn = () => {
        const amount = prompt("Input new gross amount");
        if (amount) {
            if (Util.isANumber(amount)) {
                setTotal(parseFloat(amount))
            } else {
                UIUtil.showInfo("Invalid number")
            }
        }
    }
    return (
        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 5 }} />
            <p style={{ fontSize: 14, opacity: 0.65, fontWeight: 'bold', flex: 1 }}>SAR {total}</p>
            <Edit16 style={{ cursor: 'pointer', color: '#0f62fe' }} onClick={onEditBtn} />
            <div style={{ width: 10 }} />
        </div>
    )
}

const Record = ({ sellables, predefinedClasses, editable, allowAmtEdit, item: recordItem, setItem: setRecordItem, useValue }) => {
    // const [item, setItem] = useValue("item", { name: "", selectedItem: undefined });
    // const [amount, setAmount] = useValue("amount", undefined);
    // const [qty, setQty] = useValue("qty", undefined);
    // const [saveCustomService, setSaveCustomService] = useValue("saveCustomService", false)
    const [item, setItem] = useState(() => {
        if (recordItem.item) {
            return { ...recordItem.item }
        } else {
            return { name: "", selectedItem: undefined }
        }
    });

    const [amount, setAmount] = useState(item?.selectedItem?.amount);
    const [qty, setQty] = useState(item?.selectedItem?.qty);
    const [saveCustomService, setSaveCustomService] = useState(false)

    const [taxName, setTaxName] = useState(item?.selectedItem?.taxName);
    const [tags, setTags] = useState(item?.selectedItem?.tags ?? []);
    const [memo, setMemo] = useState("")

    const type = (item?.selectedItem?.type) ?? -1;
    const customServiceName = type === -1 ? item?.name : undefined;
    const total = useMemo(() => big(qty).times(big(amount)).toFixed(2), [amount, qty])
    const setTotal = total => {
        if (!big(qty).eq(big(0))) {
            setAmount(big(total).div(big(qty)).toFixed(4))
        } else {
            setAmount(big(total).div(big(1)).toFixed(4))
        }
    }

    const dummyItem = recordItem.item === undefined;

    const firstTimeRef = useRef(true)

    useEffect(() => {
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        }

        setRecordItem({ ...recordItem, item, amount, qty, taxName, saveCustomService, tags, memo })
    }, [item, amount, qty, saveCustomService, taxName, tags, memo])

    useEffect(() => {
        if (!dummyItem) {
            setSaveCustomService(false)
        }
    }, [item])

    const onSetItem = newItem => {
        if (item.selectedItem?.type !== newItem.selectedItem?.type || item.selectedItem?.id !== newItem.selectedItem?.id) {
            setItem(newItem);
            setAmount(newItem.selectedItem?.amount ?? "")
        } else {
            setItem(newItem)
        }
        if (big(qty).lte(big(0))) {
            setQty(1)
        }
    }

    // if (!editable) {
    //     return [
    //         <div style={{ height: 40, width: '100%' }}>
    //             <TextInput light={false} value={item.name} />
    //         </div>,
    //         allowAmtEdit ? (
    //             <TextInput light={false} value={amount} onChange={e => setAmount(absAmt(e.target.value))} placeholder="Input..." />
    //         ) : (
    //             <TextInput light={false} value={amount} />
    //         ),
    //         <TextInput light={false} value={qty} />,
    //         <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
    //             <div style={{ width: '1rem' }} />
    //             <p style={{ fontSize: 14, opacity: 0.65, fontWeight: 'bold' }}>SAR {total}</p>
    //         </div>
    //     ]
    // }

    return [
        <div />,
        <div style={{ height: 40, width: '100%' }}>
            <ItemSelector light={false} sellables={sellables} item={item} setItem={onSetItem} />
        </div>,

        <div style={{ minHeight: 40, width: '100%', minWidth: 0, maxWidth: '100%', position: 'relative' }}>
            <AmountTagSelectorNormalField skipBottomBar options={predefinedClasses} value={tags} setValue={setTags} />
        </div>,
        <TextInput value={memo} onChange={e => setMemo(e.target.value)} placeholder="Input..." />,
        <TextInput light={false} value={amount} onChange={e => setAmount(absAmt(e.target.value))} placeholder="Input..." />,
        <TextInput light={false} value={qty} onChange={e => setQty(absAmt(e.target.value))} placeholder="Input..." />,
        <GrossInput total={total} setTotal={setTotal} />,

        <div className="no-input-border" style={{ height: 40, width: '100%', minWidth: 0 }}>
            <ComboBox
                placeholder="Code"
                items={TAX_TYPES}
                itemToString={item => item !== null ? item.value : ""}
                selectedItem={TAX_TYPES.find($ => $.value === taxName)}
                onChange={e => setTaxName(e.selectedItem?.value)} />
        </div>,

    ]
}

const useLoadSellables = (serviceOnly) => {
    const [sellables, setSellables] = useState(undefined);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        Api.getSellables(response => {
            if (response.status === true) {
                if (serviceOnly) {
                    setSellables(response.payload.filter(item => item.type === OBJECT_TYPE_SERVICE || item.type === OBJECT_TYPE_SERVICE_PACKAGE))
                } else {
                    setSellables(response.payload)
                }
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false)
        })
    }, [])

    return [loading, sellables]
}

export function ServiceField({ id, type, name, onUpdate }) {
    const [loading, sellables] = useLoadSellables(true);
    const [item, setItem] = useState(() => ({ name, selectedItem: { id, type, name } }))

    useEffect(() => {
        onUpdate(item)
    }, [item])

    if (loading) {
        return (
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading style={{ width: 'unset' }} />
                <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
            </div>
        )
    }

    return <ItemSelector light sellables={sellables} item={item} setItem={setItem} />
}

export const InvoiceItemsTable = ({ sellables, editable, predefinedClasses, items, setItems, allowAmtEdit }) => {
    // const [loading, sellables] = useLoadSellables(serviceOnly);

    // if (loading) {
    //     return (
    //         <div style={{ display: 'flex', width: '100%', height: 75.44, justifyContent: 'center', alignItems: 'center' }}>
    //             <InlineLoading style={{ width: 'unset' }} />
    //             <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
    //         </div>
    //     )
    // }


    return (
        <div style={{ width: '100%' }}>
            <DynamicTable

                minimalInput
                noRadius noBorder lightBorder
                items={items} setItems={setItems}
                editable={editable} entryView={Record}
                columns={COLUMNS} entryViewProps={{
                    sellables, predefinedClasses, editable, allowAmtEdit
                }} />
        </div>
    )
}