
import React from "react";
import { Content, Header, HeaderContainer, Pagination, HeaderGlobalAction, HeaderGlobalBar, HeaderMenuButton, HeaderName, HeaderPanel, Modal, SideNav, SideNavItems, SideNavLink, SideNavMenuItem, SkipToContent, Switcher, SwitcherDivider, SwitcherItem, TableContainer, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch, Tile, HeaderNavigation, DataTableSkeleton, Tag, ButtonSet, TableSelectAll, TableSelectRow } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from 'carbon-components-react';
import { Add16, Error16, Save16, Time16, ErrorFilled16, Search16, CheckmarkFilled16, ArrowRight16, ArrowLeft16, Calendar16, QueryQueue16, List16 } from '@carbon/icons-react'
import Api from "../session/Api";
import UIUtil from "../util/UIUtil";
import LoadingPage from "../components/page-messages/LoadingPage";
import Button from "../components/Button";
import { CHEQUE_STATUS_BOUNCED, CHEQUE_STATUS_PENDING, CHEQUE_STATUS_WAITING, CHEQUE_TATUS_DEPOSITED, TABLE_HEADER_TYPE_BALANCE, TABLE_HEADER_TYPE_COLOR, TABLE_HEADER_TYPE_CUSTOM, TABLE_HEADER_TYPE_DATE, TABLE_HEADER_TYPE_DISABLED_STATUS, TABLE_HEADER_TYPE_EMAIL_ADDRESS, TABLE_HEADER_TYPE_FULL_DATE, TABLE_HEADER_TYPE_MONEY, TABLE_HEADER_TYPE_PHONE_NUMBER, TABLE_HEADER_TYPE_PROFILE_PIC, TABLE_HEADER_TYPE_STOCK, TABLE_HEADER_TYPE_TABLE_LIST_ITEM, TABLE_HEADER_TYPE_TEXT, TABLE_HEADER_TYPE_THUMBNAIL } from "../constants/Constants";
import Util from "../util/Util";
import { Link, useHistory, withRouter } from "react-router-dom";
import ImageView from "../components/ImageView";
import ProfilePic from "../components/ProfilePic";
import ActiveStatusView from "../components/ActiveStatusView";
import { getObjectTypeName, OBJECT_TYPE_CHEQUE, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_TEMPLATE } from "../constants/ObjectTypes";
import PaginatedDataTable from "../components/data-table/PaginatedDataTable";
import ProductFinder from "../views/product/ProductFinder";
import { BalanceView } from "../components/BalanceView";
import { NewItemDialogBtn } from "./dialogs/new-item-dialog-btn";
import { ReportPage } from "../reporting-engine/base/report-page";

class TableTemplate extends React.Component {

    /**
     * 
     * @param {{title: string, subTitle: string, objectType: number, pagePath: string, embedded: boolean, selectableOnly: {onSelectUpdate: function(Array<any>)}, tableRelationMode: {nonEditable: boolean, tableRelationType: number, objectProperty: string, parentType: number, parentId: number, childType: number, childId: number, showNotIncluded: boolean, manyToManyChildView: boolean}, getChildrenRequest: {parentId: number}, getCustomListRequest: {requestType: number, params: any}}} dataSource 
     * @returns 
     */
    static renderTemplate(dataSource) {
        return React.createElement(TableTemplate, dataSource);
    }

    /**
     * 
     * @returns {{title: string, subTitle: string, objectType: number, pagePath: string, embedded: boolean, selectableOnly: {onSelectUpdate: function(Array<any>)}, tableRelationMode: {nonEditable: boolean, tableRelationType: number, objectProperty: string, parentType: number, parentId: number, childType: number, childId: number, showNotIncluded: boolean, manyToManyChildView: boolean}, getChildrenRequest: {parentId: number}, getCustomListRequest: {requestType: number, params: any}}}
     */
    getDataSource() {
        return this.props;
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,

            headers: [],
            rows: [],

            editTableRelationList: false,
            idsToRemove: [],
            idsToAdd: [],
            savingEditTableRelationList: false,
        }
    }

    deleteItem(id) {
        this.setState(prevState => ({ rows: prevState.rows.filter(row => row.id != id) }))
    }

    addItem(row) {
        this.setState(prevState => ({ rows: [row, ...prevState.rows] }))
    }

    saveItem(item) {
        for (let i = 0; i < this.state.rows.length; i++) {
            const row = this.state.rows[i]
            if (row.id == item.id) {
                const rows = [...this.state.rows]
                rows[i] = item
                this.setState({ rows: rows })
                break;
            }
        }
    }

    componentDidMount() {
        // this.loadList();
        this.legacyLoadList();
    }

    handleListLoadResponse(response) {
        if (response.payload.headers.length > 0) {
            this.setState({ headers: response.payload.headers })
        }
        return { loadedItems: response.payload.rows, loadedHasMore: response.payload.paginationHasMore }
    }

    legacyLoadList() {
        if (this.getDataSource().paginated) {
            return;
        }

        this.setState({ loading: true })
        this.loadList(-1, null, response => {
            if (response.status === true) {
                // this.setState({loading: false, headers: response.payload.headers, rows: this.getDataSource().filter ? response.payload.rows.filter(this.getDataSource().filter) : response.payload.rows})
                this.setState({ loading: false, headers: response.payload.headers, rows: response.payload.rows })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    loadList(page, sortRequest, listener) {
        // this.setState({loading: true})
        // const listener = response => {
        //     if (response.status === true) {
        //         this.setState({loading: false, headers: response.payload.headers, rows: response.payload.rows})
        //     } else {
        //         this.setState({loading: false})
        //     }
        // }

        const dataSource = this.getDataSource();

        const pagedLoadRequest = {
            page, sortRequest,
            requestObjectType: dataSource.objectType
        }


        if (dataSource.getCustomListRequest) {
            Api.getCustomList({
                objectType: dataSource.objectType,
                ...dataSource.getCustomListRequest,
                pagedLoadRequest
            }, listener);
        } else if (dataSource.getChildrenRequest) {
            Api.getChildren({
                parentId: dataSource.getChildrenRequest.parentId,
                childType: dataSource.objectType,
                pagedLoadRequest
            }, listener)
        } else if (dataSource.tableRelationMode) {
            const tableRelationMode = dataSource.tableRelationMode;
            Api.getTableRelationList({
                relationType: tableRelationMode.tableRelationType,
                objectType: dataSource.objectType,
                objectProperty: tableRelationMode.objectProperty,
                parentType: tableRelationMode.parentType,
                parentId: tableRelationMode.parentId,
                childType: tableRelationMode.childType,
                childId: tableRelationMode.childId,
                showNotIncluded: tableRelationMode.showNotIncluded,
                manyToManyShowChild: tableRelationMode.manyToManyChildView,
                pagedLoadRequest
            }, listener);
        } else {
            Api.getList(pagedLoadRequest, dataSource.objectType, listener);
        }
    }

    isEditOneToManyListChangesMade() {
        return this.state.idsToRemove.length > 0 || this.state.idsToAdd.length > 0;
    }

    onSaveEditOneToManyListBtn(skipSuccessMsg) {
        this.setState({ savingEditTableRelationList: true })

        const dataSource = this.getDataSource();
        const tableRelationMode = dataSource.tableRelationMode;
        Api.saveTableRelationRequest({
            relationType: tableRelationMode.tableRelationType,
            objectType: dataSource.objectType,
            objectProperty: tableRelationMode.objectProperty,
            parentType: tableRelationMode.parentType,
            parentId: tableRelationMode.parentId,
            childType: tableRelationMode.childType,
            childId: tableRelationMode.childId,
            idsToAdd: this.state.idsToAdd,
            idsToRemove: this.state.idsToRemove,
            manyToManySaveChild: tableRelationMode.manyToManyChildView
        }, response => {
            this.setState({ editTableRelationList: response.status !== true, savingEditTableRelationList: false }, () => {
                if (response.status === true) {
                    // this.loadList();
                    this.legacyLoadList();
                }
            })
            if (response.status === true) {
                if (!skipSuccessMsg) {
                    UIUtil.showSuccess();
                }
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    getCellHeader(cell) {
        for (const header of this.state.headers) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    renderCell(property, type, value, id) {
        //const header = this.getCellHeader(cell);
        const cell = { value, id }
        switch (type) {
            case TABLE_HEADER_TYPE_TEXT:
                if (typeof cell.value === "boolean") {
                    return cell.value ? "Yes" : "No";
                }

                return cell.value;
            case TABLE_HEADER_TYPE_DATE:
                return <> <Calendar16 /> {Util.getDate(cell.value)} </>
            case TABLE_HEADER_TYPE_FULL_DATE:
                if (cell.value == Number.MAX_SAFE_INTEGER) {
                    return 'Indefinite'
                }
                return <> <Calendar16 /> {Util.getDateOnly(cell.value)} </>
            case TABLE_HEADER_TYPE_DISABLED_STATUS:
                return (
                    <ActiveStatusView active={!cell.value} />
                )
            case TABLE_HEADER_TYPE_THUMBNAIL:
                return (
                    <div style={{ padding: 15 }}>
                        <ImageView style={{ width: 100, height: 100, }} src={Api.getThumbnail(this.getDataSource().objectType, cell.value)} />
                    </div>
                )
            case TABLE_HEADER_TYPE_PROFILE_PIC:
                return (
                    <ProfilePic size={34} src={Api.getThumbnail(this.getDataSource().objectType, cell.value)} />
                )
            case TABLE_HEADER_TYPE_TABLE_LIST_ITEM:
                return (
                    //<Link style={{color: 'black'}} to={"/staff-users/" + 2} onClick={e => e.stopPropagation()}>{cell.value}</Link>
                    cell.value
                )
            case TABLE_HEADER_TYPE_EMAIL_ADDRESS:
                return cell.value;

            case TABLE_HEADER_TYPE_PHONE_NUMBER:
                return cell.value;

            case TABLE_HEADER_TYPE_MONEY:
                // console.log(cell);
                //return <> SAR {cell.value.toFixed !== undefined ? cell.value.toFixed(2) : cell.value} </>;
                return <> SAR {Util.formatMoney(cell.value)} </>;

            case TABLE_HEADER_TYPE_STOCK:
                return cell.value;

            case TABLE_HEADER_TYPE_CUSTOM:
                let item;
                for (const row of this.state.rows) {
                    if (row.id == cell.id.split(":")[0]) {
                        item = row;
                    }
                }
                if (item !== undefined) {
                    //if (this.getDataSource().objectType == OBJECT_TYPE_CHEQUE && header.key == "status") {
                    if (this.getDataSource().objectType == OBJECT_TYPE_CHEQUE && property == "status") {
                        return this.renderChequeStatus(item);
                    } else if (this.getDataSource().objectType == OBJECT_TYPE_TEMPLATE && property == "templateTypeDefault") {
                        return this.renderTemplateDefault(item);
                    } else {
                        return "-";
                    }
                } else {
                    return "-"
                }

            case TABLE_HEADER_TYPE_COLOR:
                return <div style={{ width: 25, height: 25, borderRadius: 25, background: cell.value ?? "black" }} />

            case TABLE_HEADER_TYPE_BALANCE:
                return <BalanceView balance={cell.value} />
        }
    }

    renderTemplateDefault(template) {
        if (template.templateTypeDefault) {
            return <Tag style={{ color: 'green' }} renderIcon={CheckmarkFilled16}>Default</Tag>;
        }
    }

    renderChequeStatus(cheque) {
        let status;
        switch (cheque.status) {
            case CHEQUE_STATUS_WAITING:
                status = <Tag renderIcon={Time16}>Waiting for due date</Tag>;
                break;
            case CHEQUE_STATUS_PENDING:
                status = <Tag renderIcon={Time16}>Pending action</Tag>;
                break;
            case CHEQUE_STATUS_BOUNCED:
                status = <Tag style={{ color: 'red' }} renderIcon={ErrorFilled16}>Bounced</Tag>;
                break;
            case CHEQUE_TATUS_DEPOSITED:
                status = <Tag style={{ color: 'green' }} renderIcon={CheckmarkFilled16}>Deposited</Tag>;
                break;
        }

        return (
            status
        )
    }

    getNewPath() {
        const dataSource = this.getDataSource();
        if (dataSource.getChildrenRequest) {
            const request = dataSource.getChildrenRequest;
            return dataSource.pagePath + 'new?pId=' + request.parentId;
        } else {
            return dataSource.pagePath + 'new';
        }
    }

    onExportBtn() {
        const headers = this.state.headers.filter(header => header.type != TABLE_HEADER_TYPE_THUMBNAIL && header.type != TABLE_HEADER_TYPE_PROFILE_PIC);
        const rows = this.state.rows.map(row => {
            return headers.map(header => {
                const cell = { value: row[header.key] }

                switch (header.type) {
                    case TABLE_HEADER_TYPE_TEXT:
                        if (typeof cell.value === "boolean") {
                            return cell.value ? "Yes" : "No";
                        }
                        return cell.value;
                    case TABLE_HEADER_TYPE_DATE:
                        return Util.getDate(cell.value);
                    case TABLE_HEADER_TYPE_FULL_DATE:
                        return Util.getDateOnly(cell.value);
                    case TABLE_HEADER_TYPE_DISABLED_STATUS:
                        return !cell.value ? "Active" : "Disabled";
                    case TABLE_HEADER_TYPE_TABLE_LIST_ITEM:
                        return cell.value;
                    case TABLE_HEADER_TYPE_EMAIL_ADDRESS:
                        return cell.value;

                    case TABLE_HEADER_TYPE_PHONE_NUMBER:
                        return cell.value;

                    case TABLE_HEADER_TYPE_MONEY:
                        return "SAR " + Util.formatMoney(cell.value);

                    case TABLE_HEADER_TYPE_STOCK:
                        return cell.value;

                    case TABLE_HEADER_TYPE_CUSTOM:
                        return "-";
                }
            })
        })


        let csvContent = "data:text/csv;charset=utf-8,";

        [headers.map(header => header.header), ...rows].forEach(function (rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);

        const a = document.createElement("a");
        a.href = encodedUri;
        a.download = getObjectTypeName(this.getDataSource().objectType) + "Report.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ width: '100%' }}>
                    <DataTableSkeleton />
                </div>
            )
        }

        if (this.state.editTableRelationList) {
            return (
                <div style={{ width: '100%', paddingTop: 2, flex: 1, }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <NewItemDialogBtn objectType={this.getDataSource().objectType} onSuccess={createdItem => {
                            this.setState({
                                idsToAdd: [createdItem.id]
                            }, () => this.onSaveEditOneToManyListBtn(true))

                            // this.setState(prevState => ({
                            //     __selected_items_to_add_key: Util.newTempId(),
                            //     idsToAdd: [...(prevState.idsToAdd ?? []), createdItem.id]
                            // }))
                        }} />
                        <div style={{ flex: 1 }} />
                        <ButtonSet>
                            <Button onClick={() => this.setState({ editTableRelationList: false })}
                                disabled={this.state.savingEditTableRelationList}
                                kind="secondary" style={{ minWidth: 200 }} renderIcon={Error16}>Cancel</Button>
                            <Button onClick={this.onSaveEditOneToManyListBtn.bind(this)}
                                loading={this.state.savingEditTableRelationList} style={{ minWidth: 200 }} renderIcon={Save16} disabled={!this.isEditOneToManyListChangesMade()}>Save</Button>
                        </ButtonSet>
                    </div>
                    <div style={{ height: '1rem' }} />

                    {this.getDataSource().objectType !== OBJECT_TYPE_PRODUCT ? (<>
                        <div style={{ display: 'flex', gap: '1rem', }}>
                            <div style={{ flex: 1, height: '100%' }}>
                                <p style={{ marginBottom: '0.5rem', fontSize: 12 }}>Select items to add</p>
                                {TableTemplate.renderTemplate({
                                    key: this.state.__selected_items_to_add_key,
                                    ...this.getDataSource(),
                                    embedded: true,
                                    paginated: false,
                                    selectableOnly: {
                                        onSelectUpdate: selectedItems => this.setState({ idsToAdd: selectedItems.map(item => item.id) })
                                    },
                                    tableRelationMode: {
                                        ...this.getDataSource().tableRelationMode,
                                        showNotIncluded: true
                                    }
                                })}
                            </div>

                            <div style={{ flex: 1, height: '100%' }}>
                                <p style={{ marginBottom: '0.5rem', fontSize: 12 }}>Select items to remove</p>
                                {TableTemplate.renderTemplate({
                                    ...this.getDataSource(),
                                    embedded: true,
                                    paginated: false,
                                    selectableOnly: {
                                        onSelectUpdate: selectedItems => this.setState({ idsToRemove: selectedItems.map(item => item.id) })
                                    },
                                    tableRelationMode: {
                                        ...this.getDataSource().tableRelationMode,
                                        showNotIncluded: false
                                    }
                                })}
                            </div>
                        </div>
                    </>) : (<>





                        <div style={{}}>
                            <div style={{ width: '100%', marginBottom: '3rem' }}>
                                {/* <p style={{ marginBottom: '0.5rem', fontSize: 12 }}>Select items to add</p> */}
                                <div style={{ width: '100%', height: '3rem', borderRadius: 5, marginBottom: '1rem', gap: '0.5rem', display: 'flex', alignItems: 'center', paddingInline: '1rem', color: 'white', background: 'green' }}>
                                    <Add16 />
                                    <p style={{ flex: 1 }}>Adding items</p>
                                    <p style={{ fontSize: 12 }}>Select items to add</p>
                                </div>

                                <div style={{ height: 450 }}>
                                    <ReportPage reportName="ProductList" embeddedMode componentMode
                                        selectionMode={{
                                            isSelected: id => this.state.idsToAdd?.includes(id),
                                            select: id => this.setState(prevState => ({ idsToAdd: [...prevState.idsToAdd, id] })),
                                            unselect: removeId => this.setState(prevState => ({ idsToAdd: prevState.idsToAdd?.filter(id => id != removeId) })),
                                            isAnySelected: () => this.state.idsToAdd?.length > 0,
                                            clearSelection: () => this.setState({ idsToAdd: [] })
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ width: '100%', height: '3rem', borderRadius: 5, marginBottom: '1rem', gap: '0.5rem', display: 'flex', alignItems: 'center', paddingInline: '1rem', color: 'white', background: 'red' }}>
                                <Add16 />
                                <p style={{ flex: 1 }}>Removing items</p>
                                <p style={{ fontSize: 12 }}>Select items to add</p>
                            </div>
                            <div style={{ width: '100%' }}>
                                {/* <p style={{ marginBottom: '0.5rem', fontSize: 12 }}>Select items to remove</p> */}
                                {TableTemplate.renderTemplate({
                                    ...this.getDataSource(),
                                    embedded: true,
                                    paginated: false,
                                    selectableOnly: {
                                        onSelectUpdate: selectedItems => this.setState({ idsToRemove: selectedItems.map(item => item.id) })
                                    },
                                    tableRelationMode: {
                                        ...this.getDataSource().tableRelationMode,
                                        showNotIncluded: false
                                    }
                                })}
                            </div>
                        </div>
                    </>)}



                </div>
            )
        }

        if (this.getDataSource().paginated) {
            return (
                <div style={{ width: '100%' }}>
                    <PaginatedDataTable
                        title={!this.getDataSource().embedded && this.getDataSource().title} description={!this.getDataSource().embedded && this.getDataSource().subTitle}
                        loader={this.loadList.bind(this)} loadResponseHandler={this.handleListLoadResponse.bind(this)}

                        // onClick={item => this.props.history.push(this.getDataSource().pagePath + item.id)}
                        onClick={!this.getDataSource().selectableOnly ? ((row) => {
                            if (this.getDataSource().selectableOnly2) {
                                if (this.getDataSource().selectableOnly2.selectedIds.includes(row.id)) {
                                    this.getDataSource().selectableOnly2.onSelectRemoved(row.id)
                                } else {
                                    this.getDataSource().selectableOnly2.onSelectAdded(row.id)
                                }
                            } else {
                                if (this.getDataSource().customRowButtonListener) {
                                    this.getDataSource().customRowButtonListener(row.id);
                                } else {
                                    this.props.history.push(this.getDataSource().pagePath + row.id)
                                }
                            }
                        }) : (item => this.props.history.push(this.getDataSource().pagePath + item.id))}

                        columns={this.state.headers.map(header => ({
                            id: header.key,
                            name: header.header,
                            render: item => this.renderCell(header.key, header.type, item[header.key], item.id)
                        }))}


                        rowStyle={cell => {
                            return {
                                backgroundColor: (this.getDataSource().selectableOnly2 && this.getDataSource().selectableOnly2.selectedIds && this.getDataSource().selectableOnly2.selectedIds.includes(parseInt(cell.id))) ? '#00000020' : undefined
                            }
                        }}

                    >
                        <TableToolbarContent>
                            <Button onClick={() => {
                                if (this.getDataSource().objectType === OBJECT_TYPE_PRODUCT) {
                                    UIUtil.showOverlay2(onClose => <ProductFinder onProductClick={product => this.props.history.push(this.getDataSource().pagePath + product.id)} onClose={onClose} />)
                                } else {
                                    this.getDataSource()?.onSearchBtn?.()
                                }
                            }}
                                kind={"ghost"}
                                renderIcon={Search16}
                                iconDescription="Search"
                                hasIconOnly />
                            {!this.getDataSource().selectableOnly && !this.getDataSource().getCustomListRequest && <>
                                {this.getDataSource().tableRelationMode ? (
                                    !this.getDataSource().tableRelationMode.nonEditable &&
                                    <Button onClick={() => this.setState({ editTableRelationList: true, idsToRemove: [], idsToRemove: [] })} renderIcon={QueryQueue16}>Edit</Button>
                                ) : (
                                    this.getDataSource().selectableOnly2 ? (
                                        <Button onClick={() => {
                                            if (this.getDataSource().selectableOnly2.selectedIds.length >= this.state.rows.length) {
                                                this.getDataSource().selectableOnly2.onMultiSelectRemoved(this.state.rows.map(row => row.id))
                                            } else {
                                                this.getDataSource().selectableOnly2.onMultiSelectAdded(this.state.rows.map(row => row.id))
                                            }
                                        }}
                                            kind={this.getDataSource().selectableOnly2.selectedIds.length >= this.state.rows.length ? "secondary" : "primary"}
                                            renderIcon={List16}>{this.getDataSource().selectableOnly2.selectedIds.length >= this.state.rows.length ? 'Unselect All' : 'Select All'}</Button>
                                    ) : (
                                        this.getDataSource().customNewButtonListener ? (
                                            <Button onClick={this.getDataSource().customNewButtonListener} renderIcon={Add16}>New</Button>
                                        ) : (
                                            <Link to={this.getNewPath()} style={{ textDecoration: 'none' }}>
                                                <Button renderIcon={Add16}>New</Button>
                                            </Link>
                                        )
                                    )
                                )}
                            </>}
                        </TableToolbarContent>
                    </PaginatedDataTable>
                </div>
            )
        }

        return (
            <div style={{ width: '100%' }}>
                <DataTable //rows={this.state.rows} 
                    rows={this.getDataSource().filter ? this.state.rows.filter(this.getDataSource().filter) : this.state.rows}
                    headers={this.state.headers} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => {
                        if (this.lastSelection == undefined) {
                            this.lastSelection = []
                        }
                        if (this.getDataSource().selectableOnly && this.lastSelection.length !== selectedRows.length) {
                            this.lastSelection = selectedRows;
                            setTimeout(() => this.getDataSource().selectableOnly.onSelectUpdate(selectedRows))
                        }

                        return (
                            <TableContainer title={!this.getDataSource().embedded && this.getDataSource().title} description={!this.getDataSource().embedded && this.getDataSource().subTitle}>
                                <TableToolbar>
                                    <TableToolbarContent>
                                        {this.getDataSource().customToolbarContent}

                                        <TableToolbarSearch placeholder={"Search"} onChange={onInputChange} />
                                        {!this.getDataSource().selectableOnly &&
                                            //!this.getDataSource().getCustomListRequest 
                                            !this.getDataSource().hideCreateButton
                                            &&
                                            <>
                                                <TableToolbarMenu>
                                                    <TableToolbarAction onClick={() => this.onExportBtn()}>
                                                        Export to .CSV
                                                    </TableToolbarAction>
                                                </TableToolbarMenu>
                                                {(this.getDataSource().tableRelationMode && !this.getDataSource().tableRelationMode.showNewBtn) ? (
                                                    !this.getDataSource().tableRelationMode.nonEditable &&
                                                    <Button onClick={() => this.setState({ editTableRelationList: true, idsToRemove: [], idsToRemove: [] })} renderIcon={QueryQueue16}>Edit</Button>
                                                ) : (
                                                    this.getDataSource().selectableOnly2 ? (
                                                        <Button onClick={() => {
                                                            if (this.getDataSource().selectableOnly2.selectedIds.length >= this.state.rows.length) {
                                                                this.getDataSource().selectableOnly2.onMultiSelectRemoved(this.state.rows.map(row => row.id))
                                                            } else {
                                                                this.getDataSource().selectableOnly2.onMultiSelectAdded(this.state.rows.map(row => row.id))
                                                            }
                                                        }}
                                                            kind={this.getDataSource().selectableOnly2.selectedIds.length >= this.state.rows.length ? "secondary" : "primary"}
                                                            renderIcon={List16}>{this.getDataSource().selectableOnly2.selectedIds.length >= this.state.rows.length ? 'Unselect All' : 'Select All'}</Button>
                                                    ) : (
                                                        this.getDataSource().customNewButtonListener ? (
                                                            <Button onClick={this.getDataSource().customNewButtonListener} renderIcon={Add16}>New</Button>
                                                        ) : (
                                                            <Link to={this.getNewPath()} style={{ textDecoration: 'none' }}>
                                                                <Button renderIcon={Add16}>New</Button>
                                                            </Link>
                                                        )
                                                    )
                                                )}
                                            </>}

                                        {this.getDataSource().customToolbarButton}
                                    </TableToolbarContent>
                                </TableToolbar>
                                <Table {...getTableProps()} >
                                    <TableHead >
                                        <TableRow >
                                            {this.getDataSource().selectableOnly &&
                                                <TableSelectAll {...getSelectionProps()} />}
                                            {headers.map((header) => (
                                                <TableHeader key={header.key} {...getHeaderProps({ header })} >
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow onClick={!this.getDataSource().selectableOnly ? (() => {
                                                if (this.getDataSource().selectableOnly2) {
                                                    if (this.getDataSource().selectableOnly2.selectedIds.includes(row.id)) {
                                                        this.getDataSource().selectableOnly2.onSelectRemoved(row.id)
                                                    } else {
                                                        this.getDataSource().selectableOnly2.onSelectAdded(row.id)
                                                    }
                                                } else {
                                                    if (this.getDataSource().customRowButtonListener) {
                                                        this.getDataSource().customRowButtonListener(row.id);
                                                    } else {
                                                        this.props.history.push(this.getDataSource().pagePath + row.id)
                                                    }
                                                }
                                            }) : (() => { })} key={row.id} {...getRowProps({ row })}>
                                                {this.getDataSource().selectableOnly &&
                                                    <TableSelectRow {...getSelectionProps({ row })} />}

                                                {row.cells.map((cell) => (
                                                    <TableCell key={cell.id} style={{ backgroundColor: (this.getDataSource().selectableOnly2 && this.getDataSource().selectableOnly2.selectedIds && this.getDataSource().selectableOnly2.selectedIds.includes(parseInt(cell.id))) ? '#00000020' : undefined, transition: 'background-color 250ms' }}>{this.renderCell(this.getCellHeader(cell).key, this.getCellHeader(cell).type, cell.value, cell.id)}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {!this.getDataSource().hideBottomBar && <TableToolbar>
                                    {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                                    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        {/* <InlineLoading style={{width: 'unset'}} /> */}
                                        <p style={{ fontSize: 12, opacity: 0.65 }}>Loaded all {(this.state.rows ?? []).length} row{(this.state.rows ?? []).length != 1 && 's'}</p>
                                    </div>
                                </TableToolbar>}
                            </TableContainer>
                        )
                    }}
                </DataTable>
            </div>
        )
    }

}

export default TableTemplate;