import { Policy32, GraphicalDataFlow32, UserCertification32, ChartBullet32, Education32, GroupPresentation32, Currency32, CenterSquare32 } from '@carbon/icons-react'
import React from 'react'
import Util from '../../util/Util'
import { AgentCards } from './agent-cards'
import { AgentCardsLms } from './agent-cards-lms'

const Card = ({ title, icon, value, yesterdayValue, isMoney, classIndex, zIndex, newLabel = "new" }) => (
    <div className='square-stat-root' style={{ position: 'relative', width: 175, height: 125, zIndex: zIndex }}>
        <div className={"square-stat square-stat-" + classIndex} style={{ zIndex: 2 }}>
            {React.createElement(icon)}
            <p className='square-stat-title'>{title}</p>
            {isMoney ? (
                <p className='square-stat-value'>SAR {Util.formatMoney(value)}</p>
            ) : (
                <p className='square-stat-value'>{value} <strong>{newLabel}</strong></p>
            )}

        </div>
        <div className={"square-stat-extend square-stat-" + classIndex + '-mini'} style={{ color: 'white', display: 'flex', justifyContent: 'flex-end', paddingBottom: '1.5rem', alignItems: 'center', flexDirection: 'column', gap: '0.15rem', width: '80%', height: 100, position: 'absolute', right: -25, bottom: '-65%', zIndex: 1 }}>
            <p style={{ fontSize: 18 }} className='square-stat-title'>Yesterday</p>
            {isMoney ? (
                <p style={{ fontSize: 14 }} className='square-stat-value'>SAR {Util.formatMoney(yesterdayValue)}</p>
            ) : (
                <p style={{ fontSize: 14 }} className='square-stat-value'>{yesterdayValue}</p>
            )}
        </div>
    </div>
)




export function TopOverviewLms({ dashboard }) {
    return (
        <div style={{ width: '100%', display: 'flex', gap: '3.0rem', padding: '1rem' }}>
            <div style={{ position: 'relative', zIndex: 2, display: 'flex', flexDirection: 'column', gap: '1.0rem', transformStyle: 'preserve-3d' }}>
                <h3 style={{ fontWeight: 'bold' }}>Overview</h3>
                <div style={{ display: 'flex', gap: '1.0rem' }}>
                    <Card value={dashboard.classesToday} yesterdayValue={dashboard.classesYesterday} icon={GroupPresentation32} title="Classes" classIndex={1} zIndex={6} newLabel="today" />
                    <Card value={dashboard.attendanceToday} yesterdayValue={dashboard.attendanceYesterday} icon={CenterSquare32} title="Attendance" classIndex={2} zIndex={5} newLabel="scan" />
                </div>
                <div style={{ display: 'flex', gap: '1.0rem' }}>
                    <Card value={dashboard.leadsCreatedToday} yesterdayValue={dashboard.leadsCreatedYesterday} icon={UserCertification32} title="Leads" classIndex={3} zIndex={4} />
                    <Card value={dashboard.studentsCreatedToday} yesterdayValue={dashboard.studentsCreatedYesterday} icon={Education32} title="Students" classIndex={4} zIndex={3} />
                </div>
                <div style={{ display: 'flex', gap: '1.0rem' }}>
                    <Card value={dashboard.billsRecordedToday} yesterdayValue={dashboard.billsRecordedYesterday} icon={ChartBullet32} title="Bills" classIndex={5} zIndex={2} />
                    <Card value={dashboard.transactionsCreatedToday} yesterdayValue={dashboard.transactionsCreatedYesterday} icon={Currency32} title="Transactions" classIndex={6} zIndex={1} />
                </div>
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minWidth: 0 }}>
                <h3 style={{ fontWeight: 'bold' }}>Tutors</h3>
                <div className="square-stat-0" style={{ position: 'relative', zIndex: 1, flex: 1, borderRadius: 25, }}>
                    <AgentCardsLms dashboard={dashboard} />
                </div>
            </div>
        </div>
    )
}