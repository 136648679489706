import React, { useEffect, useState } from 'react'

import {
    ArrowLeft16,
    OverflowMenuVertical16,
    ShoppingCart16,
    ShoppingCart24,
    Minimize16,
    ChartNetwork24,
    ChartNetwork16,
    ChevronRight16,
    Delete16,
    ErrorFilled16,
    Copy16,
    Printer16,
    ShoppingCartPlus16,
    Error16
} from '@carbon/icons-react'
import Button from '../../../components/Button'
import CartList from '../components/CartList'
import { Tab, Tabs } from 'carbon-components-react'
import { SALES_CART_WORK_STATUS_EXPIRED, SALES_CART_WORK_STATUS_ONGOING, SALES_CART_WORK_STATUS_QUOTATION, SALES_CART_WORK_STATUS_SALES, SALES_CART_WORK_STATUS_SALES_ORDER } from '../../../constants/Constants'
import ProfilePic from '../../../components/ProfilePic'
import Api from '../../../session/Api'
import { OBJECT_TYPE_ACCOUNT } from '../../../constants/ObjectTypes'
import Util from '../../../util/Util'
import useHover from '../../../hooks/useHover'
import ReactTooltip from 'react-tooltip';

const IconBtn = ({ icon, danger, positive, title }) => {
    const hover = useHover()
    return (
        <div data-tip={title} data-effect="solid" className={"work-list-item-shortcut-btn" + (danger ? "-danger" : positive ? "-positive" : "")} style={{ width: 45, height: 45, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
            {React.createElement(icon)}
            {/* <p style={{fontSize: 12, opacity: hover.isHover ? 0.65 : 0, transition: '250ms', paddingLeft: '0.25rem'}}>{title}</p> */}
        </div>
    )
}

const ItemShortcuts = ({ item }) => {
    switch (item.status) {
        case SALES_CART_WORK_STATUS_ONGOING:
            return (<>
                <IconBtn icon={Delete16} danger title="Delete" />
            </>)
        case SALES_CART_WORK_STATUS_QUOTATION:
            return (<>
                <IconBtn icon={Copy16} title="Duplicate" />
                <IconBtn icon={Printer16} title="Print" />
                <IconBtn icon={ShoppingCartPlus16} positive title="Create Sale" />
                <IconBtn icon={ErrorFilled16} danger title="Revoke" />
            </>)
        case SALES_CART_WORK_STATUS_SALES_ORDER:
            return (<>
                <IconBtn icon={ShoppingCartPlus16} positive title="Create Sale" />
                <IconBtn icon={ErrorFilled16} danger title="Revoke" />
            </>)
        case SALES_CART_WORK_STATUS_SALES:
            return (<>
                <IconBtn icon={Copy16} title="Duplicate" />
                <IconBtn icon={Printer16} title="Print" />
            </>)
        case SALES_CART_WORK_STATUS_EXPIRED:
            return (<>
                <IconBtn icon={Copy16} title="Duplicate" />
                <IconBtn icon={Printer16} title="Print" />
            </>)
        default:
            return null;
    }
}

const Item = ({ item, onClick }) => {
    const hover = useHover()

    return (
        <div {...hover.init()}>
            <div onClick={onClick} className="work-list-item" style={{
                display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderBottomColor: '#00000020',
                paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem', alignItems: !Util.isStringExists(item.label) ? 'center' : undefined
            }}>
                <ProfilePic src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.customerId)} size={30} style={{ marginTop: Util.isStringExists(item.label) ? '0.5rem' : 0 }} />
                <div style={{ flex: 1, paddingLeft: '0.50rem', paddingRight: '0.5rem' }}>
                    <p style={{ fontSize: 18 }}>{item.label}</p>
                    <h6 style={{ marginTop: '0.15rem' }}>{item.customerName}</h6>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>{Util.getDate(item.latestStatusUpdateDate)}</p>

                    {item.status == SALES_CART_WORK_STATUS_EXPIRED && !item.revoked &&
                        <p style={{ fontSize: 12, opacity: 1, color: 'black' }}>Expired on {Util.getDate(item.quotationExpirationDate)}</p>}

                    {item.status == SALES_CART_WORK_STATUS_SALES &&
                        <p style={{ fontSize: 12, opacity: 1, color: '#00551a' }}>Sales Voucher No: {Util.getVoucherNumber(item.salesStockFlowId)}</p>}

                    {item.status == SALES_CART_WORK_STATUS_QUOTATION &&
                        <p style={{ fontSize: 12, opacity: 1, color: '#990000' }}>Quotation No: {Util.getVoucherNumber(item.id)}</p>}

                    {item.status == SALES_CART_WORK_STATUS_SALES_ORDER &&
                        // <p style={{ fontSize: 12, opacity: 1, color: '#997d00' }}>Sales Order Voucher No: {Util.getVoucherNumber(item.salesOrderId)}</p>}
                        <p style={{ fontSize: 12, opacity: 1, color: '#997d00' }}>Proforma Invoice Voucher No: {Util.getVoucherNumber(item.salesOrderId)}</p>}
                </div>

                {(item.status == SALES_CART_WORK_STATUS_SALES || item.status == SALES_CART_WORK_STATUS_QUOTATION || item.status == SALES_CART_WORK_STATUS_SALES_ORDER) && <>
                    <div style={{ height: 25, paddingLeft: '0.50rem', paddingRight: '0.50rem', borderRadius: 25, marginRight: '0.25rem', background: item.status == SALES_CART_WORK_STATUS_QUOTATION ? '#990000' : (item.status == SALES_CART_WORK_STATUS_SALES_ORDER ? '#997d00' : '#00551a'), color: 'white', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, opacity: 0.65, fontWeight: 'bold' }}>SAR {item.amount.toFixed(2)}</p>
                    </div>
                </>}

                {item.status == SALES_CART_WORK_STATUS_EXPIRED && item.revoked && <>
                    <div style={{ height: 25, paddingLeft: '0.50rem', paddingRight: '0.50rem', borderRadius: 25, marginRight: '0.25rem', background: 'black', color: 'white', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, opacity: 0.65, fontWeight: 'bold' }}>Revoked</p>
                    </div>
                </>}

                <ChevronRight16 style={{ alignSelf: 'center' }} />
            </div>
            {/* <div className="theme-bg" style={{ width: '100%', height: hover.isHover ? 45 : 0, opacity: hover.isHover ? 1 : 0, paddingLeft: '1rem', transition: '250ms', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', overflow: 'hidden' }}>
                <label className="bx--label" style={{ color: 'white', margin: 0, flex: 1 }}>Shortcuts</label>

                <ItemShortcuts item={item} />
            </div> */}
        </div>
    )
}

const List = ({ list, onItemClick }) => (
    list.length > 0 ? list.map(item => <Item key={item.id + '-workstation-cartlist'} item={item} onClick={() => onItemClick(item)} />) :
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100 }}>
            <p style={{ fontSize: 12, opacity: 0.65 }}>No items</p>
        </div>
)

const View = ({ workList, onItemClick, tabSelectRequest }) => {
    const [tab, setTab] = useState(0)

    useEffect(() => {
        if (tabSelectRequest) {
            setTab(tabSelectRequest)
        }
    }, tabSelectRequest)

    const ongoings = workList.filter(item => item.status == SALES_CART_WORK_STATUS_ONGOING)
    const quotations = workList.filter(item => item.status == SALES_CART_WORK_STATUS_QUOTATION)
    const salesOrders = workList.filter(item => item.status == SALES_CART_WORK_STATUS_SALES_ORDER)

    return (
        <Tabs className="full-width-tabs" selected={tab} onSelectionChange={tab => setTab(tab)}>
            <Tab label={"Ongoing" + (true ? " (" + ongoings.length + ")" : "")} className={tab == 0 ? "blue-tab unfocused-tab" : "unfocused-tab"}>
                <List onItemClick={onItemClick} list={ongoings} />
            </Tab>
            <Tab label={"Quotations" + (true ? " (" + quotations.length + ")" : "")} className={tab == 1 ? "red-tab unfocused-tab" : "unfocused-tab"}>
                <List onItemClick={onItemClick} list={quotations} />
            </Tab>
            {/* <Tab label={"Sales Order" + (true ? " (" + salesOrders.length + ")" : "")} className={tab == 2 ? "yellow-tab unfocused-tab" : "unfocused-tab"}> */}
            <Tab label={"Proforma" + (true ? " (" + salesOrders.length + ")" : "")} className={tab == 2 ? "yellow-tab unfocused-tab" : "unfocused-tab"}>
                <List onItemClick={onItemClick} list={salesOrders} />
            </Tab>
            <Tab label="Sales" className={tab == 3 ? "green-tab unfocused-tab" : "unfocused-tab"}>
                <List onItemClick={onItemClick} list={workList.filter(item => item.status == SALES_CART_WORK_STATUS_SALES)} />
            </Tab>
            <Tab label="Expired" className={tab == 4 ? "black-tab unfocused-tab" : "unfocused-tab"}>
                <List onItemClick={onItemClick} list={workList.filter(item => item.status == SALES_CART_WORK_STATUS_EXPIRED)} />
            </Tab>
        </Tabs>
    )
}

export default ({ workList, onWorkSelected, tabSelectRequest }) => {
    const [collapsed, setCollapsed] = useState(false)


    if (collapsed) {
        return (
            <div onClick={() => setCollapsed(false)} className="sales-cart-collapsed-panel" style={{ width: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <div style={{ display: 'flex', minWidth: 200, alignItems: 'center', justifyContent: 'center', transform: 'rotate(270deg)', }}>
                    <ChartNetwork16 style={{ marginRight: '0.25rem', marginLeft: '0.5rem' }} />
                    <p style={{ fontSize: 14, opacity: 0.65 }}>Workstation</p>
                </div>
            </div>
        )
    }

    return (
        <div className="dashboard-card stripped-down-tab-content" style={{ padding: '0rem', flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ChartNetwork24 style={{ marginRight: '0.25rem', marginLeft: '0.5rem' }} />
                <h4 style={{ flex: 1 }}>Workstation</h4>

                <Button kind="ghost" tooltipPosition="bottom" iconDescription="Collapse" tooltipAlignment="end" hasIconOnly renderIcon={Minimize16} onClick={() => setCollapsed(true)} />
            </div>
            <View workList={workList} onItemClick={item => onWorkSelected(item.id)} tabSelectRequest={tabSelectRequest} />

            <ReactTooltip />
        </div>
    )
}