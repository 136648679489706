import Button from "../../../components/Button"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { OBJECT_TYPE_CARGO_INV_ITEM } from "../../../constants/ObjectTypes"
import { openPdf } from "../../../markup-template-engine"
import { getAccountRole } from "../../../session/SessionManager"
import { FormSection } from "../../../templates/form/form"
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24, ArrowRight16 } from '@carbon/icons-react'
import { MultiSelectListMode } from "../../components/basic-filter/multi-select"
import { TextBox } from "../../components/basic-filter/text-box"
import { useState } from "react"
import BarcodesEditor from "../../../components/BarcodesEditor"

export const CargoInventory = newListDetail({
    title: "Inventory Item",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: Form, updateForm: Form,

    skipCreateBtn: true,
    canDelete: false,
    clearStateOnSave: true,
    defaultValues: {

    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

function Barcodes({ value, setValue }) {

    return (
        <BarcodesEditor
            highZ
            defaultBarcodes={value || []}
            onBarcodesUpdate={items => setValue(items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })))} />
    )
}

function Form({ endpoint, form, data }) {
    return (
        <div>
            <Button size="md" style={{ borderRadius: 5 }} onClick={() => openPdf('CargoInventoryItemDoc', data.id)} renderIcon={ArrowRight16}>Open PDF</Button>
            <div style={{ marginBottom: '1rem', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem', marginTop: '1rem' }}>
                <div>
                    <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                        Item ID
                    </label>
                    <p>{data.id}</p>
                </div>

                <div>
                    <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                        Code
                    </label>
                    <p>{data.code}</p>
                </div>

                <div>
                    <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                        Description
                    </label>
                    <p>{data.description}</p>
                </div>

                <div>
                    <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                        Width
                    </label>
                    <p>{data.width}</p>
                </div>

                <div>
                    <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                        Height
                    </label>
                    <p>{data.height}</p>
                </div>

                <div>
                    <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                        Length
                    </label>
                    <p>{data.length}</p>
                </div>

                <div>
                    <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                        Weight
                    </label>
                    <p>{data.weight}</p>
                </div>
            </div>

            <form.ComboBoxField fieldKey="conditionId" title="Condition" options={endpoint.conditions} />
            <form.TextAreaField fieldKey="note" title="Note" />

            <FormSection title="Barcodes">
                <form.CustomField fieldKey="barcodes">
                    {Barcodes}
                </form.CustomField>
            </FormSection>

            <div style={{ marginTop: '3rem' }} />
            <FormSection title={"Notes/Docs"}>
                <div style={{ marginTop: '0rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                    <AdvancedNotesView title="" objectId={data.id} objectType={OBJECT_TYPE_CARGO_INV_ITEM} />
                </div>
            </FormSection>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <TextBox state={state} placeholder="CRN No" property="crn" />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Customer" fieldKey="cargoinvitemcustomer" options={endpoint.customers.map(c => c.value)} state={state} property="customer" />
        </div>
        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Condition" fieldKey="cargoinvitemcondition" options={endpoint.conditions.map(c => c.value)} state={state} property="conditionText" />
        </div>
    </>)
}
