import React from 'react'
import Chart from 'react-chartjs-2';
import Util from '../../../util/Util';


export default ({ dashboard }) => {
    return <Chart style={{ maxHeight: 500 }} type="bar" data={{
        labels: dashboard.lastMonths,
        datasets: [
            {
                type: 'line',
                label: 'Leads Opened',
                yAxisID: 'y2',
                data: dashboard.lastLeadsOpened,


                borderColor: 'black',
                borderWidth: 2,
                fill: false,
            },
            {
                type: 'line',
                label: 'Students Joined',
                yAxisID: 'y2',
                data: dashboard.studentsCreated,


                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
            },



            {
                type: 'bar',
                label: 'Company Profit',
                data: dashboard.lastProfits,

                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'white',
                borderWidth: 2,
            },
            {
                type: 'bar',
                label: 'Tutor Payout',
                data: dashboard.lastTutorPayouts,

                backgroundColor: 'rgb(53, 162, 235)',
                borderColor: 'white',
                borderWidth: 2,
            },
        ],
    }} options={{
        scales: {
            y: {
                position: 'left',
                // min: 0,
                ticks: {
                    // beginAtZero: true,
                    callback: function (value, index, values) {
                        return 'SAR ' + Util.formatMoney(value);
                    }
                }
            },
            y2: {
                position: 'right',
                min: 0,
                ticks: {
                    beginAtZero: true,
                }
            },
        },
        elements: {
            line: {
                tension: 0.4
            }
        },
        // plugins: {
        //     tooltip: {

        //     }
        // }
    }} />

}