
import {
    Document32, Notebook16, ArrowLeft16, Report32, Run32, Calendar32, DocumentPdf32, ErrorFilled16, Currency32,
    Currency16, ArrowRight16, ChartBullet32, Catalog32, Money32, List32, TreeView32, RequestQuote16, Notebook32,
    Migrate16, Account32, Policy16
} from '@carbon/icons-react'
import { ButtonSet, Link as DLink } from "carbon-components-react"
import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { withLoadablePageWithParams } from '../../../base/Page';
import Button from '../../../components/Button';
import ProfilePic from '../../../components/ProfilePic';
import { getObjectTypeName, getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_COMMISSION } from '../../../constants/ObjectTypes';
import { CommissionStatusIndicator, COMMISSION_STATUS_NOT_PAID } from '../../../domain/commission';

import useApi from '../../../hooks/useApi';
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';
import Util, { big } from '../../../util/Util';
import NoteView from '../../../views/notes/NoteView';
import TransactionEditor from '../../transaction/TransactionEditor';

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const getAmountStatus = commission => {
    const amount = commission.amount;
    const amountPaid = commission.amountPaid;

    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Fully Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;

        status += " (SAR " + amountLeft.toFixed(2) + " left)";
    }

    return { status, statusColor }
}

const Section = ({ id, children, icon, title, subtitle, extraTopMargin }) => (
    <div id={id} style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem' }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)

const TransactionItem = ({ commission, transaction }) => {
    const [expanded, setExpanded] = useState(false);

    const paymentMethodHeight = 80 + convertRemToPixels(0.5);

    let dividedAmount = 0;
    if (transaction.againstItems && transaction.againstItems.length !== undefined) {
        for (const item of transaction.againstItems) {
            if (item.commissionId == commission.id) {
                dividedAmount = item.dividedAmount;
                break;
            }
        }
    }

    return (
        <div style={{
            transition: '250ms', height: expanded ? 75 + (115 + (paymentMethodHeight * transaction.paymentMethods.length - 1)) : 75, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: 14 }}>Transaction (voucher no: {Util.getVoucherNumber(transaction.id)})</p>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <h2>SAR {dividedAmount.toFixed(2)}</h2>
                        <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}> / SAR {transaction.amount.toFixed(2)} (Total Transaction Amount)</p>
                    </div>



                    {/* <h2>SAR {transaction.amount.toFixed(2)}</h2> */}
                </div>
                <p style={{ fontSize: 12, opacity: 0.65 }}>View details</p>
                <ArrowRight16 style={{ opacity: 0.65, transition: '250ms', marginLeft: '0.25rem', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
            </div>
            <div style={{ height: 115 + (paymentMethodHeight * transaction.paymentMethods.length - 1), paddingLeft: '15px', paddingBottom: 15, paddingRight: 15, overflow: 'auto' }} >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Link target="_blank" to={"/transaction/" + transaction.id} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={Currency16} style={{ width: 195 }}>Open Transaction</Button>
                    </Link>
                </div>

                <TransactionEditor
                    hideTotal
                    readOnly showColorForIncome
                    defaultPaymentMethods={transaction.paymentMethods}
                    currency={"SAR"}
                    //incoming={!isSourceExternal}
                    incoming={false}
                    totalAmount={transaction.amount} />
            </div>
        </div>
    )
}



const Title = ({ commission }) => {
    const history = useHistory();

    const [loading, revoke] = useApi(listener => Api.revokeCommission(commission.id, listener), () => window.location.reload());
    return (<>
        <DLink onClick={() => history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChartBullet32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <h1 style={{ flex: 1 }}>Commission</h1>

            {commission.status == COMMISSION_STATUS_NOT_PAID && !Util.isNumberExist(commission.propertyDealId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Button loading={loading} onClick={() => UIUtil.confirm(revoke)} kind="danger" renderIcon={ErrorFilled16} style={{ width: 195 }}>
                        Revoke
                    </Button>
                    <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : (
                <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
            )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <CommissionStatusIndicator status={commission.status} />
        </div>
    </>)
}

const Amount = ({ commission }) => {
    const amount = commission.amount;
    const amountPaid = commission.amountPaid;
    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;
    }

    return (<>
        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
            <p>
                SAR {amount.toFixed(2)}
            </p>
        </div>

        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
            <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                SAR {amountPaid.toFixed(2)}
            </p>
        </div>
    </>)
}

const PrintBtn = ({ commission }) => {
    let btn;
    if (Util.isNumberExist(commission.purchaseId)) {
        btn = (
            <Link to={"/purchase/" + commission.purchaseId} style={{ textDecoration: 'none' }}>
                <Button kind="secondary" style={{ width: '100%' }} renderIcon={Migrate16}>Purchase Voucher</Button>
            </Link>
        )
    } else {
        //btn = <PurchaseVoucherDownload stockFlow={this.state.stockFlow} />
        btn = null;
    }

    if (btn) {
        return (
            <div style={{ marginBottom: '2rem' }}>
                {btn}
            </div>
        )
    } else {
        return null;
    }
}

const RecordInfo = ({ commission }) => (
    <Section icon={Account32} title="Commission Record Info">
        {Util.isStringExists(commission.refNo) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Commission No'}</label>
                <p style={{}}>{commission.refNo}</p>
            </div>}
        {Util.isNumberExist(commission.commissionDate) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Commission Date'}</label>
                <p style={{}}>{Util.getDateOnly(commission.commissionDate)}</p>
            </div>}
    </Section>
)

const Info = ({ commission }) => (
    <Section icon={Report32} title="Document Info">
        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Voucher Number'}</label>
            <p style={{}}>{Util.getVoucherNumber(commission.id)}</p>
        </div>

        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{getObjectTypeName(commission.payeeType)}</label>
            <Link target="_blank" to={getObjectTypeUrl(commission.payeeType) + '/' + commission.payeeId}><p style={{}}>{commission.payeeName}</p></Link>
        </div>

        <PrintBtn commission={commission} />

        <Amount commission={commission} />

        {Util.isNumberExist(commission.propertyDealId) &&
            <Link target="_blank" to={"/property-deal/" + commission.propertyDealId} style={{ textDecoration: 'none', }}>
                <Button kind="secondary" renderIcon={Policy16} style={{ width: 195 }}>Property Deal</Button>
            </Link>}

        {/* <Link target="_blank" to={"/journal-entry/" + commission.journalEntryId} style={{ textDecoration: 'none', }}>
            <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: 195 }}>Associated Journal Entry</Button>
        </Link> */}
    </Section>

)

const Initiation = ({ commission }) => (
    <Section icon={Run32} title="Initiation">
        <label style={{}} className="bx--label">Initiator</label>
        <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + commission.initiatorId}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, commission.initiatorId)} />
                <p>{commission.initiatorFullName}</p>
            </div>
        </Link>

        <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Date</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar32 style={{ marginRight: '0.5rem' }} />
            <p>{Util.getDate(commission.initiationDate)}</p>
        </div>
    </Section>
)

const Transactions = ({ commission }) => (
    <Section icon={Currency32} title="Transactions" //subtitle={'Total Amount Paid: SAR ' + parseFloat(stockFlow.amountPaid).toFixed(2)}
        subtitle={getAmountStatus(commission).status} subtitleColor={getAmountStatus(commission).statusColor}
    >
        {commission.transactions.map(transaction => <TransactionItem key={transaction.id} commission={commission} transaction={transaction} />)}
    </Section>
)

const AmountBreakdown = ({ commission }) => (
    <Section icon={Money32} title="Amount Breakdown">
        {Util.isNumberExist(commission.subtotal) && <>
            <p style={{ fontSize: 14 }}>Subtotal (+)</p>
            <h2>SAR {commission.subtotal.toFixed(2)}</h2>
        </>}

        {Util.isNumberExist(commission.tax) && <>
            <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Tax (+)</p>
            <h2>SAR {commission.tax.toFixed(2)}</h2>
        </>}

        <p style={{ marginTop: '1rem' }}>Total Amount</p>
        <h1 style={{ color: 'green' }}>SAR {commission.amount.toFixed(2)}</h1>
    </Section>
)


const ItemRecord = ({ item, }) => {
    const total = useMemo(() => big(item.qty).times(big(item.unitAmount)).toFixed(2), [item])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', height: 40 }}>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.description}</p>
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>SAR {item.unitAmount}</p>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.qty}x</p>
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>SAR {total}</p>
            </div>
        </div>
    )
}

const ItemsTable = ({ commission }) => {
    const items = commission.items;
    return (
        <Section icon={List32} title="List">
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Description</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Unit Amount</h6>
                    </div>
                    <div style={{ flex: 1, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Qty</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Total</h6>
                    </div>
                </div>
                {items.map((item) =>
                    <ItemRecord key={item.id} item={item} />)}
            </div>
        </Section>
    )
}


const AccountRecord = ({ item, }) => {
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', height: 40 }}>
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.accountLedgerName}</p>
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>SAR {item.amount}</p>
            </div>
            <div style={{ flex: 4, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.narration}</p>
            </div>
        </div>
    )
}

const AccountsTable = ({ commission }) => {
    const items = commission.accountDivisions;
    return (
        <Section icon={List32} title="Expense Accounts">
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Account</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                    <div style={{ flex: 4, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Narration</h6>
                    </div>
                </div>
                {items.map((item) =>
                    <AccountRecord key={item.id} item={item} />)}
            </div>
        </Section>
    )
}

const View = ({ payload: commission }) => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
            <div style={{ width: '75vw' }}>
                <Title commission={commission} />
                {(Util.isNumberExist(commission.commissionDate) || Util.isStringExists(commission.refNo)) && <RecordInfo commission={commission} />}
                <Info commission={commission} />
                {Util.isNumberExist(commission.journalEntryId) && <Section icon={TreeView32} title="Accounting">
                    <Link target="_blank" to={"/journal-entry/" + commission.journalEntryId} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: undefined }}>Associated Journal Entry</Button>
                    </Link>
                </Section>}

                {/* {commission.items !== null && commission.items.length > 0 &&
                    <ItemsTable commission={commission} />} */}


                {/* amount list */}
                {/* {commission.accountDivisions !== null && commission.accountDivisions.length > 0 &&
                    <AccountsTable commission={commission} />} */}


                {commission.transactions !== null && commission.transactions.length > 0 &&
                    <Transactions commission={commission} />}

                <AmountBreakdown commission={commission} />
                <Initiation commission={commission} />
                {Util.isStringExists(commission.info) && <Section icon={Catalog32} title="Additional Information">
                    <p style={{ whiteSpace: 'pre-line' }}>{commission.info}</p>
                </Section>}

                <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                    <NoteView objectId={commission.id} objectType={OBJECT_TYPE_COMMISSION} />
                </Section>
            </div>
        </div>
    )
}

export default withLoadablePageWithParams(params => listener => Api.getCommission(params.commissionId, listener), View)

