import Logo from '../../../images/Logo.png'
import { Document, Font, View, Text, Page, Image, StyleSheet } from "@react-pdf/renderer";
import Util from "../../../util/Util";

import { ToWords } from 'to-words';
import { INVOICE_ALT_PDF } from '../../../app/Config';
import { AdidasRomarioInvoicePdf } from '../../../pdfs-alt/adidas-romario/AdidasRomarioInvoicePdf';
import PvgTradingInvoicePdf from '../../../pdfs-alt/pvg-trading/PvgTradingInvoicePdf';
import { RomarioShopInvoicePdf } from '../../../pdfs-alt/romario-shop/RomarioShopInvoicePdf';
import { RahelaRomarioInvoicePdf } from '../../../pdfs-alt/rahela-romario/RahelaRomarioInvoicePdf';
import { RunnersRomarioInvoicePdf } from '../../../pdfs-alt/runners-romario/RunnersRomarioInvoicePdf';

const numToWordFuncs = {
    aed: num => new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        },
    }).convert(num).toLowerCase().replace("dollar", "riyal").replace("cent", "halala"),
    gbp: num => new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        },
    }).convert(num).toLowerCase().replace("dollar", "pound").replace("cent", "pence"),
    usd: num => new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        },
    }).convert(num).toLowerCase(),
    eur: num => new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        },
    }).convert(num).toLowerCase().replace("dollar", "euro").replace("cent", "cent"),
}

Font.register({
    family: 'Bebas Neue',
    src: '//fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf'
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingBottom: 60 + 20,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    field: {
        fontSize: 12,
        textTransform: 'uppercase',
        fontFamily: 'Bebas Neue'
    },
    contanctField: {
        fontSize: 10,
        textTransform: 'uppercase',
        textAlignment: 'center',
        fontFamily: 'Bebas Neue'
    },
    contentField: {
        fontSize: 12,
        textTransform: 'uppercase',
        fontFamily: 'Bebas Neue'
    },
    contentField2: {
        fontSize: 12,
        lineHeight: 0,
        textTransform: 'uppercase',
        fontFamily: 'Bebas Neue'
    }
});

const COLS = [
    {
        id: "description",
        name: "Description",
        flex: 3,
        alignment: "flex-start",
        render: row => <Text>{row.description}</Text>
    },
    {
        id: "qty",
        name: "Qty",
        flex: 1,
        alignment: "center",
        render: row => <Text>{row.qty}</Text>
    },
    {
        id: "unitPrice",
        name: "Unit Price",
        flex: 2,
        alignment: "flex-end",
        render: row => <Text>{Util.formatMoney(row.unitPrice)}</Text>
    },
    {
        id: "total",
        name: "Total",
        flex: 2,
        alignment: "flex-end",
        render: row => <Text>{Util.formatMoney(row.total)}</Text>
    },
    {
        id: "vatRate",
        name: "VAT RATE",
        flex: 1,
        alignment: "flex-end",
        render: row => <Text>{row.vatRate}%</Text>
    },
    {
        id: "vatAmount",
        name: "VAT Amount",
        flex: 2,
        alignment: "flex-end",
        render: row => <Text>{Util.formatMoney(row.vatAmount)}</Text>
    },
    {
        id: "totalAmount",
        name: "Total Amount",
        flex: 2,
        alignment: "flex-end",
        render: row => <Text>{Util.formatMoney(row.totalAmount)}</Text>
    }
]


const TableHeader = ({ cols, smallHeader }) => (
    <View style={{ flexDirection: 'row' }}>
        {cols.map((col, i) => (
            // <View key={col.id} style={{ backgroundColor: '#FBFF00', border: 1, borderLeft: i == 0 ? 1 : 0, paddingLeft: 2, paddingRight: 2, height: 22, flex: col.flex, justifyContent: 'center', alignItems: 'center' }}>
            <View key={col.id} style={{ backgroundColor: '#CECECE', border: 1, borderLeft: i == 0 ? 1 : 0, paddingLeft: 2, paddingRight: 2, height: 22, flex: col.flex, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontFamily: smallHeader ? undefined : 'Bebas Neue', fontSize: smallHeader ? 8 : 11, lineHeight: 0, textAlignment: 'center', textTransform: 'uppercase', margin: 0, padding: 0 }}>{col.name}</Text>
            </View>
        ))}
    </View>
)

const TableRow = ({ cols, rows, rowBorder, normalPaddingTop }) => (
    <View style={{ backgroundColor: '#F2F2F2', borderBottom: 1 }}>
        {rows.map(row => (
            <View style={{ flexDirection: 'row', border: 1, borderTop: 0, borderBottom: rowBorder ? '1px solid black' : 0 }}>
                {cols.map((col, i) => (
                    <View key={col.id} style={{ flex: col.flex, minHeight: 22 + 10, borderLeft: i == 0 ? 0 : 1, paddingLeft: 2, paddingRight: 2, paddingTop: normalPaddingTop ? 2 : 10, justifyContent: 'center', alignItems: col.alignment }}>
                        {col.renderNonText ? (
                            col.renderNonText(row)
                        ) : (
                            <Text style={{ fontSize: 11, }}>
                                {Object.keys(row).length > 0 ? (
                                    col.render(row)
                                ) : ''}
                            </Text>
                        )}
                    </View>
                ))}
            </View>
        ))}
    </View>
)

const Table = ({ cols, rows, normalPaddingTop, rowBorder, smallHeader }) => (
    <View>
        <TableHeader cols={cols} smallHeader={smallHeader} />
        <TableRow cols={cols} rows={rows} normalPaddingTop={normalPaddingTop} rowBorder={rowBorder} />
    </View>
)

const FieldRow = ({ children }) => (
    <View style={{ flexDirection: 'row', marginTop: 15 }}>
        <View style={{ flex: 1 }}>
            {children(0)}
        </View>
        <View style={{ flex: 1 }}>
            {children(1)}
        </View>
    </View>
)

export const PDFTable = Table;

export default ({ invoice, showLogo = false, title = "TAX INVOICE", ...props }) => {
    if (INVOICE_ALT_PDF === "adidas-romario") {
        return <AdidasRomarioInvoicePdf {...{ invoice, ...props }} />
    } else if (INVOICE_ALT_PDF === "romario-shop") {
        return <RomarioShopInvoicePdf {...{ invoice, ...props }} />
    } else if (INVOICE_ALT_PDF === "pvg-trading") {
        return <PvgTradingInvoicePdf {...{ invoice, ...props }} />
    } else if (INVOICE_ALT_PDF === "rahela-romario") {
        return <RahelaRomarioInvoicePdf {...{ invoice, ...props }} />
    } else if (INVOICE_ALT_PDF === "runners-romario") {
        return <RunnersRomarioInvoicePdf {...{ invoice, ...props }} />
    }

    const currency = invoice.currency ?? "SAR";
    const numToWord = numToWordFuncs[currency.toLowerCase()]

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ flexDirection: 'row', justifyContent: "flex-end", paddingTop: 30 }}>
                    {showLogo && <>
                        <Image style={{ width: 85, objectFit: 'contain', objectPosition: 'center' }} src={Logo} />
                        <View style={{ flex: 1 }} />
                    </>}
                    <View>
                        <Text style={{ fontStyle: 'bold', fontSize: 23 }}>{invoice.systemCompany.name}</Text>
                        <View style={{ width: '100%', height: 2, backgroundColor: 'black', marginBottom: 10, marginTop: -5 }} />
                        <View style={{ flexDirection: 'row', justifyContent: "flex-end", maxWidth: '38%', paddingLeft: 35 }}>
                            <Text style={{ fontFamily: 'Bebas Neue', lineHeight: 0, textTransform: 'uppercase', fontSize: 12, }}>
                                {invoice.systemCompany.address}
                            </Text>
                        </View>
                    </View>
                </View>
                <Text style={{ fontFamily: 'Bebas Neue', alignSelf: 'center', marginTop: 15, lineHeight: 0, fontSize: 45 }}>{title}</Text>
                <Text style={{ fontFamily: 'Bebas Neue', alignSelf: 'center', marginBottom: -10, fontSize: 14 }}>TRN: {invoice.systemCompany.trn}</Text>
                <FieldRow>
                    {col => (col === 0 ? (<>

                    </>) : (<>
                        <Text style={styles.field}>Invoice No: {invoice.invoiceNo}</Text>
                    </>))}
                </FieldRow>

                <FieldRow>
                    {col => (col === 0 ? (<>
                        <Text style={styles.field}>Bill to: {invoice.customerName}</Text>
                    </>) : (<>
                        <Text style={styles.field}>Date: {Util.formatDate(invoice.date, "DD.MM.YYYY")}</Text>
                    </>))}
                </FieldRow>

                <FieldRow>
                    {col => (col === 0 ? (<>
                        <Text style={styles.field}>{invoice.customerFullAddress}</Text>
                        <Text style={styles.field}>TRN {invoice.customerTrn}</Text>
                    </>) : (<>
                    </>))}
                </FieldRow>
                <View style={{ marginTop: 10 }} />
                <Table cols={COLS} rows={invoice.items.concat([{}])} />
                <View style={{ flexDirection: 'row', backgroundColor: '#F2F2F2', border: 1, borderTop: 0 }}>
                    <View style={{ flex: 6.3, height: 22 + 10, paddingLeft: 2, paddingRight: 2, paddingTop: 10, justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Text style={{ fontSize: 11, lineHeight: 0, fontFamily: 'Bebas Neue', textTransform: "uppercase" }}>
                            TOTAL IN {currency}
                        </Text>
                    </View>

                    <View style={{ flex: 2, height: 22 + 10, borderLeft: 1, paddingLeft: 2, paddingRight: 2, paddingTop: 10, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Text style={{ fontSize: 11, lineHeight: 0, fontFamily: 'Bebas Neue' }}>
                            {Util.formatMoney(invoice.totals.subTotal)}
                        </Text>
                    </View>

                    <View style={{ flex: 1, height: 22 + 10, borderLeft: 1, paddingLeft: 2, paddingRight: 2, paddingTop: 10, justifyContent: 'flex-end', alignItems: 'flex-end' }}>

                    </View>
                    <View style={{ flex: 2, height: 22 + 10, borderLeft: 1, paddingLeft: 2, paddingRight: 2, paddingTop: 10, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Text style={{ fontSize: 11, lineHeight: 0, fontFamily: 'Bebas Neue' }}>
                            {Util.formatMoney(invoice.totals.vatTotal)}
                        </Text>
                    </View>
                    <View style={{ flex: 2, height: 22 + 10, borderLeft: 1, paddingLeft: 2, paddingRight: 2, paddingTop: 10, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Text style={{ fontSize: 11, lineHeight: 0, fontFamily: 'Bebas Neue' }}>
                            {Util.formatMoney(invoice.totals.grandTotal)}
                        </Text>
                    </View>
                </View>

                <View style={{ marginTop: 20 }}>
                    {numToWord && <Text style={styles.contentField}>Amount in words: {numToWord(invoice.totals.grandTotal)}</Text>}
                </View>

                <View style={{ marginTop: 10 }}>
                    <Text style={styles.contentField2}>PAYEE TO:</Text>
                    <Text style={styles.contentField2}>
                        Company Name: {invoice.systemCompany.name}
                    </Text>
                    <Text style={styles.contentField2}>
                        Bank Account No: {invoice.systemCompany.bankAccountNo}
                    </Text>
                    <Text style={styles.contentField2}>
                        IBAN: {invoice.systemCompany.iban}
                    </Text>
                    <Text style={styles.contentField2}>
                        Bank: {invoice.systemCompany.bankName}
                    </Text>
                    <Text style={styles.contentField2}>
                        Branch: {invoice.systemCompany.branch}
                    </Text>
                    <Text style={styles.contentField}>
                        Swift Code: {invoice.systemCompany.swiftCode}
                    </Text>
                </View>

                <FieldRow>
                    {col => (col === 0 ? (<>

                    </>) : (<>
                        <View style={{ width: '75%' }}>
                            <View style={{ width: '100%', height: 2, backgroundColor: 'black', marginBottom: 0 }} />
                            <View style={{ alignSelf: 'center' }}>
                                <Text style={styles.contentField}>STAMP / SIGNATURE</Text>
                            </View>
                        </View>
                    </>))}
                </FieldRow>
                <Text style={styles.contentField}>
                    Thank you for your business...
                </Text>

                <View style={{ position: 'absolute', bottom: 30, left: 60, width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} fixed>
                    <Text style={styles.contanctField}>TEL. {invoice.systemCompany.tel}</Text>
                    <View style={{ width: 30 }} />
                    <Text style={styles.contanctField}>FAX. {invoice.systemCompany.fax}</Text>
                    <View style={{ width: 30 }} />
                    <Text style={styles.contanctField}>EMAIL. {invoice.systemCompany.email}</Text>
                </View>
            </Page>
        </Document>
    );

}