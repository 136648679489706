import React from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';


import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16,
    ReportData32
} from '@carbon/icons-react'
import { ComboBox, DatePicker, DatePickerInput, RadioTile, TileGroup } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar
} from 'carbon-components-react';

import Util from '../../util/Util';
import { getObjectTypeName, getObjectTypeUrl, OBJECT_TYPE_ACCOUNT } from '../../constants/ObjectTypes';

import { Link } from 'react-router-dom';
import { getAccountRole } from '../../session/SessionManager';
import { ACCOUNT_TYPE_DELIVERY_MANAGER } from '../../constants/Constants';

const HEADERS = [
    {
        header: "Name",
        key: "name"
    },
    {
        header: "Transaction Count",
        key: "totalTransactionCount"
    },
    {
        header: "Amount to Return",
        key: "totalAmountToReturn"
    },
    {
        header: "Last CRV",
        key: "lastCrvDate"
    },
]

class CRVReportPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            report: undefined
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getCrvReport(listener), report => ({ report }))
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.report.staffList) {
            if (row.id == id) {
                return row;
            }
        }
    }

    getRow(id) {
        for (const row of this.state.report.staffList) {
            if (row.id == id) {
                return row;
            }
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);


        switch (header.key) {

            case "name":
                if (getAccountRole() == ACCOUNT_TYPE_DELIVERY_MANAGER) {
                    return cell.value;
                } else {
                    return <Link onClick={(e) => e.stopPropagation()} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + row.id}>{cell.value}</Link>
                }

            case "totalAmountToReturn":
                return <strong> SAR {parseFloat(cell.value).toFixed(2)} </strong>

            case "lastCrvDate":
                return <> <Calendar16 /> {Util.getDate(cell.value)} </>

            default:
                return cell.value;
        }
    }

    getLayout() {
        return (
            <div className="main-content">
                <div style={{ width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2, display: 'flex', alignItems: 'flex-start' }}>
                    <ReportData32 style={{ width: 64, height: 64 }} />
                    <div style={{ marginLeft: '0.5rem', flex: 1 }}>
                        <h4>Amount to be returned</h4>
                        <h3 style={{ color: 'red' }}>SAR {this.state.report.totalAmountToReturn.toFixed(2)}</h3>
                        <p>{this.state.report.totalTransactionCount} transaction{this.state.report.totalTransactionCount != 1 && 's'}</p>
                        <p>{this.state.report.staffList.length} staff</p>
                    </div>
                    <p style={{ fontSize: 14, opacity: 0.65 }}>Click on the rows to create a CRV</p>
                </div>
                {/* <TransactionListView report={this.state.report} hideToolbar /> */}

                <DataTable rows={this.state.report.staffList} headers={HEADERS} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => {
                                            // const item = this.getRow(row.id);
                                            this.props.history.push("/crv-creator", { staffId: row.id });
                                        })} key={row.id} {...getRowProps({ row })} style={{ cursor: 'pointer' }}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        )
    }

}

export default CRVReportPage;