import { Tag } from "carbon-components-react"
import { withLoadablePagePropsAndProps } from "../../base/Page"
import ProfilePic from "../../components/ProfilePic"
import Api from "../../session/Api"
import Util from "../../util/Util"

const Item = ({ item, onClick }) => {
    return (
        <div onClick={onClick} style={{ borderRadius: 7, background: '#1c1c1c', padding: '1rem', color: 'white', cursor: 'pointer' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', }}>
                <ProfilePic size={45} notProfile src={Api.getThumbnail(item.itemType, item.itemId)} />
                <div>
                    <p style={{ fontSize: 14 }}>{item.itemName}</p>
                    {item.discountedWithTax?.toFixed?.(2) !== item.amountWithTax?.toFixed?.(2) ? (<>
                        <p style={{ fontSize: 12, opacity: 0.65, fontStyle: 'italic' }}>Discounted</p>
                        <h5 style={{ color: '#28a745' }}>SAR {item.discountedWithTax?.toFixed?.(2)}</h5>
                        <p style={{ fontSize: 12, }}>Original: SAR {item.amountWithTax?.toFixed?.(2)}</p>
                    </>) : (<>
                        <h5>SAR {item.amountWithTax?.toFixed?.(2)}</h5>
                    </>)}

                    {/* <Tag>{item.quantityValue} {item.uom}</Tag> */}
                    <Tag>{item.quantityValue} {'Piece(s)'}</Tag>
                </div>
            </div>
        </div>
    )
}


const View = ({ payload: sale, onItemClick }) => {
    return (
        <div style={{ background: "#f4f4f4", borderRadius: 5, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', padding: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem' }}>
                <h3>Sale #{Util.getVoucherNumber(sale.id)}</h3>
                <Tag>VAT included in prices</Tag>
                <div style={{ flex: 1 }} />
                <p style={{ fontSize: 14, opacity: 0.65 }}>Click on any item to return</p>
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', gap: '1rem', marginBlock: '1rem' }}>
                {sale.items.map(item => (
                    <Item key={item.id} item={item} onClick={() => onItemClick(item)} />
                ))}
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <div>
                    <p>Subtotal</p>
                    <h4>SAR {sale.salesAmountSubtotal.toFixed(2)}</h4>
                </div>
                <div>
                    <p>Discount</p>
                    <h4>SAR {sale.salesAmountDiscount.toFixed(2)}</h4>
                </div>
                <div>
                    <p>Tax</p>
                    <h4>SAR {sale.salesAmountTax.toFixed(2)}</h4>
                </div>
                <div style={{ color: 'green' }}>
                    <p>Total</p>
                    <h2>SAR {sale.amount.toFixed(2)}</h2>
                </div>
            </div>
        </div>
    )
}

export const SalePreview = withLoadablePagePropsAndProps(props => listener => Api.getStockFlowItem(props.stockFlowId, listener), View)