
import React from 'react'
import Page from '../../base/Page';
import Button from '../../components/Button';
import Api from '../../session/Api';


import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16,
    Product24,
    Van32,
    DeliveryParcel32,
    Building32
} from '@carbon/icons-react'
import { ComboBox, DatePicker, DatePickerInput, Link, RadioTile, TileGroup } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar
} from 'carbon-components-react';
import TransactionListView from '../transaction/TransactionListView';
import Util from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import StockFlowField from '../stock-flow/StockFlowField';
import { DESTINATION_TYPE_STORE, DESTINATION_TYPE_WAREHOUSE, DESTINATION_TYPE_MOBILE_UNIT, DESTINATION_TYPE_VENUE } from '../../constants/Constants';
import ProfilePic from '../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT } from '../../constants/ObjectTypes';
import { Link as LR } from 'react-router-dom'
import { hasCapabilitySupport } from '../../app/Capabilities';
import PaginatedDataTable from '../../components/data-table/PaginatedDataTable';

const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)


const HEADERS = [
    {
        header: "Product",
        key: "name"
    },
    {
        header: "Min Stock",
        key: "minStockCount"
    },
    {
        header: "Current Stock",
        key: "rawStockCount"
    },
    {
        header: "Percentage Under Min",
        key: "stockUnderMinPercentage",
    }
]

class LowStockProductReport extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,

            generatingReport: false,
            generatedReport: true,

            report: undefined,

            locationTypeValue: DESTINATION_TYPE_STORE,
            locationIdValue: 0,
            startDateValue: 0,
            endDateValue: 0,

            suppliers: [],
            customers: [],
            warehouses: [],
            stores: [],
            venues: [],
            mobileUnits: [],

            locationPickerKey: Util.newTempId()
        }
    }

    clearReport() {
        this.setState({
            generatingReport: false,
            generatedReport: false,

            locationTypeValue: DESTINATION_TYPE_STORE,
            locationIdValue: 0,
            startDateValue: 0,
            endDateValue: 0,
        })
    }

    canGenerateReport() {
        //return Util.isNumberExist(this.state.startDateValue) && Util.isNumberExist(this.state.endDateValue);

        if (this.getLocationList() !== undefined && !Util.isNumberExist(this.state.locationIdValue)) {
            return false;
        }

        return true;
    }

    generateReportBtn() {
        this.setState({ generatingReport: true })
        Api.getLowStockProductReport(this.state.locationIdValue, response => {
            if (response.status === true) {
                // console.log(response.payload)
                this.setState({ generatingReport: false, report: response.payload, generatedReport: true, })
                UIUtil.showSuccess();
            } else {
                this.setState({ generatingReport: false, generatedReport: false })
                UIUtil.showError(response.message);
            }
        })
    }

    onPageStart() {
        // this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => ({
        //     suppliers: payload.suppliers,
        //     customers: payload.customers,
        //     warehouses: payload.warehouses,
        //     stores: payload.stores,
        //     mobileUnits: payload.mobileUnits,
        // }))
    }

    getLocationList() {
        switch (this.state.locationTypeValue) {
            case DESTINATION_TYPE_WAREHOUSE:
                return this.state.warehouses;
            case DESTINATION_TYPE_STORE:
                return this.state.stores;
            case DESTINATION_TYPE_MOBILE_UNIT:
                return this.state.mobileUnits;
        }

        return undefined;
    }

    getLocationTypeName() {
        switch (this.state.locationTypeValue) {
            case DESTINATION_TYPE_WAREHOUSE:
                return "Warehouse";
            case DESTINATION_TYPE_STORE:
                return "Store";
            case DESTINATION_TYPE_VENUE:
                return "Venue";
            case DESTINATION_TYPE_MOBILE_UNIT:
                return "Mobile Unit";
        }

        return "All";
    }

    renderInput() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>Low Stock Products</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>

                    <Section icon={Number_132} title="Location">
                        <TileGroup className="horizontal-tile-radio" valueSelected={this.state.locationTypeValue} onChange={value => this.setState({ locationTypeValue: value, locationIdValue: 0, locationPickerKey: Util.newTempId() })}>
                            <RadioTile value={DESTINATION_TYPE_STORE}>
                                <RadioItem icon={Store32} title="Store" desc="Stock in store" />
                            </RadioTile>
                            <RadioTile value={DESTINATION_TYPE_WAREHOUSE}>
                                <RadioItem icon={DataVis_232} title="Warehouse" desc="Stock in warehouse" />
                            </RadioTile>
                            {hasCapabilitySupport("mobileUnit") &&
                                <RadioTile value={DESTINATION_TYPE_MOBILE_UNIT}>
                                    <RadioItem icon={Van32} title="Mobile Unit" desc="Stock in mobile unit" />
                                </RadioTile>}
                            {hasCapabilitySupport("thirdPartyPos") &&
                                <RadioTile value={DESTINATION_TYPE_VENUE}>
                                    <RadioItem icon={Building32} title="Venue" desc="Stock in venue" />
                                </RadioTile>}
                            <RadioTile value={-1}>
                                <RadioItem icon={Cube32} title="All" desc="Total stock" />
                            </RadioTile>
                        </TileGroup>

                        {this.getLocationList() !== undefined && <>
                            <div style={{ height: '1rem' }} />
                            <ComboBox
                                key={this.state.locationPickerKey}
                                titleText={this.getLocationTypeName()}
                                items={this.getLocationList()}
                                itemToString={item => item !== null ? item.value : ""}
                                selectedItem={this.getLocationList().filter(item => item.id == this.state.locationIdValue)[0]}
                                onChange={e => {
                                    if (e.selectedItem === null) {
                                        this.setState({ locationIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, locationPickerKey: Util.newTempId(), })
                                    } else {
                                        this.setState({ locationIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                    }
                                }}
                            />
                        </>}
                    </Section>

                    {/* <Section icon={Number_232} title="Select Range" extraTopMargin>
                        <DatePicker datePickerType={"range"}
                        // value={[this.state.startDateValue, this.state.endDateValue]}
                        onChange={e => {
                            if (e.length > 1) {
                                this.setState({
                                    startDateValue: e[0].getTime(),
                                    endDateValue: e[1].getTime()
                                })
                            } else if (e.length == 1) {
                                this.setState({
                                    startDateValue: e[0].getTime(),
                                })
                            } else {
                                this.setState({
                                    startDateValue: 0,
                                    endDateValue: 0
                                })
                            }
                        }}
                        >
                            <DatePickerInput
                                style={{flex: 1}}
                                placeholder="mm/dd/yyyy"
                                labelText={"Start date"}
                            />
                            <DatePickerInput
                                placeholder="mm/dd/yyyy"
                                labelText="End date"
                            />
                        </DatePicker>
                    </Section> */}

                    <Section icon={Number_232} title="Report" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.generateReportBtn.bind(this)} disabled={!this.canGenerateReport()} loading={this.state.generatingReport} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    //renderCell(cell) {
    renderCell(key, value, id, row) {
        //const header = this.getCellHeader(cell);
        //const row = this.getCellRow(cell);
        const cell = { value, id }

        // switch (header.key) {
        switch (key) {
            case "name":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ProfilePic size={34} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, row.id)} />
                        <LR to={"/products/" + row.id}>
                            <p style={{ marginLeft: '0.5rem' }}>{cell.value}</p>
                        </LR>
                    </div>
                )

            case "totalSoldAmount":
                return <span style={{ color: 'green' }}> SAR {parseFloat(cell.value).toFixed(2)} </span>

            case "minStockCount": case "rawStockCount":
                return <> <DeliveryParcel16 /> {cell.value} {row.uom} </>

            case "initiationDate":
                return <> <Calendar16 /> {Util.getDate(cell.value)} </>

            case "stockUnderMinPercentage":
                return <span style={{ color: 'red' }}>{cell.value.toFixed !== undefined ? cell.value.toFixed(0) : cell.value}%</span>;

            default:
                return cell.value;
        }
    }

    renderReport() {
        return (
            <div className="main-content">
                {/* <div onClick={this.clearReport.bind(this)} style={{display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem'}}>
                    <Link><ArrowLeft16 style={{marginRight: '0.25rem'}} /> Generate another report</Link>    
                </div> */}
                <div style={{ width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2, display: 'flex', alignItems: 'center' }}>
                    <DeliveryParcel32 style={{ width: 64, height: 64 }} />
                    <h3 style={{ marginLeft: '0.5rem' }}>Low Stock Products</h3>

                    {/* {this.state.report !== undefined && this.state.report.values !== undefined &&
                        <h4 style={{color: 'green'}}>Total products with low stock: <Product24 /> {this.state.report.values.totalProducts}</h4>}
                    
                    <div style={{display: 'flex', alignItems: 'center', opacity: 0.65, marginTop: '0.25rem'}}>
                        <p>{this.getLocationTypeName()}</p>
                    </div> */}
                    {/* <h2>Low stock report</h2> */}
                </div>
                {/* <TransactionListView report={this.state.report} hideToolbar /> */}

                <PaginatedDataTable
                    hideToolbar
                    loader={(page, sortRequest, listener) => Api.getLowStockProductReport(-1, page, listener)}
                    loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.values.paginationHasMore })}
                    columns={HEADERS.map(header => ({
                        id: header.key,
                        name: header.header,
                        render: item => this.renderCell(header.key, item[header.key], item.id, item)
                    }))}
                    onClick={() => { }}
                />

                {/* <DataTable rows={this.state.report.items} headers={HEADERS} isSortable> 
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow //onClick={(() => this.props.history.push("/products/" + row.id))} 
                                        key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable> */}
            </div>
        )
    }

    getLayout() {
        return this.state.generatedReport ? this.renderReport() : this.renderInput()
    }

    isPageLoadable() {
        return false;
    }
}

export default LowStockProductReport;