import React from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';

import Button from '../../components/Button'

import { ButtonSet, Link as DLink, Tile } from 'carbon-components-react';

import {
    ArrowLeft16,
    Wallet32,
    Notebook16,
    Report32,
    User32,
    Catalog32,
    Notebook32,
    Calendar32,
    Run32,
    TreeView32,
    RequestQuote16,
    Currency32,
    Currency16
} from '@carbon/icons-react'
import { Link } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CRV } from '../../constants/ObjectTypes';
import Util from '../../util/Util';
import NoteView from '../../views/notes/NoteView';
import ProfilePic from '../../components/ProfilePic';
import { CrvDownload } from '../../pdfs/crv/CrvPdf';
import { getAccountRole } from '../../session/SessionManager';
import { ACCOUNT_TYPE_DELIVERY_MANAGER } from '../../constants/Constants';

const Section = ({ id, children, icon, title, subtitle, extraTopMargin }) => (
    <div id={id} style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem' }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        {Util.isStringExists(desc) && <p style={{ marginTop: 0, fontSize: 14, opacity: 1 }}>
            {desc}
        </p>}
    </div>
)

const AgainstItem = ({ againstItem, transaction }) => {
    const showLink = getAccountRole() != ACCOUNT_TYPE_DELIVERY_MANAGER;
    return (
        <div style={{
            transition: '250ms', height: 75, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{
                height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', //cursor: 'pointer' 
            }} //onClick={() => setExpanded(!expanded)}
            >
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: 14 }}>Transaction (voucher no: {Util.getVoucherNumber(againstItem.transactionId)})</p>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <h2>SAR {againstItem.dividedAmount.toFixed(2)}</h2>
                        {againstItem.transaction.amount !== null &&
                            <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}> / SAR {againstItem.transaction.amount.toFixed(2)} (Total Transaction Amount)</p>}
                    </div>
                </div>
                <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem' }}>{Util.getDate(againstItem.transaction.initiationDate)}</p>
                {showLink && <Link to={"/transaction/" + againstItem.transactionId} style={{ textDecoration: 'none', }}>
                    <Button kind="secondary" renderIcon={Currency16} style={{ width: 195 }}>Open Transaction</Button>
                </Link>}
            </div>
        </div>
    )
}


class CRVItemPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            crv: undefined
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getCrv(this.getPathParams().itemId, listener), payload => ({
            crv: payload
        }))
    }

    renderPrintBtn() {
        return (
            <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <CrvDownload crv={this.state.crv} />
            </div>
        )
    }

    renderStaffReturningAmount() {
        return (
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to={getObjectTypeUrl(this.state.crv.cashBorrowerType) + '/' + this.state.crv.cashBorrowerId}>
                <RadioItem icon={User32} title="Staff User" desc={this.state.crv.cashBorrowerName} />
            </Link>
        )
    }

    getLayout() {
        const crv = this.state.crv;
        const showLink = getAccountRole() != ACCOUNT_TYPE_DELIVERY_MANAGER;
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <DLink onClick={() => this.props.history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Wallet32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
                        <h1 style={{ flex: 1 }}>Cash Receipt Voucher</h1>

                        <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
                    </div>

                    <Section icon={Report32} title="Transaction Info">
                        <label style={{ marginBottom: '0.25rem' }} className="bx--label">Date</label>
                        <p style={{ marginLeft: '0.25rem' }}>{Util.getDateOnly(crv.initiationDate)}</p>

                        <label style={{ marginBottom: '0.25rem', marginTop: '1rem' }} className="bx--label">Total Amount Returned</label>
                        <p style={{ marginLeft: '0.25rem' }}>SAR {crv.amountReturned.toFixed(2)}</p>

                        <div style={{ marginTop: '1rem', }}>
                            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Voucher Number'}</label>
                            <p style={{}}>{Util.getVoucherNumber(this.state.crv.id)}</p>
                        </div>

                        <label style={{ marginTop: '1rem', marginBottom: '0.25rem' }} className="bx--label">CRV ID</label>
                        <p style={{}}>{crv.crvId}</p>

                        {this.renderPrintBtn()}

                        <label style={{ marginTop: '1rem', marginBottom: '0.5rem' }} className="bx--label">Staff Returning Amount</label>
                        {this.renderStaffReturningAmount()}
                    </Section>


                    {showLink && crv.againstItems != null && crv.againstItems.length > 0 &&
                        <Section icon={Currency32} title="Against">
                            <p>Total Amount</p>
                            <h1 style={{ marginBottom: "1.5rem" }}>SAR {crv.amountReturned.toFixed(2)}</h1>
                            {crv.againstItems.map(item => (
                                <AgainstItem key={item.id} againstItem={item} />
                            ))}
                        </Section>}


                    {showLink && Util.isNumberExist(crv.journalEntryId) &&
                        <Section icon={TreeView32} title="Accounting">
                            <Link to={"/journal-entry/" + crv.journalEntryId} style={{ textDecoration: 'none', }}>
                                <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: 195 }}>Associated Journal Entry</Button>
                            </Link>
                        </Section>}

                    <Section icon={Run32} title="Initiation">
                        <label style={{}} className="bx--label">Initiator</label>
                        <Link style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + crv.initiatorId}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, crv.initiatorId)} />
                                <p>{crv.initiatorFullName}</p>
                            </div>
                        </Link>

                        <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Date</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Calendar32 style={{ marginRight: '0.5rem' }} />
                            <p>{Util.getDate(crv.dateCreated)}</p>
                        </div>
                    </Section>

                    {Util.isStringExists(crv.info) &&
                        <Section icon={Catalog32} title="Additional Information">
                            <p style={{ whiteSpace: 'pre-line' }}>{crv.info}</p>
                        </Section>}

                    <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                        <NoteView objectId={crv.id} objectType={OBJECT_TYPE_CRV} />
                    </Section>
                </div>
            </div>
        )
    }

}

export default CRVItemPage;