
import { Close16, ArrowRight16 } from '@carbon/icons-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Util, { big } from '../../../../util/Util'
import { Link } from 'react-router-dom'
import { SHIPMENT_DIR_TYPE } from '../../shipment'


const ListValueEntry = ({ label, value, lg }) => (<>
    <div style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: lg ? 16 : 14, opacity: 0.65, flex: 2, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: lg ? 16 : 14, flex: 1, outline: 'none', textAlign: 'end' }}>{value}</p>
    </div>
</>)

const List = ({ label, children }) => (<>
    <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>{label}</h4>
    <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '1rem' }}>
        <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            {children}
        </div>
    </div>
</>)

const getItemQty = item => item.qty ? big(item.qty) : big(1)
const formatItemAmount = item => Util.formatMoney((big(item.amount).times(getItemQty(item))).toNumber())

export function MasterProfit({ master, }) {
    //REPEATED IN MASTERS AND SHIPMENT PROFIT VIEW
    const getExpenseRecords = useCallback(shipment => {
        const expenses = []
        const expense = (label, amount, href) => {
            if (Util.isNumberExist(amount)) {
                expenses.push({
                    label, amount, href
                })
            }
        }

        expense("Bill of Entry Bill", shipment.billOfEntryAmount)
        if (shipment.dirType === SHIPMENT_DIR_TYPE.IMPORT) {
            expense("Transportation Bill", shipment.transportationCharge);
        }
        if (shipment.billsAgainst.length) {
            shipment.billsAgainst.forEach(elem => {
                //expense(`Bill #${Util.getVoucherNumber(elem.billId)}`, elem.against.againstAmount, '/bill/' + elem.billId)
                elem.accounts.forEach(account => {
                    expense(`${account.label} - Bill #${Util.getVoucherNumber(elem.billId)}`, account.amount, '/bill/' + elem.billId)
                })
            })
        }
        return expenses;
    }, [master])

    const amounts = useMemo(() => {
        const sum = (list, property = 'amount') => (
            list.filter(item => Util.isNumberExist(item[property]))
                .map(item => big(item[property]))
                .reduce((t, c) => t.add(c), big(0))
        )
        const sumFn = (list, propertyFn) => (
            list.filter(item => Util.isNumberExist(propertyFn(item)))
                .map(item => big(propertyFn(item)))
                .reduce((t, c) => t.add(c), big(0))
        )
        const amountSum = (list) => (
            list.filter(item => Util.isNumberExist(item.amount))
                .map(item => big(item.amount).times(getItemQty(item)))
                .reduce((t, c) => t.add(c), big(0))
        )
        const number = (value) => value.round(2).toNumber();

        const expenseRecords = master.shipments.flatMap(getExpenseRecords);
        const charges = master.shipments.flatMap(shipment => [...shipment.systemDefinedCharges, ...shipment.userDefinedCharges])
        const otherInvoices = master.shipments.flatMap(shipment => shipment.otherInvoices);

        const revenue = amountSum(charges).add(sumFn(otherInvoices, invoice => invoice.totals.subTotal));
        const expense = sum(expenseRecords)

        const profitLoss = revenue.minus(expense);

        return {
            revenue: number(revenue),
            expense: number(expense),
            profitLoss: number(profitLoss)
        }
    }, [master])

    return (
        <div style={{ width: '100%', }}>
            <List label="Overview">
                <ListValueEntry label="Revenue (+)" value={"SAR " + Util.formatMoney(amounts.revenue)} />
                <ListValueEntry label="Expense (-)" value={"SAR " + Util.formatMoney(amounts.expense)} />
                <ListValueEntry lg label={<strong>{amounts.profitLoss < 0 ? 'Loss' : 'Profit'}</strong>} value={<strong style={{ color: amounts.profitLoss < 0 ? "red" : "green" }}>SAR {Util.formatAmount(amounts.profitLoss)}</strong>} />
            </List>

            <List label="Breakdown">
                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline', color: 'green' }}>Revenue</span>} />
                {master.shipments.map(shipment => <>
                    <ListValueEntry label={<span style={{ fontWeight: 'bold' }}>Shipment #{shipment.shipmentNo}</span>} />
                    {shipment.systemDefinedCharges.length === 0 && shipment.userDefinedCharges.length === 0 && shipment.otherInvoices.length === 0 &&
                        <ListValueEntry label={<span style={{ fontSize: 12, opacity: 0.65 }}>No revenue</span>} />}

                    {shipment.systemDefinedCharges.map(item => <ListValueEntry key={item.id} label={item.description} value={"SAR " + Util.formatMoney(item.amount)} />)}
                    {shipment.userDefinedCharges.map(item => <ListValueEntry key={item.id} label={item.description} value={"SAR " + formatItemAmount(item)} />)}
                    {shipment.otherInvoices.map(item => <ListValueEntry key={item.id} label={<Link to={`/service-sale/${item.fromEntityId}`}>{`Invoice #${item.invoiceNo}`}</Link>} value={"SAR " + Util.formatMoney(item.totals.subTotal)} />)}
                    <div style={{ width: '100%', height: 2, background: '#1c1c1c60', }} />
                </>)}
                <ListValueEntry label={<strong>Total</strong>} value={<strong>SAR {Util.formatMoney(amounts.revenue)}</strong>} />
                <ListValueEntry />
                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline', color: 'red' }}>Expense</span>} />
                {master.shipments.map(shipment => {
                    const expenseRecords = getExpenseRecords(shipment)
                    return (<>
                        <ListValueEntry label={<span style={{ fontWeight: 'bold' }}>Shipment #{shipment.shipmentNo}</span>} />
                        {expenseRecords.length === 0 &&
                            <ListValueEntry label={<span style={{ fontSize: 12, opacity: 0.65 }}>No expense</span>} />}
                        {expenseRecords.map(item => <ListValueEntry key={item.label + 'bill'} label={item.href ? <Link to={item.href}>{item.label}</Link> : item.label} value={"SAR " + Util.formatMoney(item.amount)} />)}
                        <div style={{ width: '100%', height: 2, background: '#1c1c1c60', }} />
                    </>)
                })}
                <ListValueEntry label={<strong>Total</strong>} value={<strong>SAR {Util.formatMoney(amounts.expense)}</strong>} />
                <ListValueEntry />

                <ListValueEntry lg label={<strong>{amounts.profitLoss < 0 ? 'Loss' : 'Profit'}</strong>} value={<strong style={{ color: amounts.profitLoss < 0 ? "red" : "green" }}>SAR {Util.formatAmount(amounts.profitLoss)}</strong>} />
            </List>

            <div style={{ height: '6rem' }} />
        </div>
    )
}