

// 12 => 71
// 14 => 60
// 16 => 53
// 18 => 47
// 20 => 42
// 22 => 38
// 40 => 21

import Logo from '../../../images/SquareLogo.png';
import ReceiptLogo from '../../../images/ReceiptLogo.png'
import AbsharLogo from '../../../images/other/abshar/sweet-thermal-logo.png'

import QRCode from "react-qr-code"
import Util, { big } from "../../../util/Util"
import { paymentMethodTextWithArabic } from "../../../pages/transaction/TransactionEditor"
import { THERMAL_RECEIPT_LOGO, VAT } from '../../../app/Config';
import { POS_SESSION_DISCOUNT_TYPE_COUPON } from '../../../constants/Constants';
import { isAbsharShop, isAnyAbshar } from '../../../app/app-util';


const Line = () => <div style={{ height: 4 }} />
const Divider = () => <p style={{ fontSize: 12, color: 'black' }}>{Array(71).fill({}).map(i => '-')}</p>

const Text = ({ bold, italic, size, center, right, underline, children }) => <p style={{
    fontWeight: bold ? "bold" : "bold", textAlign: center ? "center" : right ? "right" : "left", fontSize: size,
    textDecoration: underline ? "underline" : undefined,
    fontStyle: italic ? "italic" : undefined,
    whiteSpace: 'pre-wrap'
}}>{children}</p>

const Normal = ({ children }) => <Text bold={false} size={20}>{children}</Text>
const Italic = ({ children }) => <Text italic size={20}>{children}</Text>
const Underline = ({ children }) => <Text underline bold={false} size={20}>{children}</Text>
const ArabicNormal = ({ children }) => <Text right bold={false} size={20}>{children}</Text>
const ArabicBold = ({ children }) => <Text right bold={true} size={20}>{children}</Text>
const ArabicLeftBold = ({ children }) => <Text bold={true} size={20}>{children}</Text>
const ArabicUnderline = ({ children }) => <Text right underline={true} size={20}>{children}</Text>

const BoldCenterNormal = ({ children }) => <Text bold center size={20}>{children}</Text>
const CenterNormal = ({ children }) => <Text center size={20}>{children}</Text>
const CenterBold = ({ children }) => <Text center bold size={20}>{children}</Text>
const RightNormal = ({ children }) => <Text right size={20}>{children}</Text>
const RightSmall = ({ children }) => <Text right size={18}>{children}</Text>
const DoubleRightNormal = ({ children }) => <Text right size={40}>{children}</Text>

const Bold = ({ children }) => <Text bold size={20}>{children}</Text>
const DoubleBold = ({ children }) => <Text bold size={40}>{children}</Text>

function num(a) {
    return a.toFixed(2).replaceAll("0", "O")
}

function Row({ children }) {
    const colCount = children.map($ => $[0]).reduce((t, v) => t + v, 0)
    return (
        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: `repeat(${colCount}, minmax(0, 1fr))` }}>
            {children.map(([span, node]) => (
                <div style={{ gridColumn: `span ${span}` }}>
                    {node}
                </div>
            ))}
        </div>
    )
}

function getTotalQuantity(request) {
    return request.currentCartItems.map(item => item.continuousStockType ? 1 : item.quantityValue).reduce((t, v) => t.plus(big(v)), big(0)).toNumber();
}

export const ArabicPosReceipt = ({ request }) => {
    const couponCodes = request.discounts.filter($ => $.type === POS_SESSION_DISCOUNT_TYPE_COUPON).map($ => $.couponCode);

    // const itemNameLen = isAbsharShop() ? 20 : 24;
    // const itemQtyLen = isAbsharShop() ? 8 : 4;
    return (
        <div style={{ fontFamily: '"IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', width: 512, maxWidth: 512, background: 'transparent' }}>
            {!isAnyAbshar() && <>
                <Line />
                <Line />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={THERMAL_RECEIPT_LOGO.squareLogo ? Logo : ReceiptLogo} style={{ width: THERMAL_RECEIPT_LOGO.width, height: THERMAL_RECEIPT_LOGO.height, objectPosition: 'center', objectFit: 'contain', }} />
                </div>
            </>}
            {isAbsharShop() && <>
                <Line />
                <Line />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={AbsharLogo} style={{ width: 480, height: 173, objectPosition: 'center', objectFit: 'contain', }} />
                </div>
            </>}

            {/* <CenterNormal>{request.customerName}</CenterNormal>
            <CenterNormal>{request.terminalName}</CenterNormal>
            <CenterNormal>{request.salespersonName}</CenterNormal> */}

            <Line />
            <Line />

            <BoldCenterNormal>{request.__company_name}</BoldCenterNormal>
            <CenterNormal>{request.receiptInfo.usrsys_address}</CenterNormal>
            <CenterNormal>Tel: {request.receiptInfo.usrsys_phone}</CenterNormal>
            <CenterNormal>TRN: {request.receiptInfo.usrsys_trn}</CenterNormal>

            <Line />
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Normal>POS: {request.terminalName}</Normal>
                <div style={{ flex: 1 }} />
                <RightNormal>{request.salespersonName}</RightNormal>
            </div>
            <Line />

            <CenterNormal>Tax Invoice - فاتورة ضريبيه</CenterNormal>

            <Line />
            <Line />
            <Line />

            <div style={{ marginLeft: 25 }}>
                <Bold>{Util.isStringExists(request.customerName) ? request.customerName : "Guest"},</Bold>
            </div>

            <Divider />
            {/* <Normal>{"#   " + weightText("Description", itemNameLen, true) + " " + weightText("Qty", itemQtyLen, false) + " " + weightText("Amount", 8, false)}</Normal>
            <ArabicBold>{"    " + weightText("المبلغ", 8, true) + " " + weightText("العدد", 5, false) + " " + weightText("الوصف", 23, false)}</ArabicBold> */}
            <Row>{[
                [1, <div>
                    <Normal>#</Normal>
                </div>],

                [5, <div>
                    <Normal>Description</Normal>
                    <ArabicLeftBold>الوصف</ArabicLeftBold>
                </div>],

                [3, <div>
                    <RightNormal>Qty</RightNormal>
                    <ArabicBold>العدد</ArabicBold>
                </div>],

                [2, <div>
                    <RightNormal>Rate</RightNormal>
                    <ArabicBold>السعر</ArabicBold>
                </div>],

                [3, <div>
                    <RightNormal>Amount</RightNormal>
                    <ArabicBold>المبلغ</ArabicBold>
                </div>],
            ]}</Row>
            <Divider />

            {request.currentCartItems.map((item, index) => (<>
                <Row>{[
                    [1, <div>
                        <Normal>{index + 1}</Normal>
                    </div>],

                    [5, <div>
                        <Normal>{item.ref}</Normal>
                    </div>],

                    [3, <div>
                        <RightNormal>{item.quantityValue + "x"}</RightNormal>
                    </div>],

                    [2, <div>
                        {(item.amount.price != item.amount.originalPrice && item.amount.originalPrice && item.amount.price < item.amount.originalPrice) ? (<>
                            <RightNormal><span style={{ textDecoration: 'line-through' }}>
                                {num(item.amount?.originalPrice ?? 0)}
                            </span></RightNormal>
                            <RightNormal>{num(item.amount?.price ?? 0)}</RightNormal>
                        </>) : (<>
                            <RightNormal>{num(item.amount?.price ?? 0)}</RightNormal>
                        </>)}

                    </div>],

                    [3, <div>
                        {/* {Util.isNumberExist(item.itemDiscountedTotal) ? (<> */}
                        {item.itemDiscountedTotal !== null && item.itemDiscountedTotal !== undefined && typeof item.itemDiscountedTotal === 'number' ? (<>
                            <RightNormal><span style={{ textDecoration: 'line-through' }}>
                                {num(item.totalAmount.price)}
                            </span></RightNormal>
                            <RightNormal>{num(item.itemDiscountedTotal)}</RightNormal>
                        </>) : (<>
                            <RightNormal>{num(item.totalAmount.price)}</RightNormal>
                        </>)}
                    </div>],
                ]}</Row>
                <Row>{[
                    [1, <div />],

                    [13, <div>
                        <Normal>{item.name}</Normal>
                    </div>],
                ]}</Row>
            </>))}


            <Line />
            <Line />
            <Divider />
            <div style={{ display: "flex", alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <Normal>Total Items: {request.currentCartItems.length}</Normal>
                </div>
                <div style={{ flex: 1 }}>
                    <ArabicBold>{request.currentCartItems.length} :مجموع الاغراض</ArabicBold>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <Normal>Total Quantity: {getTotalQuantity(request)}</Normal>
                </div>
                <div style={{ flex: 1 }}>
                    <ArabicBold>{getTotalQuantity(request)} :مجموع</ArabicBold>
                </div>
            </div>
            <Divider />
            <Line />
            <Line />


            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Bold>Subtotal المبلغ اجمالي</Bold>
                <div style={{ flex: 1 }}>
                    {/* <ArabicBold>المبلغ اجمالي</ArabicBold> */}
                    <RightNormal>SAR {request.posTotals.subtotal.toFixed(2).replaceAll("0", "O")}</RightNormal>
                </div>
            </div>
            <Line />

            {request.posTotals.discount !== undefined && request.posTotals.discount !== null && request.posTotals.discount > 0 && (<>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Bold>Discount تخفيض</Bold>
                    <div style={{ flex: 1 }}>
                        {/* <ArabicBold>تخفيض</ArabicBold> */}
                        <RightNormal>SAR {request.posTotals.discount.toFixed(2).replaceAll("0", "O")}</RightNormal>
                    </div>
                </div>
                {couponCodes.map($ => (<>
                    <div style={{ marginLeft: 25 }}>
                        <Italic>Redeemed Code: {$}</Italic>
                    </div>
                </>))}
                <Line />
            </>)}

            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Bold>VAT {`${VAT.PERC}%`} ضريبة</Bold>
                <div style={{ flex: 1 }}>
                    {/* <ArabicBold>ضريبة</ArabicBold> */}
                    <RightNormal>SAR {request.posTotals.tax.toFixed(2).replaceAll("0", "O")}</RightNormal>
                </div>
            </div>
            <Line />

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <DoubleBold>Grand Total</DoubleBold>
                <div style={{ flex: 1 }}>
                    <ArabicBold>المجموع الاجمالي</ArabicBold>
                </div>
            </div>
            <DoubleRightNormal>SAR {request.posTotals.total.toFixed(2).replaceAll("0", "O")}</DoubleRightNormal>
            <Line />
            {Util.isNumberExist(request.cashbackAmount) && <>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ flex: 1 }} />
                    <Bold>Change:</Bold>
                    <div style={{ marginLeft: 15 }}>
                        {/* <ArabicNormal>ضريبة</ArabicNormal> */}
                        <RightNormal>SAR {request.cashbackAmount.toFixed(2).replaceAll("0", "O")}</RightNormal>
                    </div>
                </div>
                <Line />
            </>}


            <Line />
            <Line />

            {request.posTotals.totalSavings > 0 && <>
                <CenterNormal>You have saved SAR {request.posTotals.totalSavings}!</CenterNormal>
                <CenterBold>SAR {request.posTotals.totalSavings} لقد وفرت</CenterBold>
            </>}

            <Line />
            <Line />
            <Line />
            <Line />

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Underline>Payment</Underline>
                <div style={{ flex: 1 }}>
                    <ArabicBold>الدفع</ArabicBold>
                </div>
            </div>
            {request.paymentMethods.map(item => (
                <Normal>
                    {
                        weightText(paymentMethodTextWithArabic(item.methodType), 28, true) +
                        " " +
                        weightText(item.amount.toFixed ? item.amount.toFixed(2) : parseFloat(item.amount).toFixed(2), 13, false)
                    }
                </Normal>
            ))}

            <Line />
            <Line />
            <Line />

            <CenterNormal>Thank you for shopping with us!</CenterNormal>
            <CenterBold>شكرا للتسوق لدينا</CenterBold>
            <CenterNormal>{Util.getFullDate(new Date().getTime())}</CenterNormal>

            <Line />
            <Line />
            <Line />
            <Line />
            <Underline>Terms & Conditions</Underline>
            <Normal>{request.receiptInfo.usrsys_terms_and_conditions}</Normal>
            {Util.isStringExists(request.receiptInfo.__ar_usrsys_terms_and_conditions) &&
                <ArabicBold>{request.receiptInfo.__ar_usrsys_terms_and_conditions}</ArabicBold>
            }
            {/* <Line /> */}

            <Line />
            <Line />
            <Line />

            <CenterNormal>Sales #{Util.getVoucherNumber(request.lastStockFlowId)}</CenterNormal>
            <CenterNormal>{request.lastStockFlowRecordId}</CenterNormal>

            <Line />
            <Line />
            <Line />
            <Line />

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <QRCode size={200} value={request.lastStockFlowRecordId} />
            </div>

            <Line />
            <Line />

            {Util.isStringExists(request.receiptInfo.usrsys_receipt_bottom_text) &&
                <CenterNormal>{request.receiptInfo.usrsys_receipt_bottom_text}</CenterNormal>}

            <Line />
            <Line />
        </div>
    )

}

function weightText(text, weight, leftAligned) {
    if (text == null) {
        text = "";
    }

    if (!text || !text.substring) {
        text = text + "";
    }

    if (text.length > weight) {
        text = text.substring(0, weight - 3) + "...";
    } else {
        const textLength = text.length;
        for (let i = 0; i < (weight - textLength); i++) {
            if (leftAligned) {
                text += ' ';
            } else {
                text = " " + text;
            }
        }
    }
    return text;
}



/*
            {request.currentCartItems.map((item, index) => (<>
                {Util.isNumberExist(item.itemDiscountedTotal) ? (<>

                    <Normal>
                        {weightText(index + 1, 3, true) +
                            " " +
                            weightText(item.ref, itemNameLen, true) +
                            " " +
                            weightText(item.quantityValue + "x", itemQtyLen, false) +
                            " "}
                        <span style={{ textDecoration: 'line-through', }}>{weightText(item.totalAmount.price.toFixed(2).replaceAll("0", "O"), 8, false)}</span>
                    </Normal>

                    {item.name.length > itemNameLen ? (<>
                        <Normal>{"    " + item.name.substring(0, itemNameLen) + "      " + weightText(item.itemDiscountedTotal.toFixed(2).replaceAll("0", "O"), 8, false)}</Normal>
                        <Normal>{"    " + weightText(item.name.substring(itemNameLen), itemNameLen, true)}</Normal>
                    </>) : (
                        <Normal>{"    " + weightText(item.name, itemNameLen, true) + "      " + weightText(item.itemDiscountedTotal.toFixed(2).replaceAll("0", "O"), 8, false)}</Normal>
                    )}

                </>) : (<>

                    {Util.isStringExists(item.ref) ? (<>

                        <Normal>
                            {weightText(index + 1, 3, true) +
                                " " +
                                weightText(item.ref, itemNameLen, true) +
                                " " +
                                weightText(item.quantityValue + "x", itemQtyLen, false) +
                                " " +
                                weightText(item.totalAmount.price.toFixed(2).replaceAll("0", "O"), 8, false)}
                        </Normal>

                        {item.name.length > itemNameLen ? (<>
                            <Normal>{"    " + item.name.substring(0, itemNameLen)}</Normal>
                            <Normal>{"    " + weightText(item.name.substring(itemNameLen), itemNameLen, true)}</Normal>
                        </>) : (
                            <Normal>{"    " + weightText(item.name, itemNameLen, true)}</Normal>
                        )}

                    </>) : (<>


                        <Normal>
                            {weightText(index + 1, 3, true) +
                                " " +
                                item.name.length > itemNameLen
                                ? item.name.substring(0, itemNameLen)
                                : weightText(item.name, itemNameLen, true) +
                                " " +
                                weightText(item.quantityValue + "x", itemQtyLen, false) +
                                " " +
                                weightText(item.totalAmount.price.toFixed(2).replaceAll("0", "O"), 8, false)}
                        </Normal>

                        {item.name.length > itemNameLen && (<>
                            <Normal>{"    " + weightText(item.name.substring(itemNameLen), itemNameLen, true)}</Normal>
                        </>)}

                    </>)}

                </>)}



            </>))}
*/