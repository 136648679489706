import React from 'react'
import Page from '../../base/Page';
import Button from '../../components/Button';
import Api from '../../session/Api';


import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    Download16
} from '@carbon/icons-react'
import { ComboBox, DatePicker, DatePickerInput, Link } from 'carbon-components-react';
import TransactionListView from '../transaction/TransactionListView';
import Util, { DAY_MS, handleEndDate } from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import StockFlowListView from '../stock-flow/StockFlowListView';

import { subDays, addDays } from 'date-fns';
//import { DateRangePicker } from 'react-date-range';
import DateRangePicker from '../../components/date/DateRangePicker'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

function onExportBtn(report) {
    const headers = ['Tax Invoice', 'Customer', 'Staff', 'Quantity', 'Total', 'Date']
    const rows = report.items.map(item => [
        Util.getVoucherNumber(item.id),
        item.destinationName,
        item.initiatorFullName,
        item.totalStockCount,
        'SAR ' + Util.formatMoney(item.amount),
        item.initiationDate
    ])


    let csvContent = "data:text/csv;charset=utf-8,";

    [headers, ...rows].forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);

    const a = document.createElement("a");
    a.href = encodedUri;
    a.download = "Report.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}


class SalesReportPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,

            generatingReport: false,
            generatedReport: false,

            report: undefined,

            startDateValue: subDays(new Date(), 1).getTime(),
            endDateValue: new Date().getTime(),

            customerIdValue: 0,
            salespersonIdValue: 0,

            customerIdPickerKey: Util.newTempId(),
            salespersonIdPickerKey: Util.newTempId(),

            suppliers: [],
            customers: [],
            warehouses: [],
            stores: [],
            staff: [],
        }
    }

    clearReport() {
        this.setState({
            generatingReport: false,
            generatedReport: false,

            startDateValue: subDays(new Date(), 1).getTime(),
            endDateValue: new Date().getTime(),

            customerIdValue: 0,
            salespersonIdValue: 0,

            customerIdPickerKey: Util.newTempId(),
            salespersonIdPickerKey: Util.newTempId(),
        })
    }

    canGenerateReport() {
        return Util.isNumberExist(this.state.startDateValue) && Util.isNumberExist(this.state.endDateValue);
    }

    generateReportBtn() {
        this.setState({ generatingReport: true })
        Api.getSalesReport(this.state.startDateValue, handleEndDate(this.state.endDateValue), this.props.posMode === true, this.state.customerIdValue, this.state.salespersonIdValue, response => {
            if (response.status === true) {
                // console.log(response.payload)
                this.setState({ generatingReport: false, report: response.payload, generatedReport: true, })
                UIUtil.showSuccess();
            } else {
                this.setState({ generatingReport: false, generatedReport: false })
                UIUtil.showError(response.message);
            }
        })
    }

    onPageStart() {
        this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => ({
            suppliers: payload.suppliers,
            customers: payload.customers,
            warehouses: payload.warehouses,
            stores: payload.stores,
            staff: payload.staff
        }))
    }

    renderInput() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>{this.props.posMode && 'POS '}Sales</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>

                    <Section icon={Number_132} title="Select Range">
                        <DateRangePicker
                            //onChange={item => setState([item.selection])}
                            onChange={item => this.setState({ startDateValue: item.selection.startDate.getTime(), endDateValue: item.selection.endDate.getTime() })}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={[{
                                startDate: new Date(this.state.startDateValue),
                                endDate: new Date(this.state.endDateValue),
                                key: 'selection'
                            }]}
                            direction="horizontal"
                        />
                        {/* <DatePicker datePickerType={"range"}
                        // value={[this.state.startDateValue, this.state.endDateValue]}
                        onChange={e => {
                            if (e.length > 1) {
                                this.setState({
                                    startDateValue: e[0].getTime(),
                                    endDateValue: e[1].getTime()
                                })
                            } else if (e.length == 1) {
                                this.setState({
                                    startDateValue: e[0].getTime(),
                                })
                            } else {
                                this.setState({
                                    startDateValue: 0,
                                    endDateValue: 0
                                })
                            }
                        }}
                        >
                            <DatePickerInput
                                style={{flex: 1}}
                                placeholder="mm/dd/yyyy"
                                labelText={"Start date"}
                            />
                            <DatePickerInput
                                placeholder="mm/dd/yyyy"
                                labelText="End date"
                            />
                        </DatePicker> */}
                    </Section>

                    <Section icon={Number_232} title="Salesperson/Initiator (optional)">
                        <ComboBox
                            key={this.state.salespersonIdPickerKey}
                            //titleText={}
                            items={this.state.staff}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={this.state.staff.filter(item => item.id == this.state.salespersonIdValue)[0]}
                            onChange={e => {
                                if (e.selectedItem === null) {
                                    this.setState({ salespersonIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, salespersonIdPickerKey: Util.newTempId(), })
                                } else {
                                    this.setState({ salespersonIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                }
                            }} />
                    </Section>

                    <Section icon={Number_332} title="Customer (optional)">
                        <ComboBox
                            key={this.state.customerIdPickerKey}
                            //titleText={}
                            items={this.state.customers}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={this.state.customers.filter(item => item.id == this.state.customerIdValue)[0]}
                            onChange={e => {
                                if (e.selectedItem === null) {
                                    this.setState({ customerIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, customerIdPickerKey: Util.newTempId(), })
                                } else {
                                    this.setState({ customerIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                }
                            }} />
                    </Section>

                    <Section icon={Number_432} title="Report" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.generateReportBtn.bind(this)} disabled={!this.canGenerateReport()} loading={this.state.generatingReport} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

    renderReport() {
        return (
            <div className="main-content">
                <div onClick={this.clearReport.bind(this)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem' }}>
                    <Link><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Generate another report</Link>
                </div>
                <div style={{ width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2 }}>
                    {this.state.report !== undefined && this.state.report.values !== undefined && <>
                        <div style={{ display: 'flex', gap: '0.5rem', marginBottom: '1rem' }}>
                            <div style={{ borderRadius: 15, color: 'green', background: 'white', border: '1px solid #00000040', paddingBlock: '1rem', paddingInline: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <h5>Total sales revenue:</h5>
                                <h3>SAR {Util.formatMoney((this.state.report.values.totalSalesRevenue ?? 0))}</h3>
                            </div>

                            <div style={{ borderRadius: 15, background: 'white', border: '1px solid #00000040', paddingBlock: '1rem', paddingInline: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <h6>Total sales:</h6>
                                <h4>SAR {Util.formatMoney((this.state.report.values.totalSalesAmount ?? 0))}</h4>
                                <label className="bx--label" style={{ margin: 0, fontSize: 10 }}>(tax included)</label>
                            </div>

                            <div style={{ borderRadius: 15, background: 'white', border: '1px solid #00000040', paddingBlock: '1rem', paddingInline: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <h6>Total paid:</h6>
                                <h4>SAR {Util.formatMoney((this.state.report.values.totalSalesAmountReceived ?? 0))}</h4>
                                <label className="bx--label" style={{ margin: 0, fontSize: 10 }}>(tax included)</label>
                            </div>
                        </div>


                    </>}
                    {/* {this.state.report !== undefined && this.state.report.values !== undefined && this.state.report.values.totalSales.toFixed !== undefined &&
                        <h4 style={{color: 'green'}}>Total Sales: SAR {this.state.report.values.totalSales.toFixed(2)}</h4>} */}

                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <div style={{ display: 'flex', alignItems: 'center', opacity: 0.65, flex: 1 }}>
                            <Calendar16 style={{ marginRight: '0.25rem' }} /> <p>{Util.getDateOnly(this.state.startDateValue)}</p>
                            <p style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>-</p>
                            <Calendar16 style={{ marginRight: '0.25rem' }} /> <p>{Util.getDateOnly(this.state.endDateValue)}</p>
                        </div>

                        <Button onClick={() => onExportBtn(this.state.report)} renderIcon={Download16}>Export</Button>
                    </div>
                </div>
                <StockFlowListView report={this.state.report} hideToolbar salesReport />
            </div>
        )
    }

    getLayout() {
        return this.state.generatedReport ? this.renderReport() : this.renderInput()
    }

}

export default SalesReportPage;