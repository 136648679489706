import { Link, StructuredListBody, StructuredListCell, StructuredListHead, StructuredListRow, StructuredListWrapper } from 'carbon-components-react'
import { ShoppingCart24, Pin16, Tag16, RowDelete16, Edit16, Printer16, ErrorFilled16, Gift16, ShoppingCartPlus16, AppConnectivity16, QueryQueue16, Barcode16 } from '@carbon/icons-react'
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import Button from '../../../components/Button'
import ImageView from '../../../components/ImageView'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import Util from '../../../util/Util'
import ProfilePic from '../../../components/ProfilePic'
import ReactTooltip from 'react-tooltip';
import { ProductGroupSetAttribute } from '../../../views/product/ProductGroupSetAttribute'
import { OBJECT_TYPE_PRODUCT } from '../../../constants/ObjectTypes'
import { hasCapabilitySupport } from '../../../app/Capabilities'
import { CinemaTicketCartItem } from '../components/cinema-ticket-cart-item'
import { openCustomPriceDialog } from '../dialogs/set-custom-price-dialog'
import usePromise from '../../../hooks/usePromise'

export const getQuantityValue = item => {
    let quantity = item.quantityValue;
    let uomSymbol = 'x'
    if (item.continuousStockType) {
        if (Util.isNumberExist(item.displayUom)) {
            const uom = item.measurementType.unitOfMeasurements.filter(u => u.id == item.displayUom)[0]
            if (uom) {
                uomSymbol = ' ' + uom.symbol

                if (item.displayQuantityValue) {
                    quantity = item.displayQuantityValue;
                }
            }
        } else {
            uomSymbol = ' ' + item.measurementType.defaultUomSymbol
        }
    }

    return (<>
        {quantity}{uomSymbol}
    </>)
}

class CartItem extends React.Component {

    shouldComponentUpdate(prevProps) {
        return this.props.cartItem != prevProps.cartItem || this.props.scrollInto != prevProps.scrollInto || this.props.removingItem != prevProps.removingItem || this.props.showCost != prevProps.showCost
    }

    render() {
        return <CartItemF {...this.props} />
    }

}

const CartItemF = ({ state, removingItem, onClearBtn, cartItem, scrollInto, onScrollDone, showCost, customerFacing }) => {
    const [key, setKey] = useState(Util.newTempId());
    const [highlight, setHighlight] = useState(false);

    const [quantityKey, setQuantityKey] = useState(Util.newTempId());
    const [quantityHighlight, setQuantityHighlight] = useState(undefined);

    const [cost, setCost] = useState(() => ({ productId: 0, cost: undefined }));

    const ref = createRef();

    const onSelectAttributeBtn = () => {
        UIUtil.showOverlay2(onClose => <ProductGroupSetAttribute defaultProductId={cartItem.itemId} open onClose={onClose} groupId={cartItem.defaultProductGroupId} onSelect={id => {
            state.replaceCartItemItemId(cartItem.id, id)
            // onClearBtn(cartItem);
            // state.updateCartItem(id, 1, false)
        }} />)
    }

    useEffect(() => ReactTooltip.rebuild())

    useEffect(() => {
        if (scrollInto) {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            }
            onScrollDone();
            setKey(Util.newTempId())
            setHighlight(true);
        }
    }, [scrollInto])

    useEffect(() => {
        if (showCost && cartItem && cartItem.itemId !== cost.productId && cartItem.itemType == OBJECT_TYPE_PRODUCT) {
            let cancelled = false;
            Api.getProductCosts([cartItem.itemId], response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true && response.payload && response.payload.items && response.payload.items.length > 0) {
                    setCost({ productId: cartItem.itemId, cost: response.payload.items[0].cost })
                }
            })
            return () => cancelled = true;
        }
    }, [cartItem, showCost])


    const [lastQuantity, setLastQuantity] = useState(undefined);
    useEffect(() => {
        if (lastQuantity !== undefined && lastQuantity != cartItem.quantityValue) {
            setQuantityKey(Util.newTempId())
            setQuantityHighlight(lastQuantity > cartItem.quantityValue ? 'quantity-decrease-anim' : 'quantity-increase-anim');
        }
        setLastQuantity(cartItem.quantityValue);
    }, [cartItem.quantityValue])

    let amount = cartItem.amount;
    if (cartItem.displayAmount) {
        amount = cartItem.displayAmount;
    }


    // return useMemo(() => (
    return (
        <div key={key} ref={ref} style={{
            display: 'flex',
            animation: highlight ? 'scale-bounce-anim 750ms' : '',
            height: cartItem.bundleProducts.length > 0 ? undefined : 65, fontSize: '.875rem', color: '#525252', borderBottom: '1px solid #e0e0e0', padding: '1rem .5rem 1.5rem', paddingLeft: '0rem'
        }}>
            {cartItem.bundleProducts.length > 0 ? (
                <div style={{ flex: 2 }}>
                    <p style={{ fontSize: 11, fontWeight: 600 }}>{cartItem.ref}</p>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: -5 }}>
                        <ProfilePic style={{ minWidth: 30 }} size={30} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />
                        {cartItem.name}
                    </div>
                    {cartItem.bundleProducts.map(bundleProduct => <p style={{ paddingLeft: '0.5rem', marginLeft: 30, opacity: 0.65, fontSize: 12 }}>
                        {bundleProduct.quantity + 'x ' + bundleProduct.name}
                    </p>)}
                </div>
            ) : (
                <div style={{ flex: 2, display: 'flex', alignItems: 'center', gap: '0.5rem', }}>
                    <ProfilePic style={{ minWidth: 30 }} size={30} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />
                    <div>
                        <p style={{ fontSize: 11, fontWeight: 600 }}>{cartItem.ref}</p>
                        {Util.isStringExists(cartItem.invBatchNo) ? (<>
                            <div>
                                <p style={{ fontSize: 12, opacity: 0.65 }}>{cartItem.invBatchNo}</p>
                                <span>{cartItem.name}</span>
                            </div>
                        </>) : (<>
                            {cartItem.name}
                        </>)}
                    </div>
                </div>
            )}
            {showCost &&
                <div style={{ flex: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 5 }}>

                    <span style={{}}>SAR {cost.cost ? cost.cost.toFixed(2) : '-'}</span>

                    {/* SAR {cartItem.amount.price} */}
                </div>}
            <div style={{ flex: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 5 }}>

                {(amount.price != amount.originalPrice && amount.originalPrice && (amount.price < amount.originalPrice || !customerFacing)) ? (<>
                    <span style={{ textDecoration: 'line-through', color: 'red' }}>
                        <span style={{ color: 'black' }}>SAR {amount !== null ? amount.originalPrice.toFixed(2) : (0).toFixed(2)}</span>
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tag16 style={{ marginRight: '0.25rem', fill: 'red' }} />
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                            SAR {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}
                        </span>
                    </div>
                </>) : (<>
                    <span style={{}}>SAR {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}</span>
                </>)}

                {/* <Button renderIcon={Edit16} size="sm" style={{ borderRadius: 25, width: 75, height: 25 }}>Edit</Button> */}

                {!state.readOnly && state?.isAllowCustomPricing() &&
                    <Link style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => openCustomPriceDialog(state, cartItem)} renderIcon={Edit16}>Edit</Link>}

                {/* SAR {cartItem.amount.price} */}
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: 5 }}>
                <span key={quantityKey} style={{ animation: quantityHighlight ? quantityHighlight + ' 750ms' : '' }}>
                    {getQuantityValue(cartItem)}
                </span>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: 5 }}>
                {cartItem.itemDiscountedTotal !== null && cartItem.itemDiscountedTotal !== undefined && typeof cartItem.itemDiscountedTotal === 'number' ? (<>
                    <div>
                        <span style={{ textDecoration: 'line-through', color: 'red' }}>
                            <span style={{ color: 'black' }}>SAR {cartItem.totalAmount.price}</span>
                        </span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Tag16 style={{ marginRight: '0.25rem', fill: 'red' }} />
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                SAR {cartItem.itemDiscountedTotal}
                            </span>
                        </div>
                    </div>
                </>) : (<>
                    SAR {cartItem.totalAmount.price}
                </>)}

            </div>
            {(!state.readOnly || state.monitoringSession) && <div style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {!state.readOnly && Util.isNumberExist(cartItem.defaultProductGroupId) &&
                    <Button onClick={onSelectAttributeBtn} className="bx--tooltip--hidden" style={{ marginLeft: 5 }} hasIconOnly data-tip="Select Attribute" renderIcon={AppConnectivity16} />}

                {state?.getSession()?.haveFocButton ? <>

                    {state?.hasFocApplied(cartItem) ? <>
                        <Button onClick={() => state.onCartItemFocButton(cartItem)} className="bx--tooltip--hidden" style={{ marginLeft: 5 }} kind="danger--tertiary" renderIcon={Gift16} hasIconOnly data-tip="Remove FoC" />
                    </> : <>
                        <Button onClick={() => state.onCartItemFocButton(cartItem)} className="bx--tooltip--hidden" style={{ marginLeft: 5 }} kind="tertiary" renderIcon={Gift16} hasIconOnly data-tip="FoC" />

                        <Button onClick={() => state.setShowFreeFormDiscountDialogForProduct(true, cartItem)} className="bx--tooltip--hidden" style={{ marginLeft: 5 }} kind="tertiary" renderIcon={Tag16} hasIconOnly data-tip="Set Discount" />
                    </>}

                </> : <>

                    <Button onClick={() => state.setShowFreeFormDiscountDialogForProduct(true, cartItem)} className="bx--tooltip--hidden" style={{ marginLeft: 5 }} kind="tertiary" renderIcon={Tag16} hasIconOnly data-tip="Set Discount" />

                </>}


                {!state.readOnly && <>
                    <Button onClick={() => state.setShowQuantitySetDialog(cartItem)} className="bx--tooltip--hidden" style={{ marginLeft: 5 }} kind="tertiary" renderIcon={ShoppingCartPlus16} hasIconOnly data-tip="Set Quantity" />
                    <Button style={{ height: 48, marginLeft: 5 }} loading={removingItem === cartItem} onClick={() => UIUtil.confirm(() => onClearBtn(cartItem))} className="bx--tooltip--hidden" kind="danger--tertiary" renderIcon={RowDelete16} hasIconOnly data-tip="Remove Item" />
                </>}
            </div>}
        </div>
    )
    //, [removingItem, cartItem])
    //, [state, removingItem, onClearBtn, cartItem, scrollInto, onScrollDone])
    //[state, removingItem, onClearBtn, cartItem, scrollInto, onScrollDone])
}

//#e5e5e5

const hasCinema = hasCapabilitySupport("cinema");

const CinemaDivider = ({ state }) => {
    return (
        <div>
            {/* <div style={{ background: '#1c1c1c20', width: '100%', marginTop: '1rem', height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #00000040', borderRadius: 30 }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Tickets</p>
            </div> */}

            <CinemaTicketCartItem state={state} />

            <div style={{ background: '#1c1c1c20', width: '100%', marginTop: '0.5rem', height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #00000040', borderRadius: 5 }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>F&B</p>
            </div>
        </div>
    )
}

export default ({ state, customerFacing }) => {
    const showCost = state?.monitoringSession ?? false;

    const [loadingHeldCarts, setLoadingHeldCarts] = useState(false);
    const [loadingHolding, setLoadingHolding] = useState(false);
    const [loadingClearing, setLoadingClearing] = useState(false);

    const [removingItem, setRemovingItem] = useState(undefined);
    const [scrollIntoItem, setScrollIntoItem] = useState(undefined);

    const [lastItemCount, setLastItemCount] = useState(undefined);

    useEffect(() => {
        if (lastItemCount !== undefined && lastItemCount < state.getCartItems().length) {
            setScrollIntoItem(state.getCartItems()[state.getCartItems().length - 1])
        }
        setLastItemCount(state.getCartItems().length);
    }, [state.getCartItems().length])

    useEffect(() => {
        if (Util.isStringExists(state.getHighlightProductRequestBarcode())) {
            let found = false;
            for (const item of state.getCartItems()) {
                for (const barcode of item.barcodes) {
                    if (barcode == state.getHighlightProductRequestBarcode()) {
                        setScrollIntoItem(item);
                        found = true;
                        break;
                    }
                }
            }
            if (!found) {
                UIUtil.showInfo("Item not found")
            }

            state.setHighlightProductRequestBarcode("")
        }
    }, [state.getHighlightProductRequestBarcode()])

    const onSelectByBarcode = () => {
        state.setShowHighlightProductDialog(true);
        //setScrollIntoItem(state.getCartItems()[state.getCartItems().length - 1])
    }

    const onClearCart = () => {
        UIUtil.confirm(() => {
            setLoadingClearing(true);
            Api.clearCart(response => {
                setLoadingClearing(false);
                if (response.status === true) {
                    state.onUpdateSession(response.payload)
                } else {
                    UIUtil.showError();
                }
            })
        })
    }

    const onHoldCart = () => {
        setLoadingHolding(true);
        Api.newCart(response => {
            setLoadingHolding(false);
            if (response.status === true) {
                state.onUpdateSession(response.payload)
            } else {
                UIUtil.showError();
            }
        })
    }

    const onViewHeldCarts = () => {
        setLoadingHeldCarts(true)
        Api.getHeldCarts(response => {
            setLoadingHeldCarts(false);
            if (response.status === true) {
                state.setShowHeldCartsDialog(true, response.payload)
            } else {
                UIUtil.showError();
            }
        })
    }

    const onClearBtn = (item) => {
        setRemovingItem(item);
        state.updateCartItem(item.itemId, item.quantityValue, true, false, true, () => {
            setRemovingItem(undefined)
        }, {
            batchNo: item?.invBatchNo
        });
        ReactTooltip.hide()
    }

    const [printingCart, printCart] = usePromise(() => state.printCart())


    // const cartItems = useMemo(() => state.getCartItems().map((cartItem) => (
    //     <CartItem key={cartItem.itemId + "-cart-item"} cartItem={cartItem} state={state} 
    //     scrollInto={cartItem === scrollIntoItem} onScrollDone={() => setScrollIntoItem(undefined)}
    //     removingItem={removingItem} onClearBtn={onClearBtn} />
    // )), [state, state.getCartItems(), removingItem])

    return (
        <div className="dashboard-card" style={{ width: '100%', height: '100%', background: '#f4f4f4', padding: '1rem', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ShoppingCart24 style={{ marginRight: 5 }} />
                <h4>Cart ({state.getCartItems().length})</h4>
                <div style={{ flex: 1 }} />

                {!state.readOnly && <>
                    <Button style={{ height: 48 }} loading={printingCart} onClick={printCart} kind="secondary" renderIcon={Printer16} hasIconOnly data-tip="Print Cart" tooltipPosition="bottom" />
                    <div style={{ width: 5 }} />
                    <Button style={{ height: 48 }} loading={loadingClearing} onClick={onClearCart} kind="danger--tertiary" renderIcon={ErrorFilled16} hasIconOnly data-tip="Clear Cart" tooltipPosition="bottom" />
                    <div style={{ width: 5 }} />
                    <Button style={{ height: 48 }} onClick={onSelectByBarcode} kind="tertiary" renderIcon={Barcode16} hasIconOnly data-tip="Select by Barcode" tooltipPosition="bottom" />
                    <div style={{ width: 5 }} />
                    <div style={{ position: 'relative' }}>
                        <Button style={{ height: 48 }} loading={loadingHeldCarts} onClick={onViewHeldCarts} kind="tertiary" renderIcon={QueryQueue16} hasIconOnly data-tip="Open Cart" tooltipPosition="bottom" />
                        {state.getSession().cartIds && state.getSession().cartIds.length > 0 && <div style={{ position: 'absolute', top: -10, right: -10, width: 20, height: 20, borderRadius: '50%', background: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none', zIndex: 10 }}>
                            <p style={{ fontSize: 14, color: 'white', fontWeight: 'bold' }}>{state.getSession().cartIds.length}</p>
                        </div>}
                    </div>
                    <div style={{ width: 5 }} />
                    <Button style={{ height: 48 }} loading={loadingHolding} onClick={onHoldCart} renderIcon={Pin16} hasIconOnly data-tip="Hold Cart" tooltipPosition="bottom" tooltipAlignment="end" /> </>}
            </div>
            {!state.readOnly && <div style={{ height: '1rem' }} />}

            <div style={{ overflowY: 'auto', overflowX: 'hidden', flex: 1 }}>
                {hasCinema && <CinemaDivider state={state} />}

                <div className="bx--structured-list-th dashboard-card" style={{ display: 'flex', paddingLeft: '0rem', borderBottom: '1px solid #e0e0e0', background: '#f4f4f4', position: 'sticky', top: 0, zIndex: 1000 }}>
                    <div style={{ flex: 2 }}>
                        Item
                    </div>
                    {showCost && <div style={{ flex: 2 }}>
                        Cost
                    </div>}
                    <div style={{ flex: 2 }}>
                        Price
                    </div>
                    <div style={{ flex: 1 }}>
                        Quantity
                    </div>
                    <div style={{ flex: 1 }}>
                        Total
                    </div>
                    {!state.readOnly && <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>

                    </div>}
                </div>
                {/* {cartItems} */}

                {state.getCartItems().map((cartItem) => (
                    <CartItem key={cartItem.itemId + "-cart-item"} cartItem={cartItem} state={state}
                        scrollInto={cartItem === scrollIntoItem} onScrollDone={() => setScrollIntoItem(undefined)}
                        removingItem={removingItem} onClearBtn={onClearBtn}
                        showCost={showCost}
                        customerFacing={customerFacing}
                    />
                ))}
            </div>

        </div>
    )
}