import React from 'react'
import { COMPANY_NAME } from '../../app/Config';
import Util from '../../util/Util';
import { AccountTreeItem } from './acct-statements';

const Item = ({ item }) => (
    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '2rem' }}>
        <p style={{ flex: 1 }}>{item.name}</p>
        <p style={{}}>SAR {item.balance.toFixed(2)}</p>
    </div>
)

class BalanceSheet extends React.Component {

    getItems(property) {
        // let groupNames = new Set();
        // for (const item of this.props.statement[property]) {
        //     groupNames.add(item.groupName);
        // }

        // let groups = [];
        // for (const groupName of groupNames) {
        //     groups.push({
        //         groupName,
        //         items: this.props.statement[property].filter(item => item.groupName == groupName)
        //     })
        // }

        // return groups;

        return this.props.statement[property];
    }

    render() {
        const statement = this.props.statement;
        return (
            <div style={{ width: '100%', }}>
                {/* <pre><code>{JSON.stringify(this.props.statement, null, 4)}</code></pre> */}

                <div style={{ height: 15, background: '#f4f4f4' }} />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, background: '#f4f4f4' }}>
                    <h4 style={{ fontSize: 16 }}>{COMPANY_NAME}</h4>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, background: '#f4f4f4' }}>
                    <h4 style={{ fontWeight: 'bold', fontSize: 16 }}>Balance Sheet</h4>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, background: '#f4f4f4' }}>
                    <h4 style={{ fontSize: 16 }}>As of {Util.getStatementDate(statement.date)}</h4>
                </div>
                <div style={{ height: 15, background: '#f4f4f4' }} />

                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ padding: '1rem', flex: 1 }}>
                        <h5 style={{ marginBottom: '1rem' }}>Assets</h5>

                        {this.getItems('assets').map(item => <AccountTreeItem key={item.id} endDate={statement.endDate} item={item} hideZeroBalance={this.props.hideZeroBalance} />)}
                    </div>
                    <div style={{ padding: '1rem', flex: 1 }}>
                        <h5 style={{ marginBottom: '1rem' }}>Liabilities</h5>
                        {this.getItems('liabilities').map(item => <AccountTreeItem key={item.id} endDate={statement.endDate} item={item} hideZeroBalance={this.props.hideZeroBalance} />)}

                        {/* {this.getItems('liabilities').map(group => (<>
                            <h6 style={{ paddingLeft: '1rem', marginTop: '0.5rem' }}>{group.groupName}</h6>
                            {group.items.map(item => <Item item={item} />)}
                        </>))} */}


                        <div style={{ width: '100%', height: 1, background: 'black', opacity: 0.25, marginTop: '3rem', marginBottom: '1rem' }} />
                        <h5 style={{ marginBottom: '1rem' }}>Owner's Equities</h5>
                        {this.getItems('equities').map(item => <AccountTreeItem key={item.id} endDate={statement.endDate} item={item} hideZeroBalance={this.props.hideZeroBalance} />)}

                        {/* {this.getItems('equities').map(group => (<>
                            <h6 style={{ paddingLeft: '1rem', marginTop: '0.5rem' }}>{group.groupName}</h6>
                            {group.items.map(item => <Item item={item} />)}
                        </>))} */}
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1rem', background: '#f4f4f4', marginTop: '1rem' }}>
                    <div style={{ padding: '1rem', flex: 1, display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ flex: 1 }}>Total Assets</h5>
                        <p>SAR {Util.formatMoney(statement.totalAssets)}</p>
                    </div>
                    <div style={{ padding: '1rem', flex: 1, display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ flex: 1 }}>Total Liabilities and Owner's Equity</h5>
                        <p>SAR {Util.formatMoney(statement.totalLiabilities + statement.totalEquities)}</p>
                    </div>
                </div>
            </div>
        )
    }

}

export default BalanceSheet;