import { withLoadablePageWithParams } from "../../../base/Page"
import Api from "../../../session/Api"
import {
    Document32, Notebook16, ArrowLeft16, Report32, Run32, Calendar32, DocumentPdf32, ErrorFilled16, Currency32,
    Currency16, ArrowRight16, ChartBullet32, Catalog32, Money32, List32, TreeView32, RequestQuote16, Notebook32,
    Migrate16, Account32, Policy32, UserCertification16, Wallet32, ChartPie32, User16, AirlineManageGates32
} from '@carbon/icons-react'
import { ButtonSet, Link as DLink, Tag } from "carbon-components-react"
import React, { useMemo, useState } from 'react';
import { PropertyDealStatusIndicator } from "../../../domain/property-deal";
import Button from "../../../components/Button";
import Util, { big } from "../../../util/Util";
import { Link, useHistory } from "react-router-dom";
import { getObjectTypeName, getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_COMMISSION, OBJECT_TYPE_PROPERTY_DEAL, OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL, OBJECT_TYPE_REAL_ESTATE_PROPERTY } from "../../../constants/ObjectTypes";
import NoteView from "../../../views/notes/NoteView";
import ProfilePic from "../../../components/ProfilePic";

const Section = ({ id, children, icon, title, subtitle, extraTopMargin }) => (
    <div id={id} style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem' }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)


const Title = ({ deal }) => {
    const history = useHistory();
    return (<>
        <DLink onClick={() => history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Policy32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <h1 style={{ flex: 1 }}>Property Deal</h1>

            <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <PropertyDealStatusIndicator status={deal.status} />
        </div>
    </>)
}

const RecordInfo = ({ deal }) => (
    <Section icon={Account32} title="Property Deal Record Info">
        {Util.isStringExists(deal.refNo) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Deal No'}</label>
                <p style={{}}>{deal.refNo}</p>
            </div>}
        {Util.isNumberExist(deal.dealDate) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Deal Date'}</label>
                <p style={{}}>{Util.getDateOnly(deal.dealDate)}</p>
            </div>}
    </Section>
)

const Info = ({ deal }) => (
    <Section icon={Report32} title="Deal Info">
        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Voucher Number'}</label>
            <p style={{}}>{Util.getVoucherNumber(deal.id)}</p>
        </div>

        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Property</label>
            <Link target="_blank" to={getObjectTypeUrl(OBJECT_TYPE_REAL_ESTATE_PROPERTY) + '/' + deal.propertyId}><p style={{}}>{deal.propertyName}</p></Link>
        </div>

        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Seller</label>
            <Link target="_blank" to={getObjectTypeUrl(OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL) + '/' + deal.sellerId}><p style={{}}>{deal.sellerName}</p></Link>
        </div>

        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Buyer</label>
            <Link target="_blank" to={'/lead/' + deal.leadId}><p style={{}}>{deal.buyerName}</p></Link>
        </div>

        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Property Amount</label>
            <p>
                SAR {Util.formatMoney(deal.propertyAmount ?? 0)}
            </p>
        </div>

        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Total amount to receive from buyer</label>
            <h4 style={{ color: 'green' }}>
                SAR {Util.formatMoney(deal.totalAmountToReceive ?? 0)}
            </h4>
        </div>
    </Section>

)

const Accounting = ({ deal }) => (
    <Section icon={TreeView32} title="Accounting">
        <Link target="_blank" to={"/journal-entry/" + deal.journalEntryId} style={{ textDecoration: 'none', }}>
            <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: undefined }}>Associated Journal Entry</Button>
        </Link>
    </Section>
)

const Initiation = ({ deal }) => (
    <Section icon={Run32} title="Creation and Posting">
        <label style={{}} className="bx--label">Created by</label>
        <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + deal.initiatorId}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, deal.initiatorId)} />
                <p>{deal.initiatorFullName}</p>
            </div>
        </Link>

        <label style={{ marginTop: '1rem' }} className="bx--label">Created Date</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar32 style={{ marginRight: '0.5rem' }} />
            <p>{Util.getDate(deal.initiationDate)}</p>
        </div>

        <label style={{ marginTop: '1rem' }} className="bx--label">Posted by</label>
        <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + deal.postedById}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, deal.postedById)} />
                <p>{deal.postedByFullName}</p>
            </div>
        </Link>

        <label style={{ marginTop: '1rem' }} className="bx--label">Posted Date</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar32 style={{ marginRight: '0.5rem' }} />
            <p>{Util.getDate(deal.postedDate)}</p>
        </div>
    </Section>
)

const Commissions = ({ deal }) => {
    const history = useHistory();
    const totalAmountDivided = useMemo(() =>
        (deal.commissionDivisions ?? [])
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)), [deal])
    return (
        <Section icon={Wallet32} title="Commissions">
            <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
                <div style={{ flex: 1, background: '#00990020', border: '1px solid #00990060', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                    <Wallet32 style={{ color: 'green' }} />
                    <div style={{ marginLeft: '1rem' }}>
                        <label style={{ color: 'green', marginBottom: 0 }} className="bx--label">Commission Amount</label>
                        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-end' }}>
                            <h4>SAR {Util.formatMoney(deal.commissionAmount)}</h4>
                        </div>
                    </div>
                </div>

                <div style={{ flex: 1, background: '#99000020', border: '1px solid #99000060', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                    <ChartPie32 style={{ color: 'red' }} />
                    <div style={{ marginLeft: '1rem' }}>
                        <label style={{ color: 'red', marginBottom: 0 }} className="bx--label">Total Agent Distribution</label>
                        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-end' }}>
                            <h4>SAR {Util.formatMoney(totalAmountDivided)}</h4>
                            <p style={{ fontSize: 12, opacity: 0.65 }}> // </p>
                            <p>SAR {Util.formatMoney(deal.commissionAmount)}</p>
                        </div>
                    </div>
                </div>
            </div>

            {deal.commissionDivisions.map(item => (
                <div style={{
                    marginBottom: '1rem', background: '#f4f4f4', border: '1px solid #00000020', width: '100%', padding: '1rem', borderRadius: 10, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
                    display: 'flex', alignItems: 'center'
                }}>
                    <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.agentId)} />
                    <div style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}>
                        <p>{item.agentName}</p>
                    </div>
                    <Tag onClick={() => history.push(getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + '/' + item.agentId)} type="blue">View Agent <ArrowRight16 style={{ marginLeft: '0.15rem' }} /></Tag>
                    <Tag style={{ marginLeft: '0.25rem' }} onClick={() => history.push('/commission/' + item.commissionId)} type="magenta">View Commission <ArrowRight16 style={{ marginLeft: '0.15rem' }} /></Tag>

                    <div style={{ flex: 1, minWidth: '1rem' }} />
                    <h4>SAR {Util.formatMoney(item.amount)}</h4>
                </div>
            ))}
        </Section>
    )
}


const Profits = ({ deal }) => {
    const total = useMemo(() =>
        (deal.profits ?? [])
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)), [deal])
    return (
        <Section icon={Money32} title="Profit Amounts">
            <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
                <div style={{ flex: 1, background: '#00990020', border: '1px solid #00990060', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                    <Money32 style={{ color: 'green' }} />
                    <div style={{ marginLeft: '1rem' }}>
                        <label style={{ color: 'green', marginBottom: 0 }} className="bx--label">Total Profit Amount</label>
                        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-end' }}>
                            <h4>SAR {Util.formatMoney(total)}</h4>
                        </div>
                    </div>
                </div>
            </div>

            {deal.profits.map(item => (
                <div style={{
                    marginBottom: '1rem', background: '#f4f4f4', border: '1px solid #00000020', width: '100%', padding: '1rem', borderRadius: 10, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
                    display: 'flex', alignItems: 'center'
                }}>
                    <div style={{ marginRight: '0.5rem', }}>
                        <label style={{ marginBottom: 0 }} className="bx--label">Memo</label>
                        <p>{Util.isStringExists(item.memo) ? item.memo : <span style={{ fontSize: 12, opacity: 0.65 }}>not specified</span>}</p>
                    </div>
                    <div style={{ flex: 1, minWidth: '1rem' }} />
                    <h4 style={{ color: 'green' }}>SAR {Util.formatMoney(item.amount)}</h4>
                </div>
            ))}
        </Section>
    )
}

const IndirectCommissions = ({ deal }) => {
    const history = useHistory();
    const total = useMemo(() =>
        (deal.indirectCommissions ?? [])
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)), [deal])
    return (
        <Section icon={AirlineManageGates32} title="Indirect Commissions">
            <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
                <div style={{ flex: 1, background: '#99000020', border: '1px solid #99000060', paddingBlock: '0.5rem', paddingInline: '1rem', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                    <AirlineManageGates32 style={{ color: 'red' }} />
                    <div style={{ marginLeft: '1rem' }}>
                        <label style={{ color: 'red', marginBottom: 0 }} className="bx--label">Total Indirect Commission</label>
                        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-end' }}>
                            <h4>SAR {Util.formatMoney(total)}</h4>
                        </div>
                    </div>
                </div>
            </div>

            {deal.indirectCommissions.map(item => (
                <div style={{
                    marginBottom: '1rem', background: '#f4f4f4', border: '1px solid #00000020', width: '100%', padding: '1rem', borderRadius: 10, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',

                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.agentId)} />
                        <div style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}>
                            <p>{item.agentName}</p>
                        </div>
                        <Tag onClick={() => history.push(getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + '/' + item.agentId)} type="blue">View Agent <ArrowRight16 style={{ marginLeft: '0.15rem' }} /></Tag>
                        <Tag style={{ marginLeft: '0.25rem' }} onClick={() => history.push('/commission/' + item.commissionId)} type="magenta">View Commission <ArrowRight16 style={{ marginLeft: '0.15rem' }} /></Tag>
                        <div style={{ flex: 1, minWidth: '1rem' }} />
                        <h4 style={{}}>SAR {Util.formatMoney(item.amount)}</h4>
                    </div>
                    <div style={{ marginTop: '1rem', }}>
                        <label style={{ marginBottom: 0 }} className="bx--label">Memo</label>
                        <p>{Util.isStringExists(item.memo) ? item.memo : <strong>-</strong>}</p>
                    </div>
                </div>
            ))}
        </Section>
    )
}

const View = ({ payload: deal }) => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
            <div style={{ width: '75vw' }}>
                <Title deal={deal} />
                {(Util.isNumberExist(deal.dealDate) || Util.isStringExists(deal.refNo)) && <RecordInfo deal={deal} />}
                <Info deal={deal} />
                <Accounting deal={deal} />


                {/* Commissions and profits here */}
                {/* {JSON.stringify(deal)} */}
                <Commissions deal={deal} />
                <Profits deal={deal} />
                <IndirectCommissions deal={deal} />


                {Util.isStringExists(deal.info) && <Section icon={Catalog32} title="Additional Information">
                    <p style={{ whiteSpace: 'pre-line' }}>{deal.info}</p>
                </Section>}

                <Initiation deal={deal} />
                <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                    <NoteView objectId={deal.id} objectType={OBJECT_TYPE_PROPERTY_DEAL} />
                </Section>

            </div>
        </div>
    )
}

export const PropertyDealItemPage =
    withLoadablePageWithParams(params => listener => Api.getPropertyDeal(params.propertyDealId, listener), View)