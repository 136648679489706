

// 12 => 71
// 14 => 60
// 16 => 53
// 18 => 47
// 20 => 42
// 22 => 38
// 40 => 21

import Logo from '../../../images/SquareLogo.png';
import ReceiptLogo from '../../../images/ReceiptLogo.png'

import QRCode from "react-qr-code"
import Util from "../../../util/Util"
import { paymentMethodTextWithArabic } from "../../../pages/transaction/TransactionEditor"
import { COMPANY_NAME, THERMAL_RECEIPT_LOGO } from '../../../app/Config';
import { POS_SESSION_DISCOUNT_TYPE_COUPON } from '../../../constants/Constants';


const Line = () => <br />
const Divider = () => <p style={{ fontSize: 12, color: 'black' }}>{Array(71).fill({}).map(i => '-')}</p>

const Text = ({ bold, italic, size, center, right, underline, children }) => <p style={{
    fontWeight: bold ? "bold" : "bold", textAlign: center ? "center" : right ? "right" : "left", fontSize: size,
    textDecoration: underline ? "underline" : undefined,
    fontStyle: italic ? "italic" : undefined,
    whiteSpace: 'pre-wrap'
}}>{children}</p>

const Normal = ({ children }) => <Text bold={false} size={20}>{children}</Text>
const Italic = ({ children }) => <Text italic size={20}>{children}</Text>
const Underline = ({ children }) => <Text underline bold={false} size={20}>{children}</Text>
const ArabicNormal = ({ children }) => <Text right bold={false} size={20}>{children}</Text>
const ArabicBold = ({ children }) => <Text right bold={true} size={20}>{children}</Text>
const ArabicUnderline = ({ children }) => <Text right underline={true} size={20}>{children}</Text>

const BoldCenterNormal = ({ children }) => <Text bold center size={20}>{children}</Text>
const CenterNormal = ({ children }) => <Text center size={20}>{children}</Text>
const CenterBold = ({ children }) => <Text center bold size={20}>{children}</Text>
const RightNormal = ({ children }) => <Text right size={20}>{children}</Text>
const DoubleRightNormal = ({ children }) => <Text right size={40}>{children}</Text>
const LgCenter = ({ children }) => <Text center size={30}>{children}</Text>
const DoubleCenter = ({ children }) => <Text center size={40}>{children}</Text>

const Bold = ({ children }) => <Text bold size={20}>{children}</Text>
const DoubleBold = ({ children }) => <Text bold size={40}>{children}</Text>


function couponAmountText(coupon) {
    switch (coupon.mode) {
        case 0: //percentage
            if (coupon.maxPercentageSubtractingAmount && Util.isNumberExist(coupon.maxPercentageSubtractingAmount)) {
                return Util.formatAmount(coupon.percentageModifier) + '% max of SAR ' + Util.formatMoney(coupon.maxPercentageSubtractingAmount);
            } else {
                return '%' + Util.formatAmount(coupon.percentageModifier);
            }
        case 1: //flat amount
            return 'SAR ' + Util.formatMoney(coupon.flatSubtractionAmount);
    }

    return ""
}

function couponValidityText(coupon) {
    let policy = (() => {
        const year2100 = new Date();
        year2100.setFullYear(2100);

        const indefinite = coupon.endDate > year2100.getTime()
        switch (coupon.expirationMode) {
            case 0: // date
                if (indefinite) {
                    return `Valid from ${Util.getDateOnly(coupon.startDate)}`
                } else {
                    return `Valid from ${Util.getDateOnly(coupon.startDate)} to ${Util.getDateOnly(coupon.endDate)} only`
                }
            case 1: // use
                return `Valid for ${coupon.maxUses} time${coupon.maxUses != 1 ? 's' : ''} only`
            case 2: // both
                if (indefinite) {
                    return `Valid from ${Util.getDateOnly(coupon.startDate)} for ${coupon.maxUses} time${coupon.maxUses != 1 ? 's' : ''} only`
                } else {
                    return `Valid from ${Util.getDateOnly(coupon.startDate)} to ${Util.getDateOnly(coupon.endDate)} for ${coupon.maxUses} time${coupon.maxUses != 1 ? 's' : ''} only`
                }
        }

        return ""
    })();

    if (Util.isNumberExist(coupon.minAmountRequired)) {
        policy += `\nCan only be applied for sales above SAR ${Util.formatMoney(coupon.minAmountRequired)}`
    }
    return policy;
}


export const CouponPrint = ({ request, coupon }) => {
    return (
        <div style={{ fontFamily: '"IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', width: 512, maxWidth: 512, background: 'transparent' }}>
            <Line />
            <Line />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={THERMAL_RECEIPT_LOGO.squareLogo ? Logo : ReceiptLogo} style={{ width: THERMAL_RECEIPT_LOGO.width, height: THERMAL_RECEIPT_LOGO.height, objectPosition: 'center', objectFit: 'contain', }} />
            </div>

            {/* <CenterNormal>{request.customerName}</CenterNormal>
            <CenterNormal>{request.terminalName}</CenterNormal>
            <CenterNormal>{request.salespersonName}</CenterNormal> */}

            <Line />
            <Line />

            <BoldCenterNormal>{COMPANY_NAME.toUpperCase()}</BoldCenterNormal>
            <CenterNormal>{request.receiptInfo.usrsys_address}</CenterNormal>
            <CenterNormal>Tel: {request.receiptInfo.usrsys_phone}</CenterNormal>
            <CenterNormal>TRN: {request.receiptInfo.usrsys_trn}</CenterNormal>

            <Line />
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Normal>POS: {request.terminalName}</Normal>
                <div style={{ flex: 1 }} />
                <RightNormal>{request.salespersonName}</RightNormal>
            </div>
            <Line />

            <CenterNormal>Coupon Voucher</CenterNormal>
            {/* <CenterBold>فاتورة ضريبيه</CenterBold> */}

            <div style={{ marginLeft: 25 }}>
                <Bold>{Util.isStringExists(request.customerName) ? request.customerName : "Guest"},</Bold>
            </div>
            <Line />

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <QRCode size={250} value={coupon.code} />
            </div>
            <Line />
            <DoubleCenter>{coupon.code}</DoubleCenter>
            <LgCenter>{couponAmountText(coupon)}</LgCenter>

            <Line />
            <Normal>{couponValidityText(coupon)}</Normal>

            {Util.isStringExists(coupon.voucherNote) && <>
                <Line />
                <Line />
                <Line />
                <Line />

                <Normal>{coupon.voucherNote}</Normal>
            </>}

            <Line />
            <Line />
            <Line />
            <Line />

            <CenterNormal>As of {Util.getFullDate(new Date().getTime())}</CenterNormal>
            <CenterNormal>Gifted from sale #{Util.getVoucherNumber(request.lastStockFlowId)}</CenterNormal>
            <Line />
            <Line />
            <Line />
            <Line />
        </div>
    )

}

function weightText(text, weight, leftAligned) {
    if (text == null) {
        text = "";
    }

    if (!text || !text.substring) {
        text = text + "";
    }

    if (text.length > weight) {
        text = text.substring(0, weight - 3) + "...";
    } else {
        const textLength = text.length;
        for (let i = 0; i < (weight - textLength); i++) {
            if (leftAligned) {
                text += ' ';
            } else {
                text = " " + text;
            }
        }
    }
    return text;
}