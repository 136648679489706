import './styles.scss';
import { clearAccount, getAccount } from "../session/SessionManager";
import RoboBlack from '../images/RoboBlack.png'
import picardlogo from '../images/other/picard/picardlogo.png'
import { withLoadablePage } from '../base/Page';
import Api from '../session/Api';
import Button from '../components/Button';
import UIUtil from '../util/UIUtil';
import Util from '../util/Util';
import { OBJECT_TYPE_LEAD } from '../constants/ObjectTypes';
import { CommentView } from '../views/comment/comment-view';
import { Tab, Tabs } from 'carbon-components-react';
import { Logout16, ArrowRight16, Pdf16, ArrowLeft16, Attachment16 } from '@carbon/icons-react'
import { openPdf } from '../markup-template-engine';
import { useState } from 'react';
import { SidePanel } from '../templates/draft/components/side-panel';
import { AdvancedNotesView } from '../views/advanced-notes/advanced-notes-view';
import { asyncPrintStatementOfAccount } from '../pdfs/statements-of-account/StatementsOfAccountPdf';

async function logout() {
    const stopLoading = await UIUtil.pageLoader();
    try {
        await Api.asyncLogout();
        clearAccount();

        window.location.href = '/'
    } catch (e) {
        stopLoading();
    }
}

async function viewStatement() {
    const stopLoading = await UIUtil.pageLoader();
    try {
        await asyncPrintStatementOfAccount(-1, -1, 0, new Date().getTime());
    } finally {
        stopLoading();
    }
}

function openCdoPdf(id) {
    window.open('/api/markup-template/pdf/CargoDeliveryOrderDoc/CargoDeliveryOrderDocStandard/' + id);
}

function View({ payload: data }) {
    const [conversationId, setConversationId] = useState(null);
    const [advancedNoteId, setAdvancedNoteId] = useState(null);

    return (
        <div className='cargo-customer' style={{ width: '100%', height: '100dvh' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img className='logo' src={picardlogo} alt="Logo" style={{ objectFit: 'contain', objectPosition: 'center', width: 200 }} />
                <div style={{ flex: 1 }} />
                <Button renderIcon={Logout16} size="sm" style={{ borderRadius: 5 }} kind="ghost" onClick={logout}>Logout</Button>
            </div>
            <h1 className='cargo-c-title'>Hello, {getAccount().fullName}!</h1>
            <h2 className='cargo-c-subtitle'>Welcome to the Customer Portal</h2>


            <div className="short-width-tabs-dyn">
                <Tabs>
                    <Tab label="Account">
                        <h3 className='cargo-c-balance'>Balance: SAR {Util.formatMoney(data.balance)}</h3>
                        <Button renderIcon={ArrowRight16} size="sm" style={{ borderRadius: 5, marginBottom: '1rem' }} kind="secondary" onClick={viewStatement}>View Statement</Button>
                    </Tab>
                    <Tab label="Inventory">
                        {data.invItems.map(item => <>
                            <div key={item.id} className='cargo-inv-item'>
                                <p className='cargo-inv-item-code'>{item.code}</p>
                                <p>{item.description}</p>
                                <div className='cargo-inv-item-details' style={{ display: 'flex', alignItems: 'center' }}>
                                    <p>Width: {item.width}</p>
                                    <p>Height: {item.height}</p>
                                    <p>Length: {item.length}</p>
                                    <p>Weight: {item.weight}</p>
                                </div>
                            </div>
                        </>)}
                    </Tab>
                    <Tab label="Conversations">
                        {data.conversations.map(item => <>
                            <div key={item.id} className='cargo-inv-item' style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    <p>Request #{item.id}</p>
                                    <p style={{ fontSize: 12, opacity: 0.65 }}>Conversation</p>
                                </div>
                                <Button renderIcon={ArrowRight16} hasIconOnly iconDescription="PDF" size="md" style={{ borderRadius: 5, }} onClick={() => {
                                    setConversationId(item.id);
                                }} />
                            </div>
                        </>)}
                    </Tab>
                    <Tab label="CDOs">
                        {data.cdos.map(item => <>
                            <div key={item.id} className='cargo-inv-item' style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    <p>{item.orderNo}</p>
                                    <p style={{ fontSize: 12, opacity: 0.65 }}>Cargo Delivery Order</p>
                                </div>
                                <Button renderIcon={Pdf16} hasIconOnly iconDescription="PDF" size="md" style={{ borderRadius: 5, }} kind="secondary" onClick={() => openCdoPdf(item.id)} />
                            </div>
                        </>)}
                    </Tab>
                </Tabs>
            </div>
            {/* invItems */}


            {!!conversationId && <SidePanel fullscreen md2 onClose={() => setConversationId(0)}>
                <div style={{ height: '3rem', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.25rem' }}>
                    <Button size="sm" style={{ borderRadius: 5 }} kind="ghost" onClick={() => setConversationId(0)}>
                        <ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back
                    </Button>
                    <h3 style={{ fontWeight: 'bold', flex: 1, fontSize: 16 }}>Conversation</h3>
                    <Button renderIcon={Attachment16} size="sm" style={{ borderRadius: 5 }} kind="secondary" onClick={() => setAdvancedNoteId(conversationId)}>
                        Attachments
                    </Button>
                </div>
                <div style={{ height: 'calc(100dvh - 3rem)' }}>
                    <CommentView itemId={conversationId} itemType={OBJECT_TYPE_LEAD} noGapInSendBox />
                </div>
            </SidePanel>}

            {!!advancedNoteId && <SidePanel fullscreen md2 onClose={() => setAdvancedNoteId(0)}>
                <div style={{ height: '3rem', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.25rem' }}>
                    <Button size="sm" style={{ borderRadius: 5 }} kind="ghost" onClick={() => setAdvancedNoteId(0)}>
                        <ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back
                    </Button>
                    <h3 style={{ fontWeight: 'bold', flex: 1, fontSize: 16 }}>Attachments</h3>
                </div>
                <div style={{}}>
                    <AdvancedNotesView objectId={advancedNoteId} objectType={OBJECT_TYPE_LEAD} title="" />
                </div>
            </SidePanel>}
        </div>
    )

}

export const CargoCustomerApp = withLoadablePage(listener => Api.cargoCustomer.getIndex(listener), View);