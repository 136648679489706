import { Link } from "react-router-dom"
import { APP_NAME } from "../../app/Config"
import { withLoadablePagePropsAndProps } from "../../base/Page"
import Api from "../../session/Api"
import Util from "../../util/Util"
import Logo from './../../images/Logo.png'
import { useRef, useState } from "react"
import { LockScroll } from "../../hooks/useLockScroll"
import DeliverableItemPage from "../sales-order/DeliverableItemPage"
import { SidePanel } from "../../templates/draft/components/side-panel"
import { MainApp, isMobileScreen } from "../../App"
import { CaretRight16 } from '@carbon/icons-react'
import { SizeGetter, useDyamicRefSize, useRefSize } from "../../util/useSize"
import { Info16, Info32 } from "../../components/info"


const ORDER_PROP_LABELS = {
    tableName: 'Table Name',
    driver: 'Driver'
}

function useAccordion() {
    const [expanded, setExpanded] = useState(true)
    const parentRef = useRef()
    const [height, setHeight] = useState()
    const onExpandToggle = () => {
        if (!height) {
            setHeight(parentRef.current.getBoundingClientRect().height)
        }
        setExpanded(e => !e)
    }

    const expandToggleBtn = (
        <button className="lead-flow-icon-button lead-flow-icon-button-light-on-white rbs-accordion-btn" onClick={onExpandToggle}>
            <CaretRight16 className="rbs-accordion-btn-icon" />
        </button>
    )

    return {
        toggleParentProps: {
            ref: parentRef,
            onClick: isMobileScreen ? onExpandToggle : undefined,
            style: {
                '--elem-height': height ? height + 'px' : undefined
            },
            'data-expanded': expanded ? 'true' : 'false'
        },
        expandToggleBtn
    }
}


export function RestBusinessSummary({ type, report }) {
    return (
        <div className={`rest-business-summary-result-${type}`}>
            <div className="rbs-title-header">
                <div className="rbs-title-group">
                    <h1 className="company-label">{APP_NAME}</h1>
                    <h1 className="report-title">Business Summary</h1>
                    <h1 className="report-date">Date: {report.dateLabel}</h1>
                    {report.storeLabel &&
                        <h1 className="report-store">Store: {report.storeLabel}</h1>}
                </div>
                <img className="rbs-title-logo" src={Logo} />
            </div>

            <div className="report-gen-group">
                <p className="report-gen-message rbs-as-of-msg">Report as of {Util.formatDate(report.reportDate, 'HH:mm DD MMMM YYYY')}</p>
                <p className="report-gen-message rbs-gen-by-msg">Generated by {report.genBy}</p>
            </div>

            {report.sections.map(section =>
                <Section section={section} />)}
        </div>
    )
}

function Section({ section }) {
    const Component = (() => {
        switch (section.components[0]?.componentType) {
            case "SUMMARY_GROUP":
                return SummaryGroup;
            case "DETAIL_GROUP":
                return DetailGroup;
            case "SUMMARY":
                return Summary;
        }
    })()


    const { expandToggleBtn, toggleParentProps } = useAccordion()
    return (
        <div className="rbs-section" {...toggleParentProps}>
            <div className="rbs-section-header">
                {expandToggleBtn}

                <h2 className="rbs-section-title">{section.name}</h2>
                {section.showTotal &&
                    <h2 className="rbs-section-value">SAR {Util.formatMoney(section.total)} ({section.count} no)</h2>}
            </div>
            <div className="rbs-section-children">
                <div className="rbs-section-components">
                    {Component && section.components.map(component =>
                        <Component rec={component} />)}
                </div>
                {section.components.length === 0 &&
                    <p className="empty-message">Nothing to display</p>}
            </div>
        </div>
    )
}

function SummaryGroup({ rec }) {
    const { expandToggleBtn, toggleParentProps } = useAccordion()
    return (
        <div className="rbs-group" {...toggleParentProps}>
            <div className="rbs-group-header">
                {expandToggleBtn}

                <h3 className="rbs-group-title">
                    {rec.name}
                    {Util.isStringExists(rec.subtitle) && <Info16 info={rec.subtitle} />}
                </h3>
                <h3 className="rbs-group-value">SAR {Util.formatMoney(rec.total)} ({rec.count} no)</h3>
            </div>
            <div className="rbs-group-lines">
                {rec.lines.map(line => <SummaryLine line={line} />)}
            </div>
        </div>
    )
}

function SummaryLine({ line }) {
    return (
        <div className="rbs-line">
            <div className="rbs-line-header">
                <h4 className="rbs-line-title">{line.name}</h4>
                <h4 className="rbs-line-value">SAR {Util.formatMoney(line.amount)} ({line.count} no)</h4>
            </div>
        </div>
    )
}

function DetailGroup({ rec }) {
    const { expandToggleBtn, toggleParentProps } = useAccordion()
    return (
        <div className="rbs-detail" {...toggleParentProps}>
            <div className="rbs-detail-header">
                {expandToggleBtn}

                <h3 className="rbs-detail-title">{rec.name}</h3>
                <h3 className="rbs-detail-value">SAR {Util.formatMoney(rec.total)} ({rec.count} no)</h3>
            </div>

            <div className="rbs-detail-subgroups">
                {rec.subgroups.map(sub => <DetailSubgroup sub={sub} displayProps={rec.displayProps} />)}
            </div>
        </div>
    )
}

function DetailSubgroup({ sub, displayProps }) {
    const { expandToggleBtn, toggleParentProps } = useAccordion()
    return (
        <div className="rbs-sub" {...toggleParentProps}>
            <div className="rbs-sub-header">
                {expandToggleBtn}

                <h4 className="rbs-sub-title">{sub.name}</h4>
                <h4 className="rbs-sub-value">SAR {Util.formatMoney(sub.total)} ({sub.count} no)</h4>
            </div>

            <div className="rbs-sub-children">
                <div className="rbs-sub-orders">
                    {sub.orders.map(order => <Order order={order} displayProps={displayProps} />)}
                </div>

                <div className="rbs-sub-footer">
                    <h5 className="rbs-sub-title">Total {sub.name}</h5>
                    <div className="rbs-footer-divider" />
                    <h5 className="rbs-sub-value">SAR {Util.formatMoney(sub.total)} ({sub.count} no)</h5>
                </div>
            </div>
        </div>
    )
}

function Order({ order, displayProps }) {
    const [visible, setVisible] = useState(false);
    return (
        <div className="rbs-order">
            <div className="rbs-order-header">
                <p className="rbs-order-title">
                    Order No:
                    <span onClick={() => setVisible(true)} className="rbs-order-no">
                        {order.orderNo}
                    </span>
                </p>
                <p className="rbs-order-value">SAR {Util.formatMoney(order.amount)}</p>
            </div>

            <div className="rbs-order-props">
                {displayProps.map(prop => (
                    <p>{ORDER_PROP_LABELS[prop]}: {order[prop] || 'N/A'}</p>
                ))}
            </div>

            {visible && (
                <SidePanel md2 backBtn onClose={() => setVisible(undefined)}>
                    <LockScroll />
                    <DeliverableItemPage orderIdOverride={order.id} mainApp={MainApp.instance} />
                </SidePanel>
            )}
        </div>
    )
}



function Summary({ rec, nested }) {
    const { expandToggleBtn, toggleParentProps } = useAccordion()
    return (
        <div className="rbs-summary" {...toggleParentProps}>
            <div className="rbs-summary-header">
                {expandToggleBtn}

                <h3 className={nested ? "rbs-summary-title rbs-summary-title-nested" : "rbs-summary-title"}>
                    {rec.name}
                </h3>
                <h3 className="rbs-summary-value">SAR {Util.formatMoney(rec.total)} ({rec.count} no)</h3>
            </div>

            <div className="rbs-summary-children">
                {/* <div className="rbs-summary-subgroups">
                    {rec.subgroups.map(sub => <DetailSubgroup sub={sub} displayProps={rec.displayProps} />)}
                </div> */}
                {rec.hasGroups && <>
                    <div className="rbs-summary-summary-groups">
                        {rec.groups.map(grp => <SummaryGroup rec={grp} />)}
                    </div>
                </>}
                {rec.hasSummaries && <>
                    <div className="rbs-summary-summaries">
                        {rec.summaries.map(sum => <Summary rec={sum} nested />)}
                    </div>
                </>}
            </div>
        </div>
    )
}