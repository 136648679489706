import { ContentSwitcher, NumberInput, Switch } from "carbon-components-react";
import { useEffect, useState } from "react";
import Button from "../../components/Button";

import Big from 'big.js';
import { VAT } from "../../app/Config";

const MODE_5 = 0;
const MODE_0 = 1;
const MODE_CUSTOM = 2;

export const TAX_MODE = {
    MODE_5, MODE_0
}

const getRate = mode => {
    if (mode == MODE_5) {
        return VAT.FRAC;
    } else {
        return 0;
    }
}

const calcAmount = (amount, taxRate) => {
    if (amount === undefined || amount === null || isNaN(amount) || (amount.trim && amount.trim() === '')) {
        amount = 0;
    }

    //return (parseFloat(taxRate) * amount).toFixed(2);
    //return (taxRate * amount).toFixed(2)
    return Big(taxRate).times(amount).toFixed(2);
}

export function TaxInput({ amount, taxValue, onTaxValueChange, hideModeSwitcher, currency = "SAR" }) {
    const [mode, setMode] = useState(MODE_5)
    const [customValue, setCustomValue] = useState(0);
    const update = () => onTaxValueChange(calcAmount(amount, getRate(mode)));

    useEffect(() => {
        if (mode == MODE_CUSTOM) {
            onTaxValueChange(0)
            setCustomValue(0)
        }
    }, [mode])

    useEffect(() => {
        if (mode != MODE_CUSTOM) {
            update()
        }
    }, [amount, mode])

    return (
        <div>
            <label className="bx--label">{'Tax (+)'}</label>
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                <p>{currency}</p>
                <NumberInput
                    disabled={mode != MODE_CUSTOM}
                    size="lg" hideSteppers min={0} invalidText="Invalid numeric value" value={taxValue} onChange={e => onTaxValueChange(e.target.value < 0 ? 0 : e.target.value)} />

                {!hideModeSwitcher && <ContentSwitcher selectedIndex={mode} onChange={({ index }) => setMode(index)} style={{ width: 300 }}>
                    <Switch text={`${VAT.PERC}%`} />
                    <Switch text="0%" />
                </ContentSwitcher>}
            </div>
        </div>
    )
}

export function NoStateTaxInput({ mode, setMode, amount, taxValue, onTaxValueChange, hideModeSwitcher, currency = "SAR" }) {
    useEffect(() => {
        onTaxValueChange(calcAmount(amount, getRate(mode)))
    }, [amount, mode])

    return (
        <div>
            <label className="bx--label">{'Tax (+)'}</label>
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                <p>{currency}</p>
                <NumberInput disabled
                    size="lg" hideSteppers min={0} invalidText="Invalid numeric value" value={taxValue} />

                {!hideModeSwitcher && <ContentSwitcher selectedIndex={mode} onChange={({ index }) => setMode(index)} style={{ width: 300 }}>
                    <Switch text={`${VAT.PERC}%`} />
                    <Switch text="0%" />
                </ContentSwitcher>}
            </div>
        </div>
    )
}