import { useEffect, useMemo, useRef, useState } from "react";
import Portal from "../../../../util/Portal";
import "./styles.scss"
import { Close16, AirlinePassengerCare20, ArrowRight16, Information16, ViewFilled16, Printer20, ShoppingCart20, Receipt16, Printer16, InformationSquareFilled16, ArrowLeft16 } from '@carbon/icons-react'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { InlineLoading, Tag } from "carbon-components-react";
import Api from "../../../../session/Api";
import { useDebounce } from "../../../../hooks/useDebounce";
import Util from "../../../../util/Util";
import { SidePanel } from "../../../../templates/draft/components/side-panel";
import { ArabicPosReceipt } from "../../../../integrations/thermal-printer/resource/arabic-pos-receipt";
import { PAID_STATUS } from "../../../../constants/Constants";


const useLoadItems = ({ key, searchValue }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        setPage(0);
        setLoading(false);
        setItems([]);
        setHasNextPage(true);
        setError(undefined)
    }, [searchValue, key])

    const loadMore = () => {
        setLoading(true);
        Api.getSavedPosPrints(searchValue, page, response => {
            if (response.status === true) {
                setPage(page + 1);
                setLoading(false);
                setItems(items => [...items, ...response.payload.items])
                setHasNextPage(response.payload.hasMore);
                setError(undefined);
            } else {
                setLoading(false);
                setError(response.message);
            }
        })
    }

    return {
        loading, items, hasNextPage, loadMore, error
    }
}

const SearchBar = ({ visible, value, setValue }) => {
    const searchRef = useRef();
    useEffect(() => {
        if (visible && searchRef.current) {
            searchRef.current.focus();
        }
    }, [visible])
    return <input ref={searchRef} value={value} onChange={e => setValue(e.target.value)} placeholder="Customer name" style={{ width: '100%', height: 45, border: 'none', background: '#f4f4f4', padding: '1rem', outline: 'none' }} />
    // return <input ref={searchRef} value={value} onChange={e => setValue(e.target.value)} placeholder="Click on any to print receipt" style={{ width: '100%', height: 45, border: 'none', background: '#f4f4f4', padding: '1rem', outline: 'none', pointerEvents: 'none' }} />
}

const Item = ({ item, onClick }) => {
    const [showPreview, setShowPreview] = useState(false);


    const values = useMemo(() => {
        let data = [];
        const addToData = (key, mapper) => {
            if (item?.[key]) {
                if (mapper) {
                    data.push(mapper(item[key]));
                } else {
                    data.push(item[key]);
                }
            }
        }

        addToData('customerName')
        addToData('date', date => Util.getDate(date))
        addToData('amount', amount => 'Total: SAR ' + amount)
        addToData('cashAmount', amount => 'Cash: SAR ' + amount)
        addToData('cardAmount', amount => 'Card: SAR ' + amount)
        addToData('otherAmount', amount => 'Other: SAR ' + amount)

        return data.join('  •  ');
    }, [item])
    return (<>
        <div className="item" style={{ minHeight: '4rem', paddingInline: '1rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <ShoppingCart20 />
            <div style={{ flex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <p>Sale #{item.voucherNo}</p>
                    {item.paidStatus === PAID_STATUS.PAID && <Tag size="sm" type="green">Paid</Tag>}
                    {item.paidStatus === PAID_STATUS.PARTIAL && <Tag size="sm" type="high-contrast">Partially Paid</Tag>}
                    {item.paidStatus === PAID_STATUS.NOT_PAID && <Tag size="sm" type="red">Not Paid</Tag>}
                </div>
                <p style={{ fontSize: 14, color: '#0f62fe' }}>{values}</p>
            </div>

            <button onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
            }} className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                <Printer16 />
            </button>

            <button onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setShowPreview(true)
            }} className='lead-flow-icon-button lead-flow-icon-button-primary'>
                <ViewFilled16 />
            </button>
            {/* < /> */}
        </div>
        <div style={{ width: 'calc(100% - (1.5rem * 2))', marginLeft: '1.5rem', marginTop: -1, height: 1, background: '#00000020' }} />

        {showPreview && <Portal>
            <SidePanel fullHeight highZ onClose={() => setShowPreview(false)} background="transparent">
                {/* <LockScroll />/ */}
                <div onClick={() => setShowPreview(false)} style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
                    <div onClick={e => e.stopPropagation()} style={{ position: 'relative', background: 'white', padding: '1rem', boxShadow: '0px 8px 17px 2px rgba(0,0,0,0.14) , 0px 3px 14px 2px rgba(0,0,0,0.12) , 0px 5px 5px -3px rgba(0,0,0,0.2) ' }}>
                        <ArabicPosReceipt backBtn request={JSON.parse(item.print)} />

                        <button style={{
                            position: 'absolute', left: 0, transform: 'translateX(-50%)', top: '1.5rem',
                            animation: '250ms actual-opacity-fade-in-anim', width: 45, height: 45,
                        }} onClick={() => setShowPreview(false)} className='lead-flow-icon-button lead-flow-icon-button-primary'>
                            <ArrowLeft16 />
                        </button>
                    </div>
                </div>
            </SidePanel>
        </Portal>}
    </>)
}

export function PrintSearchDialog({ visible, onClose, onPrintSelected }) {
    const [searchValue, setSearchValue] = useState("");
    const [key, setKey] = useState(() => Util.newTempId())
    const debouncedSearch = useDebounce(searchValue, 500)
    const { loading, items, hasNextPage, error, loadMore } = useLoadItems({
        key, searchValue: Util.isStringExists(searchValue) ? debouncedSearch : ""
    });

    useEffect(() => {
        if (visible) {
            setKey(Util.newTempId());
            setSearchValue("");
        }
    }, [visible])

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <Portal>
            <div className="print-search-dialog">
                <div className={"backdrop " + (visible ? 'backdrop-visible' : "backdrop-hidden")} onClick={onClose} />

                <div className={"card " + (visible ? "card-visible" : "card-hidden")}>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', padding: '1rem' }}>
                        <h4 style={{ fontWeight: 'bold', flex: 1 }}>Sales History</h4>
                        <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                            <Close16 />
                        </button>
                    </div>
                    <SearchBar value={searchValue} setValue={setSearchValue} />
                    <div ref={rootRef} style={{ flex: 1, overflow: 'auto', width: '100%' }}>
                        {items.map(item => (
                            <Item key={item.id} item={item} onClick={() => {
                                onClose();
                                onPrintSelected(item);
                            }} />
                        ))}
                        <div ref={sentryRef} />

                        {(loading || hasNextPage) ? (
                            <div className="really_centered-progress-bar" style={{ height: '3rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                                <InlineLoading style={{ width: 'unset' }} />
                                <p style={{ fontSize: 12, opacity: 0.65 }}>Loading...</p>
                            </div>
                        ) : (
                            <div className="really_centered-progress-bar" style={{ height: '3rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                                <p style={{ fontSize: 12, opacity: 0.65 }}>{items.length ? 'No more items' : 'No items'}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Portal>
    )
}