import { User24, Add16, Search16, ErrorFilled16, VoiceActivate16, UserRole16, Identification16, InformationSquareFilled16, Launch16 } from '@carbon/icons-react'
import React, { useEffect, useState, useRef } from 'react'
import ProfilePic from '../../../components/ProfilePic'
import Button from '../../../components/Button'
import Util from '../../../util/Util'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import { TextInput } from 'carbon-components-react'
import useSize from '../../../util/useSize'
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { getTerminal } from '../../../session/SessionManager'
import { SidePanel } from '../../../templates/draft/components/side-panel'
import CustomerDetailPage from '../../customer/CustomerDetailPage'
import Portal from '../../../util/Portal'
import { CustomerSearchDialog } from '../dialogs/customer-search-dialog'

const HEIGHT_MIN = 80;
const HEIGHT_MID = 136;

const OPTIMAL_WIDTH = 452;
const OPTIMAL_HEIGHT = 180;

const Content = ({ size, state, light, hideTitle, hasCustomer, searchValue, setSearchValue, searchingCustomer, setSearchingCustomer, removingCustomer, setRemovingCustomer, onSearchCustomerBtn, onRemoveCustomerBtn, mini }) => {
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);

    const [openingCustomerQrCode, setOpeningCustomerQrCode] = useState(false);
    const [closingCustomerQrCode, setClosingCustomerQrCode] = useState(false);
    const [openingCustomerSearch, setOpeningCustomerSearch] = useState(false);

    const setCustomer = customer => {
        setSearchingCustomer(true)
        state.api.setPosCustomer(customer.id, response => {
            setSearchingCustomer(false);
            if (response.status === true) {
                state.onUpdateSession(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
        })
    }


    return (<>
        {!hideTitle && size.height >= HEIGHT_MID && <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <User24 style={{ marginRight: 5 }} />
            <h4>Customer</h4>
        </div>}

        <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <ProfilePic size={45} style={{marginRight: '1rem'}} /> */}
            <div style={(mini && size.height < HEIGHT_MIN) ? { display: 'flex', width: '100%' } : {}}>
                <div style={(mini && size.height < HEIGHT_MIN) ? { flex: 1 } : {}}>
                    <p style={{ fontStyle: 'bold' }}>{hasCustomer ? state.getSession().customerName : 'Guest'}</p>
                    {hasCustomer && state.hasLoyaltyCard() && <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, color: 'green' }}>
                            {state.getSession().customerLoyaltyPoints.toFixed(2)} points
                        </p>
                        <div style={{ width: 4, height: 4, borderRadius: '50%', marginLeft: 5, marginRight: 5, background: 'green' }} />
                        <p style={{ fontSize: 12, color: 'green' }}>
                            SAR {state.getSession().customerLoyaltyMoney.toFixed(2)}
                        </p>
                    </div>}
                </div>

                {(mini && size.height < HEIGHT_MIN) && <Button onClick={mini} hasIconOnly data-tip="Open" className="bx--tooltip--hidden" renderIcon={Launch16} style={{ height: 48 }} />}
                {hasCustomer && !state.hasLoyaltyCard() && <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, color: 'black', opacity: 0.65 }}>
                        No loyalty card
                    </p>
                </div>}
            </div>
        </div>

        {!state.readOnly && size.height >= HEIGHT_MIN && <>
            <div style={{ flex: 1 }} />

            {hasCustomer ? (<div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Button loading={removingCustomer} onClick={onRemoveCustomerBtn} kind="danger" style={{ flex: 1 }} renderIcon={ErrorFilled16}></Button>
                <div style={{ width: 5 }} />
                <Button
                    loading={openingCustomerQrCode}
                    onClick={() => {
                        setOpeningCustomerQrCode(true)
                        Api.openCustomerQrCodeDialog(getTerminal().id, response => {
                            setOpeningCustomerQrCode(false);
                            if (response.status === true) {
                                UIUtil.showSuccess();
                            } else {
                                UIUtil.showError(response.payload)
                            }
                        })
                    }}
                    style={{ height: 40 }}
                    className="bx--tooltip--hidden"
                    renderIcon={Identification16} hasIconOnly data-tip="Show Card" />

                <div style={{ width: 5 }} />
                <Button
                    loading={closingCustomerQrCode}
                    onClick={() => {
                        setClosingCustomerQrCode(true)
                        Api.closeCustomerQrCodeDialog(getTerminal().id, response => {
                            setClosingCustomerQrCode(false);
                            if (response.status === true) {
                                UIUtil.showSuccess();
                            } else {
                                UIUtil.showError(response.payload)
                            }
                        })
                    }}
                    style={{ height: 40 }}
                    className="bx--tooltip--hidden"
                    kind="secondary"
                    renderIcon={UserRole16} hasIconOnly data-tip="Close Card" />

                <div style={{ width: 5 }} />
                <Button
                    onClick={() => setShowCustomerInfo(true)}
                    style={{ height: 40 }}
                    className="bx--tooltip--hidden"
                    renderIcon={InformationSquareFilled16} hasIconOnly data-tip="Information" />
            </div>) : (
                //<Button onClick={onSearchCustomerBtn} style={{width: '100%'}} renderIcon={User16} kin>Set Customer</Button>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* <TextInput style={{ height: 48 }} light labelText="Search Customer" placeholder="Email or number" value={searchValue} onChange={e => setSearchValue(e.target.value)} /> */}
                    <TextInput style={{ height: 48 }} light labelText="Search Customer" placeholder="Email or number" value={searchValue} onChange={e => { }} onClick={() => setSearchVisible(true)} />
                    <div style={{ width: 5 }} />
                    <Button //disabled={!Util.isStringExists(searchValue)} 
                        onClick={onSearchCustomerBtn}
                        loading={searchingCustomer}
                        disabled={state.isCreatingCustomerLoading() || !Util.isStringExists(searchValue)}
                        kind="secondary" style={{ height: 40 }}
                        className="bx--tooltip--hidden" renderIcon={Search16} hasIconOnly data-tip="Search Customer" />
                    <div style={{ width: 5 }} />



                    <Button
                        loading={openingCustomerSearch}
                        onClick={() => {
                            setOpeningCustomerSearch(true)
                            Api.openCustomerSearchDialog(getTerminal().id, true, response => {
                                setOpeningCustomerSearch(false);
                                if (response.status === true) {
                                    UIUtil.showSuccess();
                                } else {
                                    UIUtil.showError(response.payload)
                                }
                            })

                            state.setState({ showSearchCustomerDialog: true, searchCustomerValue: "" })
                        }}
                        style={{ height: 40 }}
                        className="bx--tooltip--hidden"
                        renderIcon={VoiceActivate16} hasIconOnly data-tip="Ask Customer" />

                    <div style={{ width: 5 }} />

                    <Button onClick={() => state.setShowCustomerCreatorDialog(true)}
                        disabled={searchingCustomer}
                        loading={state.isCreatingCustomerLoading()}
                        style={{ height: 40 }}
                        className="bx--tooltip--hidden"
                        renderIcon={Add16} hasIconOnly data-tip="Create Customer" />


                </div>
            )}
        </>}

        {showCustomerInfo && <Portal>
            <SidePanel fullscreen md2 onClose={() => setShowCustomerInfo(false)}>
                <CustomerDetailPage
                    onSave={update => state.reloadSession()}
                    pagePathParamsOverride={{ itemId: state.getSession().customerId }} overrideBacktToListBtn={() => setShowCustomerInfo(false)} />
            </SidePanel>
        </Portal>}

        <CustomerSearchDialog visible={searchVisible} onClose={() => setSearchVisible(false)} onCustomerSelected={setCustomer} />
    </>)
}


export default ({ state, light, hideTitle, responsive }) => {
    const hasCustomer = Util.isNumberExist(state.getSession().customerId);

    const [searchValue, setSearchValue] = useState("");

    const [searchingCustomer, setSearchingCustomer] = useState(false);
    const [removingCustomer, setRemovingCustomer] = useState(false);

    useEffect(() => {
        if (hasCustomer && Util.isStringExists(searchValue)) {
            setSearchValue("");
        }
    }, [hasCustomer])

    const onSearchCustomerBtn = () => {
        //REPEATED IN POS DIALOGS FOR SEARCHING DIALOG
        setSearchingCustomer(true);
        Api.searchPosCustomer(searchValue, response => {
            setSearchingCustomer(false);
            if (response.status === true) {
                setSearchValue("")
                //state.onCustomerUpdate(response.payload)
                state.onUpdateSession(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    const onRemoveCustomerBtn = () => {
        UIUtil.confirm(() => {
            setRemovingCustomer(true)
            Api.clearPosCustomer(response => {
                setRemovingCustomer(false);
                if (response.status === true) {
                    //state.onCustomerUpdate()
                    state.onUpdateSession(response.payload)
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        })
    }


    const targetRef = useRef();
    const size = useSize(targetRef)
    const content = <Content {...({ size: responsive ? size : { height: 10000, width: 10000 }, state, light, hideTitle, hasCustomer, searchValue, setSearchValue, searchingCustomer, setSearchingCustomer, removingCustomer, setRemovingCustomer, onSearchCustomerBtn, onRemoveCustomerBtn })} />

    const [contentOpen, setContentOpen] = useState(false)

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [size])

    return (
        <div className="dashboard-card" ref={targetRef} style={{ width: '100%', height: '100%', background: light ? 'white' : '#f4f4f4', padding: '1rem', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            {responsive ? (
                size.height > HEIGHT_MIN ? (
                    content
                ) : (<>
                    {React.cloneElement(content, { mini: () => setContentOpen(true) })}

                    <Modal
                        isOpen={contentOpen}
                        onRequestClose={() => setContentOpen(false)}
                        style={{
                            overlay: { zIndex: 8999, background: 'rgba(0,0,0,0.75)' },
                            content: {
                                height: OPTIMAL_HEIGHT, width: OPTIMAL_WIDTH, background: 'rgb(244, 244, 244)', border: 'none',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex', flexDirection: 'column', borderRadius: 0
                            }
                        }}
                    >
                        {React.cloneElement(content, { size: { height: 10000, width: 10000 } })}
                    </Modal>
                </>)
            ) : (
                content
            )}

        </div>
    )
}