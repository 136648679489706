import { Tag } from "carbon-components-react";
import { withLoadablePagePropsAndProps } from "../../../../base/Page";
import useStore from "../../../../hooks/useStore";
import Api from "../../../../session/Api";
import { makeObservable } from "../../../../util/makeObservable";
import { useCallback, useEffect, useMemo, useState } from "react";
import Util, { big } from "../../../../util/Util";
import ProfilePic from "../../../../components/ProfilePic";
import { OBJECT_TYPE_PRODUCT_BUNDLE } from "../../../../constants/ObjectTypes";
import { DataVis_416, TableSplit16 } from '@carbon/icons-react'

const CheckIndicator = ({ checked, }) => (
    <div style={{ width: 20, height: 20, borderRadius: 40, border: '1px solid #00000020', background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
        <div style={{ transition: '250ms', width: '100%', height: '100%', borderRadius: 40, background: checked ? 'green' : 'transparent' }} />
    </div>
)


function Option({ option, selected, setSelected, mode }) {
    const showImage = mode === 0;
    return (
        <button onClick={() => setSelected(!selected)} style={{
            borderRadius: 7, paddingInline: '1rem', paddingBlock: '0.5rem',
            display: 'flex', alignItems: 'center', gap: '1rem', transition: '250ms',
            ...(showImage ? {
                paddingLeft: 0,
                marginLeft: '1rem',
            } : {}),
            ...(selected ? {
                background: '#e6ffe6',
                boxShadow: '3px 4px 0px 0px #046300',
                border: '1px solid #046300',
            } : {
                background: '#f4f4f4',
                border: '1px solid #1c1c1c60',
            })
        }}>
            {showImage && <ProfilePic notProfile size={64} style={{
                borderRadius: 7, marginLeft: '-1rem', width: 96, transition: '250ms',
                transform: `scale(${selected ? '1.05' : '1'})`
            }} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, option.foodItemId)} />}
            <p style={{ textAlign: 'left', flex: 1, minWidth: 0, wordBreak: 'break-word' }}>{option.foodItemName}</p>
            <CheckIndicator checked={selected} />
        </button>
    )
}



function Options({ options, isSelected, setSelected, mode }) {
    return (
        <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', paddingInline: '0.5rem' }}>
            {options
                .map(option => (
                    <Option
                        key={option.id}
                        option={option}
                        selected={isSelected(option)}
                        setSelected={val => setSelected(option, val)}
                        mode={mode}
                    />
                ))}
        </div>
    )
}

function MenuButton({ label, active, onClick, fullWidth, children }) {
    return <button style={{ width: fullWidth ? 30 : 100, paddingInline: fullWidth ? 0 : undefined }} onClick={onClick} data-active={active} className='report-engine-menu-button-light'>{children}</button>
}


function Section({ section, mode, isSelected, setSelected }) {
    const [selectCount, selectStatusType] = useMemo(() => {
        const count = section.options.filter(opt => isSelected(opt)).length

        if (count === 0) {
            return [count, 'gray']
        } else if (count === section.maxSelection) {
            return [count, 'green']
        } else if (count < section.maxSelection) {
            return [count, 'high-contrast']
        } else {
            return [count, 'magenta']
        }
    }, [section, isSelected])

    const maxReached = selectStatusType === 'magenta';

    return (
        <div key={section.id} style={{ paddingBottom: '3rem' }}>
            <div style={{
                borderRadius: 7, paddingInline: '1rem', paddingBlock: '0.5rem',
                // background: '#1c1c1c',
                // boxShadow: '3px 4px 0px 0px #1c1c1c60',
                // border: '1px solid #1c1c1c60',
                border: '1px solid #00000015',
                display: 'flex', alignItems: 'center', gap: '1rem',
                marginBottom: '1rem',

                position: 'sticky', top: '0rem',
                background: '#ffffffAA', backdropFilter: 'saturate(200%) blur(12px)', zIndex: 2
            }}>
                <h3 style={{ color: 'black', fontSize: 18 }}>{section.name}</h3>
                <div style={{ background: maxReached ? '#ffeded' : '#f4f4f4', borderRadius: 5, border: '1px solid #00000020', paddingInline: '1rem', transition: '250ms' }}>
                    <p style={{ fontSize: 14, fontWeight: 'bold', color: maxReached ? '#990000' : 'black', transition: '250ms' }}>up to {section.maxSelection}</p>
                </div>
                <div style={{ flex: 1 }} />
                <Tag style={{ transition: '250ms' }} type={selectStatusType}>{selectCount} selected</Tag>
            </div>


            <Options
                options={section.options}
                isSelected={isSelected}
                setSelected={setSelected}
                mode={mode}
            />
        </div>
    )
}


function Sections({ sections, isSelected, setSelected }) {
    const [mode, setMode] = useState(0)

    return (
        <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <div style={{ flex: 1 }}>
                    <h4 style={{ fontWeight: 'bold' }}>Event Menu</h4>
                    <p style={{ opacity: 0.65 }}>Find your experience</p>
                </div>
                <div style={{ borderRadius: 7, background: '#00000005', border: '1px solid #00000010', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                    <MenuButton active={mode === 0} onClick={() => setMode(0)}><DataVis_416 style={{ marginRight: 2 }} /> Vibrant</MenuButton>
                    <MenuButton active={mode === 1} onClick={() => setMode(1)}><TableSplit16 style={{ marginRight: 2 }} /> Simple</MenuButton>
                </div>
            </div>


            {sections.map((section, index) => (
                <Section key={section.id} {...{ section, isSelected, setSelected, mode }} />
            ))}
        </div>
    )
}


function Summary({ sections, ratePerGuest, noOfGuests, isSelected, setSelected }) {
    const total = useMemo(() => big(ratePerGuest).times(big(noOfGuests)).round(2).toNumber(), [ratePerGuest, noOfGuests])

    return (
        <div style={{
            background: "#f4f4f4", width: 400, padding: '1rem',
            borderBottomLeftRadius: 7, borderTopLeftRadius: 7,
            border: '1px solid #1c1c1c20', borderRight: 'none',
            // boxShadow: '3px 4px 0px 0px #1c1c1c60',
            // border: '1px solid #1c1c1c60',
            position: 'sticky', top: '1rem'
        }}>
            <h3 style={{ marginBottom: '1rem' }}>Summary</h3>

            {sections.map(section => (
                <div key={section.id} style={{ marginBottom: '1rem' }}>
                    <h5>{section.name}</h5>
                    <ul style={{ listStyle: 'inside', paddingInline: '0.5rem' }}>
                        {section.options.filter(option => isSelected(option)).map((option, index) => (
                            <li key={option.id} style={{
                                marginTop: index > 0 ? '0.5rem' : 0,
                                fontSize: 16,
                            }}>{option.foodItemName}</li>
                        ))}
                        {section.options.filter(option => isSelected(option)).length === 0 && <>
                            <li style={{
                                fontStyle: 'italic',
                                opacity: 0.65,
                                fontSize: 16,
                            }}>Nothing Selected</li>
                        </>}
                    </ul>
                </div>
            ))}

            <h5 style={{ marginTop: '0.5rem' }}>SAR {Util.formatMoney(ratePerGuest)} x {noOfGuests} guests</h5>
            <h3 style={{ color: 'green' }}>SAR {Util.formatMoney(total)}</h3>
        </div>
    )
}

function View({
    noOfGuests, store,
    payload: { item: sections, endpoint: { ratePerGuest } }
}) {
    const [value, setValue] = useStore(store, 'menuSelections');

    const isSelected = option => value.some($ => $.menuOptionId === option.id);
    const setSelected = useCallback((option, selected) => selected
        ? setValue(prev => [...prev.filter($ => $.menuOptionId !== option.id), { menuOptionId: option.id }])
        : setValue(prev => [...prev.filter($ => $.menuOptionId !== option.id)]), [setValue])

    return (
        <div className="rbt-menu-selector-com" style={{ display: 'flex', gap: '1rem', width: 'calc(100% + 1rem)', alignItems: 'flex-start', background: 'white' }}>
            <Sections sections={sections} isSelected={isSelected} setSelected={setSelected} />
            <Summary sections={sections} ratePerGuest={ratePerGuest} noOfGuests={noOfGuests} isSelected={isSelected} setSelected={setSelected} />
        </div >
    )
}

const MenuSelector = withLoadablePagePropsAndProps(props => listener => Api.getFnbMenuSections(props.menuId, false, listener), View)


export function MenuSelectorFormField({ store }) {
    const [menuId] = useStore(store, 'menuId');
    const [noOfGuests] = useStore(store, 'noOfGuests')

    useEffect(() => {
        store.set('menuSelections', []);
    }, [menuId])

    return <MenuSelector key={'MenuSelectorFormField-' + menuId} menuId={menuId} noOfGuests={noOfGuests} store={store} />
}

