import { useState } from "react"
import Button from "../../../components/Button"
import { MinimalTable } from "../../../components/minimal-table"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import UIUtil from "../../../util/UIUtil"
import Util, { big, isV2 } from "../../../util/Util"
import { getReportLink, listDetailItemId } from "../../base/report-page"
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"
import { DateComparison } from "../../components/basic-filter/date-comparison"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select"
import { TextBox } from "../../components/basic-filter/text-box"
import { Finance16 } from '@carbon/icons-react'
import Api from "../../../session/Api"
import { useHistory } from "react-router-dom"

export const VATSummary = {
    filter: Filter,
    payload: Payload,
    hasPayloadBar: true,

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" />
        <DateComparison state={state} />

        {/* <Divider />
        <div style={{ height: 30, marginTop: '0rem', flex: 2 }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div> */}
    </>)
}


function Payload({ payload }) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const onBtn = async () => {
        if (Util.isStringExists(payload.fileErr)) {
            UIUtil.showInfo(payload.fileErr)
            return;
        }

        const confirm = await UIUtil.confirmPrompt({
            title: "Are you sure?",
            positiveAction: "Create Bill",
            messageNode: (<div>
                <h4>Please confirm to create bill</h4>

                <MinimalTable
                    items={[
                        { name: 'VAT Payable', amt: payload.payable },
                        { name: 'VAT Receivable', amt: payload.receivable },
                        {},
                        { name: 'Bill Amount', amt: payload.amount },
                    ]}
                    columns={[
                        {
                            name: "name",
                            title: "Name",
                            flex: 1,
                            renderCell: item => !item.name ? '' : item.name
                        },
                        {
                            name: "amt",
                            title: "Amount",
                            flex: 1,
                            renderCell: item => !item.name ? '' : 'SAR ' + Util.formatMoney(item.amt)
                        }
                    ]}
                />
            </div>)
        })

        if (!confirm) {
            return;
        }

        setLoading(true);
        try {
            const billId = await Api.asyncCreateTaxBill(payload.receivable, payload.payable);
            history.push(listDetailItemId('V2BillList', billId));
            UIUtil.showSuccess();
        } catch (e) {
            UIUtil.showError(e?.roboErpApiErr ? e.msg : "");
        } finally {
            setLoading(false);
        }
    }
    return (<>
        <div style={{ flex: 1 }} />
        <Button loading={loading} onClick={onBtn} size="sm" style={{ borderRadius: 7 }} renderIcon={Finance16}>File Taxes</Button>
    </>)
}