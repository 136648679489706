import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet, pdf, usePDF, Svg, Path, Font } from '@react-pdf/renderer';
import Util, { big } from '../../../../../../util/Util';
import Logo from '../../../../../../images/doc-logo.png'
import MainStamp from '../../../../../../images/other/excells/excells-stamp.png'
import InsuranceStamp from '../../../../../../images/other/excells/insurance-stamp.png'
import { PDFTable } from '../../../../../../pdfs/invoice-document/pdf/Invoice';
import Api from '../../../../../../session/Api';
import { stringifyBlueprintInputFields } from '../../../../../activities/components/blueprint-input-fields-read';
import QRCode from 'qrcode'
import { ToWords } from 'to-words';
import UIUtil from '../../../../../../util/UIUtil';
import { getBLNo } from '../../../../../../domain/shipment';
import { isPicard } from '../../../../../../app/app-util';
import picardlogo from '../../../../../../images/other/picard/picardlogo.png'

const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
        currency: false,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
    }
});

const numToWord = num =>
    toWords.convert(num)
        .toLowerCase()
        .replace("dollar", "riyal")
        .replace("cent", "halala");

const GRAY = "#F2F2F2";
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
    }
});

const Section = ({ title, content, borderBottom, children }) => (
    <View style={{ borderBottom }}>
        <View style={{ paddingLeft: 2, paddingBottom: 2, paddingTop: 2, width: '100%', backgroundColor: '#F2F2F2', alignItems: 'flex-start', justifyContent: 'center' }}>
            <Text style={{ fontSize: 7, margin: 0, lineHeight: 0, fontFamily: "Helvetica" }}>{title}</Text>
        </View>
        <Text style={{ fontSize: 8, fontFamily: 'Helvetica-Bold', paddingTop: 5, paddingBottom: 2, paddingLeft: 2, paddingRight: 5 }}>{content}</Text>
    </View>
)

const CenterBlock = ({ title, content, lg, children }) => (
    <View>
        <View style={{ paddingLeft: 2, paddingBottom: 2, paddingTop: 2, width: '100%', backgroundColor: '#F2F2F2', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ fontSize: 7, margin: 0, lineHeight: 0, fontFamily: "Helvetica" }}>{title}</Text>
        </View>
        <View style={{ height: 30, paddingLeft: 2, paddingRight: 2, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            {typeof content === "string" ? (
                <Text style={{ fontSize: lg ? 10 : 9, fontFamily: 'Helvetica-Bold', lineHeight: 0, margin: 0 }}>{content}</Text>
            ) : content}
        </View>
    </View>
)

const TableColHeader = ({ flex, title, last, borderBottom }) => (
    <View style={{ flex, borderBottom, backgroundColor: GRAY, paddingTop: 2, paddingBottom: 2, paddingLeft: 5, paddingRight: 5, alignItems: 'center', justifyContent: 'center', borderRight: !last ? '1px solid black' : undefined }}>
        <Text style={{ fontSize: 7, margin: 0, textAlign: 'center', lineHeight: 0, fontFamily: "Helvetica" }}>{title}</Text>
    </View>
)


const TableColContent = ({ flex, title, textAlign = "left" }) => (
    <View style={{ flex, paddingTop: 2, paddingBottom: 2, paddingLeft: 5, paddingRight: 5, alignItems: 'flex-start', justifyContent: 'center', }}>
        <Text style={{ fontSize: 7, margin: 0, textAlign, lineHeight: 0, fontFamily: "Helvetica", width: '100%' }}>{title}</Text>
    </View>
)


Font.register({
    family: 'adobe-devanagari',
    src: '//use.typekit.net/af/33f24f/00000000000000007735a303/30/d?subset_id=2&fvd=n4&v=3',
});
Font.register({
    family: 'adobe-devanagari',
    src: '//use.typekit.net/af/24d77f/00000000000000007735a2ff/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3',
    fontStyle: "italic"
});
Font.register({
    family: 'adobe-devanagari',
    src: '//use.typekit.net/af/828b75/00000000000000007735a302/30/d?subset_id=2&fvd=n7&v=3',
    fontWeight: "bold",
});
Font.register({
    family: 'adobe-devanagari',
    src: '//use.typekit.net/af/aca257/00000000000000007735a2fc/30/d?subset_id=2&fvd=i7&v=3',
    fontWeight: "bold",
    fontStyle: "italic"
});


const companyInfo = "P.O.BOX: 63067, DUBAI - U.A.E\nTEL: +971 4 2209620, FAX: +971 4 2209610\nEmail: info@excellsshipping.com\nWebsite: www.excellsshipping.com"
const message = "Received the carrier the goods as specified above in apparent good order and condition unless otherwise stated, to betransported to such places as agreed, authorized or permitted hererin and subject to all the items and conditions appearing on the front and reverse of this bill of lading to which the merchant agrees by acceptiong this Bill of lading. any local privilages and customsnotwithstanding\n" +
    "The particulars given above as stated by the shipper and the weight, measure, quantity, condition, content and the value of the Goods are unknown by the carrier.\n" +
    "In witness whereof the number of original Bills of lading, stated above have been signed all of this tenor and date one of which being accomplished, the other(s) to be void";

function entityInfo({ name, address, poBox, nationalId, tel, fax, email, website, note }) {
    let text = name;
    if (Util.isStringExists(address) || Util.isStringExists(poBox)) {
        if (Util.isStringExists(address) && Util.isStringExists(poBox)) {
            text += `\nAdd: ${address}, PO BOX: ${poBox}`;
        } else if (Util.isStringExists(address)) {
            text += `\nAdd: ${address}`;
        } else {
            text += `\nPO BOX: ${poBox}`;
        }
    }
    if (Util.isStringExists(nationalId)) {
        text += "\nNational ID: " + nationalId;
    }
    if (Util.isStringExists(tel) || Util.isStringExists(fax)) {
        if (Util.isStringExists(tel) && Util.isStringExists(fax)) {
            text += `\nTel: ${tel}, Fax: ${fax}`;
        } else if (Util.isStringExists(tel)) {
            text += `\nTell: ${tel}`;
        } else {
            text += `\nFax: ${fax}`;
        }
    }
    if (Util.isStringExists(email)) {
        text += "\nEmail: " + email;
    }
    if (Util.isStringExists(website)) {
        text += "\nWebsite: " + website;
    }
    if (Util.isStringExists(note)) {
        text += "\n" + note;
    }
    return text;
}

function consignorMessage(customer, note) {
    if (customer) {
        return entityInfo({
            name: customer.fullName,
            address: customer.address,
            poBox: customer.poBox,
            nationalId: customer.nationalId,
            tel: customer.phoneNumber,
            fax: customer.fax,
            email: customer.email,
            website: customer.website,
            note,
        })
    } else {
        return "";
    }
}

function consigneeMessage(item, note) {
    if (item) {
        return entityInfo({
            name: item.name,
            address: item.address,
            poBox: item.poBox,
            nationalId: item.nationalId,
            tel: item.phoneNumber,
            fax: item.faxNumber,
            email: item.email,
            website: item.website,
            note,
        })
    } else {
        return "";
    }
}

function notifyParent(item) {
    if (!item.notifyPartySameAsConsignee) {
        if (Util.isStringExists(item.notifyPartyName)) {
            return entityInfo({
                name: item.notifyPartyName,
                address: item.notifyPartyAddress,
                poBox: item.notifyPartyPoBox,
                nationalId: item.notifyPartyNationalId,
                tel: item.notifyPartyTel,
                fax: item.notifyPartyFax,
                email: item.notifyPartyEmail,
                website: item.notifyPartyWebsite,
                note: item.notifyPartyNote,
            })

            // let text = item.notifyPartyName;
            // text += "\n" + (item.notifyPartyAddress ?? "");
            // text += "\nP.O. Box: " + (item.notifyPartyPoBox ?? "");
            // text += "\nTel: " + (item.notifyPartyTel ?? "") + ", " + "Fax: " + (item.notifyPartyFax ?? "")
            // return text;
        } else {
            return ""
        }
    } else {
        return "SAME AS CONSIGNEE";
    }
}

function deliveryAgent(item, note) {
    if (item) {
        return entityInfo({
            name: item.name,
            address: item.address,
            poBox: item.poBox,
            nationalId: item.nationalId,
            tel: item.phoneNumber,
            fax: item.faxNumber,
            email: item.email,
            website: item.website,
            note,
        })
    } else {
        return "";
    }
}


// function deliveryAgent(item) {
//     if (Util.isStringExists(item.deliveryAgentName)) {
//         return entityInfo({
//             name: item.deliveryAgentName,
//             address: item.deliveryAgentAddress,
//             poBox: item.deliveryAgentPoBox,
//             nationalId: item.deliveryAgentNationalId,
//             tel: item.deliveryAgentTel,
//             fax: item.deliveryAgentFax,
//             email: item.deliveryAgentEmail,
//             website: item.deliveryAgentWebsite,
//             note: item.deliveryAgentNote,
//         })
//         // let text = item.deliveryAgentName;
//         // text += "\n" + (item.deliveryAgentAddress ?? "");
//         // text += "\nP.O. Box: " + (item.deliveryAgentPoBox ?? "");
//         // text += "\nTel: " + (item.deliveryAgentTel ?? "") + ", " + "Fax: " + (item.deliveryAgentFax ?? "")
//         // return text;
//     } else {
//         return ""
//     }
// }


const TableRow = ({ item, index, itemBlNo, hasBottomRow }) => {
    const totalWeight = item.items
        .map($ => $.weight)
        .filter(val => Util.isNumberExist(val))
        .map(val => big(val))
        .reduce((t, c) => t.add(c), big(0))
        .round(2).toNumber()

    const totalMeasurement = item.items
        .map($ => [$.length, $.width, $.height])
        .filter(val => Util.isNumberExist(val[0]) && Util.isNumberExist(val[1]) && Util.isNumberExist(val[2]))
        .map(val => big(val[0]).times(big(val[1])).times(big(val[2])))
        .reduce((t, c) => t.add(c), big(0))
        .round(2).toNumber()


    const totalQty = item.items
        .map($ => $.quantity)
        .filter(val => Util.isNumberExist(val))
        .map(val => big(val))
        .reduce((t, c) => t.add(c), big(0))
        .round(2).toNumber()

    const descOfGoods = item.items
        //.map(item => stringifyBlueprintInputFields(item.formFieldValues))
        //.map(item => itemBlNo(item) + '\n' + (item.remark ?? ""))
        .map(item => (item.remark ?? ""))
        .filter(Util.isStringExists)
        .join("\n\n")

    const record = {
        no: index + 1,
        containerNo: item.containerNo,
        sealNo: item.sealNo,
        //qty: totalQty + ' unit' + (totalQty != 1 ? 's' : ''),
        qty: totalQty,
        description: descOfGoods,
        remark: item.remark,
        totalWeight: Util.formatAmount(totalWeight),
        totalMeasurement: Util.formatAmount(totalMeasurement),
    }

    const showDisplayQty = item.items.length === 1 && Util.isStringExists(item.items[0].displayQuantity)

    let marksAndNumbers = [];
    if (Util.isStringExists(record.containerNo)) {
        marksAndNumbers.push('Container no: ' + record.containerNo)
    }
    if (Util.isStringExists(record.sealNo)) {
        marksAndNumbers.push('Seal no: ' + record.sealNo)
    }
    if (Util.isStringExists(record.remark)) {
        marksAndNumbers.push('Remarks: ' + record.remark)
    }

    return (
        <View style={{ flexDirection: 'row', borderBottom: hasBottomRow ? '0.5px solid #878787' : undefined }}>
            <TableColContent flex={2.5} title={marksAndNumbers.join("\n")} />
            <TableColContent flex={2} title={showDisplayQty ? item.items[0].displayQuantity : record.qty} textAlign='center' />
            <TableColContent flex={3.6} title={record.description} />
            <TableColContent flex={1.5} title={record.totalWeight} textAlign='center' />
            <TableColContent flex={1.5} last title={record.totalMeasurement} textAlign='center' />
        </View>
    )
}



const RiderSheetTableRow = ({ item, index, itemBlNo }) => {
    const totalWeight = item.items
        .map($ => $.weight)
        .filter(val => Util.isNumberExist(val))
        .map(val => big(val))
        .reduce((t, c) => t.add(c), big(0))
        .round(2).toNumber()

    const totalMeasurement = item.items
        .map($ => [$.length, $.width, $.height])
        .filter(val => Util.isNumberExist(val[0]) && Util.isNumberExist(val[1]) && Util.isNumberExist(val[2]))
        .map(val => big(val[0]).times(big(val[1])).times(big(val[2])))
        .reduce((t, c) => t.add(c), big(0))
        .round(2).toNumber()


    const totalQty = item.items
        .map($ => $.quantity)
        .filter(val => Util.isNumberExist(val))
        .map(val => big(val))
        .reduce((t, c) => t.add(c), big(0))
        .round(2).toNumber()

    const descOfGoods = item.items
        //.map(item => stringifyBlueprintInputFields(item.formFieldValues))
        // .map(item => item.remark)
        // .map(item => itemBlNo(item) + '\n' + (item.remark ?? ""))
        .map(item => (item.remark ?? ""))
        .filter(Util.isStringExists)
        .join("\n\n")

    const record = {
        no: index + 1,
        containerNo: item.containerNo,
        sealNo: item.sealNo,
        //qty: totalQty + ' unit' + (totalQty != 1 ? 's' : ''),
        qty: totalQty,
        description: descOfGoods,
        remark: item.remark,
        totalWeight: Util.formatAmount(totalWeight),
        totalMeasurement: Util.formatAmount(totalMeasurement),
    }

    let marksAndNumbers = [];
    if (Util.isStringExists(record.containerNo)) {
        marksAndNumbers.push('Container no: ' + record.containerNo)
    }
    if (Util.isStringExists(record.sealNo)) {
        marksAndNumbers.push('Seal no: ' + record.sealNo)
    }
    if (Util.isStringExists(record.remark)) {
        marksAndNumbers.push('Remarks: ' + record.remark)
    }

    return (
        <View style={{ flexDirection: 'row', width: '100%', alignItems: 'flex-start', marginBottom: 10 }}>
            <Text style={{ fontSize: 11, flex: 1, fontFamily: 'Helvetica' }}>{marksAndNumbers.join("\n")}</Text>
            <View style={{ width: 10 }} />
            <Text style={{ fontSize: 11, flex: 2, fontFamily: 'Helvetica' }}>{record.description}</Text>
        </View>
    )
}


/**
 * 
 * @param {{ shipment: any, stamped: any, payload: any, calculateHeight?: { onLastPageCount: any, onRender: any } }} props 
 * @returns 
 */
function PDF({
    shipment, stamped, payload: master, calculateHeight
}) {
    // const canvas = document.createElement('canvas');
    // QRCode.toCanvas(canvas, window.location.origin.toString() + "/shipment-job-qr/" + shipment.id, {
    //     margin: 0
    // });
    // const qr = canvas.toDataURL();

    const singularPackages = getSingularPackages(shipment);

    const vesselName = (() => {
        return master?.transportationLegs.map(leg => leg.vesselName).join(" -> ");
    })();
    const voyageName = (() => {
        return master?.transportationLegs.map(leg => leg.voyageNo).join(" -> ");
    })();
    const pol = (() => {
        const firstLeg = master?.transportationLegs[0];
        return firstLeg?.departurePortName ?? "-"
    })();
    const pod = (() => {
        const lastLeg = master?.transportationLegs[master?.transportationLegs.length - 1];
        return lastLeg?.arrivalPortName ?? "-"
    })();

    const blNo = (() => {
        return getBLNo(shipment)
    })();

    const totalShipmentQty = shipment.packages
        .flatMap($ => $.items)
        .map($ => $.quantity)
        .filter(val => Util.isNumberExist(val))
        .map(val => big(val))
        .reduce((t, c) => t.add(c), big(0))
        .round(2).toNumber()

    const itemBlNo = item => {
        if (shipment._blNoSuffix) {
            return blNo;
        } else {
            const itemIndex = singularPackages.flatMap($ => $.items).indexOf(item);
            return blNo + '-' + (itemIndex + 1)
        }
    }

    const isSurrendered = shipment.blStatus === "SURRENDERED";

    return (
        <Document onRender={calculateHeight?.onRender}>
            <Page size="A4" style={styles.page}>
                <View style={calculateHeight ? {} : { maxHeight: '99%', height: '99%' }}>
                    <View style={{ height: 20, width: '100%', backgroundColor: '#F2F2F2', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 13, margin: 0, lineHeight: 0, fontFamily: "Helvetica-Bold" }}>Bill of Lading</Text>
                    </View>
                    <View style={{ border: '1px solid black', flex: 1, }}>
                        <View style={{ flexDirection: 'row', borderBottom: '1px solid black', minHeight: 150 }}>
                            <View style={{ flex: 5, borderRight: '1px solid #000000' }}>
                                <Section borderBottom="1px solid black" title="Shipper/Exporter (Complete Name and Address)" content={consignorMessage(shipment.customer, shipment.consignorNote)} />
                                <Section title="Consignee/Buyer (Complete Name and Address)" content={consigneeMessage(shipment.consignee, shipment.consigneeNote)} />
                            </View>
                            <View style={{ flex: 6 }}>
                                <View style={{ flexDirection: 'row', borderBottom: '1px solid #000000' }}>
                                    <View style={{ flex: 1 }}>
                                        <CenterBlock title="Job No." content={shipment.shipmentNo} />
                                    </View>
                                    <View style={{ flex: 2 }}>
                                        <CenterBlock lg title="B/L No." content={blNo} />
                                    </View>
                                    {!isSurrendered && <View style={{ flex: 3 }}>
                                        <CenterBlock title="Status" content={
                                            // <Text style={{ fontFamily: 'adobe-devanagari', margin: 0, marginBottom: -7, lineHeight: 0, fontWeight: 'bold', color: 'red', fontSize: 18, textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>{shipment.blStatus}</Text>
                                            <Text style={{ fontFamily: 'adobe-devanagari', margin: 0, marginBottom: -7, lineHeight: 0, fontWeight: 'bold', color: 'red', fontSize: 18, textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                                {shipment.blStatus ?? ' '}
                                            </Text>
                                        } />
                                    </View>}
                                </View>

                                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingLeft: 7, paddingRight: 7, paddingTop: 5, paddingBottom: 5, }}>
                                    {isPicard() ? <>
                                        <Image src={picardlogo} style={{ width: 150, height: 80, objectFit: 'contain', objectPosition: 'center' }} />
                                        <View style={{ paddingLeft: 7, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <Text style={{ fontSize: 12, marginBottom: 0, lineHeight: 1.4, fontFamily: "Helvetica-Bold", textAlign: 'center', color: '#00148C' }}>{"Picard Logistics"}</Text>
                                            <Text style={{ fontSize: 8, fontFamily: "Helvetica", lineHeight: 1.4, textAlign: 'center', color: '#00148C' }}>
                                                {"FZS5 BB02, 1209 Street\nJebel Ali Free Zone [Jafza]\nDubai, UAE, P.O. Box: 17728\nInfo@picardlogistics.com\n+971 4 3588698"}
                                            </Text>
                                        </View>
                                    </> : <>
                                        <Image src={Logo} style={{ width: 85, height: 85 }} />

                                        <View style={{ paddingLeft: 7, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <Text style={{ fontSize: 14, marginBottom: -5, fontFamily: "adobe-devanagari", fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', color: '#00148C' }}>{"EXCELLS SHIPPING LLC"}</Text>
                                            <Text style={{ fontSize: 8, fontFamily: "Helvetica", lineHeight: 1.4, textAlign: 'center', color: '#00148C' }}>{companyInfo}</Text>
                                        </View>
                                    </>}
                                </View>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', borderBottom: '1px solid black' }}>
                            <View style={{ flex: 5, borderRight: '1px solid #000000' }}>
                                <Section borderBottom="1px solid black" title="Notify Party" content={notifyParent(shipment)} />
                                <View style={{ flexDirection: 'row', }}>
                                    <View style={{ flex: 1 }}>
                                        <Section title="Vessel" content={vesselName} />
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Section title="VOY" content={voyageName} />
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Section title="Port of Loading" content={pol} />
                                    </View>
                                </View>
                            </View>
                            <View style={{ flex: 6 }}>
                                <Section title="Delivery Agent's Address" content={deliveryAgent(shipment.deliveryAgent, shipment.deliveryAgentNote)} />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', borderBottom: '1px solid black' }}>
                            <View style={{ flex: 5, borderRight: '1px solid #000000' }}>
                                <Section title="Port of Discharge" content={pod} />
                            </View>
                            <View style={{ flex: 6 }}>
                                <Section title="Final Destination" content={shipment.finalDestination} />
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', borderBottom: '1px solid black' }}>
                            <TableColHeader flex={2.5} title="Marks & Numbers" />
                            <TableColHeader flex={2} title="No of Pkgs or Containers" />
                            <TableColHeader flex={3.6} title="Description of Goods" />
                            <TableColHeader flex={1.5} title="Total Gross Weight (KGS)" />
                            <TableColHeader flex={1.5} last title="Total Measurement (CBM)" />
                        </View>


                        <View style={calculateHeight ? {} : { flex: 1, overflow: 'hidden' }}>
                            {calculateHeight ? (
                                <View style={{}}>
                                    {singularPackages.map((item, index) => <TableRow index={index} item={item} itemBlNo={itemBlNo} hasBottomRow={index < singularPackages.length - 1} />)}
                                    <View style={{ height: 40 }} />
                                </View>
                            ) : (
                                <View style={{ position: 'absolute', left: 0, top: 0, bottom: 0, height: 5000, width: '100%', }}>
                                    {singularPackages.slice(
                                        shipment._rowRanges.firstPage.start,
                                        shipment._rowRanges.firstPage.end
                                    ).map((item, index) => <TableRow index={index} item={item} itemBlNo={itemBlNo} hasBottomRow={index < singularPackages.slice(
                                        shipment._rowRanges.firstPage.start,
                                        shipment._rowRanges.firstPage.end
                                    ).length - 1} />)}
                                </View>
                            )}


                            {isSurrendered && <View style={{ position: 'absolute', left: 5, bottom: 25 }}>
                                <Text style={{ lineHeight: 0, margin: 0, fontFamily: "Helvetica-Bold", fontSize: 20, color: 'red', padding: 5, border: '1px solid red' }}>SURRENDERED</Text>
                            </View>}
                            {stamped && !isPicard() && <View style={{ position: 'absolute', right: -5, bottom: 15 }}>
                                <Image src={InsuranceStamp} style={{ width: 150, height: 75, objectFit: 'contain', objectPosition: 'center', }} />
                            </View>}
                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid black', borderTop: '1px solid black', paddingLeft: 2, paddingTop: 5, paddingBottom: 5, paddingRight: 2 }}>
                            <Text style={{ fontSize: 7, fontFamily: 'Helvetica', lineHeight: 0, flex: 1 }}>Total No of Packages or Containers (In Words)</Text>
                            <Text style={{ fontSize: 7, fontFamily: 'Helvetica-Bold', paddingRight: 5 }}>{numToWord(totalShipmentQty)}</Text>
                        </View>

                        <View style={{ flexDirection: 'row', borderBottom: '1px solid black' }}>
                            <View style={{ flex: 2, borderRight: '1px solid black' }}>
                                <TableColHeader title="FREIGHT AND CHARGES" borderBottom="1px solid black" last />
                                <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                                    {shipment.blFreightAndCharges && <Svg width="20" height="20" viewBox="0 0 24 24">
                                        <Path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill='black' />
                                    </Svg>}
                                </View>
                            </View>
                            <View style={{ flex: 1, borderRight: '1px solid black' }}>
                                <TableColHeader title="RATES AS" borderBottom="1px solid black" last />
                                <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                                    {shipment.blRatedAs && <Svg width="20" height="20" viewBox="0 0 24 24">
                                        <Path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill='black' />
                                    </Svg>}
                                </View>
                            </View>
                            <View style={{ flex: 1, borderRight: '1px solid black' }}>
                                <TableColHeader title="RATE" borderBottom="1px solid black" last />
                                <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                                    {shipment.blRate && <Svg width="20" height="20" viewBox="0 0 24 24">
                                        <Path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill='black' />
                                    </Svg>}
                                </View>
                            </View>
                            <View style={{ flex: 1, borderRight: '1px solid black' }}>
                                <TableColHeader title="PER" borderBottom="1px solid black" last />
                                <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                                    {shipment.blPer && <Svg width="20" height="20" viewBox="0 0 24 24">
                                        <Path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill='black' />
                                    </Svg>}
                                </View>
                            </View>
                            <View style={{ flex: 1, borderRight: '1px solid black' }}>
                                <TableColHeader title="PREPAID" borderBottom="1px solid black" last />
                                <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                                    {shipment.blPrepaid && <Svg width="20" height="20" viewBox="0 0 24 24">
                                        <Path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill='black' />
                                    </Svg>}
                                </View>
                            </View>
                            <View style={{ flex: 1, borderRight: '1px solid black' }}>
                                <TableColHeader title="COLLECT" borderBottom="1px solid black" last />
                                <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                                    {shipment.blCollect && <Svg width="20" height="20" viewBox="0 0 24 24">
                                        <Path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill='black' />
                                    </Svg>}
                                </View>
                            </View>
                            <View style={{ width: 125 }}>
                                <TableColHeader title="Place of BL Issue" borderBottom="1px solid black" />
                                <View style={{ paddingLeft: 2, paddingRight: 2, paddingTop: 2, paddingBottom: 2, width: '100%', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid black' }}>
                                    <Text style={{ fontSize: 8, fontFamily: 'Helvetica-Bold', lineHeight: 0 }}>{shipment.placeOfBlIssue}</Text>
                                </View>
                                <TableColHeader title="No. of Orig BL Signed" borderBottom="1px solid black" />
                                <View style={{ paddingLeft: 2, paddingRight: 2, paddingTop: 2, paddingBottom: 2, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                    <Text style={{ fontSize: 8, fontFamily: 'Helvetica-Bold', lineHeight: 0 }}>Three (3)</Text>
                                </View>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', borderBottom: '1px solid black' }}>
                            <View style={{ flex: 4, paddingLeft: 2, paddingRight: 2, paddingTop: 20, paddingBottom: 20, borderRight: '1px solid black' }}>
                                {isPicard() ? (
                                    <View style={{ height: 75 }} />
                                ) : (
                                    <Text style={{ fontFamily: 'Helvetica', fontSize: 7 }}>{message}</Text>
                                )}
                            </View>
                            <View style={{ flex: 3 }}>
                                <View style={{ flexDirection: 'row', borderBottom: '1px solid black' }}>
                                    <View style={{ flex: 1, justifyContent: 'center', paddingLeft: 2, borderRight: '1px solid black' }}>
                                        <Text style={{ fontSize: 13, lineHeight: 0 }}>TOTAL</Text>
                                    </View>
                                    <View style={{ width: 125 }}>
                                        <TableColHeader title="Date of BL Issue" borderBottom="1px solid black" />
                                        <View style={{ paddingLeft: 2, paddingRight: 2, paddingTop: 2, paddingBottom: 2, width: '100%', alignItems: 'center', justifyContent: 'center', }}>
                                            <Text style={{ fontSize: 8, fontFamily: 'Helvetica-Bold', lineHeight: 0 }}>{Util.getDateOnly(shipment.dateOfBlIssue)}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ borderBottom: '1px solid black' }}>
                                    <Text style={{ fontFamily: 'Helvetica', fontSize: 7, lineHeight: 0, textAlign: 'center', paddingTop: 2, paddingBottom: 2 }}>Signature & Stamp</Text>
                                </View>

                                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {stamped && !isPicard() && <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                        <Image src={MainStamp} style={{ width: 80, height: 80, minWidth: 80, minHeight: 80, maxWidth: 80, maxHeight: 80, objectFit: 'contain', objectPosition: 'center', padding: 1 }} />
                                    </View>}

                                    <Text style={{ fontFamily: 'Helvetica-Bold', lineHeight: 0, fontSize: 8, paddingBottom: 2 }}>As Agents Only</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'none' }} render={({ pageNumber }) => {
                        calculateHeight?.onLastPageCount?.(pageNumber);
                        return <View style={{ display: 'none' }} />
                    }} />
                </View>


                {shipment._rowRanges?.restPage.start && <View break style={{ flex: 1 }}>
                    <View style={{ display: 'flex', alignItems: 'center' }}>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', textDecoration: 'underline' }}>B/L Rider Sheet</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', flex: 1 }}>BL No: {blNo}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', flex: 1, textAlign: 'right' }}>Vessel: {vesselName} / Voyage: {voyageName}</Text>
                    </View>
                    <View style={{ border: '1px solid black', flex: 1, padding: 10 }}>
                        <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', marginBottom: 10, }}>
                            <Text style={{ fontSize: 9, flex: 1, fontFamily: 'Helvetica', textDecoration: 'underline' }}>Marks & No's / Container / Seal No</Text>
                            <View style={{ width: 10 }} />
                            <Text style={{ fontSize: 9, flex: 2, fontFamily: 'Helvetica', textDecoration: 'underline' }}>Number, Kind of Packages and Descriptin of Goods</Text>
                        </View>

                        {singularPackages.slice(
                            shipment._rowRanges.restPage.start,
                            shipment._rowRanges.restPage.end
                        ).map((item, index) => <RiderSheetTableRow index={index} item={item} itemBlNo={itemBlNo} />)}

                        {stamped && <View fixed style={{ position: 'absolute', right: 15, bottom: 120 }}>
                            <Image src={InsuranceStamp} style={{ width: 150, height: 75, objectFit: 'contain', objectPosition: 'center', }} />
                        </View>}

                        {stamped && <View fixed style={{ position: 'absolute', right: 15, bottom: 15 }}>
                            <Image src={MainStamp} style={{ width: 80, height: 80, minWidth: 80, minHeight: 80, maxWidth: 80, maxHeight: 80, objectFit: 'contain', objectPosition: 'center', padding: 1 }} />
                        </View>}
                    </View>
                </View>}
            </Page>
        </Document>
    )
}

function getSingularPackages(shipment) {
    return shipment.packages.flatMap(pkg => pkg.items.map(item => ({
        ...pkg, items: [item]
    })))
}

async function calculateNoOfPages(shipment) {
    return new Promise((resolve, reject) => {
        let count = 0;

        const timeout = setTimeout(() => {
            reject("Timeout")
        }, 5000)
        const onDone = () => {
            resolve(count)
            clearTimeout(timeout)
        }
        const doc = <PDF shipment={shipment} calculateHeight={{
            onRender: onDone,
            onLastPageCount: c => count = c
        }} />
        pdf(doc).toBlob();
    })
}

async function getPageRowRanges(shipment) {
    const getPageCount = () => calculateNoOfPages({ ...shipment, packages: testingRows })
    const hasMoreRows = () => packages.length > testingRows.length;

    const packages = getSingularPackages(shipment)
    let testingRows = packages.slice(0, 1)

    const ranges = { firstPage: { start: 0, end: 0 }, restPage: { start: undefined, end: undefined } }

    do {
        const pageCount = await getPageCount();
        if (pageCount > 1) {
            //we reached no of rows that exceeds first page
            ranges.restPage.start = testingRows.length - 1;
            break; // no need to test anymore
        } else {
            ranges.firstPage.end = testingRows.length;
        }
        testingRows = packages.slice(0, testingRows.length + 1)
    } while (hasMoreRows());

    return ranges;
}

export function createBillOfLading(shipment) {
    return {
        PDF,
        canGenerate: true,
        getPayload: Util.isNumberExist(shipment.shipmentMasterId) ? (shipment, listener) => {
            Api.getShipmentMaster(shipment.shipmentMasterId, response => {
                if (response.status === true) {
                    listener(true, response.payload.item)
                } else {
                    listener(false, response.message);
                }
            })
        } : undefined,
        overrideShipment: async shipment => {
            const rowRanges = await getPageRowRanges(shipment);
            return {
                ...shipment,
                _rowRanges: rowRanges
            };
        }
    }
}

export function createPerItemBillOfLading(shipment) {
    return {
        PDF,
        canGenerate: (shipment?.packages?.flatMap?.($ => $.items)?.length ?? 0) > 0,
        getPayload: createBillOfLading(shipment).getPayload,
        overrideShipment: async shipment => {
            const allItems = shipment.packages.flatMap($ => $.items).map($ => ({
                ...$, value: $.remark
            }))
            const selectedItem = await UIUtil.listPrompt("Select item", allItems);
            if (!selectedItem) {
                return null;
            }

            const mapped = {
                ...shipment,
                _blNoSuffix: allItems.indexOf(selectedItem) + 1,
                packages: shipment.packages.filter($ => $.items.find(item => item.id === selectedItem.id)).map($ => ({
                    ...$, items: $.items.filter(item => item.id === selectedItem.id)
                })),

                customer: selectedItem.consignor ?? shipment.customer,
                consignee: selectedItem.consignee ?? shipment.consignee
            }

            const rowRanges = await getPageRowRanges(mapped);
            return {
                ...mapped,
                _rowRanges: rowRanges
            };
        }
    }
}