import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import Util from '../../../util/Util';
import { paymentMethodText } from '../../../pages/transaction/TransactionEditor';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceNo2Container: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        //width: 60
        marginRight: 5,
    }
    
  });


  const InvoiceNo = ({transaction}) => (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text style={styles.label}>Voucher Number:</Text>
                <Text style={styles.invoiceDate}>{Util.getVoucherNumber(transaction.id)}</Text>
            </View >
            <View style={styles.invoiceNo2Container}>
                <Text style={styles.label}>Payment Voucher ID:</Text>
                <Text style={styles.invoiceDate}>{transaction.transactionId}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Date:</Text>
                <Text >{Util.getDateOnly(transaction.initiationDate)}</Text>
            </View >

            <Text style={{marginTop: 25}}>Amount</Text>
            <Text style={{marginTop: 0, fontSize: 25, color: 'green'}}>SAR {transaction.amount.toFixed(2)}</Text>

            <Text style={{marginTop: 25}}>Payment Methods</Text>
            {transaction.paymentMethods.map(method => (
                <View style={{marginTop: 5, flexDirection: 'row'}}>
                    <Text style={{flex: 1, fontSize: 14, color: 'gray'}}>{paymentMethodText(method.methodType)} {Util.isStringExists(method.paymentId) && `(ID: ${method.paymentId})`}</Text>
                    <Text style={{}}>SAR {method.amount.toFixed(2)}</Text>
                </View >
            ))}
        </Fragment>
  );
  
  export default InvoiceNo