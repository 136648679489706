
import { Close16, Calendar16, ArrowRight16, Activity16, Locked16, Wallet16 } from '@carbon/icons-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ArrayInput } from '../../../../../components/array-input'
import Button from '../../../../../components/Button'
import Util, { big } from '../../../../../util/Util'
import { Link } from 'react-router-dom'
import UIUtil from '../../../../../util/UIUtil'
import print from 'print-js';
import { pdf } from '@react-pdf/renderer'
import { createLoadingList } from '../pdf/loading-list'
import { InlineLoading } from 'carbon-components-react'
import { printReceiptVoucher } from '../../../../../pdfs/receipt-voucher/ReceiptVoucherPdf'
import { printInvoice } from '../../../../../pdfs/invoice-document/InvoiceDocumentPdf'
import { TRANSACTION_DIRECTION_TYPE_INWARD } from '../../../../../constants/Constants'
import { OBJECT_TYPE_SERVICE_SALE } from '../../../../../constants/ObjectTypes'
import { TransactionPayButton } from '../../../../../views/transactions/transaction-pay-button'
import { createCurrencyInvoice, createInvoice } from '../pdf/invoice'
import { SystemGeneratedDocumentWithPayload } from './documents'
import Api, { $try } from '../../../../../session/Api'


const ButtonEntry = ({ label, loading, disabled, onClick, Icon }) => (<>
    <div onClick={disabled ? () => { } : onClick} className={'shipment-side-view-entry-record'}
        style={{
            height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem',
            pointerEvents: disabled ? 'none' : undefined,
            opacity: disabled ? 0.65 : 1
        }}>
        <p style={{ fontSize: 14, opacity: 1, flex: 1, outline: 'none' }}>{label}</p>
        {loading ?
            <InlineLoading style={{ width: 'unset' }} /> :
            Icon ? <Icon style={{ opacity: 0.65 }} /> : <ArrowRight16 style={{ opacity: 0.65 }} />}
    </div>
</>)


const LiveIndicator = () => (
    <div style={{
        borderRadius: 7, width: '100%', paddingBlock: '0.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem', background: '#da093a20', color: '#da093a',
        border: '1px solid #da093a80'
    }}>
        <Activity16 /> <h6>Live</h6>
    </div>
)

const LockedIndicator = () => (
    <div style={{
        borderRadius: 7, width: '100%', paddingBlock: '0.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem', background: '#1c1c1c', color: 'white',
    }}>
        <Locked16 /> <h6>Locked</h6>
    </div>
)

const ValueField = ({ style = {}, title, value }) => (
    <div style={style}>
        <label style={{ fontWeight: 'bold', marginBottom: 0, opacity: 1, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16 }}>{value}</p>
    </div>
)

const ListValueEntry = ({ label, value, flipped }) => (<>
    <div style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 0.65, flex: flipped ? 1 : 2, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: 14, flex: flipped ? 2 : 1, outline: 'none', textAlign: 'end' }}>{value}</p>
    </div>
</>)

const List = ({ label, children }) => (<>
    <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>{label}</h4>
    <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '1rem' }}>
        <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            {children}
        </div>
    </div>
</>)


const ListValueEntryButton = ({ label, hasValue, loading, onClick }) => (<>
    <div onClick={hasValue ? onClick : undefined} className={hasValue ? 'shipment-side-view-entry-record' : undefined} style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 1, flex: 1, outline: 'none' }}>{label}</p>
        {loading ?
            <InlineLoading style={{ width: 'unset' }} /> :
            hasValue ?
                <ArrowRight16 style={{ opacity: 0.65 }} /> :
                <p style={{ opacity: 0.65, fontSize: 12 }}>not available</p>}
    </div>
</>)

const SystemGeneratedDocument = ({ label, shipment, documentCreateFn, printFn }) => {
    const { canGenerate, getPayload, PDF } = useMemo(() => documentCreateFn ? documentCreateFn(shipment) : ({ canGenerate: printFn !== undefined }), [shipment, documentCreateFn])
    const [loading, setLoading] = useState(false);
    const onPrintBtn = async () => {
        if (printFn) {
            setLoading(true)
            try {
                await printFn()
            } finally {
                setLoading(false)
            }
            return;
        }

        if (getPayload) {
            setLoading(true);
            getPayload(shipment, async (success, data) => {
                try {
                    if (success) {
                        const blob = await pdf(<PDF shipment={shipment} payload={data} />).toBlob();
                        print(URL.createObjectURL(blob));
                    } else {
                        UIUtil.showError(data);
                    }
                } finally {
                    setLoading(false);
                }
            })
        } else {
            setLoading(true);
            try {
                const blob = await pdf(<PDF shipment={shipment} />).toBlob();
                print(URL.createObjectURL(blob));
            } finally {
                setLoading(false);
            }
        }
    }
    return <ListValueEntryButton label={label} hasValue={canGenerate} loading={loading} onClick={onPrintBtn} />
}

function ChargeInput({ record, updateRecord, clearRecordIfNeeded, services, serviceList }) {
    const inputRef1 = useRef();
    const inputRef2 = useRef();

    const checkBlur = () => setTimeout(() => {
        if (document.activeElement != inputRef1.current && document.activeElement != inputRef2.current) {
            clearRecordIfNeeded(record)
        }
    }, 10)

    useEffect(() => {
        if (services && serviceList) {
            const foundService = serviceList.find(service => service.name === record.description);
            if (foundService) {
                updateRecord({ ...record, amount: foundService.amount, serviceId: foundService.id, __amount_from_system: true })
            } else if (record.__amount_from_system) {
                updateRecord({ ...record, amount: "", __amount_from_system: false })
            }
        }
    }, [record.description, services, serviceList])

    return (
        <div style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000020' }}>
            {services ? (<>
                <input list='shipment-service-charge-input' ref={inputRef1} value={record.description} onChange={e => updateRecord({ ...record, description: e.target.value })} placeholder='Description' style={{ flex: 3, height: '100%', border: 'none', background: 'none', padding: '1rem', outline: 'none' }} onBlur={checkBlur} />
                <datalist id='shipment-service-charge-input'>
                    {serviceList.map(service => <option key={service.id} label={`SAR ${service.amount}`} value={service.name} />)}
                </datalist>
                {/* <datalist id="browsers">
                    <option value="Chrome">
                    <option value="Firefox">
                    <option value="Internet Explorer">
                    <option value="Opera">
                    <option value="Safari">
                    <option value="Microsoft Edge">
                </datalist> */}
            </>) : (
                <input ref={inputRef1} value={record.description} onChange={e => updateRecord({ ...record, description: e.target.value })} placeholder='Description' style={{ flex: 3, height: '100%', border: 'none', background: 'none', padding: '1rem', outline: 'none' }} onBlur={checkBlur} />
            )}
            <p style={{ fontSize: 12, opacity: 0.65, fontWeight: 'bold', }}>SAR</p>
            <input ref={inputRef2} value={record.amount} onChange={e => updateRecord({ ...record, amount: e.target.value, __amount_from_system: false })} placeholder='Amount' style={{ flex: 1, height: '100%', border: 'none', background: 'none', padding: '1rem', paddingLeft: '0.5rem', outline: 'none' }} onBlur={checkBlur} />
        </div>
    )
}

function ChargeInputList({ readonly, label, value, setValue, services, serviceList }) {
    const records = useMemo(() => {
        const lastValue = value.at ? value.at(-1) : value[value.length - 1];
        if (!readonly && (!lastValue || (Util.isStringExists(lastValue.description) || Util.isStringExists(lastValue.amount)))) {
            return [...value, { tempId: Util.newTempId(), description: "", amount: "" }]
        } else {
            return value;
        }
    }, [value])

    const updateRecord = (update) => {
        if (readonly) {
            return;
        }

        setValue(records.map(record => (record.id ?? record.tempId) === (update.id ?? update.tempId) ? update : record))
    }

    const clearRecordIfNeeded = update => {
        if (readonly) {
            return;
        }

        if (!Util.isStringExists(update.description) && !Util.isStringExists(update.amount)) {
            setValue(records.filter(record => (record.id ?? record.tempId) === (update.id ?? update.tempId) ? false : true))
        }
    }

    return (<>
        <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>{label}</h4>
        <div style={{ width: '100%', background: '#f4f4f4', marginBottom: '3rem' }}>
            {records.map(record => <ChargeInput key={(record.id ?? record.tempId) + 'charge-input-item'} {...{ record, updateRecord, clearRecordIfNeeded, services, serviceList }} />)}
        </div>
    </>)
}

const TransactionItem = ({ invoiceId, transaction }) => {
    let dividedAmount = 0;
    if (transaction.againstItems && transaction.againstItems.length !== undefined) {
        for (const item of transaction.againstItems) {
            if (item.serviceSaleId == invoiceId) {
                dividedAmount = item.dividedAmount;
                break;
            }
        }
    }

    return <ListValueEntry label={"Receipt Voucher " + Util.getTransactionVoucherNumber(transaction.id, transaction.directionType, transaction.sequenceNo)} value={"SAR " + Util.formatMoney(dividedAmount)} />
}

function InvoiceStateIndicator({ shipment, globalState }) {
    const [lockStatus, setLockStatus] = globalState.use("lockStatus", {
        locked: shipment.locked,
        date: shipment.lockedDate,
        name: shipment.lockedByFullName
    })

    return lockStatus.locked ? <LockedIndicator /> : <LiveIndicator />;
}

function PayButton(props) {
    return <ButtonEntry label={"Record Payment"} Icon={Wallet16} {...props} />
}

function SetDateButton({ shipment, globalState, disabled }) {
    const [loading, setLoading] = useState(false)
    const onClick = async () => {
        setLoading(true);
        try {
            const date = await UIUtil.singleDateInputPrompt({ title: "Invoice Date" })
            if (!date) {
                return;
            }

            const [success, response] = await Api.try((api, listener) => api.setShipmentInvoiceDate(shipment.id, date, listener));
            if (!success) {
                return
            }


            window.location.reload()
        } finally {
            setLoading(false);
        }
    }
    return <ButtonEntry onClick={onClick} label={"Update Invoice Date"} Icon={Calendar16} disabled={disabled} loading={loading} />
}

const getItemQty = item => item.qty ? big(item.qty) : big(1)
const formatItemAmount = item => Util.formatMoney((big(item.amount).times(getItemQty(item))).toNumber())

export function Invoice({ shipment, form, endpoint, changesMade, globalState }) {
    const [items, setItems] = useState([])

    const amounts = useMemo(() => {
        const calcList = (list) => {
            const itemTotal = list.filter(item => Util.isNumberExist(item.amount))
                .map(item => big(item.amount).times(getItemQty(item)))
                .reduce((t, c) => t.add(c), big(0))
            const itemTax = list.filter(item => Util.isNumberExist(item.amount))
                .map(item => big(item.amount).times(getItemQty(item)).times(big(item.taxRateOverride ?? 0.05)))
                .reduce((t, c) => t.add(c), big(0))

            return {
                itemTotal,
                tax: itemTax
                //tax: big(0.05).times(itemTotal)
            }
        }

        const systemDefined = calcList(shipment.systemDefinedCharges);
        const userDefined = calcList(shipment.userDefinedCharges);

        const subtotal = systemDefined.itemTotal.add(userDefined.itemTotal)
        const discount = big(0);
        const tax = systemDefined.tax.add(userDefined.tax)
        const total = subtotal.add(tax);

        const amountDue = total.minus(big(shipment.amountPaid))

        return {
            subtotal: subtotal.round(2).toNumber(),
            discount: discount.round(2).toNumber(),
            tax: tax.round(2).toNumber(),
            total: total.round(2).toNumber(),
            amountDue: amountDue.round(2).toNumber()
        }
    }, [shipment])

    return (
        <div style={{ width: '100%', }}>
            <div style={{ marginBottom: '1rem', paddingInline: '1rem' }}>
                <InvoiceStateIndicator shipment={shipment} globalState={globalState} />
            </div>
            <div style={{ display: 'flex', gap: '0.5rem', paddingInline: '1rem', marginBottom: '1rem' }}>
                <ValueField title="Voucher No" value={<Link to={"/service-sale/" + shipment.invoiceId}>{Util.getVoucherNumber(shipment.invoiceId)}</Link>} style={{ flex: 1 }} />
                <ValueField title="Amount Paid" value={<>
                    <span style={{ color: 'green' }}>SAR {Util.formatAmount(shipment.amountPaid)}</span> / <strong>{Util.formatMoney(amounts.total)}</strong> <span style={{ fontSize: 10 }}>(total)</span>
                </>} style={{ flex: 2 }} />

                <br />
            </div>
            {/* {Util.isNumberExist(amounts.amountDue) &&
                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '0.5rem', paddingInline: '1rem', marginBottom: '3rem' }}>
                    <TransactionPayButton
                        buttonProps={{
                            size: "sm",
                            style: { borderRadius: 50 }
                        }}
                        onClickOverride={changesMade ? (() => {
                            UIUtil.showInfo("Please save changes before recording payment");
                        }) : undefined}
                        amount={amounts.amountDue}
                        direction={TRANSACTION_DIRECTION_TYPE_INWARD} itemId={shipment.invoiceId} itemType={OBJECT_TYPE_SERVICE_SALE}
                        partyItemType={shipment.serviceSalePayerType} partyId={shipment.serviceSalePayerId} />
                </div>} */}

            <List label=" ">
                {Util.isNumberExist(amounts.amountDue) &&
                    <TransactionPayButton
                        CustomButton={PayButton}
                        forceDisable={changesMade}
                        onClickOverride={changesMade ? (() => {
                            UIUtil.showInfo("Please save changes before recording payment");
                        }) : undefined}
                        amount={amounts.amountDue}
                        direction={TRANSACTION_DIRECTION_TYPE_INWARD} itemId={shipment.invoiceId} itemType={OBJECT_TYPE_SERVICE_SALE}
                        partyItemType={shipment.serviceSalePayerType} partyId={shipment.serviceSalePayerId} />}
                <SetDateButton disabled={changesMade} shipment={shipment} globalState={globalState} />
                {changesMade &&
                    <ListValueEntry flipped value={"Please save changes to enable actions"} />}
            </List>


            <List label="Documents">
                {/* {shipment.status === "Done" ? (
                    <SystemGeneratedDocument label="Tax Invoice" printFn={async () => await printInvoice(shipment.invoice)} />
                ) : (
                    <SystemGeneratedDocument label="Proforma Invoice" printFn={async () => await printInvoice(shipment.invoice, { title: "PROFORMA INVOICE", showLogo: true })} />
                )} */}
                {shipment.status === "Done" ? (<>
                    <SystemGeneratedDocumentWithPayload shipment={shipment} label="Tax Invoice" documentCreateFn={createInvoice} pdfProps={{ title: "TAX INVOICE" }} />
                    <SystemGeneratedDocumentWithPayload shipment={shipment} label="Tax Invoice (Currency)" documentCreateFn={createCurrencyInvoice} pdfProps={{ title: "TAX INVOICE" }} />
                </>) : (<>
                    <SystemGeneratedDocumentWithPayload shipment={shipment} label="Proforma Invoice" documentCreateFn={createInvoice} pdfProps={{ title: "PROFORMA INVOICE" }} />
                    <SystemGeneratedDocumentWithPayload shipment={shipment} label="Proforma Invoice (Currency)" documentCreateFn={createCurrencyInvoice} pdfProps={{ title: "PROFORMA INVOICE" }} />
                </>)}

                {shipment.transactions.map(transaction => <SystemGeneratedDocument key={transaction.id} label={"Receipt Voucher " + Util.getTransactionVoucherNumber(transaction.id, transaction.directionType, transaction.sequenceNo)} printFn={async () => await printReceiptVoucher(transaction)} />)}
            </List>

            <List label="Items">
                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Shipment Charges</span>} />
                {shipment.systemDefinedCharges.filter($ => !$.fromBill).map(item => <ListValueEntry key={item.id} label={item.description} value={"SAR " + Util.formatMoney(item.amount)} />)}
                {shipment.systemDefinedCharges.filter($ => !$.fromBill).length === 0 && <ListValueEntry label="-" />}

                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Invoiced Bills</span>} />
                {shipment.systemDefinedCharges.filter($ => $.fromBill).map(item => <ListValueEntry key={item.id} label={item.description} value={"SAR " + Util.formatMoney(item.amount)} />)}
                {shipment.systemDefinedCharges.filter($ => $.fromBill).length === 0 && <ListValueEntry label="-" />}

                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Services</span>} />
                {shipment.userDefinedCharges.map(item => <ListValueEntry key={item.id} label={item.description} value={"SAR " + formatItemAmount(item)} />)}
                {shipment.userDefinedCharges.length === 0 && <ListValueEntry label="-" />}
            </List>

            <List label="Financial Analysis">
                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Invoice Amount</span>} />

                <ListValueEntry label="Subtotal (+)" value={"SAR " + Util.formatMoney(amounts.subtotal)} />
                <ListValueEntry label="Discount (-)" value={"SAR " + Util.formatMoney(amounts.discount)} />
                <ListValueEntry label="5% VAT (+)" value={"SAR " + Util.formatMoney(amounts.tax)} />
                <ListValueEntry label={<strong>Grand Total</strong>} value={<strong>SAR {Util.formatMoney(amounts.total)}</strong>} />
                <ListValueEntry />

                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Payments Completed</span>} />
                {shipment.transactions.map(transaction => <TransactionItem key={transaction.id} invoiceId={shipment.invoiceId} transaction={transaction} />)}
                <ListValueEntry label={<strong>Total Payment</strong>} value={<strong style={{ color: 'green' }}>SAR {Util.formatMoney(shipment.amountPaid)}</strong>} />
                <ListValueEntry />

                <ListValueEntry label={<span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Amount Due</span>} />
                <ListValueEntry label={<strong>Total</strong>} value={<strong style={{ color: amounts.amountDue > 0 ? "red" : "green" }}>SAR {Util.formatAmount(amounts.amountDue)}</strong>} />
            </List>

            <div style={{ height: '6rem' }} />
        </div>
    )
}