import { ComboBox, ContentSwitcher, Select, SelectItem, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from '../../../components/MultipleInputEditor';
import Button from '../../../components/Button';
import Util, { big } from '../../../util/Util';
// import MultipleInputEditor, { useMultiInputState } from './MultipleInputEditor';

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved, agents, commissionAmount }) => {
    const [agentId, setAgentId] = useMultiInputState(input, 'agentId', onInputUpdate);
    const [amount, setAmount] = useMultiInputState(input, 'amount', onInputUpdate);

    const [rate, setRate] = useState(() => {
        if (Util.isNumberExist(input.amount)) {
            return ((big(input.amount).div(big(commissionAmount))).times(big(100))).round(2, 1)
        } else {
            return 0;
        }
    });

    useEffect(() => {
        const rateVal = big(rate);
        if (rateVal.gt(big(0)) || big(input.amount).gt(big(0))) {
            setAmount((rateVal.div(big(100))).times(big(commissionAmount)).round(2, 1));
        }
    }, [commissionAmount, rate])

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <div style={{ flex: 1, marginTop: 0 }}>
                <Select size='lg' labelText="Agent" light value={agentId} onChange={e => setAgentId(e.target.value)}>
                    <SelectItem value={0} text="Select agent" />
                    {agents.map(option => <SelectItem key={option.id} value={option.id} text={option.value} />)}
                </Select>
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ width: '100%' }} size="lg" labelText="Commission Percentage (%)" value={rate}
                    onChange={e => setRate(e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ width: '100%' }} readOnly light size="lg" labelText="Commission Amount" value={Util.isNumberExist(amount) ? ('SAR ' + Util.formatMoney(amount)) : ''} />
            </div>

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}


export const CommissionDivisionEditor = ({ highZ, defaultItems, onUpdate, agents, commissionAmount }) => (
    <MultipleInputEditor highZ={highZ} defaultInputs={[]} inputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} agents={agents} commissionAmount={commissionAmount} />
)