import { useMemo } from "react";
import { TAX_TYPES } from "../../../2v/modules/sales";
import Button from "../../../components/Button";
import useStore from "../../../hooks/useStore";
import Util, { big } from "../../../util/Util";
import { Migrate32, Migrate24, CheckmarkFilled16 } from '@carbon/icons-react'


export function calcAmounts({ items, taxId }) {
    const defTaxPerc = TAX_TYPES.find(tax => tax.id === taxId)?.perc ?? 0;
    const itemTaxPercFn = item => big(TAX_TYPES.find(tax => tax.value === item?.taxName)?.perc ?? defTaxPerc).div(100);

    const gross = items.slice(0, items.length - 1).map(item => big(item.qty).times(big(item.amount))).reduce((t, c) => t.add(c), big(0));
    const tax = items
        .filter($ => $.item?.selectedItem?.type)
        .map(item => {
            const itemGross = big(item.qty).times(big(item.amount));
            const itemTaxPerc = itemTaxPercFn(item);
            // const itemDiscountPerc = big(0);
            //return itemGross.minus(itemDiscountPerc.times(itemGross)).times(itemTaxPerc);
            return itemGross.times(itemTaxPerc)
        })
        .reduce((t, c) => t.add(c), big(0));

    const total = gross.plus(tax);

    return {
        gross: gross.round(2).toNumber(),
        tax: tax.round(2).toNumber(),
        total: total.round(2).toNumber(),
        itemTaxPercFn: itemTaxPercFn,
    }
}

export function useCalcAmounts(store) {
    const [items] = useStore(store, "items");
    const [taxId] = useStore(store, "taxId");

    return useMemo(() => calcAmounts({ items, taxId }), [items, taxId])
}


export function FinanceSummaryBar({ store, saveLoading, save }) {
    const { gross, tax, total } = useCalcAmounts(store);
    return (
        <div style={{ paddingInline: '3rem' }}>
            <div style={{ height: '4rem', width: '100%', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', background: '#f4f4f4', border: '1px solid #00000020', display: 'flex', alignItems: 'center', gap: '1.5rem', paddingInline: '1rem', paddingBlock: '1rem', borderRadius: 10, marginTop: '4rem', color: 'black' }}>
                <div>
                    <label className="bx--label" style={{ opacity: 0.65, marginBottom: 0 }}>Subtotal</label>
                    <h4 style={{}}>SAR {Util.formatMoney(gross)}</h4>
                </div>
                <h4>+</h4>
                <div>
                    <label className="bx--label" style={{ opacity: 0.65, marginBottom: 0 }}>Tax</label>
                    <h4 style={{}}>SAR {Util.formatMoney(tax)}</h4>
                </div>
                <h4>=</h4>
                <div>
                    <h3 style={{ fontWeight: 'bold', color: '#009900' }}>SAR {Util.formatMoney(total)}</h3>
                </div>
                <div style={{ flex: 1 }} />

                <Button loading={saveLoading} onClick={save} size={"sm"} renderIcon={CheckmarkFilled16} style={{ borderRadius: 7 }}>Create</Button>
            </div>
        </div>
    )
}