
import bg from '../../images/other/romario-shop/bg.png'
import logo from '../../images/other/romario-shop/logo.png'
import emailIcon from '../../images/other/romario-shop/email-icon.png'
import locIcon from '../../images/other/romario-shop/loc-icon.png'
import phoneIcon from '../../images/other/romario-shop/phone-icon.png'
import { Document, Font, View, Text, Page, Image, StyleSheet } from "@react-pdf/renderer";
import Util from '../../util/Util';
import Api from '../../session/Api';
import { OBJECT_TYPE_PRODUCT } from '../../constants/ObjectTypes';
import React from 'react';

import { ToWords } from 'to-words';

const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
    }
});

const numToWord = num =>
    toWords.convert(num)
        .toLowerCase()
        .replace("dollar", "riyal")
        .replace("cent", "halala");

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        width: '100%',
        lineHeight: 1.5,
        padding: 0,
        flexDirection: 'column',
    },
    pageBackground: {
        position: 'absolute',
        left: 0, top: 0,
        bottom: 0, right: 0,
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
        padding: 25,
        objectFit: 'contain',
        objectPosition: 'center'
    },
});

Font.register({
    family: "Montserrat",
    fonts: [
        {
            src: "//fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-Y3tcoqK5.ttf",
            fontWeight: 200
        },
        {
            src: "//fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
        },
        {
            src: "//fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
            fontWeight: 600
        }
    ]
})

Font.register({
    family: 'Roboto Mono',
    src: '//fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf'
});

function addNewlineAfter13Characters(inputString) {
    const maxLength = 20;
    let result = '';

    for (let i = 0; i < inputString.length; i += maxLength) {
        const chunk = inputString.slice(i, i + maxLength);
        result += chunk + '\n';
    }

    return result;
}

const COLS = (showImage, showRRP, showHsCode, showCoo) => {
    return [
        ...(showImage ? [
            {
                id: "image",
                name: "",
                flex: 1,
                alignment: "center",
                render: row => <Image style={{ width: 32, height: 32 }} src={Api.getThumbnailForSure(OBJECT_TYPE_PRODUCT, row.id)} />
            },
        ] : []),
        {
            id: "description",
            name: "Item",
            flex: 3,
            alignment: "flex-start",
            // render: row => <Text style={{ fontSize: 8 }}>{row.sku}</Text>
            render: row => <Text style={{ fontSize: 8 }}>{addNewlineAfter13Characters(row.sku)}</Text>
        },
        {
            id: "info",
            name: "Description",
            flex: 4,
            alignment: "flex-start",
            render: row => <Text style={{ fontSize: 8 }}>{row.description}</Text>
        },
        ...(showCoo ? [
            {
                id: "coo",
                name: "COO",
                flex: 1,
                alignment: "center",
                render: row => <Text style={{ fontSize: 8 }}>{row.coo}</Text>
            },
        ] : []),
        ...(showHsCode ? [
            {
                id: "hsCode",
                name: "HS Code",
                flex: 1,
                alignment: "center",
                render: row => <Text style={{ fontSize: 8 }}>{row.hsCode}</Text>
                // render: row => <Text style={{ fontSize: 8 }}>3000</Text>
            },
        ] : []),
        {
            id: "qty",
            name: "Qty",
            flex: 1,
            alignment: "center",
            render: row => <Text style={{ fontSize: 8 }}>{row.qty}</Text>
            // render: row => <Text style={{ fontSize: 8 }}>3000</Text>
        },
        ...(showRRP ? [
            {
                id: "rrp",
                name: "RRP",
                flex: 1,
                alignment: "center",
                render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(row.rrp)}</Text>
            },
        ] : []),
        {
            id: "unitPrice",
            name: "Rate",
            flex: 1,
            alignment: "flex-end",
            render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(row.unitPrice)}</Text>
            // render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(633)}</Text>
        },
        {
            id: "total",
            name: "Amount",
            flex: 2,
            alignment: "flex-end",
            render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(row.total)}</Text>
        },
    ]
}




const TableValueField = ({ label, mulitext, children, textAlign }) => (
    <View>
        <View style={{ borderBottom: '0px solid black', backgroundColor: '#2B5798', paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2 }}>
            <Text style={{ fontSize: 10, lineHeight: 0, textTransform: 'uppercase', textAlign, color: 'white', fontFamily: 'Montserrat', }}>{label}</Text>
        </View>
        <View style={{ backgroundColor: '#2B579830', paddingLeft: 5, paddingRight: 5, paddingTop: mulitext ? 5 : 2, paddingBottom: mulitext ? 5 : 2 }}>
            <Text style={{ fontSize: mulitext ? 10 : 10, fontFamily: "Montserrat", textAlign, fontWeight: 600 }}>{children ? children : ' '}</Text>
        </View>
    </View>
)

const TableHeader = ({ cols }) => (
    <View fixed style={{ flexDirection: 'row' }}>
        {cols.map((col, i) => (
            <View key={col.id} style={{ backgroundColor: '#2B5798', paddingLeft: 5, paddingRight: 0, paddingTop: 2, paddingBottom: 2, flex: col.flex, border: 1, borderLeft: i == 0 ? 1 : 0, borderColor: '#2B5798' }}>
                <Text style={{ fontFamily: 'Montserrat', fontSize: 10, lineHeight: 0, color: 'white', textAlignment: 'center', textTransform: 'uppercase', margin: 0, padding: 0 }}>{col.name}</Text>
            </View>
        ))}
    </View>
)

const TableRow = ({ cols, rows, rowBorder, normalPaddingTop }) => (
    <View style={{ backgroundColor: '#2B579830', borderBottom: 1, borderColor: '#2B5798', overflow: 'hidden' }}>
        {rows.map(row => (
            <View wrap={false} style={{ flexDirection: 'row', border: 1, borderTop: 0, borderBottom: rowBorder ? '1px solid #2B5798' : 0, borderColor: '#2B5798' }}>
                {cols.map((col, i) => (
                    <View key={col.id} style={{ flex: col.flex, minHeight: 22 + 10, borderLeft: i == 0 ? 0 : 1, paddingLeft: 1, paddingRight: 1, borderColor: '#2B5798', paddingTop: normalPaddingTop ? 2 : 10, justifyContent: 'center', alignItems: col.alignment }}>
                        <Text style={{ fontSize: 11, paddingHorizontal: 4, fontFamily: 'Roboto Mono' }}>
                            {Object.keys(row).length > 0 ? (
                                col.render(row)
                            ) : ''}
                        </Text>
                    </View>
                ))}
            </View>
        ))}
    </View>
)

const Table = ({ cols, rows, normalPaddingTop, rowBorder }) => (
    <View>
        <TableHeader cols={cols} />
        <TableRow cols={cols} rows={rows} normalPaddingTop={normalPaddingTop} rowBorder={rowBorder} />
    </View>
)


export function RomarioShopInvoicePdf({ invoice, showImage, showRRP, showMemo, showHsCode, showCoo }) {
    return (
        <Document>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <Image fixed src={bg} style={styles.pageBackground} />

                <View fixed style={{ flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginTop: 12, marginBottom: 14 }}>
                    <Image style={{ width: 250, objectFit: 'contain', objectPosition: 'center' }} src={logo} />
                </View>

                <View style={{ paddingHorizontal: 42, flex: 1 }}>
                    <Text style={{ fontFamily: 'Montserrat', fontWeight: 600, marginTop: 14, fontSize: 20, color: '#203861', }}>TAX INVOICE</Text>
                    <Text style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 12, marginTop: -6, marginBottom: 16 }}>TRN: {invoice.systemCompany.trn}</Text>

                    <View style={{ marginBottom: 16 }}>
                        <View style={{ flexDirection: 'row', }}>
                            <View style={{ flex: 4 }}>
                                <TableValueField label={"Customer Name"}>{invoice.customerName ?? ''}</TableValueField>
                            </View>
                            <View style={{ flex: 1 }}>
                                <TableValueField textAlign={"center"} label={"Voucher no"}>{invoice.invoiceNo}</TableValueField>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', }}>
                            <View style={{ flex: 4 }}>
                                <TableValueField label={"Customer TRN"}>{invoice.customerTrn ?? ''}</TableValueField>
                            </View>
                            <View style={{ flex: 1 }}>
                                <TableValueField textAlign={"center"} label={"Date"}>{Util.formatDate(invoice.date, "DD.MM.YY")}</TableValueField>
                            </View>
                        </View>
                    </View>

                    <Table cols={COLS(showImage, showRRP, showHsCode, showCoo)} rows={
                        invoice.items
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            // .concat(invoice.items)
                            .concat([{}, {}])
                    } />
                    <View wrap={false} style={{ flexDirection: 'row', marginBottom: 0 }}>
                        <View style={{ flex: 3, paddingTop: 16, paddingRight: 16 }}>
                            <View style={{ backgroundColor: '#2B579830', paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2, marginBottom: 8 }}>
                                <Text style={{ fontSize: 10, fontFamily: "Montserrat", fontWeight: 600 }}>
                                    AMOUNT IN WORDS: {numToWord(invoice.totals.grandTotal)}
                                </Text>
                            </View>

                            {showMemo && <TableValueField label={"Memo"}>
                                {invoice.memo}
                            </TableValueField>}
                            <TableValueField label={"Bank Details"}>
                                {`Account Name: ${invoice.systemCompany.name}
                                Bank Name: ${invoice.systemCompany.bankName}
                                Branch Name: ${invoice.systemCompany.branch}
                                Swift Code: ${invoice.systemCompany.swiftCode}
                                Account Number: ${invoice.systemCompany.bankAccountNo}
                                IBAN Number: ${invoice.systemCompany.iban}`}
                            </TableValueField>
                        </View>
                        <View style={{ flex: 2, }}>
                            <View style={{ backgroundColor: '#2B579810', border: '1px solid #2B5798', borderTop: 0, alignItems: 'center', paddingVertical: 7, paddingHorizontal: 15, flexDirection: 'row' }}>
                                <Text style={{ fontSize: 14, fontFamily: 'Montserrat', fontWeight: 600 }}>Subtotal</Text>
                                <Text style={{ fontSize: 12, fontFamily: 'Montserrat', flex: 1, textAlign: 'right' }}>SAR {Util.formatMoney(invoice.totals.subTotal)}</Text>
                            </View>
                            {invoice.totals.vatTotal > 0 &&
                                <View style={{ backgroundColor: '#2B579810', border: '1px solid #2B5798', borderTop: 0, alignItems: 'center', paddingVertical: 7, paddingHorizontal: 15, flexDirection: 'row' }}>
                                    <Text style={{ fontSize: 14, fontFamily: 'Montserrat', fontWeight: 600 }}>VAT</Text>
                                    <Text style={{ fontSize: 12, fontFamily: 'Montserrat', flex: 1, textAlign: 'right' }}>SAR {Util.formatMoney(invoice.totals.vatTotal)}</Text>
                                </View>}

                            <View style={{ backgroundColor: '#2B579810', border: '1px solid #2B5798', borderTop: 0, alignItems: 'center', paddingVertical: 7, paddingHorizontal: 15, flexDirection: 'row' }}>
                                <Text style={{ color: '#2B5798', fontSize: 16, fontFamily: 'Montserrat', fontWeight: 600 }}>Total</Text>
                                <Text style={{ fontSize: 12, fontFamily: 'Montserrat', flex: 1, textAlign: 'right' }}>SAR {Util.formatMoney(invoice.totals.grandTotal)}</Text>
                            </View>
                        </View>
                    </View>

                </View>


                <View fixed style={{ paddingHorizontal: 42, paddingVertical: 12, flexDirection: 'row', alignItems: 'flex-end' }}>
                    <View>
                        <Text style={{ fontSize: 12, fontFamily: 'Montserrat', fontWeight: 600 }}>
                            We specialise in
                        </Text>
                        <Text style={{ fontSize: 8, }}>
                            Retail & Wholesale of all leading brands of
                        </Text>
                        <Text style={{ fontSize: 8, }}>
                            Sports equipment, Sportswear and accessories.
                        </Text>

                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 2, }}>
                            <View style={{ marginRight: 5 }}>
                                <Image src={emailIcon} style={{ width: 25, height: 25, }} />
                            </View>
                            <View style={{ justifyContent: 'center' }}>
                                <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', marginTop: 2 }}>
                                    Email: {invoice.systemCompany.email}
                                </Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', marginTop: -3 }}>
                                    Website: romariosports.com
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1 }} />
                    <View style={{ flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                            <View style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                                <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', marginTop: 2, textAlign: 'right' }}>
                                    Tel: {invoice.systemCompany.tel}
                                </Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', textAlign: 'right', marginTop: -5 }}>
                                    Fax: {invoice.systemCompany.fax}
                                </Text>
                            </View>
                            <View style={{ marginLeft: 5 }}>
                                <Image src={phoneIcon} style={{ width: 25, height: 25 }} />
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 7 }}>
                            <View style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                                <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', marginTop: 2, textAlign: 'right' }}>
                                    {invoice.systemCompany.address}
                                </Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', textAlign: 'right', marginTop: -3 }}>
                                    United Arab Emirates
                                </Text>
                            </View>
                            <View style={{ marginLeft: 5 }}>
                                <Image src={locIcon} style={{ width: 25, height: 25 }} />
                            </View>
                        </View>
                    </View>
                </View>

                <View fixed style={{ position: 'absolute', bottom: 25, left: 0, right: 0, paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 10, textAlign: 'center', fontFamily: "Montserrat", fontWeight: 600 }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                </View>

            </Page>
        </Document >
    )
}