import { withLoadablePage, withLoadablePageWithParams } from "../../base/Page";
import Api from "../../session/Api";

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Delivery32,
    Building32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Currency16,
    Reset16,
    FlowStream16,
    Launch32,
    User32,
    ShoppingCartArrowUp32 as SaleIcon,
    ShoppingCartArrowDown32 as PurchaseIcon,
    CheckmarkOutline16,
    CheckmarkFilled16,
    ErrorFilled16,
    Enterprise32,
    Document32,
    RowDelete16,
    Migrate32,
    Money16,
    Product16,
    ListChecked16,
    Van32
} from '@carbon/icons-react'
import { ButtonSet, ComboBox, ContentSwitcher, DatePicker, DatePickerInput, NumberInput, RadioTile, Switch, Tag, TextArea, TextInput, TileGroup } from "carbon-components-react";
import { getObjectTypeName, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from "../../constants/ObjectTypes";
import { hasCapabilitySupport } from "../../app/Capabilities";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Util, { absAmt, big } from "../../util/Util";
import useStore from "../../hooks/useStore";
import { makeObservable } from "../../util/makeObservable";
import { PURCHASE_AMOUNT_MODE_FREE_FORM, PURCHASE_AMOUNT_MODE_ITEM_BASED } from "../../domain/purchase";
import { TaxInput } from "../stock-flow/TaxInput";

import Big from 'big.js';
import Button from "../../components/Button";
import UIUtil from "../../util/UIUtil";
import { useHistory } from "react-router-dom";
import CustomComboBox from "../../components/CustomComboBox";
import { DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_MOBILE_UNIT, DESTINATION_TYPE_STORE, DESTINATION_TYPE_SUPPLIER, DESTINATION_TYPE_VENUE, DESTINATION_TYPE_WAREHOUSE, LEDGER_TYPE_ASSET, LEDGER_TYPE_EXPENSE, SOURCE_TYPE_SUPPLIER } from "../../constants/Constants";
import { NumberedSections } from "../../components/numbered-sections";
import { DynamicTable } from "../../components/dynamic-table";
import StockItemEditor from "../stock-flow/StockItemEditor";
import ProductFinder from "../../views/product/ProductFinder";
import { BILL_AMOUNT_MODE_FREE_FORM } from "../../domain/bill";


const Root = ({ children }) => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
        <div style={{ width: '75vw' }}>
            <NumberedSections>
                {children}
            </NumberedSections>
        </div>
    </div>
)

const Section = ({ children, icon, title, options, extraTopMargin }) => (
    // <div style={{ marginTop: extraTopMargin ? '6rem' : '5rem', borderTop: 'solid', paddingTop: '1rem', borderWidth: 1, borderColor: '#00000060' }}>
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem', }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ flex: 1 }}>{title}</p>
            {options}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (<div style={{ display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
        {React.createElement(icon)}
        <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
    </div>
    <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
        {desc}
    </p>
</div>)

const Title = () => (
    <div>
        <h1>Purchase</h1>
        <p style={{ fontSize: 18 }}>Creating new</p>
    </div>
)

const SelectSupplier = ({ store, endpoints }) => {
    const [id, setId] = useStore(store, 'supplierId')
    const [pickerKey, setPickerKey] = useState(Util.newTempId())

    const onPickerChange = e => {
        setId(e.selectedItem !== null ? e.selectedItem.id : 0)
        if (e.selectedItem === null) {
            setPickerKey(Util.newTempId())
        }
    }

    return (
        <ComboBox
            key={pickerKey}
            titleText={'Supplier'}
            items={endpoints.suppliers}
            itemToString={item => item !== null ? item.value : ""}
            selectedItem={endpoints.suppliers.filter(item => item.id == id)[0]}
            onChange={onPickerChange}
        />
    )
}

const PurchaseDetails = ({ store }) => {
    const [invoiceNo, setInvoiceNo] = useStore(store, 'invoiceNo');
    const [invoiceDate, setInvoiceDate] = useStore(store, 'invoiceDate');

    let invoiceDateValue = invoiceDate;
    if (!Util.isNumberExist(invoiceDateValue)) {
        invoiceDateValue = undefined;
    }
    return (
        <>
            <div style={{ marginBottom: '1rem' }}>
                <TextInput
                    labelText={"Supplier Invoice No. (optional)"}
                    value={invoiceNo}
                    onChange={e => setInvoiceNo(e.target.value)}
                />
            </div>

            <div style={{ marginBottom: '1rem' }}>
                <DatePicker datePickerType="single"
                    dateFormat="d/m/Y"
                    //key={this.state.supplierDateKey}
                    value={invoiceDateValue}
                    onChange={e => setInvoiceDate(e.length > 0 ? e[0].getTime() : undefined)}
                >
                    <DatePickerInput
                        //placeholder="mm/dd/yyyy"
                        placeholder="dd/mm/yyyy"
                        labelText={"Supplier Invoice Date (optional)"}
                    />
                </DatePicker>
            </div>
        </>
    )
}

const calcAmount = (subtotal, productSubtotal, tax) => big(subtotal).add(big(productSubtotal).add(big(tax)));

// const useExpenseAmount = (store) => {
//     const [subtotal, setSubtotal] = useStore(store, "subtotal");
//     return [calcAmount(subtotal, tax).toFixed(2), () => {
//         setSubtotal(0)
//         setTax(0)
//     }];
// }

const FreeFormAmount = ({ store }) => {
    const [subtotal, setSubtotal] = useStore(store, "subtotal");
    // const [tax, setTax] = useStore(store, "tax");

    const parseVal = val => {
        const parsed = parseFloat(val);
        return isNaN(parsed) ? 0 : parsed;
    }
    return (
        <div>
            <div style={{ marginBottom: '1rem' }}>
                <label className="bx--label">{'Amount (without tax)'}</label>
                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                    <p>SAR</p>
                    <TextInput
                        size="lg" hideSteppers min={0} invalidText="Invalid numeric value"
                        value={subtotal} onChange={e => setSubtotal(e.target.value < 0 ? 0 : parseVal(e.target.value))} />
                </div>
            </div>

            <div style={{ marginBottom: '1rem' }} />
            {/* <div style={{ marginBottom: '1rem' }}>
                <TaxInput
                    amount={subtotal}
                    taxValue={tax} onTaxValueChange={setTax} />
            </div> */}
        </div>
    )
}

const ItemRecord = ({ onRemove, item, setItem, last }) => {
    const total = useMemo(() => big(item.qty).times(big(item.unitAmount)).toFixed(2), [item])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', }}>
            <div style={{ flex: 2, }}>
                <TextInput value={item.description} onChange={e => setItem({ ...item, description: e.target.value })} placeholder="Input..." />
            </div>
            <div style={{ flex: 2, }}>
                <TextInput value={item.unitAmount} onChange={e => setItem({ ...item, unitAmount: absAmt(e.target.value) })} placeholder="Input..." />
            </div>
            <div style={{ flex: 1, }}>
                <TextInput value={item.qty} onChange={e => setItem({ ...item, qty: absAmt(e.target.value) })} placeholder="Input..." />
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 0.65, fontWeight: 'bold' }}>SAR {total}</p>
            </div>
            <Button hasIconOnly iconDescription="Remove Row" onClick={onRemove} renderIcon={RowDelete16} kind="danger"
                size="sm" disabled={last}
                style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} tooltipPosition="left" tooltipAlignment="end" />
        </div>
    )
}

const ItemsAmount = ({ store }) => {
    const [subtotal, setSubtotal] = useStore(store, "subtotal");
    const [items, setItems] = useStore(store, "items")

    const updateItem = item => {
        const newItems = [...items]
        const index = newItems.findIndex(i => i.tempId === item.tempId);
        if (index >= 0) {
            newItems[index] = item;
            setItems(newItems)
        }
    }

    const removeItem = item => setItems(items.filter(i => i.tempId !== item.tempId))

    useEffect(() => {
        const lastItem = items[items.length - 1];
        if (Object.keys(lastItem).length > 1) {
            setItems([...items, { tempId: Util.newTempId() }])
        }

        setSubtotal(items
            .slice(0, items.length - 1)
            .map(item => big(item.qty).times(big(item.unitAmount)))
            .reduce((t, c) => t.add(c), big(0)))
    }, [items])

    useEffect(() => () => setItems([{ tempId: Util.newTempId() }]), [])

    return (
        <div>
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 2, paddingLeft: '1rem', }}>
                        <h6>Description</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '1rem', }}>
                        <h6>Unit Amount</h6>
                    </div>
                    <div style={{ flex: 1, paddingLeft: '1rem', }}>
                        <h6>Qty</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '1rem', }}>
                        <h6>Total</h6>
                    </div>
                    <div style={{ width: 40, height: 0, }} />
                </div>
                {items.map((item, i) =>
                    <ItemRecord key={item.tempId} item={item} setItem={updateItem} last={i === items.length - 1} onRemove={() => removeItem(item)} />)}
            </div>

            <div style={{ marginBottom: '1rem' }} />
            {/* <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <TaxInput
                    amount={subtotal}
                    taxValue={tax} onTaxValueChange={setTax} />
            </div> */}
        </div>
    )
}

const ExpenseAmount = ({ store }) => {
    const [mode, setMode] = useStore(store, "amountMode")
    const [subtotal, setSubtotal] = useStore(store, "subtotal");

    useEffect(() => {
        setSubtotal(0)
    }, [mode])
    return (
        <>
            <label className="bx--label">Amount Mode</label>
            <ContentSwitcher selectedIndex={mode} onChange={e => setMode(e.name)}>
                <Switch name={PURCHASE_AMOUNT_MODE_FREE_FORM} text="Free-Form" />
                <Switch name={PURCHASE_AMOUNT_MODE_ITEM_BASED} text="List Based" />
            </ContentSwitcher>

            <div style={{ height: '1rem' }} />
            {mode == PURCHASE_AMOUNT_MODE_FREE_FORM ?
                <FreeFormAmount store={store} /> :
                <ItemsAmount store={store} />}

            {mode == PURCHASE_AMOUNT_MODE_ITEM_BASED && <>
                <div style={{ height: '1rem' }} />
                <h5>Total Expense Amount</h5>
                <h2>SAR {(subtotal ?? 0).toFixed(2)}</h2>
            </>}
        </>
    )
}

const Amount = ({ store }) => {
    const [productSubtotal] = useStore(store, "productSubtotal");
    const [subtotal] = useStore(store, "subtotal");
    const [tax, setTax] = useStore(store, "tax");

    const totalSubtotal = big(subtotal).add(big(productSubtotal));
    const total = big(tax).add(totalSubtotal)
    return (
        <div style={{ marginBottom: '1rem' }}>
            <div style={{ marginBottom: '1rem' }}>
                <label className="bx--label">{'Subtotal (without tax)'}</label>
                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                    <p>SAR</p>
                    <TextInput
                        disabled
                        size="lg" hideSteppers min={0}
                        value={totalSubtotal} />
                </div>
            </div>

            <TaxInput
                amount={totalSubtotal}
                taxValue={tax} onTaxValueChange={setTax} />

            <div style={{ height: '1rem' }} />
            <h5>Total Amount</h5>
            <h2>SAR {total.toFixed(2)}</h2>
        </div>
    )
}

const ExpenseAccountRecord = ({ onRemove, item, setItem, last, accountTreeItems }) => {
    const [selectedAccount, setSelectedAccount] = useState(undefined)
    useEffect(() => {
        const newItem = { ...item, }
        if (selectedAccount) {
            newItem.accountLedgerId = selectedAccount.id
        } else {
            delete newItem.accountLedgerId
        }
        setItem(newItem)
        // setItem({ ...item, accountLedgerId: selectedAccount ? selectedAccount.id : undefined })
    }, [selectedAccount])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', }}>
            <div style={{ flex: 3, }}>
                {/* <TextInput value={item.description} onChange={e => setItem({ ...item, description: e.target.value })} placeholder="Input..." /> */}
                <div style={{ height: 40 }}>
                    <CustomComboBox
                        items={accountTreeItems}
                        selectedItem={selectedAccount}
                        onSelectedItemUpdate={item => setSelectedAccount(item)}
                    // selectedItem={this.state.otherPartyIdObject}
                    // onSelectedItemUpdate={item => this.setState({ otherPartyIdValue: item !== undefined ? item.id : 0, otherPartyIdObject: item })}
                    />
                </div>
            </div>
            <div style={{ flex: 2, }}>
                <TextInput value={item.amount} onChange={e => setItem({ ...item, amount: absAmt(e.target.value) })} placeholder="Input..." />
            </div>
            <div style={{ flex: 4, }}>
                <TextInput value={item.narration} onChange={e => setItem({ ...item, narration: e.target.value })} placeholder="Input..." />
            </div>
            <Button hasIconOnly iconDescription="Remove Row" onClick={onRemove} renderIcon={RowDelete16} kind="danger"
                size="sm" disabled={last}
                style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} tooltipPosition="left" tooltipAlignment="end" />
        </div>
    )
}

const ExpenseAccountsTable = ({ store, endpoints }) => {
    const [total, setTotal] = useStore(store, "expenseAccountsTotal");
    const [items, setItems] = useStore(store, "expenseAccounts")

    const updateItem = item => {
        const newItems = [...items]
        const index = newItems.findIndex(i => i.tempId === item.tempId);
        if (index >= 0) {
            newItems[index] = item;
            setItems(newItems)
        }
    }

    const removeItem = item => setItems(items.filter(i => i.tempId !== item.tempId))

    useEffect(() => {
        const lastItem = items[items.length - 1];
        if (Object.keys(lastItem).length > 1) {
            setItems([...items, { tempId: Util.newTempId() }])
        }

        setTotal(items
            .slice(0, items.length - 1)
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)))
    }, [items])

    useEffect(() => () => setItems([{ tempId: Util.newTempId() }]), [])

    // const accountTreeItems = endpoints.accountTree.filter(item => item.id == LEDGER_TYPE_EXPENSE)[0].items
    const accountTreeItems = useMemo(() => {
        const accountTree = [...endpoints.accountTree];
        for (const group of accountTree) {
            group.items = group.items.filter(item => item.name != "Inventory");
        }
        return endpoints.accountTree.filter(item => item.id == LEDGER_TYPE_EXPENSE || item.id == LEDGER_TYPE_ASSET)
    }, [endpoints])

    return (
        <div>
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Account</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                    <div style={{ flex: 4, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Narration</h6>
                    </div>
                    <div style={{ width: 40, height: 0, }} />
                </div>
                {items.map((item, i) =>
                    <ExpenseAccountRecord key={item.tempId} accountTreeItems={accountTreeItems} item={item} setItem={updateItem} last={i === items.length - 1} onRemove={() => removeItem(item)} />)}
            </div>

        </div>
    )
}

const ExpenseAccounts = ({ store, endpoints }) => {
    const [subtotal] = useStore(store, "subtotal");
    const [total, setTotal] = useStore(store, "expenseAccountsTotal");

    const totalAboveSubtotal = useMemo(() => big(total).gt(big(subtotal)), [total, subtotal])
    // const hasAnyAmount = useMemo(() => big(subtotal).gt(big(0)), [subtotal])

    return (<>
        <div style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center' }}>
            {totalAboveSubtotal ? (
                <label style={{ color: 'red', flex: 1 }} className="bx--label">Account amounts total is greater than expensable amount!</label>
            ) : (
                <label style={{ flex: 1 }} className="bx--label">Any amount not accounted for will be recorded under misc expenses</label>
            )}
            <Tag renderIcon={Money16} type="purple">Expensable Amount: SAR {big(subtotal).toFixed(2)}</Tag>
        </div>
        <ExpenseAccountsTable store={store} endpoints={endpoints} />
    </>)
}

const SelectDestination = ({ store, endpoints }) => {
    const [id, setId] = useStore(store, 'destinationId')
    const [type, setType] = useStore(store, 'destinationType')
    const [pickerKey, setPickerKey] = useState(() => Util.newTempId());

    useEffect(() => {
        setId(0)
        setPickerKey(Util.newTempId())
    }, [type])

    const getDestinationTypeName = () => {
        switch (type) {
            case DESTINATION_TYPE_WAREHOUSE:
                return "Warehouse";
            case DESTINATION_TYPE_STORE:
                return "Store";
            case DESTINATION_TYPE_VENUE:
                return "Venue"
            case DESTINATION_TYPE_MOBILE_UNIT:
                return "Mobile Unit";
        }
        return undefined;
    }

    const getDestinationTypeList = () => {
        switch (type) {
            case DESTINATION_TYPE_WAREHOUSE:
                return endpoints.warehouses;
            case DESTINATION_TYPE_STORE:
                return endpoints.stores;
            case DESTINATION_TYPE_VENUE:
                return endpoints.venues;
            case DESTINATION_TYPE_MOBILE_UNIT:
                return endpoints.mobileUnits;
        }

        return undefined;
    }

    return (
        <>
            <TileGroup className={"horizontal-tile-radio"}
                valueSelected={type} onChange={setType}>
                <RadioTile value={DESTINATION_TYPE_WAREHOUSE}>
                    <RadioItem icon={DataVis_232} title="Warehouse" desc="Transfer stock to warehouse" />
                </RadioTile>
                <RadioTile value={DESTINATION_TYPE_STORE}>
                    <RadioItem icon={Store32} title="Store" desc="Transfer stock to store" />
                </RadioTile>
                {hasCapabilitySupport("mobileUnit") &&
                    <RadioTile value={DESTINATION_TYPE_MOBILE_UNIT}>
                        <RadioItem icon={Van32} title="Mobile Unit" desc="Transfer stock to mobile unit" />
                    </RadioTile>}
                {hasCapabilitySupport("thirdPartyPos") &&
                    <RadioTile value={DESTINATION_TYPE_VENUE}>
                        <RadioItem icon={Building32} title="Venue" desc="Transfer stock to venue" />
                    </RadioTile>}
            </TileGroup>

            {getDestinationTypeList() !== undefined && <>
                <div style={{ height: '1rem' }} />
                <ComboBox
                    key={pickerKey}
                    titleText={getDestinationTypeName()}
                    items={getDestinationTypeList()}
                    itemToString={item => item !== null ? item.value : ""}
                    selectedItem={getDestinationTypeList().filter(item => item.id == id)[0]}
                    onChange={e => {
                        setId(e.selectedItem?.id ?? 0);
                        if (!e.selectedItem) {
                            setPickerKey(Util.newTempId())
                        }
                    }} />
            </>}
        </>
    )
}

const Products = ({ store }) => {
    const [subtotal, setSubtotal] = useStore(store, "productSubtotal");
    const [products, setProducts] = useStore(store, 'products')
    const editorRef = useRef()

    const addMultipleProductToStockItemEditor = (products) => {
        editorRef.current.addMultipleItems(products.map(product => ({
            id: Util.newTempId(), itemId: product.id, itemType: OBJECT_TYPE_PRODUCT
        })))
    }

    return (<>
        <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '1rem' }}>
            <label className="bx--label" style={{ marginBottom: '0.5rem', fontSize: 12, flex: 1 }}>Item costs should NOT include tax</label>
            <Button kind="secondary" renderIcon={ListChecked16} onClick={() => {
                UIUtil.showOverlay2(onClose => <ProductFinder bulkSelect onBulkSelect={addMultipleProductToStockItemEditor} onClose={onClose} />)
            }}>Add multiple products</Button>
        </div>
        <StockItemEditor
            multipleInputEditorRef={editorRef}
            defaultItems={products}
            stockItemMode={{
                //destination always store since it will always be a purchase so it does not really matter ??
                destinationType: DESTINATION_TYPE_STORE, sourceType: SOURCE_TYPE_SUPPLIER, hideLabelBtn: false,
                stockFlow: { destinationType: DESTINATION_TYPE_STORE, sourceType: SOURCE_TYPE_SUPPLIER }
            }}
            onItemsUpdated={products => {
                setProducts(products)
                setSubtotal(products
                    .map(item => big(item.quantityValue).times(big(item.amount)))
                    .reduce((t, c) => t.add(c), big(0)))
            }}
        />

        <div style={{ height: '1rem' }} />
        <h5>Total Product Amount</h5>
        <h2>SAR {subtotal.toFixed(2)}</h2>
    </>)
}

const AdditionalInformation = ({ store }) => {
    const [info, setInfo] = useStore(store, 'info')
    return (
        <TextArea placeholder="Note here..." value={info} onChange={e => setInfo(e.target.value)} />
    )
}


function initState(observable) {
    //stock flow
    observable.set("supplierId", 0)
    observable.set("products", [])
    observable.set("destinationId", 0)
    observable.set("destinationType", DESTINATION_TYPE_WAREHOUSE)
    observable.set("invoiceNo", "")
    observable.set("invoiceDate", undefined)
    observable.set("productSubtotal", 0)

    //bill
    observable.set("amountMode", BILL_AMOUNT_MODE_FREE_FORM)
    observable.set("items", [{ tempId: Util.newTempId() }])
    observable.set("expenseAccounts", [{ tempId: Util.newTempId() }])
    observable.set("expenseAccountsTotal", 0)
    observable.set("subtotal", 0)

    //purchase
    observable.set("tax", 0)
    observable.set("info", "")
}

function validate(store) {
    const {
        subtotal, productSubtotal, tax, supplierId, expenseAccountsTotal, products
    } = store.toObject();
    const amount = calcAmount(subtotal, productSubtotal, tax);
    if (big(amount).lte(big(0))) {
        return "Total amount has to be a positive number";
    }

    if (big(expenseAccountsTotal).gt(big(subtotal))) {
        return "Account amounts total is greater than expensable amount (amount - tax)!"
    }

    if (supplierId <= 0) {
        return "Supplier not selected";
    }

    if (products.find(item => isNaN(item.amount))) {
        return "Invalid numeric value specified in product list"
    }

    return undefined;
}

function toPurchase(store) {
    /*
//stock flow
    
    observable.set("products", [])
    
    
    
    */

    const state = store.toObject();
    const createBill = big(state.subtotal).gt(big(0)) || state.items.length > 1; //since last one is a dummy
    const createStockFlow = big(state.productSubtotal).gt(big(0)) || state.products.length > 0;
    return {
        billReq: createBill ? {
            payeeType: OBJECT_TYPE_SUPPLIER,
            payeeId: state.supplierId,
            accountDivisions: state.expenseAccounts.slice(0, state.expenseAccounts.length - 1),
            items: state.items.slice(0, state.items.length - 1),
            subtotal: big(state.subtotal).toFixed(2),
            tax: 0,
        } : null,
        stockFlowReq: createStockFlow ? {
            sourceType: SOURCE_TYPE_SUPPLIER,
            sourceId: state.supplierId,

            destinationType: state.destinationType,
            destinationId: state.destinationId,

            items: state.products.map(item => ({ itemId: item.itemId, itemType: item.itemType, quantityValue: item.quantityValue, amount: item.amount, serialNumber: item.serialNumber })),

            purchaseSupplierInvoiceNo: state.invoiceNo,
            purchaseSupplierInvoiceDate: state.invoiceDate,

            salesAmountSubtotal: big(state.productSubtotal).toFixed(2),
            amount: big(state.productSubtotal).toFixed(2),

            amountAdditionalCosts: big(0),
            salesAmountDiscount: big(0),
            salesAmountTax: big(0),
            transactionPaymentMethods: [],
        } : null,

        supplierId: state.supplierId,
        subtotal: big(state.subtotal).add(big(state.productSubtotal)).toFixed(2),
        tax: big(state.tax).toFixed(2),
        info: state.info,
    }
}

const Confirm = ({ store, onReset }) => {
    const [creating, setCreating] = useState(false);
    const history = useHistory();

    const createPurchase = () => {
        const err = validate(store);
        if (err) {
            UIUtil.showError(err);
            return;
        }

        setCreating(true);
        Api.createPurchase(toPurchase(store), response => {
            setCreating(false)

            if (response.status === true) {
                history.replace("/purchase/" + response.payload)
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonSet style={{ width: 392 }}>
                <Button onClick={onReset} disabled={creating} kind="secondary" renderIcon={Reset16}>Reset to Defaults</Button>
                <Button onClick={() => createPurchase()} loading={creating} renderIcon={Migrate32}>Create Purchase</Button>
            </ButtonSet>
        </div>
    )
}



// const columns = [
//     {
//         flex: 1,
//         title: "Account"
//     },
//     {
//         flex: 2,
//         title: 'input'
//     }
// ]

// const TestEntry = ({ item, useValue, ...props }) => {
//     const [value, setValue] = useValue("value", "");
//     return [
//         <p>works: {false} - {JSON.stringify(props)}</p>,
//         <input placeholder="input me" value={value} onChange={e => setValue(e.target.value)} />
//     ]
// }

// const Test = () => {
//     const [items, setItems] = useState([])
//     return (
//         <DynamicTable entryView={TestEntry} items={items} setItems={setItems} editable columns={columns}
//             entryViewProps={{ works: true }} />
//     )
// }

const View = ({ payload: endpoints }) => {
    const [rootKey, setRootKey] = useState(() => Util.newTempId());

    const store = useMemo(() => {
        const observable = makeObservable();
        initState(observable);
        return observable;
    }, [])


    const onReset = () => {
        initState(store);
        setRootKey(Util.newTempId())
    }

    return (
        <Root key={rootKey}>
            <Title />
            {['Select Supplier',
                <SelectSupplier store={store} endpoints={endpoints} />]}
            {['Select Destination',
                <SelectDestination store={store} endpoints={endpoints} />]}

            {['Supplier Purchase Details',
                <PurchaseDetails store={store} />]}

            {['Inventory Products/Bundles',
                <Products store={store} />]}

            {['Set Expense Amount',
                <ExpenseAmount store={store} />]}

            {/* {['Specify Expense Accounts (optional)',
                <ExpenseAccounts store={store} endpoints={endpoints} />]} */}
            {['Specify Accounts (optional)',
                <ExpenseAccounts store={store} endpoints={endpoints} />]}

            {['Amount',
                <Amount store={store} />]}

            {['Additional Information (optional)',
                <AdditionalInformation store={store} />]}
            {['Confirm', <Confirm store={store} onReset={onReset} />, { extraTopMargin: true }]}

        </Root>
    )
}

export default withLoadablePage(Api.getStockFlowEndpointsList.bind(Api), View);