import useStore from "../../../hooks/useStore"
import Util from "../../../util/Util"


export function Totals({ sessionStore, }) {
    const [totals] = useStore(sessionStore, "posTotals")

    return <>
        <div className="bx--structured-list-th dashboard-card" style={{ display: 'flex', paddingLeft: '1rem', borderBottom: '1px solid #e0e0e0', background: '#f4f4f4', }}>
            <div style={{ flex: 3 }}>
                Totals
            </div>
        </div>

        <div className="payment-widget" style={{
            width: '100%', height: '4rem', padding: '1rem', overflow: 'hidden', color: 'white',
            display: 'flex', alignItems: 'center',
        }}>

            <div style={{ marginRight: '1rem' }}>
                <p style={{ fontSize: 14 }}>Subtotal (+)</p>
                <h5>{Util.formatMoney(totals.subtotal)}</h5>
            </div>

            <div style={{ marginRight: '1rem' }}>
                <p style={{ fontSize: 14 }}>Deductions (-)</p>
                <h5>{Util.formatMoney(totals.discount)}</h5>
            </div>

            <div style={{ marginRight: '1rem' }}>
                <p style={{ fontSize: 14 }}>Tax (+)</p>
                <h5>{Util.formatMoney(totals.tax)}</h5>
            </div>


            <div style={{ display: 'flex', alignItems: 'center', gap: 15, flex: 1, justifyContent: 'flex-end' }}>
                <h1 style={{ color: 'white', fontWeight: 'bold', width: '100%', lineHeight: 1, textAlign: 'end' }}>
                    SAR {Util.formatMoney(totals.total)}
                </h1>
            </div>
        </div>
    </>
}