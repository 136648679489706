
import useEmblaCarousel from 'embla-carousel-react'
import ProfilePic from '../../components/ProfilePic'
import { OBJECT_TYPE_ACCOUNT } from '../../constants/ObjectTypes'
import Api from '../../session/Api'
import { ArrowLeft16, ArrowRight16 } from '@carbon/icons-react'
import { useEffect, useMemo, useState } from 'react'
import Util from '../../util/Util'


const AgentCard = ({ agent, selected }) => (
    <div style={{ width: '100%', height: '100%', paddingInline: '3rem', paddingBlock: '3rem' }}>
        <div style={{ padding: '1rem', width: '100%', height: '100%', background: 'white', borderRadius: 25, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', transition: 'transform 250ms', transform: selected ? 'scale(1.05) translateY(-15px)' : 'scale(1) translateY(0px)' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                <div style={{ flex: 1, height: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', }}>
                        <div style={{ flex: 1 }}>
                            <h5 style={{ fontWeight: 'bold', opacity: 0.25 }}>Agent</h5>
                            <h2 style={{ fontWeight: 'bold' }}>{agent.fullName}</h2>
                        </div>
                        <div style={{ flex: 1 }}>
                            <h5 style={{ fontWeight: 'bold', opacity: 0.25 }}>Properties</h5>
                            <h2 style={{ fontWeight: 'bold' }}>{agent.properties}</h2>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '3rem', }}>
                        <div style={{ flex: 1, }}>
                            <h5 style={{ fontWeight: 'bold', opacity: 0.25 }}>Commission this month</h5>
                            <h2 style={{ fontWeight: 'bold' }}>SAR {Util.formatMoney(agent.commission)}</h2>
                        </div>
                        <div style={{ flex: 1 }}>
                            <h5 style={{ fontWeight: 'bold', opacity: 0.25 }}>Active Leads</h5>
                            <h2 style={{ fontWeight: 'bold' }}>{agent.activeLeads + ''}</h2>
                        </div>
                    </div>
                </div>
                <ProfilePic src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, agent.id)} size={128} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)' }} />
            </div>
        </div>
    </div>
)

export function AgentCards({ dashboard }) {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true
    })

    const isSelected = index => selectedIndex === index

    useEffect(() => {
        if (emblaApi) {
            emblaApi.on('select', () => setSelectedIndex(emblaApi.selectedScrollSnap()))
        }
    }, [emblaApi])

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div className='align-slider'>
                <div className="embla">
                    <div className="embla__viewport" ref={emblaRef}>
                        <div className="embla__container">
                            {dashboard.agents.map((agent, index) => (
                                <div className="embla__slide" key={index}>
                                    <AgentCard agent={agent} selected={isSelected(index)} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', borderRadius: 50, paddingInline: '1rem', height: 50, background: '#1f1f1f', border: '1px solid #ffffff40', left: '50%', bottom: 0, transform: 'translate(-50%, 50%)', }}>
                <button onClick={emblaApi?.scrollPrev} className='lead-flow-icon-button lead-flow-icon-button-secondary-2' style={{ marginRight: '1rem' }}>
                    <ArrowLeft16 />
                </button>

                {dashboard.agents.map((agent, index) => (
                    <div onClick={() => emblaApi.scrollTo(index)} style={{ width: 54, height: 54, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ProfilePic key={index} size={isSelected(index) ? 54 : 36} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, agent.id)} style={{ border: isSelected(index) ? '2px solid #0f62fe' : '2px solid #ffffff', transition: '250ms' }} />
                    </div>
                ))}

                <button onClick={emblaApi?.scrollNext} className='lead-flow-icon-button lead-flow-icon-button-secondary-2' style={{ marginLeft: '1rem' }}>
                    <ArrowRight16 />
                </button>
            </div>
        </div>
    )

    return (
        //<div className="embla" ref={emblaRef} style={{ width: '100%', height: '100%', paddingBlock: '3rem', paddingInline: '6rem' }}>
        <div className="embla" ref={emblaRef} style={{ width: '100%', height: '100%' }}>
            <div className="embla__container">
                <div className="embla__slide"><AgentCard /></div>
                <div className="embla__slide"><AgentCard /></div>
                <div className="embla__slide"><AgentCard /></div>
            </div>
        </div>
    )
}